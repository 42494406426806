import {
  addIndex,
  equals,
  match,
  modulo,
  compose,
  reduce,
  __,
  pipe,
} from 'ramda';

import { fetchValue } from './util';

const prefixWeights = [10, 5, 8, 4, 2, 1];
const baseWeights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
const prefixLength = prefixWeights.length;
const baseLength = baseWeights.length;
const accountFormatRegex = /^((\d{1,6})-)?(\d{2,10})$/;
const baseFormatRegex = /^([0]*[1-9]\d*[1-9]\d*)$/;

const reduceIndexed = addIndex(reduce);

const isValidWeightSum = ({ weights, number, length }) => {
  const prefixWithPad = number.padStart(length, '0');
  return compose(
    equals(0),
    modulo(__, 11),
    reduceIndexed((acc, val, index) => acc + val * prefixWithPad[index], 0)
  )(weights);
};

const isValidBankAccount = (bankAccountNumber) => {
  const [, , prefix, base] = match(accountFormatRegex, bankAccountNumber);
  if (!base) return false;

  const isValidBaseFormat = baseFormatRegex.test(base);
  if (!isValidBaseFormat) return false;

  if (prefix) {
    const isValidPrefixSum = isValidWeightSum({
      weights: prefixWeights,
      number: prefix,
      length: prefixLength,
    });
    if (!isValidPrefixSum) return false;
  }

  return isValidWeightSum({
    weights: baseWeights,
    number: base,
    length: baseLength,
  });
};

export default pipe(fetchValue, isValidBankAccount);
