import { createAction } from 'redux-actions';

const namespace = 'crdf.auth.account';

export const FETCH_ACCOUNT = `${namespace}.fetchAccount`;
export const FETCH_ACCOUNT_SUCCESS = `${namespace}.fetchAccountSuccess`;
export const FETCH_ACCOUNT_ERROR = `${namespace}.fetchAccountError`;

export const fetchAccount = createAction(FETCH_ACCOUNT);
export const fetchAccountSuccess = createAction(FETCH_ACCOUNT_SUCCESS);
export const fetchAccountError = createAction(FETCH_ACCOUNT_ERROR);
