import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '../../../../components/Grid';
import Button from '../../../../components/Button';
import Heading from '../../../../components/text/Heading';
import TextField from '../../../forms/fields/textField';
import CheckboxField from '../../../forms/fields/checkbox';
import Spacing from '../../../../components/layout/Spacing';
import CountrySelectField from '../../../forms/fields/countrySelect';
import {
  contextSelector,
  formSelector,
} from '../../ActiveJourneyProvider/selectors';
import {
  formMount,
  formUnmount,
  submitContactDataForm,
} from '../../ActiveJourneyProvider/actions';
import messages from './messages';
import validator from './validators';

const ContactDataForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { isInitialized, contactData } = useSelector(contextSelector);
  const { isSubmitting } = useSelector(formSelector);

  useEffect(() => {
    dispatch(formMount());

    return () => dispatch(formUnmount());
  }, []);

  if (!isInitialized) return null;

  return (
    <Formik
      validateOnBlur
      initialValues={contactData}
      validate={validator({ intl })}
      onSubmit={(values) => dispatch(submitContactDataForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <Grid gap={8}>
            <TextField
              required
              name="street"
              label={formatMessage(messages.streetLabel)}
            />
            <TextField
              required
              name="city"
              label={formatMessage(messages.cityLabel)}
            />
            <TextField
              required
              name="zipCode"
              filterPattern={/^[0-9]+$/}
              label={formatMessage(messages.postalCodeLabel)}
            />
            <CountrySelectField
              required
              name="countryCode"
              label={formatMessage(messages.countryLabel)}
            />
            <CheckboxField
              name="hasDifferentContactAddress"
              label={formatMessage(messages.hasDifferentContactAddressLabel)}
            />
            {formik.values.hasDifferentContactAddress && (
              <>
                <Heading variant="subtitle">
                  <FormattedMessage {...messages.contactAddressTitle} />
                </Heading>
                <TextField
                  required
                  name="contactStreet"
                  label={formatMessage(messages.streetLabel)}
                />
                <TextField
                  required
                  name="contactCity"
                  label={formatMessage(messages.cityLabel)}
                />
                <TextField
                  required
                  name="contactPostalCode"
                  filterPattern={/^[0-9]+$/}
                  label={formatMessage(messages.postalCodeLabel)}
                />
                <CountrySelectField
                  required
                  name="contactCountry"
                  label={formatMessage(messages.countryLabel)}
                />
              </>
            )}
          </Grid>
          <Spacing variant="item" />
          <Grid xs={2} gap={4}>
            <Button
              full
              disabled={isSubmitting}
              variant="gray"
              onClick={() => navigate('../personal')}
              label={formatMessage(messages.backButtonLabel)}
            />
            <Button
              full
              type="submit"
              label={formatMessage(messages.continueButtonLabel)}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ContactDataForm;
