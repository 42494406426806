import { always, cond, path, pathEq, pipe, T, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { withLatestFrom, map as rxMap } from 'rxjs/operators';

import {
  UPDATE_STATE,
  createPassword,
  getPhoneStatus,
  verifyOtp,
  verifyPassword,
  requestPasswordReset,
  verifyPasswordReset,
} from '../actions';
import { loginMetaSelector } from '../selectors';

const processStep = (step, actionCreator) => [
  pipe(path([1]), loginMetaSelector, pathEq(['step'], step)),
  pipe(path([0, 'payload']), unary(actionCreator)),
];

const routeAction = unary(
  cond([
    processStep('phone', getPhoneStatus),
    processStep('otp', verifyOtp),
    processStep('password', verifyPassword),
    processStep('createPassword', createPassword),
    processStep('phoneReset', requestPasswordReset),
    processStep('otpReset', verifyPasswordReset),
    [T, always({ type: 'crdf.unsupportedCase' })],
  ])
);

export default (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_STATE),
    withLatestFrom(state$),
    rxMap(routeAction)
  );
