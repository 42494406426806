import React from 'react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import Layout from './modules/layout';
import HomePage from './pages/homepage';
import LoginPage from './pages/LoginPage';
import CallMeBackPage from './pages/CallMeBackPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import LegalInfoPage from './pages/LegalInfoPage';
import SettingsPage from './pages/SettingsPage';
import OnlyAnonnymous from './modules/guards/onlyAnnonymous';
import OnlyAuthenticated from './modules/guards/onlyAuthenticated';
import NavigationInjector from './modules/navigation/Injector';
import NolListPage from './pages/nols/NolListPage';
import NolDetailPage from './pages/nols/NolDetailPage';
import JourneysPersonalDataPage from './pages/journeys/JourneysPersonalDataPage';
import JourneyContactDataPage from './pages/journeys/JourneyContactDataPage';
import JourneyRiskTypePage from './pages/journeys/JourneyRiskTypePage';
import JourneyRiskDetailsPage from './pages/journeys/JourneyRiskDetailsPage';
import JourneyBankAccountPage from './pages/journeys/JourneyBankAccountPage';
import JourneyContractsPage from './pages/journeys/JourneyContractsPage';
import ActiveJourneyGuard from './modules/journeys/ActiveJourneyGuard/ActiveJourneyGuard';
import JourneyNewPage from './pages/journeys/JourneyNewPage';
import ActiveJourneyUnfinishedModal from './modules/journeys/ActiveJourneyModal/ActiveJourneyModal';
import NolCreatedModal from './modules/journeys/NolCreatedModal/NolCreatedModal';
import NPSQLinkModal from './modules/nps/Modal';
import JourneyInitializedRouteGuard from './modules/journeys/JourneyInitializedRouteGuard/JourneyInitializedRouteGuard';
import OnlyRegistered from './modules/auth/account/OnlyRegistered';
import OnlyNotRegistered from './modules/auth/account/OnlyNotRegistered ';
import RegistrationPersonalDataPage from './pages/register/RegistrationPersonalDataPage';
import RegistrationContactDataPage from './pages/register/RegistrationContactDataPage';
import ResetPasswordPage from './pages/resetPassword';
import ChangeEmailPage from './pages/ChangeEmail/changeEmail';
import DocViewModal from './modules/docView/docViewModal';

const rootRoute = {
  // Top level route used to inject navigation function to redux for use by epics
  element: (
    <>
      <NavigationInjector />
      <ActiveJourneyUnfinishedModal />
      <NolCreatedModal />
      <NPSQLinkModal />
      <Outlet />
    </>
  ),
  children: [
    // only not authenticated users
    {
      layout: true,
      element: (
        <OnlyAnonnymous>
          <Layout hideHeaderLinks />
        </OnlyAnonnymous>
      ),
      children: [
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/password-reset',
          element: <ResetPasswordPage />,
        },
      ],
    },
    // only authenticated and not registered users
    {
      element: (
        <OnlyAuthenticated>
          <OnlyNotRegistered>
            <Layout hideHeaderLinks disableLogoLink />
          </OnlyNotRegistered>
        </OnlyAuthenticated>
      ),
      path: '/register',
      children: [
        {
          index: true,
          element: <Navigate to="personal" replace />,
        },
        {
          path: 'personal',
          element: <RegistrationPersonalDataPage />,
        },
        {
          path: 'contact',
          index: true,
          element: <RegistrationContactDataPage />,
        },
      ],
    },
    // only authenticated and registered users
    {
      element: (
        <OnlyAuthenticated>
          <OnlyRegistered>
            <Layout />
            <DocViewModal />
          </OnlyRegistered>
        </OnlyAuthenticated>
      ),
      children: [
        {
          path: '/nols',
          children: [
            {
              index: true,
              element: <NolListPage />,
            },
            {
              path: ':id',
              element: <NolDetailPage />,
            },
          ],
        },
        {
          path: '/settings',
          element: <SettingsPage />,
        },
        {
          path: '/journeys',
          element: <JourneyInitializedRouteGuard />,
          children: [
            {
              path: 'new',
              element: <JourneyNewPage />,
            },
            {
              element: <ActiveJourneyGuard />,
              children: [
                {
                  path: 'personal',
                  index: true,
                  element: <JourneysPersonalDataPage />,
                },
                {
                  path: 'contact',
                  element: <JourneyContactDataPage />,
                },
                {
                  path: 'risk-type',
                  element: <JourneyRiskTypePage />,
                },
                {
                  path: 'risk-details',
                  element: <JourneyRiskDetailsPage />,
                },
                {
                  path: 'bank-account',
                  element: <JourneyBankAccountPage />,
                },
                {
                  path: 'contracts',
                  element: <JourneyContractsPage />,
                },
              ],
            },
          ],
        },
      ],
    },
    // all users
    {
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <HomePage />,
        },
        {
          path: '/cookie-policy',
          element: <CookiePolicyPage />,
        },
        {
          path: '/legal-info',
          element: <LegalInfoPage />,
        },
        {
          path: '/call-me-back',
          element: <CallMeBackPage />,
        },
        {
          path: '/change-email',
          element: <ChangeEmailPage />,
        },
      ],
    },
  ],
};

const router = createBrowserRouter([rootRoute]);

export default router;
