import { handleActions } from 'redux-actions';

import { FETCH_INSURANCE_CONTRACTS_SUCCESS } from './actions';
import {
  FINALIZE_JOURNEY,
  FINALIZE_JOURNEY_ERROR,
  FINALIZE_JOURNEY_SUCCESS,
} from '../ActiveJourneyProvider/actions/form.actions';

const initialState = {
  isInitialized: false,
  isSubmitting: false,
  policies: [],
};

export default handleActions(
  {
    [FETCH_INSURANCE_CONTRACTS_SUCCESS]: (state, { payload }) => ({
      isInitialized: true,
      policies: payload,
    }),
    [FINALIZE_JOURNEY]: (state) => ({ ...state, isSubmitting: true }),
    [FINALIZE_JOURNEY_SUCCESS]: (state) => ({ ...state, isSubmitting: false }),
    [FINALIZE_JOURNEY_ERROR]: (state) => ({ ...state, isSubmitting: false }),
  },
  initialState
);
