import { ofType, combineEpics } from 'redux-observable';
import { interval, merge, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map as rxMap,
  filter as rxFilter,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { constructN, evolve, map, pipe, prop, unary, when } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

import {
  fetchNotifications,
  fetchNotificationsError,
  fetchNotificationsSuccess,
  FETCH_NOTIFICATIONS,
  markReadSuccess,
  MARK_NOTIFICATION_READ,
  markReadError,
  MARK_NOTIFICATION_READ_SUCCESS,
} from './actions';
import { isAuthenticatedSelector } from '../auth/storage/selectors';

const parse = unary(
  pipe(
    prop('response'),
    map(
      evolve({
        createDate: when(isNotNilOrEmpty, constructN(1, Date)),
      })
    ),
    unary(fetchNotificationsSuccess)
  )
);

export const fetchNotificationsEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_NOTIFICATIONS, MARK_NOTIFICATION_READ_SUCCESS),
    exhaustMap(() =>
      ajax({
        useAuth: true,
        url: '/gateway-server/message/by-user-id',
      }).pipe(
        rxMap(parse),
        catchError((error) => of(fetchNotificationsError(error)))
      )
    )
  );

export const scheduleFetchNotificationsEpic = (action$, state$) =>
  state$.pipe(
    rxMap(unary(isAuthenticatedSelector)),
    distinctUntilChanged(),
    rxFilter((v) => v),
    switchMap(() =>
      merge(of(1), interval(15000)).pipe(
        rxMap(() => fetchNotifications()),
        takeUntil(
          state$.pipe(
            rxMap(unary(isAuthenticatedSelector)),
            rxFilter((v) => !v)
          )
        )
      )
    )
  );

export const notificationReadEpic = (aciton$, state$, { ajax }) =>
  aciton$.pipe(
    ofType(MARK_NOTIFICATION_READ),
    switchMap(({ payload }) =>
      ajax({
        useAuth: true,
        method: 'POST',
        url: `/gateway-server/message/message-read/${payload.id}`,
        body: {
          uuid: payload.id,
        },
      }).pipe(
        rxMap(() => markReadSuccess()),
        catchError((error) => of(markReadError(error)))
      )
    )
  );

export default combineEpics(
  fetchNotificationsEpic,
  scheduleFetchNotificationsEpic,
  notificationReadEpic
);
