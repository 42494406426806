import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../../components/form/Select';
import {
  getCountriesPhones,
  getCountriesWithNumbers,
  getFlagByCallingCode,
} from './utils';

const propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['phone', 'country']),
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};
const defaultProps = {
  value: '',
  label: null,
  variant: 'country',
  onChange: null,
  onBlur: null,
  onFocus: null,
};

const CountrySelectModule = ({
  value,
  onChange,
  onFocus,
  onBlur,
  name,
  label,
  variant,
  ...rest
}) => {
  const countries = useMemo(() => {
    if (variant === 'country') return getCountriesWithNumbers();
    return getCountriesPhones(value);
  }, [value, variant]);

  const selectedFlagIcon = useMemo(() => {
    if (variant === 'phone') {
      const flag = getFlagByCallingCode(value);
      return <img src={flag} alt="country flag" />;
    }

    return null;
  }, [value, variant]);

  return (
    <Select
      {...rest}
      name={name}
      label={label}
      options={countries}
      value={value}
      selectedIcon={selectedFlagIcon}
      onSelect={(e) => {
        onChange(e);
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

CountrySelectModule.propTypes = propTypes;
CountrySelectModule.defaultProps = defaultProps;

export default CountrySelectModule;
