import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import TextField from '../../fields/textField';
import Span from '../../../../components/Grid/Span';
import Paragraph from '../../../../components/text/Paragraph/paragraph';
import FormError from '../../../../components/form/Error';
import Link from '../../../../components/Link/link';
import messages from '../messages';
import { resendOtp } from '../../../auth/login/actions';

export const propTypes = {
  currentStep: PropTypes.oneOf([
    'phone',
    'password',
    'otp',
    'createPassword',
    'confirm',
    'phoneReset',
    'otpReset',
    'resetConfirm',
  ]).isRequired,
  state: PropTypes.shape({
    hasError: PropTypes.bool.isRequired,
    refreshBlocked: PropTypes.bool,
  }).isRequired,
};

const OtpFragment = ({ currentStep, state }) => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext();
  const dispatch = useDispatch();

  if (currentStep !== 'otp' && currentStep !== 'otpReset') return null;

  useEffect(() => {
    if (state.hasError) {
      formik.setFieldError('otp', formatMessage(messages.otpError));
    }
  }, [state]);

  return (
    <Span xs={11}>
      <TextField
        required
        autoFocus
        name="otp"
        filterPattern={/^[0-9]+$/}
        label={formatMessage(messages.otpLabel)}
      />
      {state.refreshBlocked && (
        <FormError error={formatMessage(messages.refreshBlocked)} />
      )}
      <Paragraph variant="caption">
        <FormattedMessage {...messages.otpSent} />{' '}
        <Link
          label={formatMessage(messages.resendOtpLink)}
          to="#"
          component="a"
          onClick={(e) => {
            e.preventDefault();
            dispatch(resendOtp());
          }}
          size="xs"
        />
      </Paragraph>
    </Span>
  );
};

OtpFragment.propTypes = propTypes;

export default OtpFragment;
