import { curryN } from 'ramda';

import { hasLength, hasOnlyDigits, isRequired } from '../forms/validators';
import runValidations from '../forms/validators/runValidations';

const phoneValidations = {
  phonePrefix: {
    validate: [isRequired()],
  },
  phoneNumber: {
    validate: [isRequired(), hasOnlyDigits(), hasLength(9)],
  },
};
export default curryN(2, (deps, values) =>
  runValidations(phoneValidations, deps, values)
);
