import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_riskDetails_td';

export default defineMessages({
  sinceLabel: {
    id: `${namespace}_sinceLabel`,
    defaultMessage: 'Od',
  },
  workDisability_sinceHelperText: {
    id: `${namespace}_workDisability_sinceHelperText`,
    defaultMessage: 'Začátek pr. neschopnosti',
  },
  hasUntilLabel: {
    id: `${namespace}_hasUntilLabel`,
    defaultMessage: 'Zadat datum ukončení',
  },
  untilLabel: {
    id: `${namespace}_untilLabel`,
    defaultMessage: 'Do',
  },
  workDisability_untilHelperText: {
    id: `${namespace}_workDisability_untilHelperText`,
    defaultMessage: 'Najdete na potvrzení o pracovní neschopnosti',
  },
  workDisability_illnessDescriptionHelperText: {
    id: `${namespace}_workDisability_illnessDescriptionHelperText`,
    defaultMessage: 'Popiště vaše onemocnění nebo úraz',
  },
  descriptionLabel: {
    id: `${namespace}_descriptionLabel`,
    defaultMessage: 'Popis',
  },
  reasonLabel: {
    id: `${namespace}_reasonLabel`,
    defaultMessage: 'Hlásíte událost kvůli',
  },
  examinatedHeading: {
    id: `${namespace}_examinatedHeading`,
    defaultMessage: 'Absolvoval/a jste:',
  },
  undergoneSpecialExamLabel: {
    id: `${namespace}_undergoneSpecialExamLabel`,
    defaultMessage: 'Vyšetření lékařem/specialistou',
  },
  undergoneImagingExamLabel: {
    id: `${namespace}_undergoneImagingExamLabel`,
    defaultMessage: 'Zobrazovací vyšetření (např. RTG, MRI, CT, SONO)',
  },
  undergoneSurgeryOrHospitalisedLabel: {
    id: `${namespace}_undergoneSurgeryOrHospitalisedLabel`,
    defaultMessage: 'Operaci nebo jste byl/a hospitalizován/a',
  },
  inRetirementLabel: {
    id: `${namespace}_inRetirementLabel`,
    defaultMessage: 'Jste ve starobním důchodu?',
  },
  workAccidentLabel: {
    id: `${namespace}_workAccidentLabel`,
    defaultMessage: 'Došlo k pracovnímu úrazu?',
  },
  isInvalidLabel: {
    id: `${namespace}_isInvalidLabel`,
    defaultMessage: 'Byl vám v minulosti přiznán invalidní důchod?',
  },
  levelOfDisabilityLabel: {
    id: `${namespace}_levelOfDisabilityLabel`,
    defaultMessage: 'Stupeň invalidity',
  },
  investigatedByPoliceLabel: {
    id: `${namespace}_investigatedByPoliceLabel`,
    defaultMessage: 'Šetřila událost Policie ČR?',
  },
  workDisability_disabilitySinceHelperText: {
    id: `${namespace}_workDisability_disabilitySinceHelperText`,
    defaultMessage: 'Od kterého data je čerpán invalidní důchod',
  },
  workDisability_descriptionHelperText: {
    id: `${namespace}_workDisability_descriptionHelperText`,
    defaultMessage: 'Uveďte, prosím, adresu útvaru, který událost řešil',
  },
});
