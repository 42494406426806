import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'settings_title',
    defaultMessage: 'Nastavení',
  },
  cardTitle_one: {
    id: 'settings_cardTitle_one',
    defaultMessage: 'Notifikace',
  },
  cardMessage_one: {
    id: 'settings_cardMessage_one',
    defaultMessage: 'Vyberte, jakým způsobem si přejete upozornění zasílat',
  },
  cardTitle_two: {
    id: 'settings_cardTitle_two',
    defaultMessage: 'Telefonní číslo',
  },
  cardMessage_two: {
    id: 'settings_cardMessage_two',
    defaultMessage:
      'Telefonní číslo využíváte pro příhlášení k vašemu uživatelského účtu',
  },
  cardTitle_three: {
    id: 'settings_cardTitle_three',
    defaultMessage: 'E-mail',
  },
  cardMessage_three: {
    id: 'settings_cardMessage_three',
    defaultMessage:
      'E-mail využíváme pro zasílání upozornění týkajicích se vašich nahlášených událostí',
  },
  cardTitle_four: {
    id: 'settings_cardTitle_four',
    defaultMessage: 'Změna hesla',
  },
});
