import React, { useEffect, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { useNavigate, useLocation } from 'react-router-dom';

import Grid from '../../../../components/Grid';
import Button from '../../../../components/Button';
import Spacing from '../../../../components/layout/Spacing';
import RadiosField from '../../../forms/fields/radios';
import Loader from '../../../../components/Loader/loader';
import riskGroupsMessages from '../../../nol/riskGroups.messages';
import Paragraph from '../../../../components/text/Paragraph/paragraph';
import {
  contextSelector,
  formSelector,
} from '../../ActiveJourneyProvider/selectors';
import {
  formMount,
  formUnmount,
  refetchRiskContext,
  submitRiskTypeForm,
} from '../../ActiveJourneyProvider/actions';
import messages from './messages';
import { risksToRadios } from './util';

const RiskTypeForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = intl;
  const { activeJourneyId, isInitialized, coverages, riskType } =
    useSelector(contextSelector);
  const { isSubmitting } = useSelector(formSelector);
  const { state } = useLocation();

  const covered = useMemo(
    () =>
      risksToRadios(
        (key) => formatMessage(riskGroupsMessages[key]),
        'coveredRisksGroups',
        coverages
      ),
    [coverages]
  );

  const uncovered = useMemo(
    () =>
      risksToRadios(
        (key) => formatMessage(riskGroupsMessages[key]),
        'uncoveredRisksGroups',
        coverages
      ),
    [coverages]
  );

  useEffect(() => {
    if (state && state.activeJourneyId === activeJourneyId)
      dispatch(refetchRiskContext(riskType, coverages));
    dispatch(formMount());

    return () => dispatch(formUnmount());
  }, []);

  if (!isInitialized) return null;

  if (coverages.isLoading)
    return <Loader message={formatMessage(messages.findingRisks)} />;

  return (
    <Formik
      initialValues={{ riskType: riskType || '' }}
      onSubmit={(values) => dispatch(submitRiskTypeForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <Grid gap={8}>
            <RadiosField name="riskType" radios={covered} dir="vertical" />
          </Grid>
          {isNotNilOrEmpty(uncovered) && (
            <>
              <Spacing>
                <Paragraph center>
                  <FormattedMessage {...messages.uncoveredRisks} />
                </Paragraph>
              </Spacing>
              <Grid gap={8}>
                <RadiosField
                  name="riskType"
                  radios={uncovered}
                  dir="vertical"
                />
              </Grid>
            </>
          )}
          <Spacing variant="item" />
          <Grid xs={2} gap={4}>
            <Button
              full
              variant="gray"
              onClick={() => navigate('../contact')}
              label={formatMessage(messages.stepThree_backButtonLabel)}
            />
            <Button
              full
              type="submit"
              disabled={!formik.values.riskType || isSubmitting}
              label={formatMessage(messages.stepThree_continueButtonLabel)}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default RiskTypeForm;
