import { fromPairs, map, pipe } from 'ramda';

const valueMirror = pipe(
  map((v) => [v, v]),
  fromPairs
);

export const riskTypes = {
  PPD: { id: 31, name: 'PPD', productType: 'CPI' },
  TPD: { id: 4, name: 'TPD', productType: 'CPI' },
  TD: { id: 1, name: 'TD', productType: 'CPI' },
  IU: { id: 3, name: 'IU', productType: 'CPI' },
  H: { id: 7, name: 'H', productType: 'CPI' },
  TAC: { id: 26, name: 'TAC', productType: 'CPI' },
};

export const riskDetailURL = {
  H: 'hospitalization',
  TPD: 'total-perm-disability',
  PPD: 'part-perm-disability',
  TD: 'temp-disability',
  TAC: 'care',
  IU: 'unemployment',
};

export const ReportReason = valueMirror(['DISEASE', 'INJURY']);
export const ReportReasonCare = valueMirror([
  'DISEASE_OF_PATIENT',
  'INJURY_OF_PATIENT',
  'CARING_FOR_FAMILY_MEMBER_DUE_TO_SCHOOL_CLOSURE',
]);
export const levelsOfDisability = valueMirror([
  'PARTIAL',
  'FULL',
  'LEVEL1',
  'LEVEL2',
  'LEVEL3',
]);
export const NurseRole = valueMirror([
  'INSURED',
  'HUSBAND_WIFE',
  'CIVIL_PARTNER',
  'COHABITATION',
]);
export const PatientRole = valueMirror([
  'HUSBAND',
  'CHILD',
  'PARENT',
  'CIVIL_PARTNER',
  'OTHER_MEMBER_OF_FAMILY',
  'OTHER',
]);
