import React from 'react';
import { useIntl } from 'react-intl';

import StepCard from '../../components/cards/StepCard';
import messages from '../../pages/homepage/messages';

const StepCardsHome = () => {
  const intl = useIntl();
  const arrImgs = [0, 1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <div className="flex justify-center align-middle mt-5 flex-wrap">
      {arrImgs.map((i) => (
        <div key={i}>
          {i % 2 === 0 ? (
            <StepCard
              step={i / 2}
              text={intl.formatMessage(messages[`howItWorks_step_${i / 2}`])}
            />
          ) : (
            <div>
              <img
                className="mt-9 relative left-1"
                style={{ height: '40px' }}
                src="/img/steps/arrow.png"
                alt="step image"
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StepCardsHome;
