import { always, filter, join, pipe, propOr, unapply, when } from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';
import { createSelector } from 'reselect';

import { authSelector } from '../selectors';

export const authStorageSelector = pipe(authSelector, propOr(null, 'storage'));

export const authStorageIsInitializedSelector = pipe(
  authStorageSelector,
  propOr(false, 'isInitialized')
);

export const authStorageTokensSelector = pipe(
  authStorageSelector,
  propOr(null, 'tokens')
);

export const accessTokenSelector = pipe(
  authStorageTokensSelector,
  propOr(null, 'accessToken')
);

export const refreshTokenSelector = pipe(
  authStorageTokensSelector,
  propOr(null, 'refreshToken')
);

export const tokenTypeSelector = pipe(
  authStorageTokensSelector,
  propOr(null, 'tokenType')
);

export const tokenExpiresAtSelector = pipe(
  authStorageTokensSelector,
  propOr(null, 'tokenExpiresAt')
);

export const isAuthenticatedSelector = pipe(
  accessTokenSelector,
  isNotNilOrEmpty
);

export const authenticationStatusSelector = createSelector(
  authStorageIsInitializedSelector,
  isAuthenticatedSelector,
  (isInitialized, isAuthenticated) => ({
    isInitialized,
    isAuthenticated,
  })
);

export const authorizationHeaderSelector = createSelector(
  tokenTypeSelector,
  accessTokenSelector,
  pipe(
    unapply(filter(isNotNilOrEmpty)),
    join(' '),
    when(isNilOrEmpty, always(null))
  )
);
