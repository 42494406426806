import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { CALL_ME_BACK_TYPES_GENERIC } from './constants';
import CallMeBackForm from './Form';
import { callMeBackSelector } from './selectors';
import { resetCallMeBack, submitCallMeBack } from './actions';
import WeWillCallStep from './WeWillCall';

export const propTypes = {
  reason: PropTypes.oneOf(Object.keys(CALL_ME_BACK_TYPES_GENERIC)),
  meta: PropTypes.shape({
    phoneNumber: PropTypes.string,
    phonePrefix: PropTypes.string,
    risk: PropTypes.string,
  }),
};
const defaultProps = {
  reason: CALL_ME_BACK_TYPES_GENERIC.LOGIN,
  meta: {
    phonePrefix: '420',
    phoneNumber: '',
  },
};

const CallMeBack = ({ reason, meta }) => {
  const dispatch = useDispatch();
  const { step, isSubmitting } = useSelector(callMeBackSelector);
  const [sVal, setSVal] = useState();

  useEffect(() => () => dispatch(resetCallMeBack()), []);

  if (step === 'weWillCall' && sVal !== undefined)
    return (
      <WeWillCallStep
        phoneNumber={sVal.phoneNumber}
        phonePrefix={sVal.phonePrefix}
        reason={reason}
      />
    );

  return (
    <CallMeBackForm
      reason={reason}
      isSubmitting={isSubmitting}
      phoneNumber={meta.phoneNumber}
      phonePrefix={meta.phonePrefix}
      onSubmit={(values) => {
        setSVal(values);
        dispatch(
          submitCallMeBack({
            meta,
            type: reason,
            phone: `+${values.phonePrefix}${values.phoneNumber}`,
          })
        );
      }}
    />
  );
};

CallMeBack.propTypes = propTypes;
CallMeBack.defaultProps = defaultProps;

export default CallMeBack;
