import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Link from '../../components/Link';
import HeaderDesign from '../../components/layout/Header';
import Dropdown from '../../components/Dropdown';
import DropdownItem from '../../components/Dropdown/Item';
import { HamburgerIcon, ColoredIcon } from '../../components/icons';
import { isAuthenticatedSelector } from '../auth/storage/selectors';
import { clearTokens } from '../auth/storage/actions';
import NotificationsDropdown from '../notifications/DropDown';
import messages from './header.messages';
import Logo from '../../components/images/Logo';

export const propTypes = {
  hideLinks: PropTypes.bool,
  disableLogoLink: PropTypes.bool,
};
const defaultProps = {
  hideLinks: false,
  disableLogoLink: false,
};

const Header = ({ hideLinks, disableLogoLink }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const logo = useMemo(() => {
    if (isAuthenticated && disableLogoLink) {
      return (
        <Link
          to="#"
          component="a"
          label={<Logo />}
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      );
    }
    if (isAuthenticated) {
      return (
        <Link
          to="/nols"
          component="a"
          label={<Logo />}
          onClick={(e) => {
            e.preventDefault();
            navigate('/nols');
          }}
        />
      );
    }
    return (
      <Link
        to="/"
        component="a"
        label={<Logo />}
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
      />
    );
  }, [isAuthenticated]);

  const menuLinks = useMemo(() => {
    if (hideLinks) return [];

    if (isAuthenticated)
      return [
        <NotificationsDropdown key="notifications" />,
        <Dropdown
          key="hamburger"
          button={<ColoredIcon icon={<HamburgerIcon />} hoverColor="green" />}
          width={270}
        >
          <DropdownItem onClick={() => navigate('/nols')}>
            {intl.formatMessage(messages.eventList)}
          </DropdownItem>
          <DropdownItem onClick={() => navigate('/settings')}>
            {intl.formatMessage(messages.settings)}
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              dispatch(clearTokens());
              navigate('/');
            }}
          >
            {intl.formatMessage(messages.logout)}
          </DropdownItem>
        </Dropdown>,
      ];

    return [
      <Link
        key="loginLink"
        label={intl.formatMessage(messages.loginLink)}
        to="/login"
        variant="header"
        component={RouterLink}
      />,
    ];
  }, [hideLinks, isAuthenticated]);

  return <HeaderDesign menu={menuLinks} logo={logo} />;
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
