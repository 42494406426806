import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Select from '../../../components/form/Select';

const propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  label: PropTypes.string,
};
const defaultProps = {
  label: null,
};

const SelectField = ({ name, options, label }) => {
  const [field, meta] = useField({ name });

  return (
    <Select
      name={name}
      options={options}
      label={label}
      value={field.value}
      onSelect={field.onChange}
      error={meta.touched && meta.error ? meta.error : null}
    />
  );
};

SelectField.propTypes = propTypes;
SelectField.defaultProps = defaultProps;

export default SelectField;
