import { curryN, prop, propEq } from 'ramda';

import runValidations, {
  isRequired,
  isNotFuture,
  isChronologicalAfter,
} from '../../../../forms/validators';

const validations = {
  hospitalizationFrom: {
    validate: [isRequired(), isNotFuture()],
  },
  hospitalizationTo: {
    when: propEq('hasUntil', true),
    validate: [
      isRequired(),
      isNotFuture(),
      isChronologicalAfter(prop('hospitalizationFrom')),
    ],
  },
};

export default curryN(2, (deps, values) =>
  runValidations(validations, deps, values)
);
