import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Spacing from '../../../../components/layout/Spacing';
import Heading from '../../../../components/text/Heading';
import EventCard from '../../../../components/cards/EventCard';
import riskGroupMessages from '../../riskGroups.messages';
import Grid from '../../../../components/Grid';

export const propTypes = {
  nols: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string.isRequired,
  onNolClick: PropTypes.func.isRequired,
};

const defaultProps = {};

const NolListSection = ({ nols, title, onNolClick }) => {
  const intl = useIntl();

  return (
    <Spacing>
      <Heading variant="subtitle">{title}</Heading>
      {!!nols && (
        <Grid gap={2}>
          {nols.map((nol) => (
            <EventCard
              key={nol.uuid}
              title={intl.formatMessage(
                riskGroupMessages[nol.riskGroupStringCode]
              )}
              dateOfReport={intl.formatDate(nol.reported)}
              docsNeeded={nol.numberOfRequirements}
              onClick={() => onNolClick(nol)}
            />
          ))}
        </Grid>
      )}
    </Spacing>
  );
};

NolListSection.propTypes = propTypes;
NolListSection.defaultProps = defaultProps;

export default NolListSection;
