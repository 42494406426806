import { createAction } from 'redux-actions';

const namespace = 'settings.changeEmail';

export const CONFIRM_CHANGE_EMAIL = `${namespace}.confirmChangeEmail`;
export const CONFIRM_CHANGE_EMAIL_SUCCESS = `${namespace}.confirmChangeEmailSuccess`;
export const CONFIRM_CHANGE_EMAIL_ERROR = `${namespace}.confirmChangeEmailError`;

export const confirmChangeEmail = createAction(CONFIRM_CHANGE_EMAIL);
export const confirmChangeEmailSuccess = createAction(
  CONFIRM_CHANGE_EMAIL_SUCCESS
);
export const confirmChangeEmailError = createAction(CONFIRM_CHANGE_EMAIL_ERROR);
