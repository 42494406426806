import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import Container from '../../../../components/layout/Container';
import Spacing from '../../../../components/layout/Spacing';
import Heading from '../../../../components/text/Heading';
import Paragraph from '../../../../components/text/Paragraph';
import Tabs from '../../../../components/Tabs';
import TabItem from '../../../../components/Tabs/Item';
import DropCard from '../../../../components/cards/DropCard';
import NolInfoCard from './cards/NolInfoCard';
import NolDetailUploadTab from './tabs/Upload';
import NolDetailUploadedTab from './tabs/Uploaded';
import ClientInfoCard from './cards/ClientInfoCard';
import Grid from '../../../../components/Grid';
import ContractsCard from './cards/ContractsCard';
import messages from './messages';
import HistoryCard from './cards/HistoryCard';
import NolDetailInfoTab from './tabs/Info';
import { cancelFetchNolDetail, fetchNolDetail } from '../actions';
import { nolDetailSelector } from '../../selectors';
import { nolUnmetRequirementsSelector } from '../selectors';
import { STEPS } from '../../../journeys/JourneyLayout/constants';
import journeyMessages from '../../../journeys/JourneyLayout/messages';
import ProgressBar from '../../../../components/ProgressBar/progressBar';

export const propTypes = {
  id: PropTypes.string.isRequired,
};

const defaultProps = {
  showDuplicitModal: false,
};

const NolDetail = ({ id }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isInitialized, nol } = useSelector(nolDetailSelector);
  const unmetRequirements = useSelector(nolUnmetRequirementsSelector);
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(fetchNolDetail(id));

    return () => dispatch(cancelFetchNolDetail());
  }, []);

  if (!isInitialized) return null;

  const arrJourneyMessages = Object.entries(journeyMessages).map(([, obj]) =>
    formatMessage(obj)
  );

  return (
    <>
      <Container>
        <div className="w-full py-5 m-4 xsm:mt-20 xsm:mb-14 sm:mt-6 md:mt-4 max-w-xl md:max-w-xl lg:max-w-2xl xl:max-w-3xl mx-auto">
          <ProgressBar
            step={unmetRequirements.length > 1 ? 7 : 8}
            stepsTotal={Object.keys(STEPS).length}
            stepsTexts={arrJourneyMessages}
            stepNumber={false}
            textPlaces="hidden xsm:cross sm:bottom"
            textClasses="text-xs font-bold"
          />
        </div>
        <Spacing>
          <Heading>
            <FormattedMessage
              {...messages.title}
              values={{ type: intl.formatMessage(messages[nol.riskType]) }}
            />
          </Heading>
          <Paragraph center>
            <FormattedMessage
              {...messages.reportedOnDate}
              values={{ date: intl.formatDate(nol.reportedOn) }}
            />
          </Paragraph>
        </Spacing>
      </Container>
      <Tabs defaultTab={1}>
        <TabItem
          label={intl.formatMessage(messages.tabLabel_one)}
          component={<NolDetailInfoTab nol={nol} />}
        />
        <TabItem
          label={intl.formatMessage(messages.tabLabel_two)}
          component={<NolDetailUploadTab />}
        />
        <TabItem
          label={intl.formatMessage(messages.tabLabel_three)}
          component={<NolDetailUploadedTab />}
        />
      </Tabs>
      <Container bgColor="gray">
        <Spacing>
          <Heading variant="subtitle">
            {intl.formatMessage(messages.detailsTitle)}
          </Heading>
          <Grid gap={3}>
            <DropCard
              startsOpen
              title={intl.formatMessage(messages.detailOne_title)}
            >
              <ClientInfoCard />
            </DropCard>
            <DropCard title={intl.formatMessage(messages.detailTwo_title)}>
              <NolInfoCard />
            </DropCard>
            <DropCard title={intl.formatMessage(messages.detailThree_title)}>
              <ContractsCard waitingForDocs />
            </DropCard>
            <DropCard title={intl.formatMessage(messages.detailFour_title)}>
              <HistoryCard />
            </DropCard>
          </Grid>
        </Spacing>
      </Container>
    </>
  );
};

NolDetail.propTypes = propTypes;
NolDetail.defaultProps = defaultProps;

export default NolDetail;
