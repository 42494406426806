import { fromPairs, map, pipe } from 'ramda';

const valueMirror = pipe(
  map((v) => [v, v]),
  fromPairs
);

export const ReportReason = valueMirror(['DISEASE', 'INJURY']);
export const ReportReasonCare = valueMirror([
  'DISEASE_OF_PATIENT',
  'INJURY_OF_PATIENT',
  'CARING_FOR_FAMILY_MEMBER_DUE_TO_SCHOOL_CLOSURE',
]);
export const LevelsOfDisability = valueMirror([
  'PARTIAL',
  'FULL',
  'LEVEL1',
  'LEVEL2',
  'LEVEL3',
]);
export const NurseRole = valueMirror([
  'INSURED',
  'HUSBAND_WIFE',
  'CIVIL_PARTNER',
  'COHABITATION',
]);
export const PatientRole = valueMirror([
  'HUSBAND',
  'CHILD',
  'PARENT',
  // 'CIVIL_PARTNER',
  'OTHER_MEMBER_OF_FAMILY',
  'OTHER',
]);
