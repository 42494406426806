import React from 'react';
import { useSelector } from 'react-redux';

import {
  notificationMessagesSelector,
  unreadMessagesCountSelector,
} from '../selectors';
import Dropdown from '../../../components/Dropdown';
import DropdownItem from '../../../components/Dropdown/Item';
import { MailIcon, ColoredIcon } from '../../../components/icons';
import NotificationModal from '../Modal/Modal';

const NotificationsDropdown = () => {
  const messages = useSelector(notificationMessagesSelector);
  const unreadCount = useSelector(unreadMessagesCountSelector);

  return (
    <Dropdown
      disableOnClickClose
      count={unreadCount}
      key="mail"
      button={<ColoredIcon icon={<MailIcon />} hoverColor="green" />}
      width={400}
    >
      {messages.map((item, idx) => (
        <DropdownItem key={idx}>
          <NotificationModal
            date={item.createDate}
            title={item.subject}
            data={item}
          />
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

export default NotificationsDropdown;
