import { handleActions } from 'redux-actions';

import {
  CHANGE_EMAIL,
  CHANGE_EMAIL_ERROR,
  CHANGE_EMAIL_SUCCESS,
  GET_SETTINGS_SUCCESS,
  RESET_STATE,
} from '../actions';

const initialState = {
  isSubmitting: false,
  hasError: false,
  submitSuccess: false,
  initialValues: {
    email: '',
  },
};

export default handleActions(
  {
    [RESET_STATE]: (state) => ({
      ...state,
      submitSuccess: false,
    }),
    [GET_SETTINGS_SUCCESS]: (state, { payload }) => ({
      ...state,
      hasError: false,
      initialValues: { email: payload.email },
    }),
    [CHANGE_EMAIL]: (state) => ({ ...state, isSubmitting: true }),
    [CHANGE_EMAIL_ERROR]: (state) => ({
      ...state,
      isSubmitting: false,
      hasError: true,
      submitSuccess: false,
    }),
    [CHANGE_EMAIL_SUCCESS]: (state, { payload }) => ({
      ...initialState,
      submitSuccess: true,
      hasError: false,
      initialValues: { email: payload.email },
    }),
  },
  initialState
);
