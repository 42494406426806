import React from 'react';
import PropTypes from 'prop-types';

import Container from './Container';
import Spacing from './Spacing';

const propTypes = {
  menu: PropTypes.arrayOf(PropTypes.node),
  logo: PropTypes.node.isRequired,
};

const defaultProps = {
  menu: [],
};

const Header = ({ menu, logo }) => (
  <Container variant="layout">
    <Spacing variant="header">
      <nav className="flex items-center justify-between">
        {logo}
        <div className="flex gap-4">
          {React.Children.map(menu, (c, i) => (
            <div key={i}>{c}</div>
          ))}
        </div>
      </nav>
    </Spacing>
  </Container>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
