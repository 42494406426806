import {
  always,
  applySpec,
  cond,
  F,
  pipe,
  prop,
  propEq,
  propOr,
  T,
  unary,
} from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map as rxMap,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

import {
  GET_PHONE_STATUS,
  getPhoneStatusSuccess,
  getPhoneStatusError,
  setOtpId,
  setServerHasError,
} from '../actions';
import { usernameSelector } from '../selectors';

const parseSuccess = pipe(
  prop('response'),
  applySpec({
    isNotValidFormat: F,
    isNotRegistered: F,
    otpId: propOr(null, 'otpId'),
  }),
  unary(getPhoneStatusSuccess)
);

const parseError = cond([
  [
    propEq('status', 404),
    always(
      of(
        getPhoneStatusError({
          isNotValidFormat: false,
          isNotRegistered: true,
          otpId: null,
        })
      )
    ),
  ],
  [
    propEq('status', 400),
    always(
      of(
        getPhoneStatusError({
          isNotValidFormat: true,
          isNotRegistered: false,
          otpId: null,
        })
      )
    ),
  ],
  [
    T,
    always(
      of(
        getPhoneStatusError({
          isNotValidFormat: false,
          isNotRegistered: false,
          otpId: null,
        }),
        setServerHasError()
      )
    ),
  ],
]);

export default (action$, state$) =>
  action$.pipe(
    ofType(GET_PHONE_STATUS),
    exhaustMap((a) =>
      of(a).pipe(
        withLatestFrom(state$),
        mergeMap(([, state]) =>
          ajax({
            url: `/gateway-server/accounts/${usernameSelector(state)}/status`,
          })
        ),
        rxMap(unary(parseSuccess)),
        mergeMap((sAction) => of(setOtpId(sAction.payload), sAction)),
        catchError(unary(parseError))
      )
    )
  );
