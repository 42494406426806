import React from 'react';
import PropTypes from 'prop-types';

import Error from '../Error';

const propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
const defaultProps = {
  error: null,
  value: undefined,
  onChange: null,
};

const Checkbox = ({ label, error, name, value, onChange }) => (
  <div className="flex flex-col">
    <label className="font-cardif-regular font-normal flex items-start cursor-pointer text-black">
      <input
        name={name}
        checked={value}
        onChange={onChange}
        type="checkbox"
        className="text-cardif-green-light cursor-pointer mr-2 mt-0.5 w-5 h-5 rounded-md border-3 border-gray-300 hover:border-gray-500 outline-none checked:ring-0 focus:ring-offset-0 focus:ring-0"
      />
      <div>{label}</div>
    </label>
    <Error error={error} extraClass="pl-7 pt-2" />
  </div>
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
