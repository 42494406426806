/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Arrow from './arrow';

const propTypes = {
  variant: PropTypes.oneOf(['first', 'prev', 'next', 'last']).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const defaultProps = {
  size: 'md',
  variant: 'next',
  onClick: null,
};

const PageArrow = ({ variant, size, className, onClick }) => {
  const color = ' border-cardif-green-light hover:border-cardif-green-dark';
  const sizeCircle = cx({
    'w-6 h-6': size === 'sm',
    'w-8 h-8': size === 'md',
    'w-10 h-10': size === 'lg',
  });

  return (
    <div
      className={cx(
        'relative flex items-center justify-center rounded-full border-2 cursor-pointer',
        sizeCircle,
        color,
        className
      )}
      onClick={onClick}
    >
      <Arrow variant={variant} />
    </div>
  );
};

PageArrow.propTypes = propTypes;
PageArrow.defaultProps = defaultProps;

export default PageArrow;
