import React from 'react';
import { useLocation } from 'react-router-dom';

import Container from '../../components/layout/Container';
import CallMeBack from '../../modules/callMeBack/CallMeBack';
import { CALL_ME_BACK_TYPES_GENERIC } from '../../modules/callMeBack/constants';

const CallMeBackPage = () => {
  const { state } = useLocation();

  return (
    <Container bgColor="gray" variant="smaller-content">
      <CallMeBack
        reason={state?.reason || CALL_ME_BACK_TYPES_GENERIC.LOGIN}
        meta={{
          phoneNumber: state?.meta?.phoneNumber,
          phonePrefix: state?.meta?.phonePrefix,
          risk: state?.meta?.risk,
        }}
      />
    </Container>
  );
};

export default CallMeBackPage;
