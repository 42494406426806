import {
  always,
  applySpec,
  converge,
  find,
  flip,
  path,
  pipe,
  prop,
  propEq,
  propOr,
  unapply,
  zipObj,
} from 'ramda';
import { createSelector } from 'reselect';
import { isNilOrEmpty } from 'ramda-adjunct';
import { format } from 'date-fns/fp';

import * as mappers from './mappers';
import { journeysSelector } from '../selectors';

const mappersByKey = {
  H: mappers.fromApiHospitalization,
  TPD: mappers.fromApiDisability,
  PPD: mappers.fromApiDisability,
  TD: mappers.fromApiTempDisability,
  TAC: mappers.fromApiTakingCare,
  IU: mappers.fromApiUnemployment,
};

const datePropByType = {
  H: 'hospitalizationFrom',
  TPD: 'disabilityFrom',
  PPD: 'disabilityFrom',
  TD: 'temporaryDisabilityFrom',
  TAC: 'takingCareFrom',
  IU: 'involuntaryUnemploymentFrom',
};

export const activeJourneySelector = pipe(
  journeysSelector,
  prop('activeJourney')
);

export const contextSelector = pipe(activeJourneySelector, prop('context'));

export const coveragesSelector = pipe(contextSelector, prop('coverages'));

export const formSelector = pipe(activeJourneySelector, prop('form'));

export const riskDetailsSelector = createSelector(
  pipe(contextSelector, propOr({}, 'riskDetails')),
  pipe(contextSelector, prop('riskType')),
  (riskDetails, riskType) => {
    if (isNilOrEmpty(riskDetails)) {
      return mappersByKey[riskType](riskDetails);
    }

    return riskDetails;
  }
);

export const bankAccountSelector = createSelector(
  pipe(contextSelector, propOr({}, 'bankAccount')),
  (bankAccount) => {
    if (isNilOrEmpty(bankAccount)) {
      return mappers.fromApiBankAccount(bankAccount);
    }

    return bankAccount;
  }
);

export const currentRiskMetaSelector = createSelector(
  pipe(contextSelector, prop('riskType')),
  pipe(contextSelector, prop('riskDetails')),
  pipe(contextSelector, path(['coverages', 'coveredRisksGroups'])),
  unapply(
    pipe(
      zipObj(['riskType', 'riskDetails', 'coveredRisks']),
      applySpec({
        riskId: pipe(
          converge(find, [
            converge(propEq, [always('name'), prop('riskType')]),
            prop('coveredRisks'),
          ]),
          propOr(null, 'id')
        ),
        riskDate: pipe(
          converge(prop, [
            pipe(prop('riskType'), flip(prop)(datePropByType)),
            prop('riskDetails'),
          ]),
          format('yyyy-MM-dd')
        ),
      })
    )
  )
);

export const currentRiskMetaRiskSelector = pipe(
  contextSelector,
  prop('riskType')
);

export const activePeriodContractsUrlSelector = createSelector(
  currentRiskMetaSelector,
  ({ riskId, riskDate }) =>
    `/gateway-server/claim-declaration/policies/${riskId}/${riskDate}`
);

export const finalizeJourneyDataSelector = createSelector(
  pipe(currentRiskMetaSelector, prop('riskDate')),
  pipe(contextSelector, prop('activeJourneyId')),
  unapply(zipObj(['riskDate', 'activeJourneyId']))
);

export const riskTypeAndDateSelector = createSelector(
  pipe(contextSelector, prop('riskType')),
  pipe(contextSelector, prop('riskDetails')),
  unapply(
    pipe(
      zipObj(['riskType', 'riskDetails']),
      applySpec({
        riskType: prop('riskType'),
        riskDate: pipe(
          converge(prop, [
            pipe(prop('riskType'), flip(prop)(datePropByType)),
            prop('riskDetails'),
          ]),
          format('yyyy-MM-dd')
        ),
      })
    )
  )
);
