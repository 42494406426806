import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  switchMap,
  withLatestFrom,
  catchError,
  mergeMap,
} from 'rxjs/operators';

import { toApiPersonalData } from '../../mappers';
import {
  SUBMIT_PERSONAL_DATA_FORM,
  genericSubmit,
  genericSubmitError,
  genericSubmitSuccess,
  submitPersonalDataFormError,
  submitPersonalDataFormSuccess,
} from '../../actions';
import { contextSelector } from '../../selectors';

const createUrl = (state) => {
  const { activeJourneyId } = contextSelector(state);

  return `/gateway-server/journeys/${activeJourneyId}/personal`;
};

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(SUBMIT_PERSONAL_DATA_FORM),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      merge(
        of(genericSubmit()),
        ajax({
          useAuth: true,
          method: 'PUT',
          url: createUrl(state),
          body: toApiPersonalData(payload),
        }).pipe(
          mergeMap(() =>
            of(submitPersonalDataFormSuccess(payload), genericSubmitSuccess())
          ),
          catchError((e) =>
            of(submitPersonalDataFormError(e), genericSubmitError())
          )
        )
      )
    )
  );
