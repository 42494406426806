import { combineEpics } from 'redux-observable';

import npsAccountApic from './npsAccount.epic';
import updateNpsEpic from './updateNps.epic';
import npsModalEpic from './npsModal.epic';
import openQuestEpic from './openQuest.epic';

export default combineEpics(
  npsAccountApic,
  updateNpsEpic,
  npsModalEpic,
  openQuestEpic
);
