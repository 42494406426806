import { ofType } from 'redux-observable';
import { switchMap, map as rxMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { RESET_PASSWORD, resetPasswordError } from '../actions';
import { navigate } from '../../../navigation/actions';

// const codeEnum = {
//   '006': 'ALREADY_USED',
// };

const parseError = (e) => {
  if (e.status >= 500) return of(resetPasswordError({ hasServerError: true }));

  return of(
    resetPasswordError({
      passwordAlreadyUsed: true,
    })
  );
};

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(RESET_PASSWORD),
    switchMap(({ payload }) =>
      ajax({
        method: 'POST',
        url: '/gateway-server/accounts/password-change',
        body: {
          pairingCode: payload.pairingCode,
          password: payload.password,
        },
      }).pipe(
        rxMap(() => navigate({ to: '/login' })),
        catchError(parseError)
      )
    )
  );
