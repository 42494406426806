import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
};
const defaultProps = {};

const TabItem = ({ component }) => component;

TabItem.propTypes = propTypes;
TabItem.defaultProps = defaultProps;

export default TabItem;
