import { format } from 'date-fns/fp';
import {
  F,
  always,
  applySpec,
  constructN,
  ifElse,
  pipe,
  prop,
  propEq,
  propOr,
  propSatisfies,
  when,
} from 'ramda';
import { isNotNilOrEmpty, propNotEq } from 'ramda-adjunct';

import { PatientRole, ReportReasonCare } from '../constants';

export const fromApiTakingCare = applySpec({
  nurseRole: propOr('INSURED', 'nurseRole'),
  nurseName: propOr('', 'nurseName'),
  nurseSurname: propOr('', 'nurseSurname'),
  nurseId: propOr('', 'nurseId'),
  patientRole: propOr('HUSBAND', 'patientRole'),
  otherText: propOr('', 'otherText'),
  patientName: propOr('', 'patientName'),
  patientSurname: propOr('', 'patientSurname'),
  patientBirthNumber: propOr('', 'patientBirthNumber'),
  takingCareFrom: pipe(
    propOr('', 'takingCareFrom'),
    when(isNotNilOrEmpty, constructN(1, Date))
  ),
  hasEnded: propSatisfies(isNotNilOrEmpty, 'takingCareTo'),
  takingCareTo: pipe(
    propOr('', 'takingCareTo'),
    when(isNotNilOrEmpty, constructN(1, Date))
  ),
  levelOfDisability: propOr('FULL', 'levelOfDisability'),
  isInvalid: propSatisfies(isNotNilOrEmpty, 'disabilityFrom'),
  disabilityFrom: pipe(
    propOr('', 'disabilityFrom'),
    when(isNotNilOrEmpty, constructN(1, Date))
  ),
  investigatedByPolice: propSatisfies(isNotNilOrEmpty, 'policeInvestigation'),
  policeInvestigation: propOr('', 'policeInvestigation'),
  reportedIncident: propOr('DISEASE_OF_PATIENT', 'reportedIncident'),
  undergoneSpecialistExamination: propOr(
    false,
    'undergoneSpecialistExamination'
  ),
  undergoneImagingExamination: propOr(false, 'undergoneImagingExamination'),
  undergoneSurgeryOrHospitalised: propOr(
    false,
    'undergoneSurgeryOrHospitalised'
  ),
  applicationForCareAllowance: propOr(false, 'applicationForCareAllowance'),
  workingLeaveDueToNursing: propOr(false, 'workingLeaveDueToNursing'),
  terminateEmploymentDueToNursing: propOr(
    false,
    'terminateEmploymentDueToNursing'
  ),
});

export const toApiTakingCare = applySpec({
  nurseRole: prop('nurseRole'),
  nurseName: prop('nurseName'),
  nurseSurname: prop('nurseSurname'),
  nurseId: prop('nurseId'),
  patientRole: prop('patientRole'),
  otherText: ifElse(
    propEq('patientRole', PatientRole.OTHER),
    prop('otherText'),
    always('')
  ),
  patientName: prop('patientName'),
  patientSurname: prop('patientSurname'),
  patientBirthNumber: prop('patientBirthNumber'),
  takingCareFrom: pipe(prop('takingCareFrom'), format('yyyy-MM-dd')),
  takingCareTo: ifElse(
    propEq('hasEnded', true),
    pipe(prop('takingCareTo'), format('yyyy-MM-dd')),
    always(undefined)
  ),
  levelOfDisability: ifElse(
    propEq('isInvalid', true),
    prop('levelOfDisability'),
    always(undefined)
  ),
  disabilityFrom: ifElse(
    propEq('isInvalid', true),
    pipe(prop('disabilityFrom'), format('yyyy-MM-dd')),
    always(undefined)
  ),
  policeInvestigation: ifElse(
    propEq('investigatedByPolice', true),
    prop('policeInvestigation'),
    always(undefined)
  ),
  reportedIncident: prop('reportedIncident'),
  undergoneSpecialistExamination: ifElse(
    propNotEq(
      'reportedIncident',
      ReportReasonCare.CARING_FOR_FAMILY_MEMBER_DUE_TO_SCHOOL_CLOSURE
    ),
    prop('undergoneSpecialistExamination'),
    always(undefined)
  ),
  undergoneImagingExamination: ifElse(
    propNotEq(
      'reportedIncident',
      ReportReasonCare.CARING_FOR_FAMILY_MEMBER_DUE_TO_SCHOOL_CLOSURE
    ),
    prop('undergoneImagingExamination'),
    always(undefined)
  ),
  undergoneSurgeryOrHospitalised: ifElse(
    propNotEq(
      'reportedIncident',
      ReportReasonCare.CARING_FOR_FAMILY_MEMBER_DUE_TO_SCHOOL_CLOSURE
    ),
    prop('undergoneSurgeryOrHospitalised'),
    F
  ),
  applicationForCareAllowance: ifElse(
    propNotEq(
      'reportedIncident',
      ReportReasonCare.CARING_FOR_FAMILY_MEMBER_DUE_TO_SCHOOL_CLOSURE
    ),
    prop('applicationForCareAllowance'),
    always(undefined)
  ),
  workingLeaveDueToNursing: prop('workingLeaveDueToNursing'),
  terminateEmploymentDueToNursing: prop('terminateEmploymentDueToNursing'),
});
