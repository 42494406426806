import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Grid from '../Grid';
import Span from '../Grid/Span';
import FormattedDate from '../formatters/FormattedDate';
import { MailIcon, ColoredIcon } from '../icons';
import Ellipsis from '../text/Ellipsis';

const propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string.isRequired,
  isRead: PropTypes.bool,
};
const defaultProps = {
  isRead: false,
};

const Notification = ({ date, title, isRead }) => (
  <Grid xs={9} md={12} extraClass="text-sm">
    <ColoredIcon
      icon={<MailIcon size={20} />}
      color={!isRead ? 'green' : 'inherit'}
    />
    <Span xs={3} extraClass="self-center">
      <FormattedDate
        date={date}
        underline={!isRead}
        color={!isRead ? 'green' : 'inherit'}
      />
    </Span>
    <Span
      xs={5}
      md={8}
      extraClass={cx('self-center', {
        'text-cardif-green-light underline': !isRead,
      })}
    >
      <Ellipsis title={title}>{title}</Ellipsis>
    </Span>
  </Grid>
);

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;
