import {
  basicLocalize,
  formatDate,
  formatAddress,
  prefixLocalize,
  addDefaultFormatters,
} from '../../../../../SimpleTable/fieldFormatters';

// default name formatter: basic localize
// default value formatter: noFormat

export const common = addDefaultFormatters([
  {
    name: 'partnerName',
  },
  {
    name: 'riskType',
    valueFormatter: basicLocalize,
  },
  {
    name: 'reportedOn',
    valueFormatter: formatDate,
  },
  {
    name: 'policeInvestigationDescription',
  },
]);

/**
 * This mapping is not correct and needs to be fixed.
 * The reason for it is incorrect business requirement that has not been
 * fixed despite dev feedback.
 *
 * It has been discussed with Kristyna Pezlova and future change request
 * should address this issue
 *
 * More information can be found in the ticket and its comments.
 * https://tfs.ssc-assurance.is.echonet/tfs/CARDIF%20CZ/Digital%20Factory/_workitems/edit/137790/
 */
export const claimDetailTemporaryDisability = addDefaultFormatters([
  { name: 'description' },
  { name: 'disabilityFrom', valueFormatter: formatDate },
  { name: 'disabilityTo', valueFormatter: formatDate },
  {
    name: 'levelOfDisability',
    valueFormatter: prefixLocalize('levelOfDisability_'),
  },
  { name: 'retirementPensionFrom', valueFormatter: formatDate },
  { name: 'temporaryDisabilityFrom', valueFormatter: formatDate },
  { name: 'temporaryDisabilityTo', valueFormatter: formatDate },
]);

export const claimDetailUnemployment = addDefaultFormatters([
  {
    name: 'employerName',
  },
  {
    name: 'involuntaryUnemploymentFrom',
    valueFormatter: formatDate,
  },
  {
    name: 'involuntaryUnemploymentTo',
    valueFormatter: formatDate,
  },
  { name: 'employerAddress', valueFormatter: formatAddress },
]);

export const claimDetailTakingCare = addDefaultFormatters([
  {
    name: 'disabilityFrom',
    valueFormatter: formatDate,
  },
  {
    name: 'levelOfDisability',
    valueFormatter: prefixLocalize('levelOfDisability_'),
  },
  { name: 'nurseId' },
  { name: 'nurseName' },
  { name: 'nurseSurname' },
  { name: 'nurseRole', valueFormatter: prefixLocalize('role_') },
  { name: 'patientRole', valueFormatter: prefixLocalize('role_') },
  { name: 'otherText' },
  { name: 'patientName' },
  { name: 'patientSurname' },
  { name: 'patientBirthNumber' },
  {
    name: 'takingCareFrom',
    valueFormatter: formatDate,
  },
  { name: 'takingCareTo', valueFormatter: formatDate },
]);

export const claimDetailPartialPermanentDisability = addDefaultFormatters([
  { name: 'description' },
  { name: 'disabilityFrom', valueFormatter: formatDate },
  { name: 'disabilityTo', valueFormatter: formatDate },
  {
    name: 'levelOfDisability',
    valueFormatter: prefixLocalize('levelOfDisability_'),
  },
  { name: 'disabilityRentalFrom', valueFormatter: formatDate },
  { name: 'retirementPensionFrom', valueFormatter: formatDate },
  { name: 'temporaryDisabilityFrom', valueFormatter: formatDate },
  { name: 'temporaryDisabilityTo', valueFormatter: formatDate },
]);

export const claimDetailPermanentDisability = addDefaultFormatters([
  {
    name: 'disabilityFrom',
    valueFormatter: formatDate,
  },
  { name: 'disabilityTo', valueFormatter: formatDate },
  {
    name: 'levelOfDisability',
    valueFormatter: prefixLocalize('levelOfDisability_'),
  },
  {
    name: 'disabilityRentalFrom',
    valueFormatter: formatDate,
  },
]);
export const claimDetailHospitalization = addDefaultFormatters([
  {
    name: 'hospitalizationFrom',
    valueFormatter: formatDate,
  },
  {
    name: 'hospitalizationTo',
    valueFormatter: formatDate,
  },
]);
