import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';

import Button from '../../../components/Button';
import Spacing from '../../../components/layout/Spacing';
import Grid from '../../../components/Grid';
import Paragraph from '../../../components/text/Paragraph';
import TextField from '../../forms/fields/textField';
import PasswordValidations from './passwordValidations';
import messages from './messages';
import commonMessages from '../../i18n/common.messages';
import DropCard from '../../../components/cards/DropCard/drop';
import Container from '../../../components/layout/Container';
import FlashCard from '../../../components/cards/FlashCard';
import validator from './validator';
import { changePasswordFormSelector } from '../selectors';
import { changePassword } from './actions';

const ChangePasswordForm = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    isSubmitting,
    initialValues,
    changeTS,
    originalPasswordInvalid,
    newPasswordUsedInPast,
    submitSucceeded,
    hasServerError,
  } = useSelector(changePasswordFormSelector);

  const [messageObj, setMessageObj] = useState({
    variant: 'warning',
    message: '',
  });

  const [newPassword, setNewPassword] = useState(initialValues.newPassword);
  const [newPasswordRepeat, setNewPasswordRepeat] = useState(
    initialValues.newPasswordRepeat
  );

  const newRepeatPasswordMatch = () => newPassword === newPasswordRepeat;

  const newRepeatPasswordNotEmpty = () => newPassword && newPasswordRepeat;

  useEffect(() => {
    if (originalPasswordInvalid) {
      setMessageObj({
        variant: 'warning',
        message: formatMessage(messages.savePasswordErrorOriginalPassword),
      });
    }

    if (newPasswordUsedInPast) {
      setMessageObj({
        variant: 'warning',
        message: formatMessage(messages.passwordWasUsedInThePast),
      });
    }

    if (submitSucceeded) {
      setMessageObj({
        variant: 'success',
        message: formatMessage(messages.savePasswordSuccess),
      });
    }

    if (hasServerError) {
      setMessageObj({
        variant: 'warning',
        message: formatMessage(commonMessages.backendInternalServerError),
      });
    }
  }, [
    originalPasswordInvalid,
    newPasswordUsedInPast,
    submitSucceeded,
    hasServerError,
  ]);

  return (
    <DropCard title={formatMessage(messages.cardTitle)}>
      <Spacing>
        <Container variant="form" bgColor="gray">
          <Formik
            key={changeTS}
            validate={validator}
            validateOnBlur
            validateOnChange
            initialValues={initialValues}
            onSubmit={(values) => dispatch(changePassword(values))}
          >
            {(formik) => (
              <Form>
                <Grid gap={8}>
                  {(originalPasswordInvalid ||
                    newPasswordUsedInPast ||
                    submitSucceeded ||
                    hasServerError) && (
                    <FlashCard
                      variant={messageObj.variant}
                      message={messageObj.message}
                    />
                  )}
                  <TextField
                    required
                    name="originalPassword"
                    type="password"
                    label={formatMessage(messages.oldPasswordLabel)}
                  />
                  <TextField
                    required
                    name="newPassword"
                    type="password"
                    label={formatMessage(messages.newPasswordLabel)}
                    onChange={setNewPassword(formik.values.newPassword)}
                  />
                  <TextField
                    required
                    name="newPasswordRepeat"
                    type="password"
                    label={formatMessage(messages.confirmNewPasswordLabel)}
                    onChange={setNewPasswordRepeat(
                      formik.values.newPasswordRepeat
                    )}
                  />
                  {newRepeatPasswordNotEmpty() && !newRepeatPasswordMatch() && (
                    <FlashCard
                      variant="warning"
                      message={formatMessage(
                        messages.newPasswordRepeatNotMatch
                      )}
                    />
                  )}
                </Grid>
                <Spacing>
                  <Paragraph color="gray">
                    {formatMessage(messages.newPasswordSpecsText)}
                  </Paragraph>
                  <PasswordValidations value={formik.values.newPassword} />
                </Spacing>
                <Button
                  center
                  type="submit"
                  disabled={isSubmitting || !formik.isValid || !formik.dirty}
                  label={formatMessage(messages.saveButtonLabel)}
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Spacing>
    </DropCard>
  );
};

export default ChangePasswordForm;
