import { handleActions } from 'redux-actions';

import {
  OPEN_DOCVIEW_MODAL,
  CLOSE_DOCVIEW_MODAL,
  FETCH_NOL_DOC_ERROR,
  FETCH_NOL_DOC_SUCCESS,
  FETCH_NOL_DOC,
  CONVERT_NOL_DOC_SUCCESS,
  CONVERT_NOL_DOC,
  DOWNLOAD_NOL_DOC,
  CONVERT_NOL_DOC_ERROR,
} from './actions';

const initialState = {
  isDocLoading: false,
  isDocViewOpen: true,
  isDocConverting: false,
  isDocReady: false,
  fileWrapper: null,
  document: null,
  errorLoading: null,
  errorConverting: null,
};

export default handleActions(
  {
    [OPEN_DOCVIEW_MODAL]: (state, { payload: fileWrapper }) => ({
      ...initialState,
      fileWrapper,
      isDocReady: true,
      isDocViewOpen: true,
    }),
    [CLOSE_DOCVIEW_MODAL]: (state) => ({
      ...state,
      document: null,
      fileWrapper: null,
      isDocViewOpen: false,
    }),
    [FETCH_NOL_DOC]: (state, { payload: document }) => ({
      ...state,
      isDocLoading: true,
      isDocViewOpen: true,
      isDocReady: false,
      errorLoading: null,
      errorConverting: null,
      document,
    }),
    [FETCH_NOL_DOC_ERROR]: (state, { payload: errorCode }) => ({
      ...state,
      isDocLoading: false,
      isDocViewOpen: true,
      isDocReady: false,
      errorLoading: errorCode,
    }),
    [FETCH_NOL_DOC_SUCCESS]: (state, { payload: fileWrapper }) => ({
      ...state,
      isDocLoading: false,
      isDocReady: true,
      fileWrapper,
    }),
    [DOWNLOAD_NOL_DOC]: (state, { payload: document }) => ({
      ...state,
      isDocLoading: false,
      isDocViewOpen: false,
      isDocReady: false,
      document,
    }),
    [CONVERT_NOL_DOC]: (state) => ({
      ...state,
      isDocLoading: false,
      isDocConverting: true,
      errorConverting: null,
    }),
    [CONVERT_NOL_DOC_ERROR]: (state, { payload: errorCode }) => ({
      ...state,
      isDocConverting: false,
      isDocViewOpen: true,
      isDocReady: false,
      errorConverting: errorCode,
    }),
    [CONVERT_NOL_DOC_SUCCESS]: (state, { payload: fileWrapper }) => ({
      ...state,
      isDocConverting: false,
      isDocReady: true,
      fileWrapper,
    }),
  },
  initialState
);
