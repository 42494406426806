import { defineMessages } from 'react-intl';

const namespace = 'registration_contactDataForm';

export default defineMessages({
  streetLabel: {
    id: `${namespace}_streetLabel`,
    defaultMessage: 'Ulice a č. popisné',
  },
  cityLabel: {
    id: `${namespace}_cityLabel`,
    defaultMessage: 'Město',
  },
  postalCodeLabel: {
    id: `${namespace}_postalCodeLabel`,
    defaultMessage: 'PSČ',
  },
  countryLabel: {
    id: `${namespace}_countryLabel`,
    defaultMessage: 'Stát',
  },
  hasDifferentContactAddressLabel: {
    id: `${namespace}_hasDifferentContactAddressLabel`,
    defaultMessage: 'Nastavit jinou kontaktní adresu',
  },
  stepTwo_backButtonLabel: {
    id: `${namespace}_stepTwo_backButtonLabel`,
    defaultMessage: 'Krok zpět',
  },
  stepTwo_continueButtonLabel: {
    id: `${namespace}_stepTwo_continueButtonLabel`,
    defaultMessage: 'Pokračovat',
  },
});
