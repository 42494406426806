import { combineEpics } from 'redux-observable';

import createJourneyEpic from './createJourney.epic';
import initContextEpic from './initContext.epic';
import formEpic from './form.epic';
import continueJourneyEpic from './continueJourney.epic';
import destroyJourneyEpic from './destroyJourneyEpic';

export default combineEpics(
  createJourneyEpic,
  initContextEpic,
  formEpic,
  continueJourneyEpic,
  destroyJourneyEpic
);
