import { combineEpics } from 'redux-observable';

import getPhoneStatusEpic from './getPhoneStatus.epic';
import verifyPasswordEpic from './verifyPassword.epic';
import verifyOtpEpic from './verifyOtp.epic';
import createPasswordEpic from './createPassword.epic';
import fetchTokensEpic from './fetchTokens.epic';
import updateStateEpic from './updateState.epic';
import createAccountEpic from './createAccount.epic';
import deletePasswordEpic from './deletePassword.epic';
import resetOtpEpic from './resetOtp.epic';
import refreshOtpEpic from './refreshOtp.epic';

export default combineEpics(
  getPhoneStatusEpic,
  verifyPasswordEpic,
  verifyOtpEpic,
  createPasswordEpic,
  fetchTokensEpic,
  updateStateEpic,
  createAccountEpic,
  deletePasswordEpic,
  resetOtpEpic,
  refreshOtpEpic
);
