import { ofType } from 'redux-observable';
import { withLatestFrom, tap as rxTap, ignoreElements } from 'rxjs/operators';

import { REFRESH_TOKENS_SUCCESS, CLEAR_TOKENS } from '../actions';
import { FETCH_TOKENS_SUCCESS } from '../../login/actions';
import { authStorageTokensSelector } from '../selectors';
import { storageEncode } from '../utils';
import { TOKEN_STORAGE_KEY } from '../constants';

export default (action$, state$, { window }) =>
  action$.pipe(
    ofType(REFRESH_TOKENS_SUCCESS, CLEAR_TOKENS, FETCH_TOKENS_SUCCESS),
    withLatestFrom(state$),
    rxTap(([, state]) =>
      window.sessionStorage.setItem(
        TOKEN_STORAGE_KEY,
        storageEncode(authStorageTokensSelector(state))
      )
    ),
    ignoreElements()
  );
