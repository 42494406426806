import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  validation: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
};
const defaultProps = {
  isValid: false,
};

const PasswordValidationsItem = ({ validation, isValid }) => (
  <div
    className={cx(
      'before:content-["✔"] before:absolute before:left-0 before:text-center before:top-0 before:w-5 before:h-5 before:text-gray-50 before:rounded-full text-sm relative pl-7.5',
      {
        'before:bg-gray-200': !isValid,
        'before:bg-cardif-green-light': isValid,
      }
    )}
  >
    {validation}
  </div>
);

PasswordValidationsItem.propTypes = propTypes;
PasswordValidationsItem.defaultProps = defaultProps;

export default PasswordValidationsItem;
