import { ofType } from 'redux-observable';
import { EMPTY, of, switchMap } from 'rxjs';
import { isNilOrEmpty } from 'ramda-adjunct';

import {
  FETCH_ACTIVE_JOURNEYS_SUCCESS,
  fetchJourneyState,
  fetchRiskTypes,
} from '../../actions/context.actions';

export default (action$) =>
  action$.pipe(
    ofType(FETCH_ACTIVE_JOURNEYS_SUCCESS),
    switchMap(({ payload: { activeJourneyId } }) => {
      if (isNilOrEmpty(activeJourneyId)) {
        return EMPTY;
      }

      return of(fetchJourneyState({ activeJourneyId }), fetchRiskTypes());
    })
  );
