import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  extraClass: PropTypes.string,
};
const defaultProps = {
  xs: 1,
  sm: null,
  md: null,
  lg: null,
  extraClass: null,
};

const GridSpan = ({ children, xs, sm, md, lg, extraClass }) => (
  <div
    className={cx({
      [extraClass]: !!extraClass,
      'col-span-1': xs === 1,
      'col-span-2': xs === 2,
      'col-span-3': xs === 3,
      'col-span-4': xs === 4,
      'col-span-5': xs === 5,
      'col-span-6': xs === 6,
      'col-span-7': xs === 7,
      'col-span-8': xs === 8,
      'col-span-9': xs === 9,
      'col-span-10': xs === 10,
      'col-span-11': xs === 11,
      'col-span-12': xs === 12,
      'sm:col-span-1': sm === 1,
      'sm:col-span-2': sm === 2,
      'sm:col-span-3': sm === 3,
      'sm:col-span-4': sm === 4,
      'sm:col-span-5': sm === 5,
      'sm:col-span-6': sm === 6,
      'sm:col-span-7': sm === 7,
      'sm:col-span-8': sm === 8,
      'sm:col-span-9': sm === 9,
      'sm:col-span-10': sm === 10,
      'sm:col-span-11': sm === 11,
      'sm:col-span-12': sm === 12,
      'md:col-span-1': md === 1,
      'md:col-span-2': md === 2,
      'md:col-span-3': md === 3,
      'md:col-span-4': md === 4,
      'md:col-span-5': md === 5,
      'md:col-span-6': md === 6,
      'md:col-span-7': md === 7,
      'md:col-span-8': md === 8,
      'md:col-span-9': md === 9,
      'md:col-span-10': md === 10,
      'md:col-span-11': md === 11,
      'md:col-span-12': md === 12,
      'lg:col-span-1': lg === 1,
      'lg:col-span-2': lg === 2,
      'lg:col-span-3': lg === 3,
      'lg:col-span-4': lg === 4,
      'lg:col-span-5': lg === 5,
      'lg:col-span-6': lg === 6,
      'lg:col-span-7': lg === 7,
      'lg:col-span-8': lg === 8,
      'lg:col-span-9': lg === 9,
      'lg:col-span-10': lg === 10,
      'lg:col-span-11': lg === 11,
      'lg:col-span-12': lg === 12,
    })}
  >
    {children}
  </div>
);

GridSpan.propTypes = propTypes;
GridSpan.defaultProps = defaultProps;

export default GridSpan;
