import { handleActions } from 'redux-actions';

import {
  GENERIC_SUBMIT,
  GENERIC_SUBMIT_SUCCESS,
  GENERIC_SUBMIT_ERROR,
  FORM_UNMOUNT,
} from '../actions/form.actions';

const initialState = {
  isSubmitting: false,
  hasError: false,
};

export default handleActions(
  {
    [GENERIC_SUBMIT]: (state) => ({ ...state, isSubmitting: true }),
    [GENERIC_SUBMIT_SUCCESS]: (state) => ({ ...state, isSubmitting: false }),
    [GENERIC_SUBMIT_ERROR]: (state) => ({
      ...state,
      isSubmitting: false,
      hasError: true,
    }),
    [FORM_UNMOUNT]: () => ({ ...initialState }),
  },
  initialState
);
