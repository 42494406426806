import { always, converge, pipe, prop, propOr, unapply } from 'ramda';
import { concatAll } from 'ramda-adjunct';

import { authSelector } from '../selectors';

export const loginSelector = pipe(authSelector, propOr(null, 'login'));
export const loginMetaSelector = pipe(loginSelector, propOr(null, 'meta'));
export const loginPasswordSelector = pipe(
  loginSelector,
  propOr(null, 'password')
);
export const loginPhoneSelector = pipe(loginSelector, propOr(null, 'phone'));
export const loginOtpSelector = pipe(loginSelector, propOr(null, 'otp'));
export const usernameSelector = pipe(
  loginPhoneSelector,
  converge(unapply(concatAll), [
    always('+'),
    prop('phoneNumberPrefix'),
    prop('phoneNumber'),
  ])
);
export const otpIdSelector = pipe(loginOtpSelector, prop('otpId'));

export const afterLoginSelector = pipe(
  loginMetaSelector,
  prop('afterLoginAction')
);

export const loginHasServerErrorSelector = pipe(
  loginMetaSelector,
  propOr(false, 'hasServerError')
);

export const resetConfirmSelector = pipe(
  loginSelector,
  propOr(null, 'confirm')
);
