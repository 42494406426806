import { defineMessages } from 'react-intl';

export default defineMessages({
  cardTitle: {
    id: 'settings_forms_email_cardTitle',
    defaultMessage: 'E-mail',
  },
  cardText: {
    id: 'settings_forms_email_cardText',
    defaultMessage:
      'E-mail využíváme pro zasílání upozornění týkajících se vašich nahlášených událostí.',
  },
  emailLabel: {
    id: 'settings_forms_email_emailLabel',
    defaultMessage: 'E-mail',
  },
  saveButtonLabel: {
    id: 'settings_forms_email_saveButtonLabel',
    defaultMessage: 'Uložit',
  },
  successText: {
    id: 'settings_forms_email_successText',
    defaultMessage:
      'V případě, že nemůžete nalézt příslušný e-mail, zkontrolujte prosím složku SPAM nebo počkejte.',
  },
  invalidEmailError: {
    id: 'settings_forms_email_invalidEmailError',
    defaultMessage: 'Neplatný e-mail',
  },
  confirmText: {
    id: 'settings_forms_email_confirmText',
    defaultMessage: 'Potvrdit změnu e-mailu',
  },
  confirmNewEmail: {
    id: 'settings_forms_email_confirmNewEmail',
    defaultMessage: 'Nový e-mail: {email}',
  },
  cancelButtonLabel: {
    id: 'settings_forms_email_cancelButtonLabel',
    defaultMessage: 'Zrušit',
  },
  saveSuccessNewEmail: {
    id: 'settings_forms_email_saveSuccessNewEmail',
    defaultMessage: 'Na novou adresu vám posíláme e-mail k dokončení změny.',
  },
  checkEmailSpam: {
    id: 'settings_forms_email_checkEmailSpam',
    defaultMessage:
      'V případě, že nemůžete nalézt příslušný e-mail, zkontrolujte prosím složku SPAM nebo počkejte.',
  },
});
