import { defineMessages } from 'react-intl';

const namespace = 'crdf_nol_detail_nolDetail';

export default defineMessages({
  clientPersonalInfo: {
    id: `${namespace}_clientPersonalInfo`,
    defaultMessage: 'Osobní údaje klienta',
  },
  claimDetail: {
    id: `${namespace}_claimDetail`,
    defaultMessage: 'Detail škodní události',
  },
  claimDetails: {
    id: `${namespace}_claimDetails`,
    defaultMessage: 'Detaily o škodní události',
  },
  relatedInsuredContracts: {
    id: `${namespace}_relatedInsuredContracts`,
    defaultMessage: 'Související pojistné smlouvy',
  },
  permanentAddress: {
    id: `${namespace}_permanentAddress`,
    defaultMessage: 'Trvalá adresa',
  },
  toUpload: { id: `${namespace}_toUpload`, defaultMessage: 'To upload' },
  underReview: {
    id: `${namespace}_underReview`,
    defaultMessage: 'Under review',
  },
  approved: {
    id: `${namespace}_approved`,
    defaultMessage: 'Approved',
  },
  reported: {
    id: `${namespace}_approved`,
    defaultMessage: 'Approved',
  },
  employerApprovement: {
    id: `${namespace}_employerApprovement`,
    defaultMessage: 'Employer approvement',
  },
  doctorsQuestionnaire: {
    id: `${namespace}_doctorsQuestionnaire`,
    defaultMessage: 'Doctor questionnaire',
  },
  dragHere: { id: `${namespace}_dragHere`, defaultMessage: 'Drag here' },
  upload: { id: `${namespace}_upload`, defaultMessage: 'Upload' },
  mobileUpload: {
    id: `${namespace}_mobileUpload`,
    defaultMessage:
      'Tip: If you dont have your documents ready on your computer, you can take photos with your phone and upload them right in your mobile device. Continue to log in from my phone.',
  },
  employerApprovementInfo: {
    id: `${namespace}_employerApprovementInfo`,
    defaultMessage:
      'The document containing the information and the date of first treatment will be provided by your attending physician. Download the document template.',
  },
  anyDocument: {
    id: `${namespace}_anyDocument`,
    defaultMessage: 'Any document',
  },
  doctorsApprovementInfo: {
    id: `${namespace}_doctorsApprovementInfo`,
    defaultMessage: 'sdfsdfsd',
  },
  anyDocumentInfo: {
    id: `${namespace}_anyDocumentInfo`,
    defaultMessage: 'Any related and important document to the claim.',
  },
  solutionHistory: {
    id: `${namespace}_solutionHistory`,
    defaultMessage: 'Historie řešení',
  },
  contractNumber: {
    id: `${namespace}_contractNumber`,
    defaultMessage: 'Číslo smlouvy',
  },
  policyNumber: {
    id: `${namespace}_policyNumber`,
    defaultMessage: 'Číslo smlouvy',
  },
  insuranceEventNumber: {
    id: `${namespace}_insuranceEventNumber`,
    defaultMessage: 'Resolved negatively',
  },
  repayBankAccount: {
    id: `${namespace}_repayBankAccount`,
    defaultMessage: 'Účet pro výplatu pojistného plnění:',
  },
  accountNumber: {
    id: `${namespace}_accountNumber`,
    defaultMessage: 'Účet pro výplatu plnění',
  },
  bankAccountNumber: {
    id: `${namespace}_bankAccountNumber`,
    defaultMessage: 'Účet pro výplatu pojistného plnění:',
  },
  riskType: { id: `${namespace}_riskType`, defaultMessage: 'Riziko' },
  partnerName: { id: `${namespace}_partnerName`, defaultMessage: 'Partner' },
  nolItemId: { id: `${namespace}_nolItemId`, defaultMessage: 'Číslo události' },
  reportedOn: {
    id: `${namespace}_reportedOn`,
    defaultMessage: 'Nahlášeno dne',
  },
  reportedOnDate: {
    id: `${namespace}_reportedOn`,
    defaultMessage: 'Nahlášeno dne {date}',
  },
  bankAccountUpdate: {
    id: `${namespace}_bankAccountUpdate`,
    defaultMessage: 'Upravit',
  },
  workIncapacityStartDate: {
    id: `${namespace}_workIncapacityStartDate`,
    defaultMessage: 'Pracovní neschopnost od',
  },
  reportingDate: {
    id: `${namespace}_reportingDate`,
    defaultMessage: 'Datum nahlášení',
  },
  description: { id: `${namespace}_description`, defaultMessage: 'Popis' },
  disabilityFrom: {
    id: `${namespace}_disabilityFrom`,
    defaultMessage: 'Datum vzniku události',
  },
  disabilityTo: {
    id: `${namespace}_disabilityTo`,
    defaultMessage: 'Nezpůsobilost do',
  },
  selfEmployerNumber: {
    id: `${namespace}_selfEmployerNumber`,
    defaultMessage: 'IČO',
  },
  status: { id: `${namespace}_status`, defaultMessage: 'Status' },
  HOSPITALIZATION: {
    id: `${namespace}_HOSPITALIZATION`,
    defaultMessage: 'Hospitalizace',
  },
  TOTAL_PERMANENT_DISABILITY: {
    id: `${namespace}_TOTAL_PERMANENT_DISABILITY`,
    defaultMessage: 'Invalidita III. stupně',
  },
  TEMPORARY_DISABILITY: {
    id: `${namespace}_TEMPORARY_DISABILITY`,
    defaultMessage: 'Pracovní neschopnost',
  },
  INVOLUNTARY_UNEMPLOYMENT: {
    id: `${namespace}_INVOLUNTARY_UNEMPLOYMENT`,
    defaultMessage: 'Ztráta zaměstnání',
  },
  TAKING_CARE: {
    id: `${namespace}_TAKING_CARE`,
    defaultMessage: 'Ošetřování osoby blízké',
  },
  PARTIAL_PERMANENT_DISABILITY: {
    id: `${namespace}_PARTIAL_PERMANENT_DISABILITY`,
    defaultMessage: 'Invalidita II. stupně',
  },
  policeInvestigationDescription: {
    id: `${namespace}_policeInvestigationDescription`,
    defaultMessage: 'Adresa útvaru, který událost šetřil (Policie ČR)',
  },
  employerName: {
    id: `${namespace}_employerName`,
    defaultMessage: 'Název zaměstnavatele',
  },
  involuntaryUnemploymentFrom: {
    id: `${namespace}_involuntaryUnemploymentFrom`,
    defaultMessage: 'Nezaměstaný od',
  },
  involuntaryUnemploymentTo: {
    id: `${namespace}_involuntaryUnemploymentTo`,
    defaultMessage: 'Nezaměstnaný do',
  },
  unemploymentDueToMedicalReason: {
    id: `${namespace}_unemploymentDueToMedicalReason`,
    defaultMessage: 'Nezaměstnaný kvůli zdravotním důvodům',
  },
  employerAddress: {
    id: `${namespace}_employerAddress`,
    defaultMessage: 'Adresa zaměstnavatele',
  },
  patientDisabled: {
    id: `${namespace}_patientDisabled`,
    defaultMessage: 'Pacient je zdravotně postižený',
  },
  nurseId: { id: `${namespace}_nurseId`, defaultMessage: 'RČ ošetřovatele' },
  nurseName: {
    id: `${namespace}_nurseName`,
    defaultMessage: 'Jméno ošetřovatele',
  },
  nurseRole: {
    id: `${namespace}_nurseRole`,
    defaultMessage: 'Status ošetřovatele',
  },
  nurseSurname: {
    id: `${namespace}_nurseSurname`,
    defaultMessage: 'Příjmení ošetřovatele',
  },
  patientRole: {
    id: `${namespace}_patientRole`,
    defaultMessage: 'Ošetřovaná osoba je',
  },
  otherText: {
    id: `${namespace}_otherText`,
    defaultMessage: 'Vztah k ošetřované osobě',
  },
  patientName: {
    id: `${namespace}_patientName`,
    defaultMessage: 'Jméno ošetřovaného',
  },
  patientSurname: {
    id: `${namespace}_patientSurname`,
    defaultMessage: 'Příjmení ošetřovaného',
  },
  patientBirthNumber: {
    id: `${namespace}_patientBirthNumber`,
    defaultMessage: 'RČ ošetřovaného',
  },
  takingCareFrom: {
    id: `${namespace}_takingCareFrom`,
    defaultMessage: 'Datum začátku ošetřování',
  },
  takingCareTo: {
    id: `${namespace}_takingCareTo`,
    defaultMessage: 'Datum konce ošetřování',
  },
  levelOfDisability: {
    id: `${namespace}_levelOfDisability`,
    defaultMessage: 'Úroveň invalidity',
  },
  retirementPensionFrom: {
    id: `${namespace}_retirementPensionFrom`,
    defaultMessage: 'Starobní důchod od',
  },
  temporaryDisabilityFrom: {
    id: `${namespace}_temporaryDisabilityFrom`,
    defaultMessage: 'Pracovní neschopnost od',
  },
  temporaryDisabilityTo: {
    id: `${namespace}_temporaryDisabilityTo`,
    defaultMessage: 'Pracovní neschopnost do',
  },
  disabilityRentalFrom: {
    id: `${namespace}_disabilityRentalFrom`,
    defaultMessage: 'Invalidní důchod od',
  },
  hospitalizationFrom: {
    id: `${namespace}_hospitalizationFrom`,
    defaultMessage: 'Hospitalizace od',
  },
  hospitalizationTo: {
    id: `${namespace}_hospitalizationTo`,
    defaultMessage: 'Hospitalizace do',
  },
  levelOfDisability_LEVEL1: {
    id: `${namespace}_level1`,
    defaultMessage: 'Stupeň 1',
  },
  levelOfDisability_LEVEL2: {
    id: `${namespace}_level2`,
    defaultMessage: 'Stupeň 2',
  },
  levelOfDisability_LEVEL3: {
    id: `${namespace}_level3`,
    defaultMessage: 'Stupeň 3',
  },
  levelOfDisability_FULL: { id: `${namespace}_full`, defaultMessage: 'Plný' },
  levelOfDisability_PARTIAL: {
    id: `${namespace}_partial`,
    defaultMessage: 'Částečný',
  },
  nolHistory: {
    id: `${namespace}_nolHistory`,
    defaultMessage: 'Historie řešení škodní události',
  },
  noHistoryRecords: {
    id: `${namespace}_noHistoryRecords`,
    defaultMessage: 'Zatím žádné záznamy',
  },
  accountInfo: {
    id: `${namespace}_accountInfo`,
    defaultMessage: 'Účet pro výplatu pojistného plnění',
  },
  accountNumberInfo: {
    id: `${namespace}_accountNumberInfo`,
    defaultMessage:
      'Číslo bankovního účtu obsahující předčíslí uveďte prosím ve tvaru XXXXXX-XXXXXXXXXX',
  },
  bankCode: { id: `${namespace}_bankCode`, defaultMessage: 'Kód banky' },
  bankAccountOwnerIsDifferent: {
    id: `${namespace}_bankAccountOwnerIsDifferent`,
    defaultMessage: 'Vlastníkem účtu je jiná osoba',
  },
  bankAccountOwnerInfo: {
    id: `${namespace}_bankAccountOwnerInfo`,
    defaultMessage: 'Údaje o vlastníkovi účtu',
  },
  bankAccountOwnerAddress: {
    id: `${namespace}_bankAccountOwnerAddress`,
    defaultMessage: 'Trvalá adresa vlastníka účtu',
  },
  bankAccountError: {
    id: `${namespace}_bankAccountError`,
    defaultMessage: 'Formát není validní.',
  },
  save: { id: `${namespace}_save`, defaultMessage: 'Uložit' },
  cancel: { id: `${namespace}_cancel`, defaultMessage: 'Zrušit' },
  notAbleToSaveBankAccount: {
    id: `${namespace}_notAbleToSaveBankAccount`,
    defaultMessage: 'Došlo k chybě během ukládání nového účtu.',
  },
  role_INSURED: { id: `${namespace}_insured`, defaultMessage: 'Pojištěný' },
  role_HUSBAND_WIFE: {
    id: `${namespace}_husbandWife`,
    defaultMessage: 'Manžel/Manželka pojištěného',
  },
  role_CIVIL_PARTNER: {
    id: `${namespace}_civilPartner`,
    defaultMessage: 'Registrovaný partner/partnerka pojištěného',
  },
  role_COHABITATION: {
    id: `${namespace}_cohabitation`,
    defaultMessage: 'Druh/Družka',
  },
  role_HUSBAND: {
    id: `${namespace}_husband`,
    defaultMessage: 'Manžel/Manželka pojištěného',
  },
  role_CHILD: { id: `${namespace}_child`, defaultMessage: 'Dítě' },
  role_PARENT: { id: `${namespace}_parent`, defaultMessage: 'Rodič' },
  role_OTHER_MEMBER_OF_FAMILY: {
    id: `${namespace}_otherMemberOfFamily`,
    defaultMessage: 'Jiný člen rodiny žijící ve společné domácnosti',
  },
  role_OTHER: { id: `${namespace}_other`, defaultMessage: 'Jiný' },
  title: {
    id: `${namespace}_title`,
    defaultMessage: 'Nahlášená škodní událost: {type}',
  },
  tabLabel_one: {
    id: `${namespace}_tabLabel_one`,
    defaultMessage: 'Důležité info',
  },
  tabLabel_two: {
    id: `${namespace}_tabLabel_two`,
    defaultMessage: 'Nahrát',
  },
  tabLabel_three: {
    id: `${namespace}_tabLabel_three`,
    defaultMessage: 'Nahráno',
  },
  detailsTitle: {
    id: `${namespace}_detailsTitle`,
    defaultMessage: 'Detaily o škodní události',
  },
  detailOne_title: {
    id: `${namespace}_detailOne_title`,
    defaultMessage: 'Osobní údaje klienta',
  },
  detailTwo_title: {
    id: `${namespace}_detailTwo_title`,
    defaultMessage: 'Detail škodní události',
  },
  detailThree_title: {
    id: `${namespace}_detailThree_title`,
    defaultMessage: 'Související pojistné smlouvy',
  },
  detailFour_title: {
    id: `${namespace}_detailFour_title`,
    defaultMessage: 'Historie řešení škodní události',
  },
  duplicitNolModalText: {
    id: `${namespace}_duplicitNolModalText`,
    defaultMessage:
      'Vypadá to, že se nám snažíte nahlásit událost, kterou jste nám již nahlásil/a. Byl/a jste přesměrován/a na detail existující události.',
  },
  duplicitNolModalBtnLabel: {
    id: `${namespace}_duplicitNolModalBtnLabel`,
    defaultMessage: 'Rozumím',
  },
});
