/* eslint-disable no-unused-vars */
import { handleActions } from 'redux-actions';

import {
  OPEN_NPS_MODAL,
  CLOSE_NPS_MODAL,
  SET_NPS_APPLICABLE,
  UPDATE_NPS_SUCCESS,
} from './actions';

const initialState = {
  isNPSApplicable: false,
  isModalOpen: false,
  npsLinkUrl: '',
};

export default handleActions(
  {
    [OPEN_NPS_MODAL]: (state) => ({ ...state, isModalOpen: true }),
    [CLOSE_NPS_MODAL]: (state) => ({
      ...state,
      isModalOpen: false,
    }),
    [SET_NPS_APPLICABLE]: (state, { payload }) => ({
      ...state,
      isNPSApplicable: payload.value,
    }),
    // TODO: handle errors if NPS Update Fails
    [UPDATE_NPS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isNPSApplicable: false,
    }),
  },
  initialState
);
