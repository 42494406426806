import { ajax } from 'rxjs/ajax';
import {
  catchError,
  switchMap,
  withLatestFrom,
  map as rxMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';

import { authorizationHeaderSelector } from '../../auth/storage/selectors';
import {
  changePasswordError,
  changePasswordSuccess,
  CHANGE_PASSWORD,
} from './actions';

export default (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_PASSWORD),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      ajax({
        method: 'POST',
        url: '/gateway-server/accounts/settings/password',
        headers: {
          authorization: authorizationHeaderSelector(state),
        },
        body: payload,
      }).pipe(
        rxMap(() => changePasswordSuccess()),
        catchError((e) => of(changePasswordError(e)))
      )
    )
  );
