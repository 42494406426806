import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_riskTypesForm';

export default defineMessages({
  choiceOne: {
    id: `${namespace}_stepThree_choiceOne`,
    defaultMessage: 'Invalidita II. stupně',
  },
  choiceTwo: {
    id: `${namespace}_stepThree_choiceTwo`,
    defaultMessage: 'Invalidita III. stupně',
  },
  choiceThree: {
    id: `${namespace}_stepThree_choiceThree`,
    defaultMessage: 'Pracovní neschopnost',
  },
  choiceFour: {
    id: `${namespace}_stepThree_choiceFour`,
    defaultMessage: 'Ztráta zaměstnaní',
  },
  choiceFive: {
    id: `${namespace}_stepThree_choiceFive`,
    defaultMessage: 'Hospitalizace',
  },
  choiceSix: {
    id: `${namespace}_stepThree_choiceSix`,
    defaultMessage: 'Ošetřování osoby blízké',
  },
  stepThree_backButtonLabel: {
    id: `${namespace}_stepThree_backButtonLabel`,
    defaultMessage: 'Zpět',
  },
  stepThree_continueButtonLabel: {
    id: `${namespace}_stepThree_continueButtonLabel`,
    defaultMessage: 'Pokračovat',
  },
  findingRisks: {
    id: `${namespace}_findingRisks`,
    defaultMessage: 'Dohledáváme pojištěná rizika, které máte s námi uzavřena.',
  },
  uncoveredRisks: {
    id: `${namespace}_uncoveredRisks`,
    defaultMessage: 'Vypadá to, že pro tato rizika nejste pojištěn',
  },
});
