import { createAction } from 'redux-actions';

const namespace = 'crdf.journeys.insuranceContracts';

export const FETCH_INSURANCE_CONTRACTS = `${namespace}.fetchContracts`;
export const FETCH_INSURANCE_CONTRACTS_SUCCESS = `${namespace}.fetchContractsSuccess`;
export const FETCH_INSURANCE_CONTRACTS_ERROR = `${namespace}.fetchContractsError`;

export const fetchContracts = createAction(FETCH_INSURANCE_CONTRACTS);
export const fetchContractsSuccess = createAction(
  FETCH_INSURANCE_CONTRACTS_SUCCESS
);
export const fetchContractsError = createAction(
  FETCH_INSURANCE_CONTRACTS_ERROR
);
