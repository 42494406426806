import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_riskDetails_hospitalization';

export default defineMessages({
  sinceLabel: {
    id: `${namespace}_stepFour_sinceLabel`,
    defaultMessage: 'Od',
  },
  hasUntilLabel: {
    id: `${namespace}_stepFour_hasUntilLabel`,
    defaultMessage: 'Zadat datum ukončení',
  },
  untilLabel: {
    id: `${namespace}_stepFour_untilLabel`,
    defaultMessage: 'Do',
  },
  reasonLabel: {
    id: `${namespace}_stepFour_reasonLabel`,
    defaultMessage: 'Hlásíte událost kvůli',
  },
  hospitalisation_sinceHelperText: {
    id: `${namespace}_hospitalisation_sinceHelperText`,
    defaultMessage: 'Datum začátku hospitalizace',
  },
  hospitalisation_untilHelperText: {
    id: `${namespace}_hospitalisation_untilHelperText`,
    defaultMessage: 'Datum ukončení invalidity',
  },
  examinatedHeading: {
    id: `${namespace}_stepFour_examinatedHeading`,
    defaultMessage: 'Absolvoval/a jste:',
  },
  undergoneSpecialExamLabel: {
    id: `${namespace}_stepFour_undergoneSpecialExamLabel`,
    defaultMessage: 'Vyšetření lékařem/specialistou',
  },
  undergoneImagingExamLabel: {
    id: `${namespace}_stepFour_undergoneImagingExamLabel`,
    defaultMessage: 'Zobrazovací vyšetření (např. RTG, MRI, CT, SONO)',
  },
  undergoneSurgeryOrHospitalisedLabel: {
    id: `${namespace}_stepFour_undergoneSurgeryOrHospitalisedLabel`,
    defaultMessage: 'Operaci nebo jste byl/a hospitalizován/a',
  },
});
