import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import FormattedDate from '../../../../../../components/formatters/FormattedDate';
import { ColoredIcon, WarningIcon } from '../../../../../../components/icons';

const propTypes = {
  message: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  isFirst: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  withWarning: PropTypes.bool,
};
const defaultProps = {
  isFirst: false,
  isHighlighted: false,
  withWarning: false,
};

const HistoryItem = ({
  message,
  date,
  isFirst,
  isHighlighted,
  withWarning,
}) => (
  <div className="relative text-cardif-gray-dark text-sm">
    <span
      className={cx('absolute z-10', {
        'w-2 h-2 bg-gray-300 rounded-full left-0 bottom-7.5': !withWarning,
        '-left-1.5 bottom-6.5': withWarning,
      })}
    >
      {withWarning && (
        <ColoredIcon color="green" icon={<WarningIcon size={19} />} />
      )}
    </span>
    <span
      className={cx('absolute left-[3px] w-[1px] border border-gray-300', {
        'h-full': !isFirst,
        'h-0': isFirst,
        'bottom-7.5': !withWarning,
        'bottom-8': withWarning,
      })}
    />
    <div
      className={cx(
        'py-2 pl-10 relative before:absolute before:content-[""] before:w-0 before:h-0 before:left-5 before:top-auto before:right-auto before:bottom-8 before:border-[20px]',
        {
          'before:border-history-white': !isHighlighted || !withWarning,
          'before:border-history-gray': isHighlighted || withWarning,
        }
      )}
    >
      <div
        className={cx('rounded-md p-4 mb-1', {
          'bg-white': !isHighlighted || !withWarning,
          'bg-gray-200': isHighlighted || withWarning,
        })}
      >
        {message}
      </div>
      <span className="px-4">
        <FormattedDate date={date} />
      </span>
    </div>
  </div>
);

HistoryItem.propTypes = propTypes;
HistoryItem.defaultProps = defaultProps;

export default HistoryItem;
