import { combineEpics } from 'redux-observable';

import fetchActiveJourneyEpic from './fetchActiveJourney';
import fetchJourneyStateEpic from './fetchJourneyState';
import fetchRiskTypesEpic from './fetchRisks';
import initEpic from './init';
import initStateEpic from './initState';
import initFinalizerEpic from './initFinalizer';
import reinitWatcher from './reinitWatcher';
import refetchRiskDetail from './refetchRiskDetail';

export default combineEpics(
  fetchActiveJourneyEpic,
  fetchJourneyStateEpic,
  fetchRiskTypesEpic,
  initStateEpic,
  initEpic,
  initFinalizerEpic,
  reinitWatcher,
  refetchRiskDetail
);
