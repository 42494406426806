import { useState, Children, cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';

import FAQItem from './Item/item';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
const defaultProps = {};

const Faqs = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    Children.forEach(children, (child) => {
      if (child.type.name !== FAQItem.name)
        throw new Error(
          'Faqs component children must be an instance of FAQItem'
        );
    });
  }, [children]);

  return Children.map(children, (child, index) =>
    cloneElement(child, {
      onOpen: () => setActiveIndex(index),
      onClose: () => setActiveIndex(null),
      isOpen: activeIndex === index,
    })
  );
};

Faqs.propTypes = propTypes;
Faqs.defaultProps = defaultProps;

export default Faqs;
