import { handleActions } from 'redux-actions';

import {
  FETCH_NOL_LIST,
  FETCH_NOL_LIST_ERROR,
  FETCH_NOL_LIST_SUCCESS,
} from './actions';

const initialState = {
  isLoading: false,
  isInitialized: false,
  nols: [],
};

export default handleActions(
  {
    [FETCH_NOL_LIST]: (state) => ({ ...state, isLoading: true }),
    [FETCH_NOL_LIST_SUCCESS]: (state, { payload }) => ({
      ...state,
      isInitialized: true,
      isLoading: false,
      nols: [...payload],
    }),
    [FETCH_NOL_LIST_ERROR]: (state) => ({ ...state, isLoading: false }), // TODO: handle errors
  },
  initialState
);
