import { curryN, propEq } from 'ramda';

import runValidations, {
  isRequired,
  hasLengthMax,
  isEmail,
  hasOnlyDigits,
  hasAgeInInterval,
  hasLength,
  isTrimmedBothSides,
} from '../../../forms/validators';

const validations = {
  firstName: {
    validate: [isRequired(), hasLengthMax(100), isTrimmedBothSides()],
  },
  lastName: {
    validate: [isRequired(), hasLengthMax(100), isTrimmedBothSides()],
  },
  email: {
    validate: [isRequired(), isEmail(), hasLengthMax(100)],
  },
  businessId: {
    when: propEq('isSelfEmployed', true),
    validate: [isRequired(), hasOnlyDigits(), hasLength(8)],
  },
  birthDate: {
    validate: [isRequired(), hasAgeInInterval(18, 110)],
  },
  'birthNumber.number': {
    when: propEq('foreigner', false),
    validate: [isRequired(), hasOnlyDigits(), hasLengthMax(6)],
  },
  'birthNumber.suffix': {
    when: propEq('foreigner', false),
    validate: [isRequired(), hasOnlyDigits(), hasLengthMax(4)],
  },
  nationality: {
    validate: [isRequired()],
  },
};

export default curryN(2, (deps, values) =>
  runValidations(validations, deps, values)
);
