import { combineEpics, ofType } from 'redux-observable';
import { forkJoin } from 'rxjs';
import {
  map as rxMap,
  withLatestFrom,
  filter as rxFilter,
  switchMap,
  take,
} from 'rxjs/operators';

import { openNpsModal } from '../actions';
import { UPLOAD_DOCUMENTS_SUCCESS } from '../../upload/actions';
import { isNPSApplicableSelector } from '../selectors';
import { nolUnmetRequirementsSelector } from '../../nol/detail/selectors';
import { FETCH_NOL_DETAIL_SUCCESS } from '../../nol/detail/actions';

const openModal = (action$, state$) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTS_SUCCESS),
    switchMap(() =>
      forkJoin({
        oldState: state$.pipe(rxMap(nolUnmetRequirementsSelector), take(1)),
        isNPSAppl: state$.pipe(rxMap(isNPSApplicableSelector), take(1)),
        newState: action$.pipe(
          ofType(FETCH_NOL_DETAIL_SUCCESS),
          take(1),
          withLatestFrom(state$),
          rxMap(([, state]) => nolUnmetRequirementsSelector(state))
        ),
      }).pipe(
        rxFilter(
          ({ oldState, newState, isNPSAppl }) =>
            newState.length === 1 && oldState.length > 1 && isNPSAppl
        ),
        rxMap(() => openNpsModal())
      )
    )
  );

export default combineEpics(openModal);
