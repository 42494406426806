import React from 'react';
import { useIntl } from 'react-intl';

import JourneyLayout from '../../modules/journeys/JourneyLayout/JourneyLayout';
import messages from '../journeys/messages';
import { PersonIcon } from '../../components/icons';
import ContactDataForm from '../../modules/registration/ContactDataForm/ContactDataForm';

const RegistrationContactDataPage = () => {
  const { formatMessage } = useIntl();
  return (
    <JourneyLayout
      step="contact"
      heading={formatMessage(messages.stepOne_title)}
      text={formatMessage(messages.stepOne_text)}
      icon={<PersonIcon />}
    >
      <ContactDataForm />
    </JourneyLayout>
  );
};

export default RegistrationContactDataPage;
