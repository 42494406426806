import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import Link from '../../../../../../components/Link';
import Grid from '../../../../../../components/Grid';
import Span from '../../../../../../components/Grid/Span';
import Container from '../../../../../../components/layout/Container';
import Spacing from '../../../../../../components/layout/Spacing';
import Heading from '../../../../../../components/text/Heading';
import { nolMetRequirementsSelector } from '../../../selectors';
import messages from './messages';
import VerificationCard from './verificationCard';
import { fetchNolDoc } from '../../../../../docView/actions';
import Image from '../../../../../../components/images';

const propTypes = {};
const defaultProps = {};

const NolDetailUploadedTab = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const uploaded = useSelector(nolMetRequirementsSelector);

  return (
    <Spacing>
      {uploaded.length ? (
        uploaded.map((req, idx) => (
          <Grid key={idx} extraClass="">
            <Heading variant="subtitle">{req.documentType.name}</Heading>
            <Container variant="form" bgColor="gray" extraClass="">
              {req.documents.map((doc, i) => (
                <div
                  key={`${req.requirementId}-${i}`}
                  className="flex items-center p-2"
                >
                  {doc.name?.substr(doc.name.length - 4) === '.jpg' ? (
                    <Image
                      srcFileName="picture_BG.png"
                      title="Obrázek / Foto"
                      className="mr-5 mb-1 mt-1"
                      height={26}
                    />
                  ) : (
                    <Image
                      srcFileName="document_BG.png"
                      title="Dokument"
                      className="mr-6"
                      height={36}
                    />
                  )}
                  {doc.documentId ? (
                    <>
                      <Link
                        to="#"
                        label={doc.originalName}
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(fetchNolDoc({ doc, action: 'view' }));
                        }}
                        className="mr-4 max-w-full break-all"
                      />
                      <Link
                        to="#"
                        label={
                          <Image
                            srcFileName="download.png"
                            title="Stáhnout soubor"
                            className="mr-6"
                            height={16}
                          />
                        }
                        size="sm"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(fetchNolDoc({ doc, action: 'download' }));
                        }}
                      />{' '}
                      <a id={`download_${doc.documentId}`} />
                    </>
                  ) : (
                    <Span xs={5} extraClass="font-cardif-light text-sm">
                      <></>
                    </Span>
                  )}
                </div>
              ))}
            </Container>
          </Grid>
        ))
      ) : (
        <VerificationCard message={formatMessage(messages.verification)} />
      )}
    </Spacing>
  );
};

NolDetailUploadedTab.propTypes = propTypes;
NolDetailUploadedTab.defaultProps = defaultProps;

export default NolDetailUploadedTab;
