import React from 'react';
import { useIntl } from 'react-intl';

import Container from '../../components/layout/Container';
import Spacing from '../../components/layout/Spacing';
import Heading from '../../components/text/Heading';
import Settings from '../../modules/settings/Settings';
import messages from './messages';

const SettingsPage = () => {
  const intl = useIntl();

  return (
    <>
      <Container bgColor="white">
        <Spacing>
          <Heading>{intl.formatMessage(messages.title)}</Heading>
        </Spacing>
      </Container>
      <Settings />
    </>
  );
};

export default SettingsPage;
