import { isNotNil } from 'ramda-adjunct';
import { handleActions } from 'redux-actions';

import {
  LOGIN_INIT,
  LOGIN_DEINIT,
  GET_PHONE_STATUS,
  GET_PHONE_STATUS_SUCCESS,
  GET_PHONE_STATUS_ERROR,
  VERIFY_OTP,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
  CREATE_ACCOUNT_ERROR,
  VERIFY_PASSWORD,
  VERIFY_PASSWORD_ERROR,
  VERIFY_PASSWORD_SUCCESS,
  AFTER_LOGIN_ACTION,
  SET_PASSWORD_RESET_STEP,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_ERROR,
  REQUEST_PASSWORD_RESET,
  VERIFY_PASSWORD_RESET,
  VERIFY_PASSWORD_RESET_SUCCESS,
  VERIFY_PASSWORD_RESET_ERROR,
  SET_SERVER_HAS_ERROR,
} from '../actions';

const initialState = {
  isInitialized: false,
  step: 'phone', // phone, otp, password, register, createPassword, notFound, phoneReset, otpReset, resetConfirm
  hasNextStep: true,
  isSubmitting: false,
  flowVariant: null, // login or register
  afterLoginAction: null,
  hasServerError: false,
};

const loadingStartReducer = (state) => ({ ...state, isSubmitting: true });
const loadingStopReducer = (state) => ({ ...state, isSubmitting: false });

const otpSuccessReducer = (state) => {
  if (state.flowVariant === 'login') return { ...state, hasNextStep: false };
  return { ...state, isSubmitting: false, step: 'createPassword' };
};

export default handleActions(
  {
    [LOGIN_INIT]: () => ({ ...initialState, isInitialized: true }),
    [LOGIN_DEINIT]: () => ({ ...initialState }),
    [GET_PHONE_STATUS]: loadingStartReducer,
    [GET_PHONE_STATUS_SUCCESS]: (state, { payload }) => ({
      ...state,
      isSubmitting: false,
      flowVariant: isNotNil(payload.otpId) ? 'register' : 'login',
      step: isNotNil(payload.otpId) ? 'otp' : 'password',
    }),
    [GET_PHONE_STATUS_ERROR]: (state, { payload }) => ({
      ...state,
      isSubmitting: false,
      step:
        payload.isNotRegistered && !payload.isNotValidFormat
          ? 'notFound'
          : 'phone',
      hasNextStep: !(payload.isNotRegistered && !payload.isNotValidFormat),
    }),
    [VERIFY_OTP]: loadingStartReducer,
    [VERIFY_OTP_ERROR]: loadingStopReducer,
    [VERIFY_OTP_SUCCESS]: otpSuccessReducer,
    [CREATE_ACCOUNT_ERROR]: (state) => ({
      ...state,
      hasServerError: true,
    }),
    [VERIFY_PASSWORD]: loadingStartReducer,
    [VERIFY_PASSWORD_ERROR]: loadingStopReducer,
    [VERIFY_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      isSubmitting: false,
      step: 'otp',
    }),
    [AFTER_LOGIN_ACTION]: (state, { payload }) => ({
      ...state,
      afterLoginAction: payload,
    }),
    [SET_PASSWORD_RESET_STEP]: (state) => ({ ...state, step: 'phoneReset' }),
    [REQUEST_PASSWORD_RESET]: loadingStartReducer,
    [VERIFY_PASSWORD_RESET]: loadingStartReducer,
    [REQUEST_PASSWORD_RESET_ERROR]: (state) => ({
      ...state,
      hasServerError: true,
      isSubmitting: false,
    }),
    [VERIFY_PASSWORD_RESET_ERROR]: loadingStopReducer,
    [REQUEST_PASSWORD_RESET_SUCCESS]: (state) => ({
      ...state,
      step: 'otpReset',
      isSubmitting: false,
    }),
    [VERIFY_PASSWORD_RESET_SUCCESS]: (state) => ({
      ...state,
      step: 'resetConfirm',
      isSubmitting: false,
    }),
    [SET_SERVER_HAS_ERROR]: (state) => ({
      ...state,
      hasServerError: true,
    }),
  },
  initialState
);
