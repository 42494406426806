import { createAction } from 'redux-actions';

const namespace = 'crdf.notifications';

export const FETCH_NOTIFICATIONS = `${namespace}.fetch`;
export const FETCH_NOTIFICATIONS_SUCCESS = `${namespace}.fetchSuccess`;
export const FETCH_NOTIFICATIONS_ERROR = `${namespace}.fetchError`;

export const MARK_NOTIFICATION_READ = `${namespace}.markRead`;
export const MARK_NOTIFICATION_READ_SUCCESS = `${namespace}.markReadSuccess`;
export const MARK_NOTIFICATION_READ_ERROR = `${namespace}.markReadError`;

export const fetchNotifications = createAction(FETCH_NOTIFICATIONS);
export const fetchNotificationsSuccess = createAction(
  FETCH_NOTIFICATIONS_SUCCESS
);
export const fetchNotificationsError = createAction(FETCH_NOTIFICATIONS_ERROR);

export const markRead = createAction(MARK_NOTIFICATION_READ);
export const markReadSuccess = createAction(MARK_NOTIFICATION_READ_SUCCESS);
export const markReadError = createAction(MARK_NOTIFICATION_READ_ERROR);
