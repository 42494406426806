import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Grid from '../../../../components/Grid';
import Item from './item';
import messages from '../messages';

const propTypes = {
  value: PropTypes.string.isRequired,
};
const defaultProps = {};

const PasswordValidations = ({ value }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid gap={2} extraClass="pt-2">
      <Item
        validation={formatMessage(messages.lengthValidation)}
        isValid={value.length >= 8}
      />
      <Item
        validation={formatMessage(messages.uppercaseValidation)}
        isValid={/\p{Lu}/u.test(value)}
      />
      <Item
        validation={formatMessage(messages.lowercaseValidation)}
        isValid={/\p{Ll}/u.test(value)}
      />
      <Item
        validation={formatMessage(messages.digitValidation)}
        isValid={/[0-9]/.test(value)}
      />
      <Item
        validation={`${formatMessage(
          messages.specialCharacterValidation
        )} ${'(!"#$%&()*+,-./:;<=>?@[]^_`{|}~)'}'.`}
        isValid={/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\\/?~]/.test(value)}
      />
    </Grid>
  );
};

PasswordValidations.propTypes = propTypes;
PasswordValidations.defaultProps = defaultProps;

export default PasswordValidations;
