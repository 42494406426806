import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '../../../../../components/Grid/grid';
import Spacing from '../../../../../components/layout/Spacing';
import Button from '../../../../../components/Button/button';
import DatePickerField from '../../../../forms/fields/datePicker';
import CheckboxField from '../../../../forms/fields/checkbox';
import Paragraph from '../../../../../components/text/Paragraph/paragraph';
import commonMessages from '../common/messages';
import TextField from '../../../../forms/fields/textField';
import TextAreaField from '../../../../forms/fields/textarea';
import CountrySelectField from '../../../../forms/fields/countrySelect';
import {
  formMount,
  formUnmount,
  submitRiskDetailForm,
} from '../../../ActiveJourneyProvider/actions';
import {
  contextSelector,
  formSelector,
  riskDetailsSelector,
} from '../../../ActiveJourneyProvider/selectors';
import messages from './messages';
import validate from './validators';

const IUForm = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isInitialized } = useSelector(contextSelector);
  const riskDetails = useSelector(riskDetailsSelector);
  const { isSubmitting } = useSelector(formSelector);

  useEffect(() => {
    dispatch(formMount());

    return () => dispatch(formUnmount());
  }, []);

  if (!isInitialized) return null;

  return (
    <Formik
      validate={validate({ intl })}
      validateOnBlur
      validateOnChange
      initialValues={riskDetails}
      onSubmit={(values) => dispatch(submitRiskDetailForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <Grid gap={8}>
            <DatePickerField
              required
              name="involuntaryUnemploymentFrom"
              label={formatMessage(messages.sinceLabel)}
              helperText={formatMessage(messages.jobLoss_sinceHelperText)}
            />
            <CheckboxField
              name="hasEnded"
              label={formatMessage(messages.jobLoss_hasUntilLabel)}
            />
            {formik.values.hasEnded && (
              <DatePickerField
                required
                name="involuntaryUnemploymentTo"
                label={formatMessage(messages.untilLabel)}
              />
            )}
            <CheckboxField
              name="unemploymentDueToMedicalReason"
              label={formatMessage(messages.jobLossDueToIllness)}
            />
            <TextAreaField
              required
              name="description"
              label={formatMessage(messages.descriptionLabel)}
              helperText={formatMessage(messages.jobLoss_descriptionHelperText)}
            />
            <Grid gap={2}>
              <Paragraph center color="gray">
                {formatMessage(messages.jobLossEmployerInfo)}
              </Paragraph>
              <TextField
                required
                name="employer.name"
                label={formatMessage(messages.jobLossEmployerNameLabel)}
              />
            </Grid>
            <TextField
              required
              name="employer.street"
              label={formatMessage(messages.streetLabel)}
            />
            <TextField
              required
              name="employer.city"
              label={formatMessage(messages.cityLabel)}
            />
            <TextField
              required
              name="employer.postalCode"
              filterPattern={/^[0-9]+$/}
              label={formatMessage(messages.postalCodeLabel)}
            />
            <CountrySelectField
              name="employer.country"
              label={formatMessage(messages.countryLabel)}
            />
          </Grid>
          <Spacing variant="item" />
          <Grid xs={2} gap={4}>
            <Button
              full
              variant="gray"
              onClick={() => navigate('../risk-type')}
              label={formatMessage(commonMessages.stepFour_backButtonLabel)}
            />
            <Button
              full
              disabled={isSubmitting}
              type="submit"
              label={formatMessage(commonMessages.stepFour_continueButtonLabel)}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default IUForm;
