import {
  applySpec,
  defaultTo,
  map,
  pipe,
  prop,
  propOr,
  splitAt,
  zipObj,
  slice,
} from 'ramda';
import { createSelector } from 'reselect';

import { authSelector } from '../selectors';

// eslint-disable-next-line import/prefer-default-export
export const accountSelector = pipe(authSelector, prop('account'));
export const accountPhonePartsSelector = pipe(
  accountSelector,
  prop('phoneNumber'),
  applySpec({
    phonePrefix: slice(1, 4),
    phoneNumber: slice(4, undefined),
  })
);

export const npsConfirmedSelector = pipe(accountSelector, prop('npsConfirmed'));

export const accountFormValuesSelector = createSelector(
  accountSelector,
  applySpec({
    firstName: propOr('', 'firstName'),
    lastName: propOr('', 'lastName'),
    email: propOr('', 'email'),
    birthNumber: pipe(
      propOr('', 'birthNumber'),
      splitAt(6),
      map(defaultTo('')),
      zipObj(['number', 'suffix'])
    ),
    foreigner: propOr(false, 'foreigner'),
    birthDate: propOr(null, 'birthDate'),
    nationality: propOr('203', 'nationality'),
    isSelfEmployed: propOr(false, 'isSelfEmployed'),
    businessId: propOr('', 'businessId'),
    npsConfirmed: propOr(null, 'npsConfirmed'),
  })
);
