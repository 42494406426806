import React from 'react';
import PropTypes from 'prop-types';

import UploadBox from '../../components/UploadBox';

const propTypes = {
  inputProps: PropTypes.shape().isRequired,
  rootProps: PropTypes.shape().isRequired,
  title: PropTypes.string,
  info: PropTypes.node,
  inModal: PropTypes.bool,
};
const defaultProps = {
  title: null,
  info: null,
  inModal: false,
};

const DropZone = ({ inputProps, rootProps, title, info, inModal }) => (
  <div {...rootProps}>
    <input {...inputProps} />
    <UploadBox title={title} info={info} inModal={inModal} />
  </div>
);

DropZone.propTypes = propTypes;
DropZone.defaultProps = defaultProps;

export default DropZone;
