import { handleActions } from 'redux-actions';

import { FINALIZE_JOURNEY_SUCCESS } from '../ActiveJourneyProvider/actions';
import { CLOSE_NOL_CREATED_MODAL } from './actions';

const initalState = {
  isOpen: false,
};

export default handleActions(
  {
    [FINALIZE_JOURNEY_SUCCESS]: () => ({ isOpen: true }),
    [CLOSE_NOL_CREATED_MODAL]: () => ({ isOpen: false }),
  },
  initalState
);
