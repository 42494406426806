import { pipe, prop } from 'ramda';

export const settingsSelector = prop('settings');
export const settingsSettingsSelector = pipe(
  settingsSelector,
  prop('settings')
);
export const notificationsFormSelector = pipe(
  settingsSelector,
  prop('notificationsForm')
);
export const phoneNumberFormSelector = pipe(
  settingsSelector,
  prop('phoneNumberForm')
);
export const changeEmailFormSelector = pipe(
  settingsSelector,
  prop('changeEmailForm')
);
export const changePasswordFormSelector = pipe(
  settingsSelector,
  prop('changePasswordForm')
);

export const changeEmailLandingSelector = pipe(
  settingsSelector,
  prop('changeEmailLanding')
);
