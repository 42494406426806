import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '../../../../../../components/Grid';
import Span from '../../../../../../components/Grid/Span';
import { nolDetailNolSelector } from '../../../selectors';
import messages from '../../messages';
import {
  common,
  claimDetailTakingCare,
  claimDetailUnemployment,
  claimDetailHospitalization,
  claimDetailPermanentDisability,
  claimDetailPartialPermanentDisability,
  claimDetailTemporaryDisability,
} from './rowDefinitions';
import SimpleTable from '../../../../../SimpleTable/SimpleTable';

const NolInfoCard = () => {
  const detail = useSelector(nolDetailNolSelector);

  return (
    <Grid xs={3}>
      <Span xs={3} lg={2}>
        <SimpleTable messages={messages} data={detail} rows={common} />
        {detail.unemployment && (
          <SimpleTable
            messages={messages}
            data={detail.unemployment}
            rows={claimDetailUnemployment}
          />
        )}
        {detail.temporaryDisability && (
          <SimpleTable
            messages={messages}
            data={detail.temporaryDisability}
            rows={claimDetailTemporaryDisability}
          />
        )}
        {detail.partialPermanentDisability && (
          <SimpleTable
            messages={messages}
            data={detail.partialPermanentDisability}
            rows={claimDetailPartialPermanentDisability}
          />
        )}
        {detail.takingCare && (
          <SimpleTable
            messages={messages}
            data={detail.takingCare}
            rows={claimDetailTakingCare}
          />
        )}
        {detail.permanentDisability && (
          <SimpleTable
            messages={messages}
            data={detail.permanentDisability}
            rows={claimDetailPermanentDisability}
          />
        )}
        {detail.hospitalization && (
          <SimpleTable
            messages={messages}
            data={detail.hospitalization}
            rows={claimDetailHospitalization}
          />
        )}
      </Span>
    </Grid>
  );
};

export default NolInfoCard;
