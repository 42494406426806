import { ofType } from 'redux-observable';
import { withLatestFrom, map as rxMap } from 'rxjs/operators';

import { CONTINUE_JOURNEY } from '../actions';
import { contextSelector } from '../selectors';
import { navigate } from '../../../navigation/actions';

const determineStep = (state) => {
  const { riskType } = contextSelector(state);

  if (riskType) {
    return navigate({ to: '/journeys/risk-type' });
  }

  return navigate({ to: '/journeys/personal' });
};

export default (action$, state$) =>
  action$.pipe(
    ofType(CONTINUE_JOURNEY),
    withLatestFrom(state$),
    rxMap(([, state]) => determineStep(state))
  );
