import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  switchMap,
  withLatestFrom,
  map as rxMap,
  mergeMap,
  catchError,
} from 'rxjs/operators';
import {
  applySpec,
  identity,
  last,
  path,
  pipe,
  split,
  trim,
  unary,
} from 'ramda';

import {
  FINALIZE_JOURNEY,
  finalizeJourneyError,
  finalizeJourneySuccess,
  reset,
} from '../../actions';
import { finalizeJourneyDataSelector } from '../../selectors';
import { navigate } from '../../../../navigation/actions';

const remapResponse = unary(
  pipe(
    path(['responseHeaders', 'location']),
    split('/'),
    last,
    trim,
    applySpec({ nolId: identity })
  )
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FINALIZE_JOURNEY),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      of(state).pipe(
        rxMap(unary(finalizeJourneyDataSelector)),
        mergeMap(({ riskDate, activeJourneyId }) =>
          ajax({
            useAuth: true,
            method: 'PUT',
            url: `/gateway-server/journeys/${activeJourneyId}`,
            body: {
              occurrenceDate: riskDate,
              productType: 'CPI',
            },
          })
        ),
        rxMap(remapResponse),
        mergeMap(({ nolId }) =>
          of(
            finalizeJourneySuccess({ nolId }),
            navigate({ to: `/nols/${nolId}` }),
            reset()
          )
        ),
        catchError((e) => of(finalizeJourneyError(e)))
      )
    )
  );
