import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../../Grid';

const propTypes = {
  data: PropTypes.shape().isRequired,
  name: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  renderValue: PropTypes.func,
};
const defaultProps = {
  valueSuffix: null,
};

const TextGroup = ({ name, value, renderValue, data }) => (
  <Grid xs={2} gap={6}>
    <div>
      <b>{name}</b>
    </div>
    <div className="break-words">
      {renderValue ? renderValue({ value, data }) : <b>{value}</b>}
    </div>
  </Grid>
);

TextGroup.propTypes = propTypes;
TextGroup.defaultProps = defaultProps;

export default TextGroup;
