import React from 'react';
import PropTypes from 'prop-types';

import { ColoredIcon, LoaderIcon } from '../../../../../../components/icons';
import Paragraph from '../../../../../../components/text/Paragraph';

const propTypes = {
  message: PropTypes.string.isRequired,
};
const defaultProps = {};

const VerificationCard = ({ message }) => (
  <div className="bg-white shadow-faq flex gap-4 py-6 px-8 justify-center items-center rounded-md">
    <ColoredIcon icon={<LoaderIcon />} color="green" />
    <Paragraph>{message}</Paragraph>
  </div>
);

VerificationCard.propTypes = propTypes;
VerificationCard.defaultProps = defaultProps;

export default VerificationCard;
