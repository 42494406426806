import { defineMessages } from 'react-intl';

export default defineMessages({
  legalInfo: {
    id: 'layout_footer_links_legalInfo',
    defaultMessage: 'Právní informace',
  },
  cookiePolicy: {
    id: 'layout_footer_links_cookiePolicy',
    defaultMessage: 'Politika cookies',
  },
});
