import { constructN, evolve, map, pipe, prop, unary, when } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  switchMap,
  withLatestFrom,
  map as rxMap,
  catchError,
  takeUntil,
} from 'rxjs/operators';

import { authorizationHeaderSelector } from '../../auth/storage/selectors';
import {
  CANCEL_FETCH_NOL_LIST,
  fetchNolListError,
  fetchNolListSuccess,
  FETCH_NOL_LIST,
} from './actions';

const parse = unary(
  pipe(
    prop('response'),
    map(evolve({ reported: when(isNotNilOrEmpty, constructN(1, Date)) })),
    unary(fetchNolListSuccess)
  )
);

export default (action$, state$) =>
  action$.pipe(
    ofType(FETCH_NOL_LIST),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      ajax({
        url: '/gateway-server/nol/preview',
        headers: {
          authorization: authorizationHeaderSelector(state),
        },
      }).pipe(
        rxMap(parse),
        catchError((e) => of(fetchNolListError(e))),
        takeUntil(action$.pipe(ofType(CANCEL_FETCH_NOL_LIST)))
      )
    )
  );
