import { defineMessages } from 'react-intl';

export default defineMessages({
  phoneNumberLabel: {
    id: 'settings_forms_phone_phoneNumberLabel',
    defaultMessage: 'Telefonní číslo',
  },
  cardTitle: {
    id: 'settings_forms_phone_cardTitle',
    defaultMessage: 'Telefonní číslo',
  },
  cardText: {
    id: 'settings_forms_phone_cardText',
    defaultMessage:
      'Telefonní číslo využíváte pro přihlášení k vašemu uživatelskému účtu.',
  },
  saveButtonLabel: {
    id: 'settings_forms_phone_saveButtonLabel',
    defaultMessage: 'Uložit',
  },
  otpLabel: {
    id: 'settings_forms_phone_otpLabel',
    defaultMessage: 'Kód z ověřovací SMS',
  },
  otpSent: {
    id: 'settings_forms_phone_otpSent',
    defaultMessage:
      'Ověřovací SMS jsme právě odeslali na zadané telefonní číslo.',
  },
  resendOtpLink: {
    id: 'settings_forms_phone_resendOtpLink',
    defaultMessage: 'Odeslat ověřovací SMS znovu?',
  },
  phoneInUseError: {
    id: 'settings_forms_phone_phoneInUseError',
    defaultMessage: 'Telefonní číslo již používá jiný uživatel.',
  },
  phoneNotValidError: {
    id: 'settings_forms_phone_phoneNotValidError',
    defaultMessage: 'Telefonní číslo není platné.',
  },
  otpNotValidError: {
    id: 'settings_forms_phone_otpNotValidError',
    defaultMessage: 'Ověřovací kód není platný.',
  },
  savePhoneSuccess: {
    id: 'settings_forms_phone_savePhoneSuccess',
    defaultMessage: 'Telefonní číslo bylo uloženo',
  },
});
