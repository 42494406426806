import { curryN, prop, propEq } from 'ramda';

import runValidations, {
  isRequired,
  isNotFuture,
  isChronologicalAfter,
  hasLengthMax,
  hasOnlyDigits,
  hasLength,
} from '../../../../forms/validators';

const validations = {
  involuntaryUnemploymentFrom: {
    validate: [isRequired(), isNotFuture()],
  },
  involuntaryUnemploymentTo: {
    when: propEq('hasEnded', true),
    validate: [
      isRequired(),
      isNotFuture(),
      isChronologicalAfter(prop('involuntaryUnemploymentFrom')),
    ],
  },
  description: {
    validate: [isRequired(), hasLengthMax(500)],
  },
  'employer.name': {
    validate: [isRequired(), hasLengthMax(255)],
  },
  'employer.street': {
    validate: [isRequired(), hasLengthMax(255)],
  },
  'employer.city': {
    validate: [isRequired(), hasLengthMax(255)],
  },
  'employer.postalCode': {
    validate: [isRequired(), hasOnlyDigits(), hasLength(5)],
  },
  'employer.country': {
    validate: [isRequired()],
  },
};

export default curryN(2, (deps, values) =>
  runValidations(validations, deps, values)
);
