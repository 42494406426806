import { createAction } from 'redux-actions';

const namespace = 'crdf.nps';

export const UPDATE_NPS = `${namespace}.updateNPS`;
export const UPDATE_NPS_SUCCESS = `${namespace}.updateNPSSuccess`;
export const UPDATE_NPS_ERROR = `${namespace}.updateNPSError`;

export const updateNps = createAction(UPDATE_NPS);
export const updateNpsSuccess = createAction(UPDATE_NPS_SUCCESS);
export const updateNpsError = createAction(UPDATE_NPS_ERROR);

export const OPEN_NPS_MODAL = `${namespace}.openNpsModal`;
export const CLOSE_NPS_MODAL = `${namespace}.closeNpsModal`;
export const SET_NPS_APPLICABLE = `${namespace}.setNPSApplicable`;
export const OPEN_NPS_QUESTIONNAIRE = `${namespace}.openNpsQuestionnaire`;

export const openNpsModal = createAction(OPEN_NPS_MODAL);
export const closeNpsModal = createAction(CLOSE_NPS_MODAL);
export const setNPSApplicable = createAction(SET_NPS_APPLICABLE);
export const openNpsQuestionnaire = createAction(OPEN_NPS_QUESTIONNAIRE);
