import React from 'react';
import { useIntl } from 'react-intl';

import JourneyLayout from '../../modules/journeys/JourneyLayout/JourneyLayout';
import messages from './messages';
import { PhonendoscopeIcon } from '../../components/icons';
import RiskTypeForm from '../../modules/journeys/forms/RiskTypeForm/RiskTypeForm';

const JourneyRiskTypePage = () => {
  const { formatMessage } = useIntl();

  return (
    <JourneyLayout
      step="risk-type"
      heading={formatMessage(messages.stepThree_title)}
      text=""
      icon={<PhonendoscopeIcon />}
    >
      <RiskTypeForm />
    </JourneyLayout>
  );
};

export default JourneyRiskTypePage;
