import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  ColoredIcon,
  SuccessIcon,
  WarningIcon,
} from '../../../components/icons';
import Link from '../../../components/Link';
import Text from '../../../components/text/Paragraph';
import messages from './messages';
import { FILE_VALIDATION_STATUS_SUCCESS } from '../lnd-legacy/constants';

const propTypes = {
  fWrapper: PropTypes.shape({
    file: PropTypes.shape({ name: PropTypes.string.isRequired }),
    error: PropTypes.shape({ type: PropTypes.string.isRequired }),
    status: PropTypes.string,
  }).isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  onShow: PropTypes.func,
};
const defaultProps = {
  onShow: null,
};

const UploadItem = ({ fWrapper, onCancelUpload, onShow }) => (
  <div className="flex items-center gap-8">
    {!fWrapper.error && (
      <ColoredIcon
        icon={<SuccessIcon size={20} disableScaler />}
        color="green"
      />
    )}
    {fWrapper.error && (
      <ColoredIcon
        icon={<WarningIcon size={20} disableScaler />}
        color="orange"
      />
    )}
    <span className="grow break-all">{fWrapper.file.name}</span>
    {!!onShow && fWrapper.status === FILE_VALIDATION_STATUS_SUCCESS && (
      <Link
        to="#"
        label="Zobrazit"
        size="sm"
        onClick={(e) => {
          e.preventDefault();
          onShow();
        }}
      />
    )}
    {fWrapper.error && (
      <Text color="red" variant="caption" multiline>
        <FormattedMessage {...messages[fWrapper.error.type]} />
      </Text>
    )}
    <span
      className="cursor-pointer text-lxl text-black hover:text-cardif-gray-dark"
      onClick={onCancelUpload}
    >
      &#10005;
    </span>
  </div>
);

UploadItem.propTypes = propTypes;
UploadItem.defaultProps = defaultProps;

export default UploadItem;
