import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import Container from '../../../components/layout/Container';
import Modal from '../../../components/Modal';
import Paragraph from '../../../components/text/Paragraph';
import { npsSelector } from '../selectors';
import { nolDetailSelector } from '../../nol/selectors';
import { closeNpsModal, openNpsQuestionnaire } from '../actions';
import messages from './messages';

const NPSModal = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { isModalOpen } = useSelector(npsSelector);
  const nol = useSelector(nolDetailSelector);

  return (
    <Modal
      onOpen={() => {}}
      onClose={() => dispatch(closeNpsModal())}
      isOpen={isModalOpen}
    >
      <Container variant="modal">
        <Paragraph multiline color="green" extraClass="pb-8 md:pb-16">
          {intl.formatMessage(messages.message)}
        </Paragraph>
        <Grid xs={2} gap={4}>
          <Button
            center
            full
            variant="gray"
            onClick={(e) => {
              e.preventDefault();
              dispatch(closeNpsModal());
            }}
            label={intl.formatMessage(messages.button_close)}
          />
          <Button
            center
            full
            onClick={(e) => {
              e.preventDefault();
              dispatch(openNpsQuestionnaire(nol.id));
              dispatch(closeNpsModal());
            }}
            label={intl.formatMessage(messages.button_fillQuest)}
          />
        </Grid>
      </Container>
    </Modal>
  );
};

export default NPSModal;
