import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bold: PropTypes.bool,
  center: PropTypes.bool,
  variant: PropTypes.oneOf(['body', 'caption']),
  multiline: PropTypes.bool,
  color: PropTypes.oneOf(['black', 'gray', 'red', 'green']),
  extraClass: PropTypes.string,
};
const defaultProps = {
  bold: false,
  center: false,
  variant: 'body',
  multiline: false,
  color: 'black',
};

const Paragraph = ({
  children,
  bold,
  center,
  variant,
  multiline,
  color,
  extraClass,
}) => (
  <p
    className={cx({
      'font-bold': bold,
      'text-center': center,
      'text-xs py-1 px-4 text-gray-700': variant === 'caption',
      'whitespace-pre-wrap': multiline,
      'text-cardif-gray-dark font-light': color === 'gray',
      'text-red-600': color === 'red',
      'text-cardif-green-light': color === 'green',
      [extraClass]: !!extraClass,
    })}
  >
    {children}
  </p>
);

Paragraph.propTypes = propTypes;
Paragraph.defaultProps = defaultProps;

export default Paragraph;
