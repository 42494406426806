import { combineReducers } from 'redux';

import confirmReducer from './confirm.reducer';
import metaReducers from './meta.reducers';
import otpReducers from './otp.reducers';
import passwordReducers from './password.reducers';
import phoneReducers from './phone.reducers';

export default combineReducers({
  meta: metaReducers,
  otp: otpReducers,
  phone: phoneReducers,
  password: passwordReducers,
  confirm: confirmReducer,
});
