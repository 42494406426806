import { handleActions } from 'redux-actions';

import { FETCH_TOKENS_SUCCESS } from '../login/actions';
import {
  INIT_DONE,
  CLEAR_TOKENS,
  REFRESH_TOKENS_SUCCESS,
  AUTHORIZATION_ERROR,
} from './actions';

const initialState = {
  isInitialized: false,
  tokens: null,
};

export default handleActions(
  {
    [INIT_DONE]: (state, { payload }) => ({
      isInitialized: true,
      tokens: payload,
    }),
    [FETCH_TOKENS_SUCCESS]: (state, { payload }) => ({
      ...state,
      tokens: { ...payload },
    }),
    [REFRESH_TOKENS_SUCCESS]: (state, { payload }) => ({
      ...state,
      tokens: { ...payload },
    }),
    [CLEAR_TOKENS]: (state) => ({ ...state, tokens: null }),
    [AUTHORIZATION_ERROR]: (state) => ({ ...state, tokens: null }),
  },
  initialState
);
