import React from 'react';
import { useIntl } from 'react-intl';

import JourneyLayout from '../../modules/journeys/JourneyLayout/JourneyLayout';
import messages from './messages';
import { CardIcon } from '../../components/icons';
import BankAccountForm from '../../modules/journeys/forms/BankAccountForm/BankAccountForm';

const JourneyBankAccountPage = () => {
  const { formatMessage } = useIntl();

  return (
    <JourneyLayout
      step="bank-account"
      heading={formatMessage(messages.stepFive_title)}
      text=""
      icon={<CardIcon size={68} />}
    >
      <BankAccountForm />
    </JourneyLayout>
  );
};

export default JourneyBankAccountPage;
