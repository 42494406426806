import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import FooterDesign from '../../components/layout/Footer';
import Link from '../../components/Link';
import { isAuthenticatedSelector } from '../auth/storage/selectors';
import Logo from '../../components/images/Logo';
import messages from './footer.messages';

export const propTypes = {};
const defaultProps = {};

const Footer = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const logo = useMemo(() => {
    if (isAuthenticated) {
      return (
        <Link
          to="/nols"
          component="a"
          label={<Logo variant="footer" />}
          onClick={(e) => {
            e.preventDefault();
            navigate('/nols');
          }}
        />
      );
    }
    return (
      <Link
        to="/"
        component="a"
        label={<Logo variant="footer" />}
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
      />
    );
  }, [isAuthenticated]);

  const links = useMemo(
    () => [
      <Link
        key="legal-info"
        label={formatMessage(messages.legalInfo)}
        to=" https://cardif.cz/ochrana-osobnich-udaju"
        target="_blank"
      />,
      <Link
        key="cookie-policy"
        label={formatMessage(messages.cookiePolicy)}
        to="https://cardif.cz/cookies"
        target="_blank"
      />,
    ],
    []
  );

  return <FooterDesign links={links} logo={logo} />;
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
