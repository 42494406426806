import {
  addDefaultFormatters,
  formatAddress,
  formatDate,
} from '../../../../../SimpleTable/fieldFormatters';

export default addDefaultFormatters([
  { name: 'name' },
  { name: 'email' },
  { name: 'phoneNumber' },
  { name: 'citizenship' },
  { name: 'birthDate', valueFormatter: formatDate },
  { name: 'selfEmployerNumber' },
  { name: 'permanentAddress', valueFormatter: formatAddress },
  { name: 'contactAddress', valueFormatter: formatAddress },
]);
