import { unary } from 'ramda';
import { ofType } from 'redux-observable';
import { switchMap, map as rxMap, catchError, of } from 'rxjs';

import { submitError, submitSuccess, SUBMIT_CALL_ME_BACK } from './actions';
import { CALL_ME_BACK_TYPES_GENERIC } from './constants';

const getAjaxConfig = ({ type }) => {
  if (type === CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_DECLARATION) {
    return {
      useAuth: true,
      method: 'POST',
      url: '/gateway-server/call-me-back/risk',
    };
  }

  if (type === CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_REGISTRATION) {
    return {
      useAuth: true,
      method: 'POST',
      url: '/gateway-server/call-me-back',
    };
  }

  return {
    method: 'POST',
    url: '/gateway-server/call-me-back/login',
  };
};

const toApiPayload = ({ type, meta, phone }) => {
  if (type === CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_REGISTRATION)
    return {
      phone,
      callMeBackType: CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_REGISTRATION,
      typeRelatedAttributes: {},
    };

  if (type === CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_DECLARATION)
    return { phone, product: 'CPI', risk: meta.risk };

  return { phone };
};

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(SUBMIT_CALL_ME_BACK),
    switchMap(({ payload }) =>
      ajax({
        ...getAjaxConfig({ type: payload.type }),
        body: toApiPayload(payload),
      }).pipe(
        rxMap(unary(submitSuccess)),
        catchError((e) => of(submitError(e)))
      )
    )
  );
