/* eslint-disable no-dupe-keys */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  variant: PropTypes.oneOf(['first', 'prev', 'next', 'last']).isRequired,
};

const defaultProps = {};

const Arrow = ({ variant }) => (
  <>
    <div
      className={cx(
        'absolute arrow border-t-2 border-r-2 border-inherit w-1/3 h-1/3',
        {
          'rotate-[225deg]': ['first', 'prev'].includes(variant),
          'rotate-45': ['next', 'last'].includes(variant),
          'translate-x-[40%]': variant === 'first',
          'translate-x-[20%]': variant === 'prev',
          '-translate-x-[10%]': variant === 'next',
          '-translate-x-[30%]': variant === 'last',
        }
      )}
    ></div>
    {['first', 'last'].includes(variant) && (
      <div
        className={cx('absolute arrow border-r-2 border-inherit w-1/2 h-1/2', {
          '-translate-x-[70%]': variant === 'first',
          '-translate-x-[10%]': variant === 'last',
        })}
      />
    )}
  </>
);

Arrow.propTypes = propTypes;
Arrow.defaultProps = defaultProps;

export default Arrow;
