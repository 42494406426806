import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  withLatestFrom,
  catchError,
  mergeMap,
  map as rxMap,
  takeLast,
} from 'rxjs/operators';
import { curryN, unary } from 'ramda';

import { toApiAddresses } from '../../mappers';
import {
  SUBMIT_CONTACT_DATA_FORM,
  submitContactDataFormSuccess,
  submitContactDataFormError,
  genericSubmit,
  genericSubmitSuccess,
  genericSubmitError,
} from '../../actions';
import { contextSelector } from '../../selectors';

const createUrl = (state) => {
  const { activeJourneyId } = contextSelector(state);

  return `/gateway-server/journeys/${activeJourneyId}/address`;
};

const submitAjax = curryN(3, (ajax, state, data) =>
  ajax({
    useAuth: true,
    method: 'PUT',
    url: createUrl(state),
    body: data,
  })
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(SUBMIT_CONTACT_DATA_FORM),
    rxMap(({ payload }) => ({
      addresses: toApiAddresses(payload),
      values: payload,
    })),
    withLatestFrom(state$),
    mergeMap(([{ addresses, values }, state]) =>
      merge(
        of(genericSubmit()),
        of(...addresses).pipe(
          mergeMap(unary(submitAjax(ajax, state))),
          takeLast(1),
          mergeMap(() =>
            of(submitContactDataFormSuccess(values), genericSubmitSuccess())
          ),
          catchError((e) =>
            of(submitContactDataFormError(e), genericSubmitError())
          ) // TODO: Handle errors
        )
      )
    )
  );
