import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '../../../../../components/Grid/grid';
import Spacing from '../../../../../components/layout/Spacing';
import Button from '../../../../../components/Button/button';
import DatePickerField from '../../../../forms/fields/datePicker';
import CheckboxField from '../../../../forms/fields/checkbox';
import SelectField from '../../../../forms/fields/select';
import Paragraph from '../../../../../components/text/Paragraph/paragraph';
import TextAreaField from '../../../../forms/fields/textarea';
import TextField from '../../../../forms/fields/textField';
import {
  createLevelsOfDisabilityOptions,
  createReportReasonCareOptions,
  createNurseRoleOptions,
  createPatientRoleOptions,
} from '../common/createLocalizedSelectValues';
import { PatientRole, ReportReasonCare } from '../common/constants';
import commonMessages from '../common/messages';
import {
  formMount,
  formUnmount,
  submitRiskDetailForm,
} from '../../../ActiveJourneyProvider/actions';
import {
  contextSelector,
  formSelector,
  riskDetailsSelector,
} from '../../../ActiveJourneyProvider/selectors';
import messages from './messages';
import validate from './validators';
import AutofillWatcher from './AutofillWatcher';

const TACForm = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isInitialized } = useSelector(contextSelector);
  const riskDetails = useSelector(riskDetailsSelector);
  const { isSubmitting } = useSelector(formSelector);

  const reasonOpts = useMemo(() => createReportReasonCareOptions(intl), [intl]);
  const nurseRoleOptions = useMemo(() => createNurseRoleOptions(intl), [intl]);
  const patientRoleOptions = useMemo(
    () => createPatientRoleOptions(intl),
    [intl]
  );
  const levelOfDisabilitysOptions = useMemo(
    () => createLevelsOfDisabilityOptions(intl),
    [intl]
  );

  useEffect(() => {
    dispatch(formMount());

    return () => dispatch(formUnmount());
  }, []);

  if (!isInitialized) return null;

  return (
    <Formik
      validate={validate({ intl })}
      validateOnBlur
      validateOnChange
      initialValues={riskDetails}
      onSubmit={(values) => dispatch(submitRiskDetailForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <AutofillWatcher />
          <Grid gap={8}>
            <SelectField
              name="nurseRole"
              label={formatMessage(messages.carerIsLabel)}
              options={nurseRoleOptions}
            />
            <TextField
              required
              name="nurseName"
              label={formatMessage(messages.carerFirstNameLabel)}
            />
            <TextField
              required
              name="nurseSurname"
              label={formatMessage(messages.carerLastNameLabel)}
            />
            <TextField
              required
              name="nurseId"
              label={formatMessage(messages.carerBirthNumberLabel)}
            />
            <SelectField
              name="patientRole"
              label={formatMessage(messages.caredForIsLabel)}
              options={patientRoleOptions}
            />
            {formik.values.patientRole === PatientRole.OTHER && (
              <TextField
                required
                name="otherText"
                label={formatMessage(messages.caredForIsOtherLabel)}
              />
            )}
            <TextField
              required
              name="patientName"
              label={formatMessage(messages.caredForFirstNameLabel)}
            />
            <TextField
              required
              name="patientSurname"
              label={formatMessage(messages.caredForLastNameLabel)}
            />
            <TextField
              required
              name="patientBirthNumber"
              label={formatMessage(messages.caredForBirthNumberLabel)}
            />
            <DatePickerField
              required
              name="takingCareFrom"
              label={formatMessage(messages.caredForStartDateLabel)}
            />
            <CheckboxField
              name="hasEnded"
              label={formatMessage(messages.hasUntilLabel)}
            />
            {formik.values.hasEnded && (
              <DatePickerField
                required
                name="takingCareTo"
                label={formatMessage(messages.caredForEndDateLabel)}
              />
            )}
            <SelectField
              name="reportedIncident"
              label={formatMessage(messages.eventReportedLabel)}
              options={reasonOpts}
            />
            {formik.values.reportedIncident !==
              ReportReasonCare.CARING_FOR_FAMILY_MEMBER_DUE_TO_SCHOOL_CLOSURE && (
              <>
                <Paragraph center bold>
                  {formatMessage(messages.closeCare_examinatedHeading)}
                </Paragraph>
                <CheckboxField
                  name="undergoneSpecialistExamination"
                  label={formatMessage(messages.undergoneSpecialExamLabel)}
                />
                <CheckboxField
                  name="undergoneImagingExamination"
                  label={formatMessage(messages.undergoneImagingExamLabel)}
                />
                <CheckboxField
                  name="undergoneSurgeryOrHospitalised"
                  label={formatMessage(
                    messages.undergoneSurgeryOrHospitalisedLabel
                  )}
                />
                <div className="pt-4 mb-4 mx-20 border-b-2 border-cardif-gray-400 border-dashed" />
                <CheckboxField
                  name="applicationForCareAllowance"
                  label={formatMessage(messages.contributionRequestLabel)}
                />
              </>
            )}
            <CheckboxField
              name="workingLeaveDueToNursing"
              label={formatMessage(messages.usedTimeOffWorkLabel)}
            />
            <CheckboxField
              name="terminateEmploymentDueToNursing"
              label={formatMessage(messages.endedWorkLabel)}
            />
            <CheckboxField
              name="isInvalid"
              label={formatMessage(messages.isInvalidLabel)}
            />
            {formik.values.isInvalid && (
              <>
                <SelectField
                  name="levelOfDisability"
                  options={levelOfDisabilitysOptions}
                  label={formatMessage(messages.levelOfDisabilityLabel)}
                />
                <DatePickerField
                  name="disabilityFrom"
                  label={formatMessage(messages.sinceLabel)}
                  helperText={formatMessage(
                    messages.closeCare_disabilitySinceHelperText
                  )}
                />
              </>
            )}
            <CheckboxField
              name="investigatedByPolice"
              label={formatMessage(messages.investigatedByPoliceLabel)}
            />
            {formik.values.investigatedByPolice && (
              <TextAreaField
                rows={5}
                name="policeInvestigation"
                label={formatMessage(messages.descriptionLabel)}
                helperText={formatMessage(
                  messages.closeCare_descriptionHelperText
                )}
              />
            )}
          </Grid>
          <Spacing variant="item" />
          <Grid xs={2} gap={4}>
            <Button
              full
              variant="gray"
              onClick={() => navigate('../risk-type')}
              label={formatMessage(commonMessages.stepFour_backButtonLabel)}
            />
            <Button
              full
              disabled={isSubmitting}
              type="submit"
              label={formatMessage(commonMessages.stepFour_continueButtonLabel)}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default TACForm;
