import { forEach } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, map as rxMap, catchError } from 'rxjs/operators';

import {
  uploadDocumentsError,
  uploadDocumentsFileErrors,
  uploadDocumentsSuccess,
  UPLOAD_DOCUMENTS,
} from '../actions';

const createBody = (files) => {
  const formData = new FormData();

  const addFormData = (x) => formData.append('files', x);
  forEach(addFormData, files);
  return formData;
};

const uploadProcessError = (e) => {
  if (e.status === 422) {
    const errFiles = e.response
      ? e.response
          .filter((file) => !!file.errorCode)
          .map((err) => ({
            ...err,
            errorCode: err.errorCode.replace(/-/g, '_'),
          }))
      : [];

    return of(uploadDocumentsFileErrors(errFiles));
  }
  return of(uploadDocumentsError(e));
};

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTS),
    switchMap(({ payload }) =>
      ajax({
        useAuth: true,
        url: `/gateway-server/requirements/${payload.requirementId}/upload`,
        method: 'POST',
        body: createBody(payload.files),
      }).pipe(
        rxMap(() => uploadDocumentsSuccess()),
        catchError((e) => uploadProcessError(e))
      )
    )
  );
