import { defineMessages } from 'react-intl';

const namespace = 'crdf_auth_inactivity';

export default defineMessages({
  warning: {
    id: `${namespace}_warning`,
    defaultMessage: 'Varování',
  },
  text: {
    id: `${namespace}_text`,
    defaultMessage:
      'Kvůli nečinnosti Vaše relace vyprší za {remainingTime}. Pro prodloužení klikněte na tlačítko {prolong}.',
  },
  prolong: {
    id: `${namespace}_prolong`,
    defaultMessage: 'Prodloužit',
  },
});
