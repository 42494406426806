import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_riskDetails_common';

export default defineMessages({
  patientRole_HUSBAND: {
    id: `${namespace}_husband`,
    defaultMessage: 'Manžel/Manželka pojištěného',
  },
  patientRole_CHILD: {
    id: `${namespace}_child`,
    defaultMessage: 'Dítě',
  },
  patientRole_PARENT: {
    id: `${namespace}_parent`,
    defaultMessage: 'Rodič',
  },
  patientRole_CIVIL_PARTNER: {
    id: `${namespace}_civilPartner`,
    defaultMessage: 'Registrovaný partner/partnerka',
  },
  patientRole_OTHER_MEMBER_OF_FAMILY: {
    id: `${namespace}_otherMemberOfFamily`,
    defaultMessage: 'Jiný člen rodiny žijící ve společné domácnosti',
  },
  patientRole_OTHER: {
    id: `${namespace}_other`,
    defaultMessage: 'Jiný - uveďte',
  },
  reportedIncident_DISEASE_OF_PATIENT: {
    id: `${namespace}_reasonCareDisease`,
    defaultMessage: 'Nemoci ošetřovaného',
  },
  reportedIncident_INJURY_OF_PATIENT: {
    id: `${namespace}_reasonCareInjury`,
    defaultMessage: 'Úrazu ošetřovaného',
  },
  reportedIncident_CARING_FOR_FAMILY_MEMBER_DUE_TO_SCHOOL_CLOSURE: {
    id: `${namespace}_reasonCareSchoolShutdown`,
    defaultMessage: 'Péči o člena rodiny kvůli uzavření školského zařízení',
  },
  levelOfDisability_LEVEL1: {
    id: `${namespace}_level1`,
    defaultMessage: 'Stupeň 1',
  },
  levelOfDisability_LEVEL2: {
    id: `${namespace}_level2`,
    defaultMessage: 'Stupeň 2',
  },
  levelOfDisability_LEVEL3: {
    id: `${namespace}_level3`,
    defaultMessage: 'Stupeň 3',
  },
  levelOfDisability_FULL: {
    id: `${namespace}_full`,
    defaultMessage: 'Plný',
  },
  levelOfDisability_PARTIAL: {
    id: `${namespace}_partial`,
    defaultMessage: 'Částečný',
  },
  nurseRole_INSURED: {
    id: `${namespace}_insured`,
    defaultMessage: 'Pojištěný',
  },
  nurseRole_HUSBAND_WIFE: {
    id: `${namespace}_husbandWife`,
    defaultMessage: 'Manžel/Manželka pojištěného',
  },
  nurseRole_CIVIL_PARTNER: {
    id: `${namespace}_civilPartner`,
    defaultMessage: 'Registrovaný partner/partnerka pojištěného',
  },
  nurseRole_COHABITATION: {
    id: `${namespace}_cohabitation`,
    defaultMessage: 'Druh/Družka',
  },
  reportedIncident_DISEASE: {
    id: `${namespace}_reasonDisease`,
    defaultMessage: 'Nemoci',
  },
  reportedIncident_INJURY: {
    id: `${namespace}_reasonInjury`,
    defaultMessage: 'Úrazu',
  },
  stepFour_backButtonLabel: {
    id: 'newEvent_stepFour_backButtonLabel',
    defaultMessage: 'Zpět',
  },
  stepFour_continueButtonLabel: {
    id: 'newEvent_stepFour_continueButtonLabel',
    defaultMessage: 'Pokračovat',
  },
});
