import React from 'react';
import { useSelector } from 'react-redux';

import { nolContractsSelectorWRType } from '../../../selectors';
import ContractCardItem from './Item';

const propTypes = {};
const defaultProps = {};

const ContractsCard = () => {
  const contracts = useSelector(nolContractsSelectorWRType);

  return (
    <>
      {contracts.map((c, i) => (
        <ContractCardItem
          key={i}
          isLast={contracts.length - 1 === i}
          data={c}
        />
      ))}
    </>
  );
};

ContractsCard.propTypes = propTypes;
ContractsCard.defaultProps = defaultProps;

export default ContractsCard;
