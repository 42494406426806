import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Container from '../../../components/layout/Container';
import Modal from '../../../components/Modal';
import { getFilesToUpload } from '../utils';
import Upload from './upload';
import { openDocViewModal } from '../../docView/actions';
import { closeUploadModalOnFinish, uploadDocuments } from '../actions';

const propTypes = {
  requirementId: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  inputProps: PropTypes.shape().isRequired,
  rootProps: PropTypes.shape().isRequired,
  fWrappers: PropTypes.arrayOf(PropTypes.shape()),
  deleteFile: PropTypes.func.isRequired,
  clearFiles: PropTypes.func.isRequired,
  docsValidation: PropTypes.shape({
    allDocsValid: PropTypes.bool,
    allDocsValidated: PropTypes.bool,
  }),
};
const defaultProps = {
  fWrappers: [],
  docsValidation: {
    allDocsValid: false,
    allDocsValidated: false,
  },
};

const UploadDocumentModal = ({
  requirementId,
  heading,
  fWrappers,
  deleteFile,
  clearFiles,
  inputProps,
  rootProps,
  docsValidation,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (fWrappers.length) setOpen(true);
  }, [fWrappers]);

  useEffect(() => {
    if (!open) clearFiles();
  }, [open]);

  return (
    <Modal
      hasCross
      isOpen={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <Container variant="modal">
        <Upload
          heading={heading}
          fWrappers={fWrappers}
          inputProps={inputProps}
          rootProps={rootProps}
          onDeleteFile={deleteFile}
          docsValidation={docsValidation}
          onPreviewFile={(fWrapper) => {
            dispatch(openDocViewModal(fWrapper));
          }}
          onCancel={() => setOpen(false)}
          onSave={() => {
            const files = getFilesToUpload(fWrappers);
            dispatch(uploadDocuments({ requirementId, files }));
            dispatch(closeUploadModalOnFinish({ close: () => setOpen(false) }));
          }}
        />
      </Container>
    </Modal>
  );
};

UploadDocumentModal.propTypes = propTypes;
UploadDocumentModal.defaultProps = defaultProps;

export default UploadDocumentModal;
