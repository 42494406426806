import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isNotNilOrEmpty } from 'ramda-adjunct';

import Spacing from '../../../../components/layout/Spacing';
import Paragraph from '../../../../components/text/Paragraph/paragraph';
import messages from '../messages';

const propTypes = {
  currentStep: PropTypes.oneOf([
    'phone',
    'password',
    'otp',
    'createPassword',
    'confirm',
    'phoneReset',
    'otpReset',
    'resetConfirm',
    'notFound',
  ]).isRequired,
  state: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

const ResetConfirmFragment = ({ currentStep, state }) => {
  if (currentStep !== 'resetConfirm') return null;

  const { email, phone } = state;
  const isEmail = isNotNilOrEmpty(email);

  return (
    <>
      <Spacing variant="item">
        <Paragraph center>
          {isEmail ? (
            <FormattedMessage {...messages.resetConfirmPar1} />
          ) : (
            <FormattedMessage {...messages.resetConfirmParSMS1} />
          )}
        </Paragraph>
      </Spacing>
      <Spacing variant="item">
        <Paragraph center bold>
          {isEmail ? email : phone}
        </Paragraph>
      </Spacing>
      <Spacing variant="item">
        <Paragraph center>
          {isEmail ? (
            <FormattedMessage {...messages.resetConfirmPar2} />
          ) : (
            <FormattedMessage {...messages.logInAfterResettingPassSMS} />
          )}
        </Paragraph>
      </Spacing>
    </>
  );
};

ResetConfirmFragment.propTypes = propTypes;

export default ResetConfirmFragment;
