import { combineReducers } from 'redux';

import authReducers from '../modules/auth/reducers';
import notificationsReducer from '../modules/notifications/reducers';
import settingsReducer from '../modules/settings/reducers';
import nolsReducer from '../modules/nol/reducers';
import journeysReducer from '../modules/journeys/reducers';
import callMeBackReducer from '../modules/callMeBack/reducers';
import registrationReducer from '../modules/registration/reducers';
import uploadReducer from '../modules/upload/reducers';
import docViewReducer from '../modules/docView/reducers';
import npsReducer from '../modules/nps/reducers';
import globalDataLoaderReducer from '../modules/globalDataLoader/reducers';

export default combineReducers({
  auth: authReducers,
  notifications: notificationsReducer,
  settings: settingsReducer,
  nol: nolsReducer,
  journeys: journeysReducer,
  callMeBack: callMeBackReducer,
  nps: npsReducer,
  registration: registrationReducer,
  upload: uploadReducer,
  docView: docViewReducer,
  globalData: globalDataLoaderReducer,
});
