import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { contextSelector } from '../ActiveJourneyProvider/selectors';

const ActiveJourneyGuard = () => {
  const { activeJourneyId } = useSelector(contextSelector);

  if (!activeJourneyId) return <Navigate to="/nols" replace />;

  return <Outlet />;
};

export default ActiveJourneyGuard;
