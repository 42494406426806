import { defineMessages } from 'react-intl';

export default defineMessages({
  cardTitle: {
    id: 'settings_forms_changePassword_cardTitle',
    defaultMessage: 'Změna hesla',
  },
  oldPasswordLabel: {
    id: 'settings_forms_changePassword_oldPasswordLabel',
    defaultMessage: 'Původní heslo',
  },
  newPasswordLabel: {
    id: 'settings_forms_changePassword_newPasswordLabel',
    defaultMessage: 'Heslo',
  },
  confirmNewPasswordLabel: {
    id: 'settings_forms_changePassword_confirmNewPasswordLabel',
    defaultMessage: 'Zopakujte heslo',
  },
  newPasswordSpecsText: {
    id: 'settings_forms_changePassword_newPasswordSpecsText',
    defaultMessage: 'Nové heslo musí obsahovat následující',
  },
  lengthValidation: {
    id: 'settings_forms_changePassword_lengthValidation',
    defaultMessage: 'Heslo musí obsahovat minimálně 8 znaků.',
  },
  uppercaseValidation: {
    id: 'settings_forms_changePassword_uppercaseValidation',
    defaultMessage: 'Alespoň jedno velké písmeno.',
  },
  lowercaseValidation: {
    id: 'settings_forms_changePassword_lowercaseValidation',
    defaultMessage: 'Alespoň jedno malé písmeno.',
  },
  digitValidation: {
    id: 'settings_forms_changePassword_digitValidation',
    defaultMessage: 'Alespoň jedna číslice (0,1,2,3,4,5,6,7,8,9).',
  },
  specialCharacterValidation: {
    id: 'settings_forms_changePassword_specialCharacterValidation',
    defaultMessage: 'Alespoň jeden speciální znak',
  },
  saveButtonLabel: {
    id: 'settings_forms_changePassword_saveButtonLabel',
    defaultMessage: 'Uložit',
  },
  passwordWasUsedInThePast: {
    id: 'settings_form_changePassword_passwordWasUsedInThePast',
    defaultMessage:
      'Zvolené heslo již bylo v minulosti použito, prosím zvolte jiné.',
  },
  savePasswordErrorOriginalPassword: {
    id: 'settings_form_changePassword_savePasswordErrorOriginalPassword',
    defaultMessage: 'Aktuální heslo je zadané chybně',
  },
  savePasswordSuccess: {
    id: 'settings_form_changePassword_savePasswordSuccess',
    defaultMessage: 'Heslo bylo uloženo',
  },
  newPasswordRepeatNotMatch: {
    id: 'settings_form_changePasswordnewPasswordRepeatNotMatch',
    defaultMessage: 'Hesla se neshodují',
  },
});
