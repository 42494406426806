import { pipe, prop, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { map as rxMap, withLatestFrom } from 'rxjs/operators';

import { fetchNolDetail } from '../../nol/detail/actions';
import { nolDetailSelector } from '../../nol/selectors';
import { UPLOAD_DOCUMENTS_SUCCESS } from '../actions';

export default (action$, state$) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTS_SUCCESS),
    withLatestFrom(state$),
    rxMap(([, state]) => nolDetailSelector(state)),
    rxMap(unary(pipe(prop('id'), unary(fetchNolDetail))))
  );
