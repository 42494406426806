import { Reader } from 'monet';

import {
  isEmail as isEmailValidator,
  isTrimmedBothSides as isTrimmedBothSidesValidator,
  hasAgeInInterval as hasAgeInIntervalValidator,
  hasOnlyDigits as hasOnlyDigitsValidator,
  hasLength as hasLengthValidator,
  hasLengthMax as hasLengthMaxValidator,
  isNotFuture as isNotFutureValidator,
  isChronologicalAfter as isChronologicalAfterValidator,
  isChronologicalBefore as isChronologicalBeforeValidator,
  isRequired as isRequiredValidator,
  isBirthNumberValid as isBirthNumberValidValidator,
  isBankAccountValid as isBankAccountValidValidator,
} from './validators';
import validationsMessages from './validations.messages';

export const isRequired = () =>
  Reader(({ intl }) => ({
    validate: isRequiredValidator,
    message: intl.formatMessage(validationsMessages.isRequired),
  }));

export const hasLengthMax = (num) =>
  Reader(({ intl }) => ({
    validate: hasLengthMaxValidator(num),
    message: intl.formatMessage(validationsMessages.hasLengthMax, {
      max: num,
    }),
  }));

export const isEmail = () =>
  Reader(({ intl }) => ({
    validate: isEmailValidator,
    message: intl.formatMessage(validationsMessages.isEmail),
  }));

export const isTrimmedBothSides = () =>
  Reader(({ intl }) => ({
    validate: isTrimmedBothSidesValidator,
    message: intl.formatMessage(validationsMessages.startEndSpace),
  }));

export const hasAgeInInterval = (min, max) =>
  Reader(({ intl }) => ({
    validate: hasAgeInIntervalValidator(min, max),
    message: intl.formatMessage(validationsMessages.hasAgeInInterval, {
      minAge: min,
      maxAge: max,
    }),
  }));

export const hasOnlyDigits = () =>
  Reader(({ intl }) => ({
    validate: hasOnlyDigitsValidator,
    message: intl.formatMessage(validationsMessages.hasOnlyDigits),
  }));

export const hasLength = (len) =>
  Reader(({ intl }) => ({
    validate: hasLengthValidator(len),
    message: intl.formatMessage(validationsMessages.hasLength, {
      length: len,
    }),
  }));

export const isNotFuture = () =>
  Reader(({ intl }) => ({
    validate: isNotFutureValidator,
    message: intl.formatMessage(validationsMessages.isNotInFuture),
  }));

export const isChronologicalAfter = (selector) =>
  Reader(({ intl }) => ({
    validate: isChronologicalAfterValidator(selector),
    message: intl.formatMessage(validationsMessages.isChronological),
  }));

export const isChronologicalBefore = (selector) =>
  Reader(({ intl }) => ({
    validate: isChronologicalBeforeValidator(selector),
    message: intl.formatMessage(validationsMessages.isChronological),
  }));

export const isBirthNumberValid = (selector) =>
  Reader(({ intl }) => ({
    validate: isBirthNumberValidValidator(selector),
    message: intl.formatMessage(validationsMessages.isBirthNumberValid),
  }));

export const isBankAccountValid = () =>
  Reader(({ intl }) => ({
    validate: isBankAccountValidValidator,
    message: intl.formatMessage(validationsMessages.isBankAccountValid),
  }));
