import { curryN, propEq } from 'ramda';

import runValidations, {
  isRequired,
  isBankAccountValid,
  hasOnlyDigits,
  hasAgeInInterval,
  hasLength,
} from '../../forms/validators';

const validations = {
  accountNumber: {
    validate: [isRequired(), isBankAccountValid()],
  },
  bankCode: {
    validate: [isRequired(), hasOnlyDigits()],
  },

  'thirdParty.firstName': {
    when: propEq('isThirdParty', true),
    validate: [isRequired()],
  },
  'thirdParty.lastName': {
    when: propEq('isThirdParty', true),
    validate: [isRequired()],
  },
  'thirdParty.birthDate': {
    when: propEq('isThirdParty', true),
    validate: [isRequired(), hasAgeInInterval(18, 110)],
  },
  'thirdParty.citizenShip': {
    when: propEq('isThirdParty', true),
    validate: [isRequired()],
  },
  'thirdParty.street': {
    when: propEq('isThirdParty', true),
    validate: [isRequired()],
  },
  'thirdParty.city': {
    when: propEq('isThirdParty', true),
    validate: [isRequired()],
  },
  'thirdParty.postalCode': {
    when: propEq('isThirdParty', true),
    validate: [isRequired(), hasOnlyDigits(), hasLength(5)],
  },
  'thirdParty.country': {
    when: propEq('isThirdParty', true),
    validate: [isRequired()],
  },
};

export default curryN(2, (deps, values) =>
  runValidations(validations, deps, values)
);
