import { curryN } from 'ramda';

import runValidations, {
  isRequired,
  hasOnlyDigits,
  hasLength,
  isTrimmedBothSides,
} from '../validators';

const phoneValidations = {
  phoneNumberPrefix: {
    validate: [isRequired()],
  },
  phoneNumber: {
    validate: [isRequired(), hasOnlyDigits(), hasLength(9)],
  },
};

export const phoneValidator = curryN(2, (deps, values) =>
  runValidations(phoneValidations, deps, values)
);

export const otpValidations = {
  otp: {
    validate: [
      isRequired(),
      hasOnlyDigits(),
      hasLength(4),
      isTrimmedBothSides(),
    ],
  },
};

export const otpValidator = curryN(2, (deps, values) =>
  runValidations(otpValidations, deps, values)
);

const passwordValidations = {
  password: {
    validate: [isRequired()],
  },
};

export const passwordValidator = curryN(2, (deps, values) =>
  runValidations(passwordValidations, deps, values)
);
