import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = { size: PropTypes.oneOf(['sm', 'md', 'lg']) };

const defaultProps = { size: 'md' };

const PageArrowSpacer = ({ size }) => {
  const sizeCircle = cx({
    'w-6 h-6': size === 'sm',
    'w-8 h-8': size === 'md',
    'w-10 h-10': size === 'lg',
  });

  return <div className={cx('relative', sizeCircle)} />;
};

PageArrowSpacer.propTypes = propTypes;
PageArrowSpacer.defaultProps = defaultProps;

export default PageArrowSpacer;
