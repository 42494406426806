import { handleActions } from 'redux-actions';

import { VERIFY_PASSWORD_RESET_SUCCESS } from '../actions';

const initialState = {
  email: '',
  phone: '',
};

export default handleActions(
  {
    [VERIFY_PASSWORD_RESET_SUCCESS]: (state, { payload }) => ({
      ...state,
      email: payload.email,
      phone: payload.phone,
    }),
  },
  initialState
);
