import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { navigate } from '../navigation/actions';
import { authenticationStatusSelector } from '../auth/storage/selectors';

export const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
const defaultProps = {};

const OnlyAuthenticated = ({ children }) => {
  const { pathname } = useLocation();
  const { isInitialized, isAuthenticated } = useSelector(
    authenticationStatusSelector
  );

  if (!isInitialized) return null;
  if (isInitialized && !isAuthenticated)
    return (
      <Navigate
        to="/login"
        replace
        state={{ afterLoginAction: navigate({ to: pathname }) }}
      />
    );

  return children;
};

OnlyAuthenticated.propTypes = propTypes;
OnlyAuthenticated.defaultProps = defaultProps;

export default OnlyAuthenticated;
