import { ofType } from 'redux-observable';
import { switchMap, tap as rxTap, ignoreElements } from 'rxjs';

import { INJECT_NAVIGATE, NAVIGATE } from './actions';

// This is a hack to enable us to navigate using redux actions from observable context
// connected-react-router still does not support router v6

export default (action$) =>
  action$.pipe(
    ofType(INJECT_NAVIGATE),
    switchMap(({ payload: navigate }) =>
      action$.pipe(
        ofType(NAVIGATE),
        rxTap(({ payload: { to, options } }) => navigate(to, options)),
        ignoreElements()
      )
    )
  );
