import { handleActions } from 'redux-actions';

import {
  FETCH_NOL_DETAIL,
  FETCH_NOL_DETAIL_ERROR,
  FETCH_NOL_DETAIL_SUCCESS,
} from './actions';

const initialState = {
  isLoading: false,
  isInitialized: false,
  id: null,
  nol: null,
};

export default handleActions(
  {
    [FETCH_NOL_DETAIL]: (state, { payload }) => ({
      ...state,
      isLoading: true,
      id: payload,
      nol: state.id !== payload ? null : state.nol,
      isInitialized: state.id !== payload ? false : state.isInitialized,
    }),
    [FETCH_NOL_DETAIL_SUCCESS]: (state, { payload }) => ({
      ...state,
      isInitialized: true,
      isLoading: false,
      nol: payload,
    }),
    [FETCH_NOL_DETAIL_ERROR]: (state) => ({ ...state, isLoading: false }), // TODO: handle errors
  },
  initialState
);
