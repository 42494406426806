import { pipe, prop, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { switchMap, map as rxMap, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import {
  FETCH_RISK_TYPES,
  fetchRiskTypesSuccess,
} from '../../actions/context.actions';

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_RISK_TYPES),
    switchMap(() =>
      ajax({
        url: '/gateway-server/claim-declaration/risk-groups',
        useAuth: true,
      }).pipe(
        rxMap(unary(pipe(prop('response'), unary(fetchRiskTypesSuccess)))),
        catchError(() => EMPTY) // TODO: Error handling
      )
    )
  );
