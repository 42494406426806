import { defineMessages } from 'react-intl';

const namespace = 'crdf_nols_detail_tabs_uploadedTab';

export default defineMessages({
  verification: {
    id: `${namespace}_verification`,
    defaultMessage: 'Nyní pro vás žádné dokumenty neověřujeme',
  },
  download: {
    id: `${namespace}_download`,
    defaultMessage: 'download',
  },
});
