import { combineEpics, ofType } from 'redux-observable';
import { map as rxMap, withLatestFrom } from 'rxjs/operators';

import { navigate } from '../../../../navigation/actions';
import {
  SUBMIT_BANK_ACCOUNT_FORM_SUCCESS,
  SUBMIT_CONTACT_DATA_FORM_SUCCESS,
  SUBMIT_PERSONAL_DATA_FORM_SUCCESS,
  SUBMIT_RISK_DETAIL_FORM_SUCCESS,
  SUBMIT_RISK_TYPE_FORM_ERROR,
  SUBMIT_RISK_TYPE_FORM_SUCCESS,
} from '../../actions';
import { contextSelector } from '../../selectors';

const progressPersonal$ = (action$) =>
  action$.pipe(
    ofType(SUBMIT_PERSONAL_DATA_FORM_SUCCESS),
    rxMap(() => navigate({ to: '/journeys/contact' }))
  );

const progressContact$ = (action$) =>
  action$.pipe(
    ofType(SUBMIT_CONTACT_DATA_FORM_SUCCESS),
    rxMap(() => navigate({ to: '/journeys/risk-type' }))
  );

const progressRiskType$ = (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_RISK_TYPE_FORM_SUCCESS),
    withLatestFrom(state$),
    rxMap(([, state]) =>
      navigate({
        to: '/journeys/risk-details',
        options: { state: contextSelector(state) },
      })
    )
  );

const progressRiskTypeError$ = (action$) =>
  action$.pipe(
    ofType(SUBMIT_RISK_TYPE_FORM_ERROR),
    rxMap(() => navigate({ to: '/call-me-back' }))
  );

const progressRiskDetails$ = (action$) =>
  action$.pipe(
    ofType(SUBMIT_RISK_DETAIL_FORM_SUCCESS),
    rxMap(() => navigate({ to: '/journeys/bank-account' }))
  );

const progressBankAccount$ = (action$) =>
  action$.pipe(
    ofType(SUBMIT_BANK_ACCOUNT_FORM_SUCCESS),
    rxMap(() => navigate({ to: '/journeys/contracts' }))
  );

export default combineEpics(
  progressPersonal$,
  progressContact$,
  progressRiskType$,
  progressRiskTypeError$,
  progressRiskDetails$,
  progressBankAccount$
);
