import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { noop } from 'ramda-adjunct';

import Grid from '../../../components/Grid/grid';
import Spacing from '../../../components/layout/Spacing';
import Button from '../../../components/Button';
import messages from './messages';
import commonMessages from '../../i18n/common.messages';
import PhoneFragment from './fragments/phone';
import PasswordFragment from './fragments/password';
import OtpFragment from './fragments/otp';
import NoPhoneFragment from './fragments/noPhoneFragment';
import {
  loginMetaSelector,
  loginOtpSelector,
  loginPasswordSelector,
  loginPhoneSelector,
  resetConfirmSelector,
} from '../../auth/login/selectors';
import {
  loginInit,
  loginDeinit,
  updateState,
  afterLoginAction,
} from '../../auth/login/actions';
import CreatePasswordFragment from './fragments/createPassword';
import { otpValidator, passwordValidator, phoneValidator } from './validators';
import FlashCard from '../../../components/cards/FlashCard/flash';
import ResetConfirmFragment from './fragments/resetConfirmFragment';
import { CALL_ME_BACK_TYPES_GENERIC } from '../../callMeBack/constants';
import { StarsIcon } from '../../../components/icons';

export const propTypes = {
  setPageTitles: PropTypes.func.isRequired,
  resetPageTitles: PropTypes.func.isRequired,
  setTitleIcon: PropTypes.func.isRequired,
};
const defaultProps = {};

const getStepValidator = (step) => {
  if (step === 'phone') return phoneValidator;
  if (step === 'otp') return otpValidator;
  if (step === 'password') return passwordValidator;

  return noop;
};

const LoginForm = ({ setPageTitles, resetPageTitles, setTitleIcon }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [messageObj, setMessageObj] = useState({
    variant: 'warning',
    message: '',
  });

  // form initialization => used in epics
  useEffect(() => {
    dispatch(loginInit());

    return () => dispatch(loginDeinit());
  }, []);

  // form state
  const { hasServerError, step, isInitialized, isSubmitting, hasNextStep } =
    useSelector(loginMetaSelector);
  const phoneStep = useSelector(loginPhoneSelector);
  const passwordStep = useSelector(loginPasswordSelector);
  const otpStep = useSelector(loginOtpSelector);
  const resetConfirm = useSelector(resetConfirmSelector);

  useEffect(() => {
    if (['phoneReset', 'otpReset', 'resetConfirm'].includes(step))
      setPageTitles({
        title: intl.formatMessage(messages.resetPasswordTitle),
        subtitle: ' ',
      });
    else if (step === 'createPassword') {
      setPageTitles({
        title: intl.formatMessage(messages.createPasswordTitle),
        subtitle: intl.formatMessage(messages.createPasswordText),
      });
      setTitleIcon(<StarsIcon />);
    }
    return () => resetPageTitles();
  }, [step]);

  const validator = useMemo(() => getStepValidator(step), [step]);

  const formikState = {
    initialValues: {
      phoneNumberPrefix: phoneStep.phoneNumberPrefix || '420',
      phoneNumber: phoneStep.phoneNumber || '',
      password: '',
      otp: '',
      newPassword: '',
      newPasswordRepeat: '',
      agreeGdpr: false,
      agreeToc: false,
    },
    onSubmit: (values) => {
      dispatch(updateState(values));

      if (state?.afterLoginAction) {
        dispatch(afterLoginAction(state.afterLoginAction));
      }

      if (['resetConfirm'].includes(step)) {
        navigate('/');
      }
    },
  };

  useEffect(() => {
    if (hasServerError) {
      setMessageObj({
        variant: 'warning',
        message: formatMessage(messages.hasServerErrorMessage),
      });
    }

    if (passwordStep.passwordVerificationIsBlocked) {
      setMessageObj({
        variant: 'warning',
        message: formatMessage(
          commonMessages.passwordVerificationIsBlockedCommon
        ),
      });
    }
  }, [hasServerError, passwordStep.passwordVerificationIsBlocked]);

  if (!isInitialized) return null;

  return (
    <Formik key={step} {...formikState} validate={validator({ intl })}>
      {({ resetForm }) => (
        <Form noValidate>
          {(hasServerError || passwordStep.passwordVerificationIsBlocked) && (
            <Spacing variant="header">
              <FlashCard
                variant={messageObj.variant}
                message={messageObj.message}
              />
            </Spacing>
          )}
          {otpStep.refreshSent && (
            <Spacing variant="header">
              <FlashCard
                message={intl.formatMessage(messages.otpRefreshSentFlash)}
              />
            </Spacing>
          )}
          {otpStep.isDiscarded && (
            <Spacing variant="header">
              <FlashCard
                message={intl.formatMessage(messages.otpDiscardedError)}
                variant="warning"
              />
            </Spacing>
          )}
          <Grid xs={11} gap={4}>
            <PhoneFragment currentStep={step} state={phoneStep} />
            <PasswordFragment currentStep={step} state={passwordStep} />
            <OtpFragment currentStep={step} state={otpStep} />
            <CreatePasswordFragment
              setPageTitles={setPageTitles}
              resetPageTitles={resetPageTitles}
              currentStep={step}
              state={{ hasError: false }}
              onSetSubmitDisabled={setSubmitDisabled}
            />
          </Grid>
          <NoPhoneFragment
            state={phoneStep}
            onReset={() => {
              resetForm();
              dispatch(loginInit());
            }}
            onCallMeBack={() =>
              navigate('/call-me-back', {
                state: {
                  reason: CALL_ME_BACK_TYPES_GENERIC.LOGIN,
                  meta: {
                    phonePrefix: phoneStep.phoneNumberPrefix,
                    phoneNumber: phoneStep.phoneNumber,
                  },
                },
              })
            }
          />
          <ResetConfirmFragment
            currentStep={step}
            state={resetConfirm}
            setPageTitles={setPageTitles}
            resetPageTitles={resetPageTitles}
          />
          {hasNextStep && (
            <Spacing>
              {otpStep.isDiscarded ? (
                <Button
                  center
                  label={formatMessage(messages.backButtonLabel)}
                  onClick={() => {
                    resetForm();
                    dispatch(loginInit());
                  }}
                />
              ) : (
                <Button
                  center
                  type="submit"
                  disabled={isSubmitting || submitDisabled}
                  label={formatMessage(messages.submitButtonLabel)}
                />
              )}
            </Spacing>
          )}
        </Form>
      )}
    </Formik>
  );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
