// eslint-disable-next-line import/prefer-default-export
export const STEPS = {
  personal: 1,
  contact: 2,
  'risk-type': 3,
  'risk-details': 4,
  'bank-account': 5,
  contracts: 6,
  'doc-uploads': 7,
};
