import { combineEpics } from 'redux-observable';

import settingsEpic from './Settings/epic';
import notificationFormEpic from './NotificationForm/epics';
import changePhoneFormEpic from './PhoneNumberForm/epics';
import changeEmailFormEpic from './EmailForm/epics';
import changePasswordFormEpic from './ChangePasswordForm/epics';
import changeEmailLandingEpic from './changeEmail/epic';

export default combineEpics(
  settingsEpic,
  notificationFormEpic,
  changePhoneFormEpic,
  changeEmailFormEpic,
  changePasswordFormEpic,
  changeEmailLandingEpic
);
