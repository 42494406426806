import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

export const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
const defaultProps = {};

const I18nProvider = ({ children }) => (
  <IntlProvider messages={{}} defaultLocale="cs" locale="cs">
    {children}
  </IntlProvider>
);

I18nProvider.propTypes = propTypes;
I18nProvider.defaultProps = defaultProps;

export default I18nProvider;
