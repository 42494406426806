import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_NolCreatedModal';

export default defineMessages({
  title: {
    id: `${namespace}_title`,
    defaultMessage: 'Nyní nahrajte potřebné dokumenty',
  },
  message: {
    id: `${namespace}_message`,
    defaultMessage:
      'Teď již zbývá pouze nahrát dokumenty, které potřebujeme pro vyřízení nahlášené škodní události.',
  },
  button: {
    id: `${namespace}_button`,
    defaultMessage: 'Pokračovat k nahrání dokumentů',
  },
  link: {
    id: `${namespace}_link`,
    defaultMessage: 'Nahlásit další událost',
  },
});
