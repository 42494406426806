import React, { useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import UploadFeedbackModalDesign from '../../../components/UploadFeedbackModal';
import { uploadSelector } from '../selectors';
import { closeUploadFeedback } from '../actions';
import messages from './messages';

const propTypes = {};

const UploadFeedbackModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { uploadFeedback, uploadFileErrors } = useSelector(uploadSelector);

  const onClose = useCallback(() => dispatch(closeUploadFeedback()), []);

  if (!uploadFeedback.show) return null;

  let feedbackTitle = '';
  let feedbackText = '';

  if (uploadFeedback.type === 'success') {
    feedbackTitle = formatMessage(messages.feedbackSuccessTitle);
    feedbackText = formatMessage(messages.feedbackSuccessText);
  } else {
    feedbackTitle = formatMessage(messages.feedbackUploadErrorTitle);
    if (uploadFileErrors.length > 0) {
      feedbackTitle = formatMessage(messages.feedbackFileErrorTitle);
      feedbackText = (
        <div className="mt-8">
          {uploadFileErrors.map((err, i) => (
            <div key={i} className="my-2">
              <b>{err.filename}: </b>
              {formatMessage(messages[`feedbackError_${err.errorCode}`])}
            </div>
          ))}
          <br />
        </div>
      );
    }
    feedbackText = (
      <div>
        {feedbackText}
        <span className="text-2xl whitespace-pre-line">
          {formatMessage(messages.feedbackErrorText)}
        </span>
      </div>
    );
  }

  return (
    <UploadFeedbackModalDesign
      type={uploadFeedback.type}
      onClose={onClose}
      title={feedbackTitle}
      description={feedbackText}
    />
  );
};

UploadFeedbackModal.propTypes = propTypes;

export default UploadFeedbackModal;
