import { createAction } from 'redux-actions';

const namespace = 'crdf.journeys.activeJourney.context';

export const INIT = `${namespace}.init`;
export const INIT_SUCCESS = `${namespace}.initSuccess`;
export const DEINIT = `${namespace}.deinit`;
export const RESET = `${namespace}.reset`;
export const CONTINUE_JOURNEY = `${namespace}.continueJourney`;

export const FETCH_ACTIVE_JOURNEYS = `${namespace}.fetchActiveJourneys`;
export const FETCH_ACTIVE_JOURNEYS_SUCCESS = `${namespace}.fetchActiveJourneysSuccess`;
export const FETCH_ACTIVE_JOURNEYS_ERROR = `${namespace}.fetchActiveJourneysError`;

export const FETCH_JOURNEY_STATE = `${namespace}.fetchJourneyState`;
export const FETCH_JOURNEY_STATE_SUCCESS = `${namespace}.fetchJourneyStateSuccess`;
export const FETCH_JOURNEY_STATE_ERROR = `${namespace}.fetchJourneyStateError`;

export const FETCH_RISK_TYPES = `${namespace}.fetchRiskTypes`;
export const FETCH_RISK_TYPES_SUCCESS = `${namespace}.fetchRiskTypesSuccess`;
export const FETCH_RISK_TYPES_ERROR = `${namespace}.fetchRiskTypesError`;

export const REFETCH_RISK_CONTEXT = `${namespace}.refetchRiskContext`;
export const REFETCH_RISK_DETAIL_SUCCESS = `${namespace}.refetchRiskDetailSuccess`;

export const init = createAction(INIT);
export const initSuccess = createAction(INIT_SUCCESS);
export const deinit = createAction(DEINIT);
export const reset = createAction(RESET);
export const continueJourney = createAction(CONTINUE_JOURNEY);

export const fetchActiveJourneys = createAction(FETCH_ACTIVE_JOURNEYS);
export const fetchActiveJourneysSuccess = createAction(
  FETCH_ACTIVE_JOURNEYS_SUCCESS
);
export const fetchActiveJourneysError = createAction(
  FETCH_ACTIVE_JOURNEYS_ERROR
);

export const fetchJourneyState = createAction(FETCH_JOURNEY_STATE);
export const fetchJourneyStateSuccess = createAction(
  FETCH_JOURNEY_STATE_SUCCESS
);
export const fetchJourneyStateError = createAction(FETCH_JOURNEY_STATE_ERROR);

export const fetchRiskTypes = createAction(FETCH_RISK_TYPES);
export const fetchRiskTypesSuccess = createAction(FETCH_RISK_TYPES_SUCCESS);
export const fetchRiskTypesError = createAction(FETCH_RISK_TYPES_ERROR);

export const refetchRiskContext = createAction(REFETCH_RISK_CONTEXT);
export const refetchRiskDetailSuccess = createAction(
  REFETCH_RISK_DETAIL_SUCCESS
);
