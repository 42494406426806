import React from 'react';

export const propTypes = {};
const defaultProps = {};

const Divider = () => <div className="h-[1px] my-5 w-full bg-[#e0e0e0]" />;

Divider.propTypes = propTypes;
Divider.defaultProps = defaultProps;

export default Divider;
