import { applySpec, of, pick, pipe, prop, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import {
  switchMap,
  withLatestFrom,
  map as rxMap,
  catchError,
  takeUntil,
} from 'rxjs/operators';

import { authorizationHeaderSelector } from '../../../auth/storage/selectors';
import {
  DEINIT_FORM,
  verifyPhoneError,
  verifyPhoneSuccess,
  VERIFY_PHONE,
} from '../actions';

const mapToApi = unary(
  applySpec({
    phoneNumber: ({ phonePrefix, phoneNumber }) =>
      `+${phonePrefix + phoneNumber}`,
  })
);

const parseResponse = unary(
  pipe(prop('response'), pick(['otpId']), unary(verifyPhoneSuccess))
);

export default (action$, state$) =>
  action$.pipe(
    ofType(VERIFY_PHONE),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      ajax({
        method: 'PUT',
        url: '/gateway-server/accounts/settings/verify-phone',
        headers: { authorization: authorizationHeaderSelector(state) },
        body: mapToApi(payload),
      }).pipe(
        rxMap(parseResponse),
        catchError((e) => of(verifyPhoneError(e))),
        takeUntil(action$.pipe(ofType(DEINIT_FORM)))
      )
    )
  );
