import { ofType } from 'redux-observable';
import { switchMap, take, mergeMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';

import { FETCH_TOKENS_SUCCESS } from '../../../auth/login/actions';
import {
  FETCH_ACTIVE_JOURNEYS_SUCCESS,
  continueJourney,
  destroyJourney,
} from '../../ActiveJourneyProvider/actions';
import { openUnfinishedModal } from '../actions';

export default (action$) =>
  action$.pipe(
    ofType(FETCH_TOKENS_SUCCESS),
    switchMap(({ payload: { registrationCompleted } }) => {
      if (registrationCompleted) {
        return action$.pipe(
          ofType(FETCH_ACTIVE_JOURNEYS_SUCCESS),
          mergeMap(({ payload }) => {
            if (payload.activeJourneyId) {
              return of(
                openUnfinishedModal({
                  onNextAction: continueJourney(),
                  onDismissAction: destroyJourney(),
                })
              );
            }

            return EMPTY;
          }),
          take(1)
        );
      }
      return EMPTY;
    })
  );
