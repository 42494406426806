import { ofType } from 'redux-observable';
import { ignoreElements, switchMap, take, tap as rxTap } from 'rxjs/operators';

import {
  UPLOAD_DOCUMENTS_ERROR,
  UPLOAD_DOCUMENTS_FILE_ERRORS,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_MODAL_CLOSE_ON_FINISH,
} from '../actions';

export default (action$) =>
  action$.pipe(
    ofType(UPLOAD_MODAL_CLOSE_ON_FINISH),
    switchMap(({ payload }) =>
      action$.pipe(
        ofType(
          UPLOAD_DOCUMENTS_ERROR,
          UPLOAD_DOCUMENTS_FILE_ERRORS,
          UPLOAD_DOCUMENTS_SUCCESS
        ),
        rxTap(() => payload.close()),
        take(1),
        ignoreElements()
      )
    )
  );
