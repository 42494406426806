import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import TextArea from '../../../components/form/Textarea';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.bool,
};
const defaultProps = {
  placeholder: null,
  rows: 4,
  required: false,
};

const TextAreaField = ({
  name,
  label,
  placeholder,
  rows,
  required,
  ...rest
}) => {
  const [field, meta] = useField({ name });

  return (
    <TextArea
      {...rest}
      name={name}
      label={label}
      placeholder={placeholder}
      rows={rows}
      required={required}
      error={meta.touched && meta.error ? meta.error : null}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
    />
  );
};

TextAreaField.propTypes = propTypes;
TextAreaField.defaultProps = defaultProps;

export default TextAreaField;
