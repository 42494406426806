import { combineReducers } from 'redux';

import loginReducers from './login/reducers';
import storageReducers from './storage/reducers';
import accountReducers from './account/reducers';
import resetPasswordReducers from './resetPassword/reducers';
import inactivityReducers from './inactivity/reducers';

export default combineReducers({
  login: loginReducers,
  storage: storageReducers,
  account: accountReducers,
  resetPassword: resetPasswordReducers,
  inactivity: inactivityReducers,
});
