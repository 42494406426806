import React from 'react';

import DropCard from '../../../../../../../../components/cards/DropCard';
import StepsCard from './steps';
import BewareCard from './beware';
import EvaluationCard from './evaluation';
import InsuranceBenefitsCard from './insuranceBenefits';
import Grid from '../../../../../../../../components/Grid';
import Spacing from '../../../../../../../../components/layout/Spacing';

const DisabilityInfoCards = () => (
  <Spacing>
    <Grid gap={3}>
      <DropCard startsOpen title="2 kroky pro hladký průběh">
        <StepsCard />
      </DropCard>
      <DropCard title="Vyplacení pojistného plnění">
        <InsuranceBenefitsCard />
      </DropCard>
      <DropCard title="Posouzení škodní události">
        <EvaluationCard />
      </DropCard>
      <DropCard title="Na co si dát pozor">
        <BewareCard />
      </DropCard>
    </Grid>
  </Spacing>
);

export default DisabilityInfoCards;
