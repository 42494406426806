import { applySpec, curryN, map, pipe, prop, propOr, unary } from 'ramda';

// eslint-disable-next-line import/prefer-default-export
export const risksToRadios = curryN(3, (translateKey, key, risks) =>
  pipe(
    propOr([], key),
    map(
      applySpec({
        label: pipe(prop('name'), unary(translateKey)),
        value: prop('name'),
      })
    )
  )(risks)
);
