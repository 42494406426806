import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '../../../../../components/Grid/grid';
import Spacing from '../../../../../components/layout/Spacing';
import Button from '../../../../../components/Button/button';
import DatePickerField from '../../../../forms/fields/datePicker';
import CheckboxField from '../../../../forms/fields/checkbox';
import SelectField from '../../../../forms/fields/select';
import Paragraph from '../../../../../components/text/Paragraph/paragraph';
import {
  formMount,
  formUnmount,
  submitRiskDetailForm,
} from '../../../ActiveJourneyProvider/actions';
import {
  contextSelector,
  formSelector,
  riskDetailsSelector,
} from '../../../ActiveJourneyProvider/selectors';
import commonMessages from '../common/messages';
import messages from './messages';
import validate from './validators';

const getReasonOptions = () => [
  { label: 'Nemoci', value: 'n' },
  { label: 'Úrazu', value: 'u' },
];

const HospitalizationForm = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isInitialized } = useSelector(contextSelector);
  const riskDetails = useSelector(riskDetailsSelector);
  const { isSubmitting } = useSelector(formSelector);

  useEffect(() => {
    dispatch(formMount());

    return () => dispatch(formUnmount());
  }, []);

  if (!isInitialized) return null;

  return (
    <Formik
      validate={validate({ intl })}
      validateOnBlur
      validateOnChange
      initialValues={riskDetails}
      onSubmit={(values) => dispatch(submitRiskDetailForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <Grid gap={8}>
            <DatePickerField
              required
              name="hospitalizationFrom"
              label={formatMessage(messages.sinceLabel)}
              helperText={formatMessage(
                messages.hospitalisation_sinceHelperText
              )}
            />
            <CheckboxField
              name="hasUntil"
              label={formatMessage(messages.hasUntilLabel)}
            />
            {formik.values.hasUntil && (
              <DatePickerField
                required
                name="hospitalizationTo"
                label={formatMessage(messages.untilLabel)}
              />
            )}
            <SelectField
              name="reason"
              options={getReasonOptions()}
              label={formatMessage(messages.reasonLabel)}
            />
            <Paragraph center bold>
              {formatMessage(messages.examinatedHeading)}
            </Paragraph>
            <CheckboxField
              name="undergoneSpecialistExamination"
              label={formatMessage(messages.undergoneSpecialExamLabel)}
            />
            <CheckboxField
              name="undergoneImagingExamination"
              label={formatMessage(messages.undergoneImagingExamLabel)}
            />
            <CheckboxField
              name="undergoneSurgeryOrHospitalised"
              label={formatMessage(
                messages.undergoneSurgeryOrHospitalisedLabel
              )}
            />
          </Grid>
          <Spacing variant="item" />
          <Grid xs={2} gap={4}>
            <Button
              full
              variant="gray"
              onClick={() => navigate('../risk-type')}
              label={formatMessage(commonMessages.stepFour_backButtonLabel)}
            />
            <Button
              full
              disabled={isSubmitting}
              type="submit"
              label={formatMessage(commonMessages.stepFour_continueButtonLabel)}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default HospitalizationForm;
