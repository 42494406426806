import { createAction } from 'redux-actions';

const namespace = 'globalData';

export const INIT = `${namespace}.init`;

export const FETCH_BANK_CODES_SUCCESS = `${namespace}.fetchBankCodesSuccess`;
export const FETCH_BANK_CODES_ERROR = `${namespace}.fetchBankCodesError`;

export const init = createAction(INIT);

export const fetchBankCodesSuccess = createAction(FETCH_BANK_CODES_SUCCESS);
export const fetchBankCodesError = createAction(FETCH_BANK_CODES_ERROR);
