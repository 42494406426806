import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import FlashCard from '../../components/cards/FlashCard';
import Loader from '../../components/Loader/loader';
import Heading from '../../components/text/Heading';
import { confirmChangeEmail } from '../../modules/settings/changeEmail/actions';
import { changeEmailLandingSelector } from '../../modules/settings/selectors';
import messages from './messages';

const ChangeEmailPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { isInitialized, success } = useSelector(changeEmailLandingSelector);

  useEffect(() => {
    if (state?.pairingCode) {
      dispatch(confirmChangeEmail({ pairingCode: state.pairingCode }));
    }
  }, [state?.pairingCode]);

  const pairingCode = useMemo(
    () => searchParams.get('pairingCode'),
    [searchParams]
  );

  if (pairingCode) {
    return <Navigate to="/change-email" state={{ pairingCode }} />;
  }

  if (!state?.pairingCode) {
    return <Navigate to="/" />;
  }

  return (
    <div className="bg-white pt-40">
      <Heading>
        <FormattedMessage {...messages.title} />
      </Heading>

      <div className="max-w-sm mx-auto pb-20">
        {!isInitialized && <Loader message={formatMessage(messages.pending)} />}
        {isInitialized && !success && (
          <FlashCard variant="error" message={formatMessage(messages.error)} />
        )}
        {isInitialized && success && (
          <FlashCard
            variant="success"
            message={formatMessage(messages.success)}
          />
        )}
      </div>
    </div>
  );
};

export default ChangeEmailPage;
