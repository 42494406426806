import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { injectNavigate } from './actions';

const NavigationInjector = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(injectNavigate((path, options) => navigate(path, options)));
  }, [navigate, dispatch]);

  return null;
};

export default NavigationInjector;
