import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const colors = ['green', 'orange', 'red', 'inherit'];

const propTypes = {
  icon: PropTypes.node.isRequired,
  color: PropTypes.oneOf(colors),
  hoverColor: PropTypes.oneOf(colors),
  className: PropTypes.string,
};
const defaultProps = {
  hoverColor: null,
  color: 'inherit',
};

const ColoredIcon = ({ icon, color, hoverColor, className }) => (
  <span
    className={cx(
      'leading-none transition-colors ease-in-out duration-200',
      {
        'text-cardif-green-light': color === 'green',
        'hover:text-cardif-green-light': hoverColor === 'green',
        'text-cardif-orange': color === 'orange',
        'hover:text-cardif-orange': hoverColor === 'orange',
        'text-red-600': color === 'red',
        'hover:text-red-600': hoverColor === 'red',
      },
      className
    )}
  >
    {icon}
  </span>
);

ColoredIcon.propTypes = propTypes;
ColoredIcon.defaultProps = defaultProps;

export default ColoredIcon;
