import { handleActions } from 'redux-actions';

import {
  CONFIRM_CHANGE_EMAIL,
  CONFIRM_CHANGE_EMAIL_ERROR,
  CONFIRM_CHANGE_EMAIL_SUCCESS,
} from './actions';

const initialState = {
  isInitialized: false,
  success: false,
};

export default handleActions(
  {
    [CONFIRM_CHANGE_EMAIL]: () => ({ ...initialState }),
    [CONFIRM_CHANGE_EMAIL_SUCCESS]: (state) => ({
      ...state,
      success: true,
      isInitialized: true,
    }),
    [CONFIRM_CHANGE_EMAIL_ERROR]: (state) => ({
      ...state,
      success: false,
      isInitialized: true,
    }),
  },
  initialState
);
