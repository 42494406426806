import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, exhaustMap, map as rxMap } from 'rxjs/operators';

import {
  confirmChangeEmailSuccess,
  confirmChangeEmailError,
  CONFIRM_CHANGE_EMAIL,
} from './actions';

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(CONFIRM_CHANGE_EMAIL),
    exhaustMap(({ payload }) =>
      ajax({
        url: `/gateway-server/accounts/settings/email/confirm/${payload.pairingCode}`,
      }).pipe(
        rxMap(() => confirmChangeEmailSuccess()),
        catchError(() => of(confirmChangeEmailError()))
      )
    )
  );
