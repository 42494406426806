import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ColoredIcon, WarningIcon } from '../../../components/icons';
import Link from '../../../components/Link';
import Paragraph from '../../../components/text/Paragraph';
import { clearTokens } from '../storage/actions';
import { prolongSession, trackActivity } from './actions';
import messages from './messages';
import { authInactivitySelector } from './selectors';

const InactivityPopup = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { inactivityPopupActive } = useSelector(authInactivitySelector);
  const [timer, setTimer] = useState(59);
  const location = useLocation();

  useEffect(() => {
    dispatch(trackActivity());
  }, [location]);

  useEffect(() => {
    let interval = null;

    if (inactivityPopupActive) {
      interval = setInterval(() => {
        setTimer((val) => val - 1);
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
      setTimer(59);
    };
  }, [inactivityPopupActive]);

  useEffect(() => {
    if (timer === 0) dispatch(clearTokens());
  }, [timer]);

  if (!inactivityPopupActive) return null;

  return (
    <div className="fixed top-8 right-8 bg-white">
      <div className="p-4 bg-cardif-orange bg-opacity-30 w-80">
        <Paragraph variant="caption">
          <span className="text-sm">
            <ColoredIcon icon={<WarningIcon />} />{' '}
            <FormattedMessage {...messages.warning} />
          </span>{' '}
          <FormattedMessage
            {...messages.text}
            values={{
              prolong: (
                <span className="italic">
                  <FormattedMessage {...messages.prolong} />
                </span>
              ),
              remainingTime: `00:00:${timer < 10 ? '0' : ''}${timer}`,
            }}
          />
        </Paragraph>
        <div className="font-bold">
          <Link
            variant="small"
            size="sm"
            to="#"
            onClick={(e) => {
              e.preventDefault();
              dispatch(prolongSession());
            }}
            label={formatMessage(messages.prolong)}
          />
        </div>
      </div>
    </div>
  );
};

export default InactivityPopup;
