import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { noop } from 'ramda-adjunct';

import Button from '../../../../components/Button';
import Container from '../../../../components/layout/Container';
import Spacing from '../../../../components/layout/Spacing';
import Modal from '../../../../components/Modal';
import Heading from '../../../../components/text/Heading';
import Paragraph from '../../../../components/text/Paragraph';
import messages from '../messages';
import Link from '../../../../components/Link/link';

const propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
const defaultProps = {
  isOpen: false,
};

const GDPRModal = ({ isOpen, onConfirm, onClose }) => {
  const intl = useIntl();

  return (
    <Modal hasCross onClose={onClose} onOpen={noop} isOpen={isOpen}>
      <Container variant="modal">
        <Heading variant="modal">
          {intl.formatMessage(messages.gdprModalTitle)}
        </Heading>
        <Paragraph center>
          {intl.formatMessage(messages.gdprModalBody, {
            link: (
              <Link
                label="zde"
                to="https://cardif.cz/ochrana-osobnich-udaju"
                target="_blank"
              />
            ),
          })}
        </Paragraph>
        <Spacing>
          <Button
            center
            onClick={onConfirm}
            label={intl.formatMessage(messages.gdprModalConfirm)}
          />
        </Spacing>
      </Container>
    </Modal>
  );
};

GDPRModal.propTypes = propTypes;
GDPRModal.defaultProps = defaultProps;

export default GDPRModal;
