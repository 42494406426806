import { applySpec, pipe, prop, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map as rxMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  VERIFY_PASSWORD_RESET,
  verifyPasswordResetSuccess,
  verifyPasswordResetError,
} from '../actions';
import { otpIdSelector, usernameSelector } from '../selectors';

const parseResponse = unary(
  pipe(
    prop('response'),
    applySpec({ email: prop('email'), phone: prop('phone') }),
    unary(verifyPasswordResetSuccess)
  )
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(VERIFY_PASSWORD_RESET),
    withLatestFrom(state$),
    exhaustMap(([{ payload }, state]) =>
      ajax({
        method: 'POST',
        url: '/gateway-server/accounts/password/verify',
        body: {
          phoneNumber: usernameSelector(state),
          otpId: otpIdSelector(state),
          code: payload.otp,
        },
      }).pipe(
        rxMap(parseResponse),
        catchError((e) => of(verifyPasswordResetError(e)))
      )
    )
  );
