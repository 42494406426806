import { Reader } from 'monet';
import {
  always,
  constructN,
  curryN,
  filter,
  flip,
  join,
  lensProp,
  map,
  over,
  pathOr,
  pipe,
  unapply,
  unless,
  when,
} from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';

const ADDRESS_FIELDS = ['streetAddress1', 'city', 'postalCode', 'country'];

export const basicLocalize = (value) =>
  Reader(({ messages, intl }) => intl.formatMessage(messages[value]));

export const prefixLocalize = curryN(2, (prefix, value) =>
  Reader(({ messages, intl }) => intl.formatMessage(messages[prefix + value]))
);

export const formatDate = (value) =>
  Reader(({ intl }) =>
    when(
      isNotNilOrEmpty,
      pipe(constructN(1, Date), (d) => intl.formatDate(d))
    )(value)
  );

export const noFormat = (value) => Reader(() => value);

export const formatAddress = (value) =>
  Reader(() =>
    pipe(
      map(unapply(flip(pathOr(null))(value))),
      filter(isNotNilOrEmpty),
      join(', '),
      unless(isNotNilOrEmpty, always(null))
    )(ADDRESS_FIELDS)
  );

export const addDefaultFormatters = map(
  pipe(
    over(lensProp('nameFormatter'), when(isNilOrEmpty, always(basicLocalize))),
    over(lensProp('valueFormatter'), when(isNilOrEmpty, always(noFormat)))
  )
);
