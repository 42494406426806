/* eslint-disable import/prefer-default-export */

import { ifElse, map, path, pathSatisfies, prop, reduce, unapply } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

import { FILE_VALIDATION_STATUS_PENDING } from './lnd-legacy/constants';
import FileWrapper from './lnd-legacy/FileWrapper';

// export const createFWrapper = (file) => ({
//   file,
//   id: Math.random()
//     .toString(36)
//     .replace(/[^a-z]+/g, '')
//     .substr(0, 5),
//   name: file.name,
// });

export const createFWrapper = (
  file,
  authToken,
  setLastFileValidationTime,
  bProcess
) => new FileWrapper({ file, authToken, setLastFileValidationTime, bProcess });

export const createFWrappers = (files, authToken, setLastFileValidationTime) =>
  files.map((f) => createFWrapper(f, authToken, setLastFileValidationTime));

export const getFileToUpload = ({ file }) => {
  const blob = new Blob([file], { type: file.type });
  const actualFile = new File([blob], file.name, { type: file.type });
  return actualFile;
};

export const getFilesToUpload = (fWrappers) => fWrappers.map(getFileToUpload);

export const deleteFWrapperUtil = (fWrappers, id) =>
  fWrappers.filter((fWrapper) => fWrapper.id !== id);

const documentValidReducer = unapply(
  ifElse(path([0]), pathSatisfies(isNilOrEmpty, [1, 'error']), path([0]))
);

export const allDocumentsValid = reduce(documentValidReducer, true);

const documentValidatedReducer = unapply(
  ifElse(
    path([0]),
    pathSatisfies(
      (status) => status !== FILE_VALIDATION_STATUS_PENDING,
      [1, 'status']
    ),
    path([0])
  )
);

export const allDocumentsValidated = reduce(documentValidatedReducer, true);

export const unwrapFiles = map(prop('file'));
