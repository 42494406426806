import { createAction } from 'redux-actions';

const namespace = 'crdf.nols.list';

export const FETCH_NOL_LIST = `${namespace}.fetchNolList`;
export const FETCH_NOL_LIST_SUCCESS = `${namespace}.fetchNolListSuccess`;
export const FETCH_NOL_LIST_ERROR = `${namespace}.fetchNolListError`;
export const CANCEL_FETCH_NOL_LIST = `${namespace}.cancelFetchNolList`;

export const fetchNolList = createAction(FETCH_NOL_LIST);
export const fetchNolListSuccess = createAction(FETCH_NOL_LIST_SUCCESS);
export const fetchNolListError = createAction(FETCH_NOL_LIST_ERROR);
export const cancelFetchNolList = createAction(CANCEL_FETCH_NOL_LIST);
