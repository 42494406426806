import { both, curryN, prop, propEq } from 'ramda';

import runValidations, {
  isRequired,
  isNotFuture,
  isChronologicalAfter,
  hasLengthMax,
} from '../../../../forms/validators';

const validations = {
  temporaryDisabilityFrom: {
    validate: [isRequired(), isNotFuture()],
  },
  temporaryDisabilityTo: {
    when: propEq('hasEnded', true),
    validate: [
      isRequired(),
      isNotFuture(),
      isChronologicalAfter(prop('temporaryDisabilityFrom')),
    ],
  },
  description: {
    validate: [isRequired(), hasLengthMax(500)],
  },
  retirementPensionFrom: {
    when: propEq('inRetirement', true),
    validate: [isRequired(), isNotFuture()],
  },
  policeInvestigation: {
    when: propEq('investigatedByPolice', true),
    validate: [isRequired(), hasLengthMax(500)],
  },
  disabilityFrom: {
    when: propEq('isInvalid', true),
    validate: [isRequired(), isNotFuture()],
  },
  disabilityTo: {
    when: both(propEq('isInvalid', true), propEq('hasDisabilityUntil', true)),
    validate: [
      isRequired(),
      isNotFuture(),
      isChronologicalAfter(prop('disabilityFrom')),
    ],
  },
};

export default curryN(2, (deps, values) =>
  runValidations(validations, deps, values)
);
