import React from 'react';
import { useIntl } from 'react-intl';

import FAQ from '../../components/Faq';
import FAQItem from '../../components/Faq/Item';
import Paragraph from '../../components/text/Paragraph';
import Link from '../../components/Link/link';
import EventTypeLinks from './EventTypeLinks';
import List from '../../components/List';
import ListItem from '../../components/List/Item';
import messages from './homepage.messages';
import cMessages from '../i18n/common.messages';

export const propTypes = {};
const defaultProps = {};

const HomePageFAQ = () => {
  const intl = useIntl();

  return (
    <FAQ>
      <FAQItem title={intl.formatMessage(messages.whoIsBNP_question)}>
        <Paragraph multiline>
          {intl.formatMessage(messages.whoIsBNP_answer)}
        </Paragraph>
      </FAQItem>
      <FAQItem title={intl.formatMessage(messages.howToReport_question)}>
        <Paragraph multiline>
          {intl.formatMessage(messages.howToReport_answer)}
        </Paragraph>
        <List variant="unordered" type="disc">
          <ListItem type="faq">
            {intl.formatMessage(messages.howToReport_answer_bullet_1)}
          </ListItem>
          <ListItem type="faq">
            {intl.formatMessage(messages.howToReport_answer_bullet_2)}
          </ListItem>
          <ListItem type="faq">
            {intl.formatMessage(messages.howToReport_answer_bullet_3, {
              link: (
                <Link
                  label={intl.formatMessage(cMessages.here)}
                  to="https://cardif.cz/documents/801651/801767/e-form%20Prubeh%20udalosti%20Pracovni%20neschopnost.pdf/d4fddfbb-3775-4028-1a7b-abb92c7c6fd3?t=1662451260040"
                  target="_blank"
                />
              ),
            })}
          </ListItem>
        </List>
        <Paragraph multiline extraClass="pt-4">
          {intl.formatMessage(messages.howToReport_answer_2)}
        </Paragraph>
        <EventTypeLinks />
      </FAQItem>
      <FAQItem title={intl.formatMessage(messages.reportForOther_question)}>
        <Paragraph multiline extraClass="pb-2">
          {intl.formatMessage(messages.reportForOther_answer)}
        </Paragraph>
        <EventTypeLinks />
      </FAQItem>
      <FAQItem
        title={intl.formatMessage(messages.whenCanIReportEvent_question)}
      >
        <Paragraph multiline>
          {intl.formatMessage(messages.whenCanIReportEvent_answer)}
        </Paragraph>
      </FAQItem>
      <FAQItem title={intl.formatMessage(messages.inactiveInsurance_question)}>
        <Paragraph>
          {intl.formatMessage(messages.inactiveInsurance_answer)}
        </Paragraph>
      </FAQItem>
      <FAQItem title={intl.formatMessage(messages.fullfillment_question)}>
        <Paragraph multiline>
          {intl.formatMessage(messages.fullfillment_answer)}
        </Paragraph>
      </FAQItem>
      <FAQItem title={intl.formatMessage(messages.toc_question)}>
        <Paragraph multiline>
          {intl.formatMessage(messages.toc_answer, {
            link: (
              <Link label="czinfo@cardif.com" to="mailto:czinfo@cardif.com" />
            ),
          })}
        </Paragraph>
      </FAQItem>
    </FAQ>
  );
};

HomePageFAQ.propTypes = propTypes;
HomePageFAQ.defaultProps = defaultProps;

export default HomePageFAQ;
