import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { accountSelector } from './selectors';

export const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
const defaultProps = {};

const OnlyNotRegistered = ({ children }) => {
  const { isLoading, isRegistered } = useSelector(accountSelector);

  if (isLoading) return null;

  if (isRegistered) return <Navigate to="/nols" replace />;

  return <>{children}</>;
};

OnlyNotRegistered.propTypes = propTypes;
OnlyNotRegistered.defaultProps = defaultProps;

export default OnlyNotRegistered;
