import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { last, pipe, split } from 'ramda';

import Spacing from '../../../components/layout/Spacing';
import PdfDoc from '../../../components/images/PdfDoc';
import Link from '../../../components/Link';
import Ellipsis from '../../../components/text/Ellipsis';
import messages from '../messages';

export const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      mimeType: PropTypes.string.isRequired,
    })
  ).isRequired,
};
const defaultProps = {};

const mimeToExt = pipe(split('/'), last);

const ModalAttachment = ({ data }) => (
  <Spacing variant="modal">
    <FormattedMessage {...messages.attachementsLabel} />

    {data.map(({ uuid, fileName, mimeType }) => (
      <div className="my-2 w-full flex flex-nowrap items-end gap-1" key={uuid}>
        <PdfDoc />
        <Ellipsis title={`${fileName} (${mimeType})`}>
          <Link
            to={`/gateway-server/message/attachment/download/${uuid}`}
            component="a"
            label={`${fileName} (${mimeType})`}
            download={`${fileName}.${mimeToExt(mimeType)}`}
          />
        </Ellipsis>
      </div>
    ))}
  </Spacing>
);

ModalAttachment.propTypes = propTypes;
ModalAttachment.defaultProps = defaultProps;

export default ModalAttachment;
