import { handleActions } from 'redux-actions';

import {
  LOGIN_DEINIT,
  LOGIN_INIT,
  VERIFY_PASSWORD_ERROR,
  VERIFY_PASSWORD_SUCCESS,
} from '../actions';

const initialState = {
  isInvalid: false,
  remainingAttempts: null,
  lockedUntil: null,
  secondsToNextAttempt: null,
  passwordVerificationIsBlocked: false,
};

export default handleActions(
  {
    [LOGIN_INIT]: () => ({ ...initialState }),
    [LOGIN_DEINIT]: () => ({ ...initialState }),
    [VERIFY_PASSWORD_SUCCESS]: () => ({
      passwordVerificationIsBlocked: false,
    }),
    [VERIFY_PASSWORD_ERROR]: (state, { payload }) => ({
      isInvalid: true,
      remainingAttempts: payload.remainingAttempts,
      lockedUntil: payload.lockedUntil,
      secondsToNextAttempt: payload.secondsToNextAttempt,
      passwordVerificationIsBlocked: payload.passwordVerificationIsBlocked,
    }),
  },
  initialState
);
