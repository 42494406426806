import { combineEpics, ofType } from 'redux-observable';
import { timer } from 'rxjs';
import { map as rxMap, switchMap, take, takeUntil } from 'rxjs/operators';

import {
  openUploadFeedback,
  closeUploadFeedback,
  UPLOAD_DOCUMENTS_ERROR,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_FEEDBACK_OPEN,
  UPLOAD_FEEDBACK_CLOSE,
  UPLOAD_DOCUMENTS_FILE_ERRORS,
} from '../actions';

const errorFeedbackEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTS_ERROR, UPLOAD_DOCUMENTS_FILE_ERRORS),
    rxMap(() => openUploadFeedback({ type: 'error' }))
  );

const successFeedbackEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_DOCUMENTS_SUCCESS),
    rxMap(() => openUploadFeedback({ type: 'success' }))
  );

const feedbackTimerEpic = (action$) =>
  action$.pipe(
    ofType(UPLOAD_FEEDBACK_OPEN),
    switchMap(() =>
      timer(10000).pipe(
        rxMap(() => closeUploadFeedback()),
        take(1),
        takeUntil(action$.pipe(ofType(UPLOAD_FEEDBACK_CLOSE)))
      )
    )
  );

export default combineEpics(
  errorFeedbackEpic,
  successFeedbackEpic,
  feedbackTimerEpic
);
