import { pick, pipe, prop, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  switchMap,
  withLatestFrom,
  map as rxMap,
  catchError,
} from 'rxjs/operators';

import { authorizationHeaderSelector } from '../../auth/storage/selectors';
import { getSettingsError, getSettingsSuccess, GET_SETTINGS } from '../actions';

const parse = unary(
  pipe(
    prop('response'),
    pick(['email', 'notificationMethod', 'phone']),
    unary(getSettingsSuccess)
  )
);

export default (action$, state$) =>
  action$.pipe(
    ofType(GET_SETTINGS),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      ajax({
        url: '/gateway-server/accounts',
        headers: { authorization: authorizationHeaderSelector(state) },
      }).pipe(
        rxMap(parse),
        catchError((e) => of(getSettingsError(e)))
      )
    )
  );
