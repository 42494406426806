import { startsWith } from 'ramda';

import countryList from '../../forms/common/CountrySelect/countries.json';

// eslint-disable-next-line import/prefer-default-export
export const splitPrefix = (phone) => {
  const country = countryList.find((c) =>
    startsWith(`+${c.callingCode}`, phone)
  );

  const beginIndex = `+${country.callingCode}`.length;

  return {
    phonePrefix: country.callingCode,
    phoneNumber: phone.slice(beginIndex),
  };
};
