import { T, always, cond, curryN, flip, includes, pipe, prop } from 'ramda';

import messages from './messages';

const flipIncludes = flip(includes);

const pickMessage = curryN(2, (msgs, key) => prop(key, msgs));

export default pipe(
  cond([
    [flipIncludes([115, 116, 118]), always('home')],
    [flipIncludes([123, 151, 124]), always('doctor')],
    [flipIncludes([136]), always('confirmationOfTemporaryWorkingInability')],
    [flipIncludes([197]), always('doctorAdditionalInfo')],
    [flipIncludes([262]), always('docByEmployer')],
    [flipIncludes([261]), always('docByTac')],
    [flipIncludes([263]), always('docYouAlreadyHaveOrByDoctor')],
    [flipIncludes([139, 254]), always('cssz')],
    [flipIncludes([167, 168]), always('ossz')],
    [flipIncludes([134]), always('employment_department')],
    [flipIncludes([119, 166]), always('work')],
    [flipIncludes([117]), always('workConfirmation')],
    [flipIncludes([137]), always('hospital')],
    [flipIncludes([171]), always('other')],
    [T, always('missingDescription')],
  ]),
  pickMessage(messages)
);
