import { defineMessages } from 'react-intl';

export default defineMessages({
  loginLink: {
    id: 'layout_header_loginLink',
    defaultMessage: 'Přihlásit se',
  },
  logout: {
    id: 'layout_header_logout',
    defaultMessage: 'Odhlásit se',
  },
  settings: {
    id: 'layout_header_settings',
    defaultMessage: 'Nastavení',
  },
  eventList: {
    id: 'layout_header_eventList',
    defaultMessage: 'Přehled nahlášených událostí',
  },
});
