import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Modal from '../../../../components/Modal';
import Container from '../../../../components/layout/Container';
import Paragraph from '../../../../components/text/Paragraph';
import Spacing from '../../../../components/layout/Spacing';
import Button from '../../../../components/Button';
import messages from './messages';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

const DuplicitNolModal = ({ isOpen, onClose }) => {
  const intl = useIntl();

  return (
    <Modal onClose={() => onClose()} onOpen={() => {}} isOpen={isOpen}>
      <Container variant="modal">
        <Paragraph center>
          {intl.formatMessage(messages.duplicitNolModalText)}
        </Paragraph>
        <Spacing>
          <Button
            center
            full
            onClick={() => onClose()}
            label={intl.formatMessage(messages.duplicitNolModalBtnLabel)}
          />
        </Spacing>
      </Container>
    </Modal>
  );
};

DuplicitNolModal.propTypes = propTypes;

export default DuplicitNolModal;
