import React, { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import NolDetail from '../../../modules/nol/detail/NolDetail';
import DuplicitNolModal from '../../../modules/nol/detail/NolDetail/DuplicitNolModal';

const NolDetailPage = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const [showDuplicitModal, setShowDuplicitModal] = useState(
    !!state?.isDuplicitNav
  );

  if (state?.isDuplicitNav) {
    return <Navigate to="." state={{}} />;
  }

  return (
    <>
      <DuplicitNolModal
        isOpen={showDuplicitModal}
        onClose={() => setShowDuplicitModal(false)}
      />
      <NolDetail id={id} />
    </>
  );
};

export default NolDetailPage;
