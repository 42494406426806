import { defineMessages } from 'react-intl';

const namespace = 'crdf_nols_detail_tabs_uploadTab';

export default defineMessages({
  home: {
    id: `${namespace}_home`,
    defaultMessage: 'Dokumenty byste měl/a mít k dispozici doma.',
  },
  doctor: {
    id: `${namespace}_doctor`,
    defaultMessage: 'Dokument vám vyplní váš ošetřující lékař.',
  },
  confirmationOfTemporaryWorkingInability: {
    id: `${namespace}_confirmationOfTemporaryWorkingInability`,
    defaultMessage: 'Dokument vám vyplní váš ošetřující lékař.',
  },
  doctorAdditionalInfo: {
    id: `${namespace}_doctorAdditionalInfo`,
    defaultMessage: 'Doplňující informace, které máte, nebo získáte od lékaře.',
  },
  docByEmployer: {
    id: `${namespace}_docByEmployer`,
    defaultMessage: 'Dokument vám vyplní váš zaměstnavatel.',
  },
  docByTac: {
    id: `${namespace}_docByTac`,
    defaultMessage: 'Dokument dejte vyplnit ošetřované osobě.',
  },
  docYouAlreadyHaveOrByDoctor: {
    id: `${namespace}_docYouAlreadyHaveOrByDoctor`,
    defaultMessage:
      'Dokument byste měl/a mít k dispozici doma. Pokud ne, požádejte lékaře.',
  },
  cssz: {
    id: `${namespace}_cssz`,
    defaultMessage: 'Dokument získáte od České správy sociálního zabezpečení.',
  },
  ossz: {
    id: `${namespace}_ossz`,
    defaultMessage:
      'Dokument získáte od Okresní správy sociálního zabezpečení.',
  },
  employment_department: {
    id: `${namespace}_employment_department`,
    defaultMessage: 'Dokument vám vyplní váš zaměstnavatel.',
  },
  work: {
    id: `${namespace}_work`,
    defaultMessage: 'Dokument získáte na úřadu práce.',
  },
  workConfirmation: {
    id: `${namespace}_workConfirmation`,
    defaultMessage: 'Dokument získáte na úřadu práce.',
  },
  hospital: {
    id: `${namespace}_hospital`,
    defaultMessage: 'Dokument vám dají/dali v místě, kde vás hospitalizovali.',
  },
  other: {
    id: `${namespace}_other`,
    defaultMessage:
      'Nahrajte libovolný další dokument ke škodní události, který považujete za důležitý.',
  },
  missingDescription: {
    id: `${namespace}_missingDescription`,
    defaultMessage: 'Nahrajte požadovaný dokument ke škodní události.',
  },
  downloadTemplate: {
    id: `${namespace}_downloadTemplate`,
    defaultMessage: 'Stáhnout vzor',
  },
});
