import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Group from '../../../../components/text/Group';
import messages from '../messages';

const propTypes = {
  partner: PropTypes.string.isRequired,
  contractNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  risk: PropTypes.string.isRequired,
};
const defaultProps = {};

const ContractCard = ({ partner, contractNumber, risk }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="bg-white shadow-faq gap-4 py-6 px-8 w-full rounded-md">
      <Group
        name={<b>{formatMessage(messages.partner)}</b>}
        value={<b>{partner}</b>}
      />
      <Group
        name={<b>{formatMessage(messages.contractNumber)}</b>}
        value={<b>{contractNumber}</b>}
      />
      <Group
        name={<b>{formatMessage(messages.risk)}</b>}
        value={<b>{risk}</b>}
      />
    </div>
  );
};

ContractCard.propTypes = propTypes;
ContractCard.defaultProps = defaultProps;

export default ContractCard;
