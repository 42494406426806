import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { SuccessIcon, ErrorIcon } from './icons';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['success', 'error']).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
const defaultProps = {};

const Banner = ({ onClose, type, title, description }) => {
  let descrNode = description;

  if (!isValidElement(descrNode))
    descrNode = <div className="text-2xl">{descrNode}</div>;

  return (
    <div className="fixed top-0 left-0 right-0 flex px-4 pt-4 md:pt-8 justify-center z-30">
      <div
        className={cx(
          'relative mx-auto max-w-2xl rounded-lg border-2 bg-white bg-opacity-95',
          {
            'border-cardif-green-light': type === 'success',
            'border-[#B0342D]': type === 'error',
          }
        )}
      >
        <div
          className={cx('p-8 bg-opacity-15 font-cardif-regular text-center', {
            'bg-cardif-green-light': type === 'success',
            'bg-[#B0342D]': type === 'error',
          })}
        >
          <span
            className="absolute top-3 right-4 cursor-pointer text-lg text-black hover:text-cardif-gray-dark"
            onClick={onClose}
          >
            &#10005;
          </span>
          <div className="flex justify-center">
            {type === 'success' ? <SuccessIcon /> : <ErrorIcon />}
          </div>
          <h3 className="text-4xl mt-8 mb-4">{title}</h3>
          {descrNode}
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = propTypes;
Banner.defaultProps = defaultProps;

export default Banner;
