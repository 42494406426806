import { createAction } from 'redux-actions';

const namespace = 'crdf.journeys.activeJourney.form';

export const FORM_MOUNT = `${namespace}.mount`;
export const FORM_UNMOUNT = `${namespace}.unmount`;

export const GENERIC_SUBMIT = `${namespace}.genericSubmit`;
export const GENERIC_SUBMIT_SUCCESS = `${namespace}.genericSubmitSuccess`;
export const GENERIC_SUBMIT_ERROR = `${namespace}.genericSubmitError`;

export const SUBMIT_PERSONAL_DATA_FORM = `${namespace}.submitPersonalDataForm`;
export const SUBMIT_PERSONAL_DATA_FORM_SUCCESS = `${namespace}.submitPersonalDataFormSuccess`;
export const SUBMIT_PERSONAL_DATA_FORM_ERROR = `${namespace}.submitPersonalDataFormError`;

export const SUBMIT_CONTACT_DATA_FORM = `${namespace}.submitContactDataForm`;
export const SUBMIT_CONTACT_DATA_FORM_SUCCESS = `${namespace}.submitContactDataFormSuccess`;
export const SUBMIT_CONTACT_DATA_FORM_ERROR = `${namespace}.submitContactDataFormError`;

export const SUBMIT_RISK_TYPE_FORM = `${namespace}.submitRiskTypeForm`;
export const SUBMIT_RISK_TYPE_FORM_SUCCESS = `${namespace}.submitRiskTypeFormSuccess`;
export const SUBMIT_RISK_TYPE_FORM_ERROR = `${namespace}.submitRiskTypeFormError`;

export const SUBMIT_RISK_DETAIL_FORM = `${namespace}.submitRiskDetailForm`;
export const SUBMIT_RISK_DETAIL_FORM_SUCCESS = `${namespace}.submitRiskDetailFormSuccess`;
export const SUBMIT_RISK_DETAIL_FORM_ERROR = `${namespace}.submitRiskDetailFormError`;

export const SUBMIT_BANK_ACCOUNT_FORM = `${namespace}.submitBankAccountForm`;
export const SUBMIT_BANK_ACCOUNT_FORM_SUCCESS = `${namespace}.submitBankAccountFormSuccess`;
export const SUBMIT_BANK_ACCOUNT_FORM_ERROR = `${namespace}.submitBankAccountFormError`;

export const FINALIZE_JOURNEY = `${namespace}.finalizeJourney`;
export const FINALIZE_JOURNEY_SUCCESS = `${namespace}.finalizeJourneySuccess`;
export const FINALIZE_JOURNEY_ERROR = `${namespace}.finalizeJourneyError`;

export const formMount = createAction(FORM_MOUNT);
export const formUnmount = createAction(FORM_UNMOUNT);

export const genericSubmit = createAction(GENERIC_SUBMIT);
export const genericSubmitSuccess = createAction(GENERIC_SUBMIT_SUCCESS);
export const genericSubmitError = createAction(GENERIC_SUBMIT_ERROR);

export const submitPersonalDataForm = createAction(SUBMIT_PERSONAL_DATA_FORM);
export const submitPersonalDataFormSuccess = createAction(
  SUBMIT_PERSONAL_DATA_FORM_SUCCESS
);
export const submitPersonalDataFormError = createAction(
  SUBMIT_PERSONAL_DATA_FORM_ERROR
);

export const submitContactDataForm = createAction(SUBMIT_CONTACT_DATA_FORM);
export const submitContactDataFormSuccess = createAction(
  SUBMIT_CONTACT_DATA_FORM_SUCCESS
);
export const submitContactDataFormError = createAction(
  SUBMIT_CONTACT_DATA_FORM_ERROR
);

export const submitRiskTypeForm = createAction(SUBMIT_RISK_TYPE_FORM);
export const submitRiskTypeFormSuccess = createAction(
  SUBMIT_RISK_TYPE_FORM_SUCCESS
);
export const submitRiskTypeFormError = createAction(
  SUBMIT_RISK_TYPE_FORM_ERROR
);

export const submitRiskDetailForm = createAction(SUBMIT_RISK_DETAIL_FORM);
export const submitRiskDetailFormSuccess = createAction(
  SUBMIT_RISK_DETAIL_FORM_SUCCESS
);
export const submitRiskDetailFormError = createAction(
  SUBMIT_RISK_DETAIL_FORM_ERROR
);

export const submitBankAccountForm = createAction(SUBMIT_BANK_ACCOUNT_FORM);
export const submitBankAccountFormSuccess = createAction(
  SUBMIT_BANK_ACCOUNT_FORM_SUCCESS
);
export const submitBankAccountFormError = createAction(
  SUBMIT_BANK_ACCOUNT_FORM_ERROR
);

export const finalizeJourney = createAction(FINALIZE_JOURNEY);
export const finalizeJourneySuccess = createAction(FINALIZE_JOURNEY_SUCCESS);
export const finalizeJourneyError = createAction(FINALIZE_JOURNEY_ERROR);
