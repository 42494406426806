import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';

import CountrySelectField from '../../fields/countrySelect';
import TextField from '../../fields/textField';
import Span from '../../../../components/Grid/Span';
import messages from '../messages';

export const propTypes = {
  currentStep: PropTypes.oneOf([
    'phone',
    'password',
    'otp',
    'createPassword',
    'confirm',
    'phoneReset',
    'otpReset',
    'resetConfirm',
  ]).isRequired,
  state: PropTypes.shape({
    isNotRegistered: PropTypes.bool.isRequired,
    isNotValidFormat: PropTypes.bool.isRequired,
    updateTS: PropTypes.number,
  }).isRequired,
};

const PhoneFragment = ({ currentStep, state }) => {
  const { formatMessage } = useIntl();
  const { setFieldError } = useFormikContext();

  useEffect(() => {
    if (state.isNotValidFormat)
      setFieldError('phoneNumber', formatMessage(messages.phoneNotValid));
  }, [state.isNotValidFormat, state.updateTS]);

  const isDisabled =
    (currentStep !== 'phone' && currentStep !== 'phoneReset') ||
    state.isNotRegistered;

  if (
    currentStep === 'createPassword' ||
    currentStep === 'confirm' ||
    currentStep === 'resetConfirm'
  )
    return null;

  return (
    <>
      <Span xs={4}>
        <CountrySelectField
          disabled={isDisabled}
          name="phoneNumberPrefix"
          variant="phone"
        />
      </Span>
      <Span xs={7}>
        <TextField
          required
          disabled={isDisabled}
          autoFocus={!isDisabled}
          name="phoneNumber"
          type="text"
          filterPattern={/^[0-9]+$/}
          label={formatMessage(messages.phoneLabel)}
        />
      </Span>
    </>
  );
};

PhoneFragment.propTypes = propTypes;

export default PhoneFragment;
