import { defineMessages } from 'react-intl';

const namespace = 'crdf_upload';

export default defineMessages({
  cancelLabel: {
    id: `${namespace}_cancelLabel`,
    defaultMessage: 'Zrušit',
  },
  uploadLabel: {
    id: `${namespace}_uploadLabel`,
    defaultMessage: 'Odeslat dokumenty',
  },
  noUploadedDocsMessage: {
    id: `${namespace}_noUploadedDocsMessage`,
    defaultMessage: 'Počet souborů musí být minimálně 1 a maximálně 50.',
  },
  WRONG_MIME_TYPE: {
    id: `${namespace}_WRONG_MIME_TYPE`,
    defaultMessage: 'Špatný typ souboru',
  },
  FILE_IS_TOO_BIG: {
    id: `${namespace}_FILE_IS_TOO_BIG`,
    defaultMessage: 'Soubor je příliš velký',
  },
  IMAGE_IS_TOO_BIG_RESIZING_IS_USELESS: {
    id: `${namespace}_IMAGE_IS_TOO_BIG_RESIZING_IS_USELESS`,
    defaultMessage: 'Soubor je příliš velký',
  },
  CANNOT_RESIZE_IMAGE: {
    id: `${namespace}_CANNOT_RESIZE_IMAGE`,
    defaultMessage: 'Soubor nelze zmenšit',
  },
  CANNOT_CONVERT_DOC: {
    id: `${namespace}_CANNOT_CONVERT_DOC`,
    defaultMessage: 'Dokument nelze zkonvertovat do PDF',
  },
  CANNOT_UPLOAD_FILE: {
    id: `${namespace}_CANNOT_UPLOAD_FILE`,
    defaultMessage: 'Nelze nahrát soubor',
  },
  sizeCountIsInvalid: {
    id: `${namespace}_sizeCountIsInvalid`,
    defaultMessage: 'Velikost všech souborů přesahuje limit 100MB.',
  },
  documentsUploading: {
    id: `${namespace}_documentsUploading`,
    defaultMessage: 'Soubory se nahrávají, prosím počkejte.',
  },
  imagesConverting: {
    id: `${namespace}_imagesConverting`,
    defaultMessage: 'Soubory se kontrolují, prosím počkejte.',
  },
  feedbackErrorTitle: {
    id: `${namespace}_feedbackErrorTitle`,
    defaultMessage: 'Dokument se nepodařilo nahrát.',
  },
  feedbackSuccessTitle: {
    id: `${namespace}_feedbackSuccessTitle`,
    defaultMessage: 'Dokument byl úspěšně nahrán',
  },
  feedbackSuccessText: {
    id: `${namespace}_feedbackSuccessText`,
    defaultMessage: 'Pro kontrolu dokumentu navštivte záložku "Nahráno"',
  },
  feedbackUploadErrorTitle: {
    id: `${namespace}_feedbackUploadErrorTitle`,
    defaultMessage: 'Dokument(y) se nepodařilo nahrát.',
  },
  feedbackFileErrorTitle: {
    id: `${namespace}_feedbackFileErrorTitle`,
    defaultMessage: 'Následující dokument(y) se nepodařilo nahrát:',
  },
  feedbackErrorText: {
    id: `${namespace}_feedbackErrorText`,
    defaultMessage:
      'Zkuste prosím případně soubor(y) nahrát znovu.\n( Žádný dokument se zatím neuložil... )',
  },
  feedbackError_DOC_004: {
    id: `${namespace}_feedbackError_DOC_004`,
    defaultMessage: 'Neznámý typ/formát souboru',
  },
  feedbackError_DOC_005: {
    id: `${namespace}_feedbackError_DOC_005`,
    defaultMessage: 'Nelze změnit na požadovanou velikost',
  },
  feedbackError_DOC_008: {
    id: `${namespace}_feedbackError_DOC_008`,
    defaultMessage: 'Problém při čtení souboru',
  },
  feedbackError_008: {
    id: `${namespace}_feedbackError_008`,
    defaultMessage: 'Obsah souboru není validní - neodpovídá typu souboru',
  },
  feedbackError_EXC_002: {
    id: `${namespace}_feedbackError_EXC_002`,
    defaultMessage: 'Problém s uchováním do databáze',
  },
});
