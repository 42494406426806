import { ofType } from 'redux-observable';
import { switchMap, map as rxMap, distinctUntilChanged } from 'rxjs/operators';
import { identity, ifElse, unary } from 'ramda';

import { isAuthenticatedSelector } from '../../../../auth/storage/selectors';
import {
  INIT,
  fetchActiveJourneys,
  reset,
} from '../../actions/context.actions';

export default (action$, state$) =>
  action$.pipe(
    ofType(INIT),
    switchMap(() =>
      state$.pipe(
        rxMap(unary(isAuthenticatedSelector)),
        distinctUntilChanged(),
        rxMap(unary(ifElse(identity, unary(fetchActiveJourneys), unary(reset))))
      )
    )
  );
