import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Document, Page, pdfjs } from 'react-pdf';
import './AnnotationLayer.css';
import './TextLayer.css';
import './custom.css';

import Paragraph from '../../../components/text/Paragraph';
import messages from './messages';
import Paginator from './Paginator';
import { isImage, isPdf } from '../../upload/lnd-legacy/FileWrapper';
import FlashCard from '../../../components/cards/FlashCard/flash';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.3.11.174.min.js';

const propTypes = {
  fileWrapper: PropTypes.object.isRequired,
};
const defaultProps = {};

const DocView = ({ fileWrapper }) => {
  const { formatMessage } = useIntl();
  const docPreview = useRef();
  const [page, setPage] = useState(1);
  const [totPages, setTotPages] = useState(1);
  const [fileBlobUrl, setFileBlobUrl] = useState('');
  const [fileLoadError, setFileLoadError] = useState('');

  useEffect(() => {
    setPage(1);
  }, [fileWrapper]);

  // Setting the fixed width/height should avoid resizing - flickering when changing the doc page....
  const changePage = (newPage) => {
    const doc = docPreview.current;
    doc.style.width = `${doc.clientWidth}px`;
    doc.style.height = `${doc.clientHeight}px`;
    setPage(newPage);
  };

  // This will reset the document width/height to be flexible when resizing the window
  useEffect(() => {
    const handleResize = () => {
      const doc = docPreview.current;
      if (doc) {
        doc.style.width = null;
        doc.style.height = null;
      }
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setFileBlobUrl(
      URL.createObjectURL(
        isImage(fileWrapper) || isPdf(fileWrapper)
          ? fileWrapper.file
          : fileWrapper.fileView
      )
    );
    return URL.revokeObjectURL(fileBlobUrl);
  }, [fileWrapper]);

  const onDocumentLoad = ({ numPages }) => {
    setTotPages(numPages);
  };

  return (
    <>
      <Paragraph center extraClass="font-bold mb-4">
        {fileWrapper.file.name}
      </Paragraph>
      <div className="w-fit min-w-[200px] max-h-[70vh] overflow-y-auto">
        {isImage(fileWrapper) ? (
          <img
            src={fileBlobUrl}
            alt="preview picture"
            className="max-h-[50vh]"
          />
        ) : (
          <div className="flex flex-col items-center justify-center">
            {fileWrapper.fileView && (
              <Paragraph center extraClass="whitespace-pre-line mb-2">
                {formatMessage(messages.messageConverted)}
              </Paragraph>
            )}
            <div
              ref={docPreview}
              className="flex flex-col items-center justify-center"
            >
              {fileLoadError ? (
                <FlashCard
                  variant="error"
                  message={formatMessage(messages.fileContentError)}
                />
              ) : (
                <Document
                  file={fileBlobUrl}
                  onLoadSuccess={onDocumentLoad}
                  onLoadError={(err) => setFileLoadError(err.message)}
                  className="w-fit mx-auto"
                >
                  <Page
                    pageNumber={page}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </Document>
              )}
            </div>
            {totPages > 1 && (
              <Paginator
                totPages={totPages}
                page={page}
                changePage={changePage}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

DocView.propTypes = propTypes;
DocView.defaultProps = defaultProps;

export default DocView;
