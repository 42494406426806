import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { accountSelector } from '../../../../auth/account/selectors';
import { NurseRole } from '../common/constants';
import { riskDetailsSelector } from '../../../ActiveJourneyProvider/selectors';

const AutofillWatcher = () => {
  const userAcc = useSelector(accountSelector);
  const riskDetails = useSelector(riskDetailsSelector);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (values.nurseRole === NurseRole.INSURED && !values.nurseId) {
      setFieldValue('nurseId', userAcc.birthNumber, false);
      setFieldValue('nurseName', userAcc.firstName, false);
      setFieldValue('nurseSurname', userAcc.lastName, false);
    }
    if (values.nurseRole !== NurseRole.INSURED) {
      setFieldValue('nurseId', riskDetails.nurseId, false);
      setFieldValue('nurseName', riskDetails.nurseName, false);
      setFieldValue('nurseSurname', riskDetails.nurseSurname, false);
    }
    setFieldTouched('nurseId', false);
    setFieldTouched('nurseName', false);
    setFieldTouched('nurseSurname', false);
  }, [values.nurseRole]);

  return null;
};

export default AutofillWatcher;
