import { defineMessages } from 'react-intl';

const namespace = 'crdf_i18n_common';

export default defineMessages({
  name: {
    id: `${namespace}_name`,
    defaultMessage: 'Jméno',
  },
  firstName: {
    id: `${namespace}_firstName`,
    defaultMessage: 'Křestní jméno',
  },
  surname: {
    id: `${namespace}_surname`,
    defaultMessage: 'Příjmení',
  },
  email: {
    id: `${namespace}_email`,
    defaultMessage: 'E-mail',
  },
  street: {
    id: `${namespace}_street`,
    defaultMessage: 'Ulice',
  },
  zipCode: {
    id: `${namespace}_zipCode`,
    defaultMessage: 'PSČ',
  },
  city: {
    id: `${namespace}_city`,
    defaultMessage: 'Město',
  },
  phoneNumber: {
    id: `${namespace}_phoneNumber`,
    defaultMessage: 'Telefonní číslo',
  },
  submit: {
    id: `${namespace}_submit`,
    defaultMessage: 'Potvrdit',
  },
  save: {
    id: `${namespace}_save`,
    defaultMessage: 'Uložit',
  },
  cancel: {
    id: `${namespace}_cancel`,
    defaultMessage: 'Zrušit',
  },
  finish: {
    id: `${namespace}_finish`,
    defaultMessage: 'Dokončit',
  },
  yes: {
    id: `${namespace}_yes`,
    defaultMessage: 'Ano',
  },
  no: {
    id: `${namespace}_no`,
    defaultMessage: 'Ne',
  },
  create: {
    id: `${namespace}_create`,
    defaultMessage: 'Vytvořit',
  },
  from: {
    id: `${namespace}_from`,
    defaultMessage: 'Od',
  },
  to: {
    id: `${namespace}_to`,
    defaultMessage: 'Do',
  },
  description: {
    id: `${namespace}_description`,
    defaultMessage: 'Popis',
  },
  birthDate: {
    id: `${namespace}_birthDate`,
    defaultMessage: 'Datum narození',
  },
  streetAndHouseNo: {
    id: `${namespace}_streetAndHouseNo`,
    defaultMessage: 'Ulice a č.p.',
  },
  country: {
    id: `${namespace}_country`,
    defaultMessage: 'Stát',
  },
  nationality: {
    id: `${namespace}_nationality`,
    defaultMessage: 'Státní příslušnost',
  },
  continue: {
    id: `${namespace}_continue`,
    defaultMessage: 'Pokračovat',
  },
  back: {
    id: `${namespace}_back`,
    defaultMessage: 'Zpět',
  },
  edit: {
    id: `${namespace}_edit`,
    defaultMessage: 'Upravit',
  },
  birthNumber: {
    id: `${namespace}_birthNumber`,
    defaultMessage: 'Rodné číslo',
  },
  contactAddress: {
    id: `${namespace}_contactAddress`,
    defaultMessage: 'Kontaktní adresa',
  },
  citizenship: {
    id: `${namespace}_citizenship`,
    defaultMessage: 'Občanství',
  },
  zipCodeError: {
    id: `${namespace}_zipCodeError`,
    defaultMessage: 'Formát není validní.',
  },
  otpRequestIsBlocked: {
    id: `${namespace}_otpRequestIsBlocked`,
    defaultMessage:
      'Jednorázová hesla pro dané číslo jsou dočasně zablokovaná, prosím počkejte {time} minut.',
  },
  passwordRemainingAttempts: {
    id: `${namespace}_passwordRemainingAttempts`,
    defaultMessage:
      'Zadané heslo není správné. Zbývá vám {remainingAttempts} pokusů.',
  },
  passwordVerificationIsBlocked: {
    id: `${namespace}_passwordVerificationIsBlocked`,
    defaultMessage:
      'Ověřování hesla je zablokované, prosím zkuste za {time} minut.',
  },
  otpRequestIsBlockedCommon: {
    id: `${namespace}_otpRequestIsBlockedCommon`,
    defaultMessage:
      'Daný účet má dočasně zablokované požadavky na jednorázová hesla.',
  },
  passwordVerificationIsBlockedCommon: {
    id: `${namespace}_passwordVerificationIsBlockedCommon`,
    defaultMessage: 'Daný účet má dočasně zablokovanou kontrolu hesla.',
  },
  passwordIsRequiredByAdministrator: {
    id: `${namespace}_passwordIsRequiredByAdministrator`,
    defaultMessage: 'Administrátor vyžádal změnu hesla.',
  },
  WAITING_FOR_DOCUMENTS: {
    id: `${namespace}_WAITING_FOR_DOCUMENTS`,
    defaultMessage: 'Čekáme na doklady',
  },
  PROCESSING: {
    id: `${namespace}_PROCESSING`,
    defaultMessage: 'Probíhá šetření události',
  },
  ACCEPTED: {
    id: `${namespace}_ACCEPTED`,
    defaultMessage: 'Máte nárok na pojistné plnění',
  },
  REJECTED: {
    id: `${namespace}_REJECTED`,
    defaultMessage: 'Nevznikl Vám nárok na pojistné plnění',
  },
  DELETED: {
    id: `${namespace}_DELETED`,
    defaultMessage: 'Nevznikl Vám nárok na pojistné plnění',
  },
  phoneNumberIsUsed: {
    id: `${namespace}_phoneNumberIsUsed`,
    defaultMessage: 'Telefonní číslo již používá jiný uživatel.',
  },
  phoneNumberIsInvalid: {
    id: `${namespace}_phoneNumberIsInvalid`,
    defaultMessage: 'Telefonní číslo nenní validní.',
  },
  phoneNumberWasChanged: {
    id: `${namespace}_phoneNumberWasChanged`,
    defaultMessage: 'Telefonní číslo bylo změněno.',
  },
  otpRequestIsSent: {
    id: `${namespace}_otpRequestIsSent`,
    defaultMessage: 'Jednorázové heslo bylo odesláno.',
  },
  codeCorrectError: {
    id: `${namespace}_codeCorrectError`,
    defaultMessage: 'Byl zadán špatný potvrzovací kód. Zkuste to prosím znovu.',
  },
  backendInternalServerError: {
    id: `${namespace}_backendInternalServerError`,
    defaultMessage:
      'Na našem serveru došlo při zpracování vašeho požadavku k chybě. Prosím zkuste to znovu později.',
  },
  backendServiceNotFound: {
    id: `${namespace}_backendServiceNotFound`,
    defaultMessage:
      'Požadovaný webový zdroj nebyl nalezen. Prosím zkuste to znovu později.',
  },
  backendUnkknownError: {
    id: `${namespace}_backendUnkknownError`,
    defaultMessage:
      'Při zpracování vašeho požadavku došlo k chybě. Prosím zkuste to znovu později.',
  },
  accountLoginTemporarilyDisabled: {
    id: `${namespace}_accountLoginTemporarilyDisabled`,
    defaultMessage:
      'K tomuto účtu se momentálně nelze přihlásit, zkuste to, prosím, později.',
  },
  selfEmployerNumber: {
    id: `${namespace}_selfEmployerNumber`,
    defaultMessage: 'IČO',
  },
  permanentAddress: {
    id: `${namespace}_permanentAddress`,
    defaultMessage: 'Trvalá adresa',
  },
  here: {
    id: `${namespace}_here`,
    defaultMessage: 'zde',
  },
});
