import { format } from 'date-fns/fp';
import {
  always,
  applySpec,
  both,
  constructN,
  defaultTo,
  ifElse,
  pipe,
  prop,
  propEq,
  propOr,
  propSatisfies,
  when,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

export const fromApiTempDisability = applySpec({
  temporaryDisabilityFrom: pipe(
    prop('temporaryDisabilityFrom'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  hasEnded: propSatisfies(isNotNilOrEmpty, 'temporaryDisabilityTo'),
  temporaryDisabilityTo: pipe(
    prop('temporaryDisabilityTo'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  description: propOr('', 'description'),
  isInvalid: propSatisfies(isNotNilOrEmpty, 'disabilityFrom'),
  disabilityFrom: pipe(
    prop('disabilityFrom'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  hasDisabilityUntil: propSatisfies(isNotNilOrEmpty, 'disabilityTo'),
  disabilityTo: pipe(
    prop('disabilityTo'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  levelOfDisability: propOr('FULL', 'levelOfDisability'),
  inRetirement: propSatisfies(isNotNilOrEmpty, 'retirementPensionFrom'),
  retirementPensionFrom: pipe(
    prop('retirementPensionFrom'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  investigatedByPolice: propSatisfies(isNotNilOrEmpty, 'policeInvestigation'),
  policeInvestigation: propOr('', 'policeInvestigation'),
  reportedIncident: propOr('DISEASE', 'reportedIncident'),
  undergoneSpecialistExamination: propOr(
    false,
    'undergoneSpecialistExamination'
  ),
  undergoneImagingExamination: propOr(false, 'undergoneImagingExamination'),
  undergoneSurgeryOrHospitalised: propOr(
    false,
    'undergoneSurgeryOrHospitalised'
  ),
  accidentAtWork: propOr(false, 'accidentAtWork'),
});

export const toApiTempDisability = applySpec({
  temporaryDisabilityFrom: pipe(
    prop('temporaryDisabilityFrom'),
    format('yyyy-MM-dd')
  ),
  temporaryDisabilityTo: ifElse(
    propEq('hasEnded', true),
    pipe(prop('temporaryDisabilityTo'), format('yyyy-MM-dd')),
    always('')
  ),
  description: prop('description'),
  retirementPensionFrom: ifElse(
    propEq('inRetirement', true),
    pipe(prop('retirementPensionFrom'), format('yyyy-MM-dd')),
    always('')
  ),
  policeInvestigation: ifElse(
    propEq('investigatedByPolice', true),
    prop('policeInvestigation'),
    always('')
  ),
  reportedIncident: prop('reportedIncident'),
  disabilityFrom: ifElse(
    propEq('isInvalid', true),
    pipe(prop('disabilityFrom'), format('yyyy-MM-dd')),
    always(undefined)
  ),
  levelOfDisability: ifElse(
    propEq('isInvalid', true),
    prop('levelOfDisability'),
    always(undefined)
  ),
  disabilityTo: ifElse(
    both(propEq('isInvalid', true), propEq('hasDisabilityUntil', true)),
    pipe(prop('disabilityTo'), format('yyyy-MM-dd')),
    always(undefined)
  ),
  undergoneSpecialistExamination: prop('undergoneSpecialistExamination'),
  undergoneImagingExamination: prop('undergoneImagingExamination'),
  undergoneSurgeryOrHospitalised: prop('undergoneSurgeryOrHospitalised'),
  accidentAtWork: prop('accidentAtWork'),
});
