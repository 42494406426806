import { ofType } from 'redux-observable';
import { catchError, switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  applySpec,
  identity,
  last,
  path,
  pipe,
  split,
  trim,
  unary,
} from 'ramda';

import {
  CREATE_JOURNEY,
  createJourneyError,
  createJourneySuccess,
} from '../actions';
import { navigate } from '../../../navigation/actions';

const remapResponse = unary(
  pipe(
    path(['responseHeaders', 'location']),
    split('/'),
    last,
    trim,
    applySpec({ journeyId: identity }),
    unary(createJourneySuccess)
  )
);

const createJourneyEpic$ = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(CREATE_JOURNEY),
    switchMap(({ payload }) =>
      ajax({
        useAuth: true,
        method: 'POST',
        url: '/gateway-server/journeys',
        body: { discardOld: true },
      }).pipe(
        mergeMap((response) => {
          const sAction = remapResponse(response);

          if (payload && payload.redirectTo) {
            return of(sAction, navigate({ to: payload.redirectTo }));
          }

          return of(sAction, navigate({ to: '/journeys/personal' }));
        }),
        catchError((e) => of(createJourneyError(e)))
      )
    )
  );

export default createJourneyEpic$;
