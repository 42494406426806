import { ofType } from 'redux-observable';
import { withLatestFrom, map as rxMap } from 'rxjs/operators';

import { phoneNumberFormSelector } from '../../selectors';
import { UPDATE_FORM, changePhone, verifyPhone } from '../actions';

const routeAction = ([{ payload }, state]) => {
  const { meta } = phoneNumberFormSelector(state);
  if (meta.step === 'otp') {
    return changePhone(payload);
  }

  return verifyPhone(payload);
};

export default (action$, state$) =>
  action$.pipe(ofType(UPDATE_FORM), withLatestFrom(state$), rxMap(routeAction));
