import { handleActions } from 'redux-actions';

import { FETCH_ACCOUNT, FETCH_ACCOUNT_SUCCESS } from './actions';
import { CLEAR_TOKENS } from '../storage/actions';

const initialState = {
  isLoading: false,
  isRegistered: null,
  phoneNumber: null,
  firstName: null,
  lastName: null,
  birthNumber: null,
  birthDate: null,
  nationality: null,
  email: null,
  foreigner: false,
  npsConfirmed: null,
};

export default handleActions(
  {
    [FETCH_ACCOUNT]: (state) => ({ ...state, isLoading: true }),
    [FETCH_ACCOUNT_SUCCESS]: (state, { payload }) => ({
      ...payload,
      isLoading: false,
    }),
    [CLEAR_TOKENS]: () => ({ ...initialState }),
  },
  initialState
);
