import { applySpec, curryN, map, pipe, prop, toPairs } from 'ramda';

import {
  levelsOfDisability,
  NurseRole,
  PatientRole,
  ReportReason,
  ReportReasonCare,
} from './constant.localization';

const createOptions = curryN(2, (options, intl) =>
  pipe(
    toPairs,
    map(
      applySpec({
        value: prop(0),
        label: ([, msg]) => intl.formatMessage(msg),
      })
    )
  )(options)
);

export const createLevelsOfDisabilityOptions =
  createOptions(levelsOfDisability);
export const createNurseRoleOptions = createOptions(NurseRole);
export const createPatientRoleOptions = createOptions(PatientRole);
export const createReportReasonOptions = createOptions(ReportReason);
export const createReportReasonCareOptions = createOptions(ReportReasonCare);
