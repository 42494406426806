import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';

import { HidePasswordIcon, ShowPasswordIcon } from '../../icons';
import Error from '../Error';

const propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'number']),
  required: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
};
const defaultProps = {
  type: 'text',
  placeholder: '',
  required: false,
  error: null,
  value: undefined,
  onChange: null,
  onBlur: null,
  onFocus: null,
  helperText: null,
};

const TextInput = forwardRef(
  (
    {
      label,
      placeholder,
      required,
      type,
      error,
      name,
      value,
      onChange,
      onBlur,
      onFocus,
      helperText,
      ...rest
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);

    useEffect(() => {
      if (isNotNilOrEmpty(value)) setHasValue(true);
    }, [value]);

    return (
      <div className="flex flex-col">
        <div
          className={cx(
            'shadow-faq rounded-md relative transition-colors ease-in-out duration-150',
            {
              'opacity-50 cursor-not-allowed': rest.disabled,
              'bg-white': !hasValue || isFocused,
              'bg-cardif-gray-light': hasValue && !isFocused,
            }
          )}
        >
          <label
            className={cx(
              'absolute pointer-events-none transition-all ease-out duration-200',
              {
                'text-gray-900': !error && !isFocused && !hasValue,
                'text-red-600': !!error && !isFocused,
                'text-xs left-3 top-1.5': isFocused || hasValue,
                'text-cardif-green-light':
                  isFocused || (hasValue && !error && !rest.disabled),
                'left-4 top-[30%]': !isFocused && !hasValue,
              }
            )}
          >
            {label}
            {required ? '' : ''}
          </label>
          <input
            ref={ref}
            {...rest}
            value={value}
            name={name}
            onFocus={(e) => {
              setIsFocused(true);
              if (onFocus) onFocus(e);
            }}
            onBlur={(e) => {
              if (onBlur) onBlur(e);
              setIsFocused(false);
              if (isNilOrEmpty(e.target.value)) setHasValue(false);
            }}
            onInput={(e) => {
              if (onChange) onChange(e);
              if (isNotNilOrEmpty(e.target.value)) setHasValue(true);
              if (isNilOrEmpty(e.target.value)) setHasValue(false);
            }}
            className={cx(
              'px-3 py-2 pt-8 w-full outline-none bg-transparent focus:ring-0 rounded-sm border-l-0 border-t-0 border-r-0 border-b-4',
              {
                'placeholder:invisible': !isFocused,
                'border-red-600': !!error && !isFocused,
                'focus:border-cardif-green-light':
                  !error || (error && isFocused),
                'border-cardif-green-light': hasValue && !error,
                'pr-12': type === 'password',
              }
            )}
            placeholder={placeholder}
            required={required}
            type={type === 'password' && passwordShown ? 'text' : type}
          />
          {type === 'password' && (
            <div
              className="absolute right-0 bottom-0 top-0 flex items-center pr-4 text-gray-400 cursor-pointer"
              onClick={() => setPasswordShown(!passwordShown)}
            >
              {passwordShown ? <HidePasswordIcon /> : <ShowPasswordIcon />}
            </div>
          )}
        </div>
        <Error error={error} />
        {!!helperText && (
          <span className="text-xs pt-2 text-gray-600 px-4">{helperText}</span>
        )}
      </div>
    );
  }
);

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;
TextInput.displayName = 'TextInput';

export default TextInput;
