import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import DatePicker from '../../../components/form/DatePicker';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};
const defaultProps = {
  label: '',
};

const DatePickerField = ({ name, label, ...rest }) => {
  const [field, meta, helpers] = useField({ name });

  return (
    <DatePicker
      key={(field.value && field.value.toString()) || `${name}_EMPTY`}
      {...rest}
      name={name}
      label={label}
      value={field.value || null}
      onChange={(v) => {
        helpers.setTouched(true, true);
        helpers.setValue(v, true);
      }}
      error={meta.touched && meta.error ? meta.error : null}
    />
  );
};

DatePickerField.propTypes = propTypes;
DatePickerField.defaultProps = defaultProps;

export default DatePickerField;
