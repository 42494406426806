import { combineEpics } from 'redux-observable';
import { pipe, unary } from 'ramda';
import { distinctUntilChanged, map as rxMap } from 'rxjs/operators';
import { isNilOrEmpty } from 'ramda-adjunct';

import { accountSelector } from '../../auth/account/selectors';
import { setNPSApplicable } from '../actions';

const extractNpsConfirmed = (d) => d.npsConfirmed;

const setNpsApplicable = (action$, state$) =>
  state$.pipe(
    rxMap(unary(pipe(accountSelector, extractNpsConfirmed))),
    distinctUntilChanged(),
    rxMap((x) => {
      if (isNilOrEmpty(x)) return setNPSApplicable({ value: true });
      return setNPSApplicable({ value: false });
    })
  );

export default combineEpics(setNpsApplicable);
