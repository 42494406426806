import { isBefore, isDate } from 'date-fns/fp';
import { F, both, converge, curryN, ifElse, pipe, prop } from 'ramda';

import { fetchValue } from './util';

export default curryN(2, (selector, kv) =>
  ifElse(
    both(pipe(prop('values'), selector, isDate), pipe(fetchValue, isDate)),
    converge(isBefore, [pipe(prop('values'), selector), fetchValue]),
    F
  )(kv)
);
