import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { switchMap, map as rxMap, catchError } from 'rxjs/operators';

import { updateNpsError, updateNpsSuccess, UPDATE_NPS } from '../actions';

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(UPDATE_NPS),
    switchMap(() =>
      ajax({
        method: 'POST',
        useAuth: true,
        url: '/gateway-server/accounts/nps-confirm',
      }).pipe(
        rxMap(() => updateNpsSuccess()),
        catchError((e) => of(updateNpsError(e)))
      )
    )
  );
