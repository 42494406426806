import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Radios from '../../../components/form/Radios';

const propTypes = {
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};
const defaultProps = {
  label: undefined,
};

const RadiosField = ({ name, label, radios, ...rest }) => {
  const [field, meta] = useField({ name });

  return (
    <Radios
      {...rest}
      name={name}
      radios={radios}
      label={label}
      error={meta.touched && meta.error ? meta.error : null}
      onChange={field.onChange}
      value={field.value}
    />
  );
};

RadiosField.propTypes = propTypes;
RadiosField.defaultProps = defaultProps;

export default RadiosField;
