import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { isNotNull } from 'ramda-adjunct';
import { useDispatch } from 'react-redux';

import TextField from '../../fields/textField';
import Span from '../../../../components/Grid/Span';
import Paragraph from '../../../../components/text/Paragraph/paragraph';
import Link from '../../../../components/Link/link';
import { setPasswordResetStep } from '../../../auth/login/actions';
import messages from '../messages';

export const propTypes = {
  currentStep: PropTypes.oneOf([
    'phone',
    'password',
    'otp',
    'createPassword',
    'confirm',
    'phoneReset',
    'otpReset',
    'resetConfirm',
    'notFound',
  ]).isRequired,
  state: PropTypes.shape({
    remainingAttempts: PropTypes.number,
    lockedUntil: PropTypes.string,
    secondsToNextAttempt: PropTypes.number,
  }).isRequired,
};

const PasswordFragment = ({ currentStep, state }) => {
  const { formatMessage } = useIntl();
  const intl = useIntl();
  const dispatch = useDispatch();
  const formik = useFormikContext();

  useEffect(() => {
    if (state.lockedUntil) {
      formik.setFieldError(
        'password',
        intl.formatMessage(messages.accountLockedError, {
          minutesToNextAttempt: Math.ceil(state.secondsToNextAttempt / 60),
        })
      );
    } else if (isNotNull(state.remainingAttempts)) {
      formik.setFieldError(
        'password',
        intl.formatMessage(messages.passwordNotValidError, {
          remainingAttempts: state.remainingAttempts,
        })
      );
    }
  }, [state]);

  if (currentStep !== 'password') return null;

  return (
    <Span xs={11}>
      <TextField
        required
        autoFocus
        name="password"
        label={formatMessage(messages.passwordLabel)}
        type="password"
      />
      <Paragraph variant="caption">
        <FormattedMessage {...messages.passwordCreated} />{' '}
        <Link
          component="a"
          label={formatMessage(messages.passwordResetLink)}
          to="#"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setPasswordResetStep());
          }}
          size="xs"
        />
      </Paragraph>
    </Span>
  );
};

PasswordFragment.propTypes = propTypes;

export default PasswordFragment;
