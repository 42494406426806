import { createAction } from 'redux-actions';

const namespace = 'crdf.callMeBack';

export const SUBMIT_CALL_ME_BACK = `${namespace}.submit`;
export const SUBMIT_CALL_ME_BACK_SUCCESS = `${namespace}.submitSuccess`;
export const SUBMIT_CALL_ME_BACK_ERROR = `${namespace}.submitError`;

export const CALL_ME_BACK_RESET = `${namespace}.resetCallMeBack`;

export const submitCallMeBack = createAction(SUBMIT_CALL_ME_BACK);
export const submitSuccess = createAction(SUBMIT_CALL_ME_BACK_SUCCESS);
export const submitError = createAction(SUBMIT_CALL_ME_BACK_ERROR);

export const resetCallMeBack = createAction(CALL_ME_BACK_RESET);
