import { combineEpics } from 'redux-observable';

import activeJourneyEpics from './ActiveJourneyProvider/epics';
import activeJourneyModalEpics from './ActiveJourneyModal/epics';
import insuranceContractsEpics from './insuranceContracts/epics';

export default combineEpics(
  activeJourneyEpics,
  activeJourneyModalEpics,
  insuranceContractsEpics
);
