import { both, identity, not, pipe, prop, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { timer } from 'rxjs';
import {
  distinctUntilChanged,
  filter as rxFilter,
  map as rxMap,
  repeat,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

import { authenticationStatusSelector } from '../../storage/selectors';
import {
  PROLONG_SESSION,
  TRACK_ACTIVITY,
  triggerInactivityTimer,
  TRIGGER_INACTIVITY_TIMER,
} from '../actions';

// 14 mins before 1 min countdown popup
const timerInterval = 14 * 60 * 1000;

const createAuth$ = (state$) =>
  state$.pipe(
    rxMap(
      pipe(
        unary(authenticationStatusSelector),
        both(prop('isInitialized'), prop('isAuthenticated'))
      )
    ),
    distinctUntilChanged()
  );

export default (action$, state$) =>
  createAuth$(state$).pipe(
    rxFilter(unary(identity)),
    switchMap(() =>
      timer(timerInterval).pipe(
        takeUntil(action$.pipe(ofType(TRACK_ACTIVITY))),
        repeat(),
        takeUntil(action$.pipe(ofType(TRIGGER_INACTIVITY_TIMER))),
        repeat({ delay: () => action$.pipe(ofType(PROLONG_SESSION)) }),
        rxMap(() => triggerInactivityTimer()),
        takeUntil(createAuth$(state$).pipe(rxFilter(unary(not))))
      )
    )
  );
