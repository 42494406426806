import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { rodnecislo } from 'rodnecislo';

export const propTypes = {};
const defaultProps = {};

const DoBAutocomplete = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (
      !values.foreigner &&
      values.birthNumber.number &&
      values.birthNumber.suffix
    ) {
      const rc = rodnecislo(
        `${values.birthNumber.number}/${values.birthNumber.suffix}`
      );

      if (rc.isValid()) {
        setFieldValue('birthDate', rc.birthDate(), true);
      }
    }
  }, [values.birthNumber.number, values.birthNumber.suffix, values.foreigner]);

  return null;
};

DoBAutocomplete.propTypes = propTypes;
DoBAutocomplete.defaultProps = defaultProps;

export default DoBAutocomplete;
