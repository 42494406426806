import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

import Spacing from '../../../../components/layout/Spacing';
import Paragraph from '../../../../components/text/Paragraph/paragraph';
import Link from '../../../../components/Link/link';
import Grid from '../../../../components/Grid/grid';
import Button from '../../../../components/Button';
import messages from '../messages';

export const propTypes = {
  onReset: PropTypes.func.isRequired,
  onCallMeBack: PropTypes.func.isRequired,
  state: PropTypes.shape({
    isNotRegistered: PropTypes.bool.isRequired,
    isNotValidFormat: PropTypes.bool.isRequired,
  }).isRequired,
};

const NoPhoneFragment = ({ state, onReset, onCallMeBack }) => {
  const { formatMessage } = useIntl();

  if (!state.isNotRegistered) return null;

  return (
    <>
      <Spacing variant="item">
        <Paragraph center>
          <FormattedMessage {...messages.phoneNotFound} />
        </Paragraph>
      </Spacing>
      <Spacing variant="item">
        <Paragraph center>
          <FormattedMessage
            {...messages.retryFormWithLink}
            values={{
              link: (
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onReset();
                  }}
                  component={RouterLink}
                  label={formatMessage(messages.retryFormLinkLabel)}
                />
              ),
            }}
          />
        </Paragraph>
      </Spacing>
      <Spacing variant="item">
        <Paragraph center>
          <FormattedMessage {...messages.requestCallText} />
        </Paragraph>
      </Spacing>
      <Spacing>
        <Grid md={2} gap={4}>
          <Button
            variant="gray"
            label={formatMessage(messages.backButtonLabel)}
            onClick={onReset}
          />
          <Button
            label={formatMessage(messages.requestCallButtonLabel)}
            onClick={onCallMeBack}
          />
        </Grid>
      </Spacing>
    </>
  );
};

NoPhoneFragment.propTypes = propTypes;

export default NoPhoneFragment;
