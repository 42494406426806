import { combineEpics, ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import { switchMap, map as rxMap, tap as rxTap, take } from 'rxjs/operators';

import { updateNps, OPEN_NPS_QUESTIONNAIRE } from '../actions';
import {
  fetchNolDetail,
  FETCH_NOL_DETAIL_SUCCESS,
} from '../../nol/detail/actions';
import { createNPSQLinkURL } from '../utils';

const openQuest = (action$) =>
  action$.pipe(
    ofType(OPEN_NPS_QUESTIONNAIRE),
    switchMap(({ payload }) =>
      merge(
        of(fetchNolDetail(payload)),
        action$.pipe(
          ofType(FETCH_NOL_DETAIL_SUCCESS),
          take(1),
          rxTap(({ payload: nolData }) => {
            const npsQuestLink = createNPSQLinkURL(nolData);
            window.open(npsQuestLink, '_blank').focus();
          }),
          rxMap(() => updateNps())
        )
      )
    )
  );

export default combineEpics(openQuest);
