import { handleActions } from 'redux-actions';

import {
  SUBMIT_RISK_TYPE_FORM_SUCCESS,
  FETCH_ACTIVE_JOURNEYS_SUCCESS,
  FETCH_JOURNEY_STATE_SUCCESS,
  FETCH_RISK_TYPES_SUCCESS,
  RESET,
  CREATE_JOURNEY_SUCCESS,
  INIT_SUCCESS,
  SUBMIT_RISK_DETAIL_FORM_SUCCESS,
  SUBMIT_BANK_ACCOUNT_FORM_SUCCESS,
  REFETCH_RISK_DETAIL_SUCCESS,
  FETCH_ACTIVE_JOURNEYS,
  SUBMIT_CONTACT_DATA_FORM_SUCCESS,
  SUBMIT_PERSONAL_DATA_FORM_SUCCESS,
  REFETCH_RISK_CONTEXT,
} from '../actions';

const initialState = {
  isInitialized: false,
  activeJourneyId: null,
  personalData: null,
  contactData: null,
  coverages: {
    isLoading: true,
    coveredRisksGroups: [],
    uncoveredRisksGroups: [],
  },
  riskType: null, // H | TPD | PPD | TD | TAC | IU
  riskDetailRefetching: false,
  riskDetails: null, // payload dependent on riskType
  bankAccount: null,
  contracts: {
    isInitialized: false,
    data: [],
  },
};

export default handleActions(
  {
    [RESET]: () => ({ ...initialState }),
    [INIT_SUCCESS]: (state) => ({ ...state, isInitialized: true }),
    [CREATE_JOURNEY_SUCCESS]: () => ({ ...initialState }),
    [FETCH_ACTIVE_JOURNEYS]: () => ({ ...initialState }),
    [FETCH_ACTIVE_JOURNEYS_SUCCESS]: (state, { payload }) => ({
      ...state,
      activeJourneyId: payload.activeJourneyId,
    }),
    [FETCH_JOURNEY_STATE_SUCCESS]: (state, { payload }) => ({
      ...state,
      personalData: payload.personalData,
      contactData: payload.contactData,
      riskType: payload.riskType.riskType,
      riskDetails: payload.riskType.riskDetails,
      bankAccount: payload.bankAccount,
    }),
    [SUBMIT_PERSONAL_DATA_FORM_SUCCESS]: (state, { payload }) => ({
      ...state,
      personalData: payload,
    }),
    [SUBMIT_CONTACT_DATA_FORM_SUCCESS]: (state, { payload }) => ({
      ...state,
      contactData: payload,
    }),
    [FETCH_RISK_TYPES_SUCCESS]: (state, { payload }) => ({
      ...state,
      coverages: { ...payload, isLoading: false },
    }),
    [SUBMIT_RISK_TYPE_FORM_SUCCESS]: (state, { payload }) => ({
      ...state,
      riskType: payload.riskType,
      riskDetailRefetching: true,
    }),
    [SUBMIT_RISK_DETAIL_FORM_SUCCESS]: (state, { payload }) => ({
      ...state,
      riskDetails: payload,
    }),
    [SUBMIT_BANK_ACCOUNT_FORM_SUCCESS]: (state, { payload }) => ({
      ...state,
      bankAccount: payload,
    }),
    [REFETCH_RISK_CONTEXT]: (state, { payload }) => ({
      ...state,
      riskType: payload.riskType,
      coverages: payload.coverages,
    }),
    [REFETCH_RISK_DETAIL_SUCCESS]: (state, { payload }) => ({
      ...state,
      riskDetailRefetching: false,
      riskDetails: payload,
    }),
  },
  initialState
);
