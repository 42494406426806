import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Grid from '../../../../../../components/Grid/grid';
import Span from '../../../../../../components/Grid/Span';
import SimpleTable from '../../../../../SimpleTable/SimpleTable';
import messages from '../../messages';
import rowDefinitions from './rowDefinitions';
import { nolHasUnmetRequirementsSelector } from '../../../selectors';
import StatusRenderer from './StatusRenderer';
import computeStatus from './computeStatus';
import Divider from '../../../../../../components/Divider/Divider';

export const propTypes = {
  data: PropTypes.shape().isRequired,
  isLast: PropTypes.bool,
};
const defaultProps = {
  isLast: false,
};

const ContractCardItem = ({ data, isLast }) => {
  const hasUnmetRequirements = useSelector(nolHasUnmetRequirementsSelector);
  const status = useMemo(
    () => computeStatus(hasUnmetRequirements, data.status),
    [data, hasUnmetRequirements]
  );

  return (
    <>
      <Grid xs={2} lg={3} gap={2}>
        <Span xs={2}>
          <SimpleTable rows={rowDefinitions} messages={messages} data={data} />
        </Span>
        <StatusRenderer status={status} />
      </Grid>
      {!isLast && <Divider />}
    </>
  );
};

ContractCardItem.propTypes = propTypes;
ContractCardItem.defaultProps = defaultProps;

export default ContractCardItem;
