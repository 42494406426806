import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Spacing from '../../../components/layout/Spacing';
import Heading from '../../../components/text/Heading';
import Grid from '../../../components/Grid';
import Button from '../../../components/Button';
import FlashCard from '../../../components/cards/FlashCard/flash';
import DropZone from '../dropzone';
import messages from './messages';
import UploadItem from './uploadItem';
import { uploadSelector } from '../selectors';

const propTypes = {
  heading: PropTypes.string.isRequired,
  inputProps: PropTypes.shape().isRequired,
  rootProps: PropTypes.shape().isRequired,
  fWrappers: PropTypes.arrayOf(PropTypes.shape()),
  onDeleteFile: PropTypes.func.isRequired,
  onPreviewFile: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  docsValidation: PropTypes.shape({
    allDocsValid: PropTypes.bool,
    allDocsValidated: PropTypes.bool,
  }),
};
const defaultProps = {
  docsValidation: { allDocsValid: false, allDocsValidated: false },
};

const Upload = ({
  heading,
  fWrappers,
  rootProps,
  inputProps,
  onDeleteFile,
  onPreviewFile,
  onSave,
  onCancel,
  docsValidation,
}) => {
  const { formatMessage } = useIntl();
  const { isUploading } = useSelector(uploadSelector);

  const { allDocsValid, allDocsValidated } = docsValidation;

  return (
    <>
      <Heading variant="subtitle">{heading}</Heading>
      <DropZone
        rootProps={{ ...rootProps, className: 'mb-5' }}
        inputProps={inputProps}
        inModal
      />
      {isUploading && (
        <FlashCard
          variant="wait"
          message={formatMessage(messages.documentsUploading)}
        />
      )}
      {!allDocsValidated && fWrappers.length > 0 && (
        <FlashCard
          variant="wait"
          message={formatMessage(messages.imagesConverting)}
        />
      )}
      <Spacing>
        {fWrappers.length && fWrappers.length <= 50 ? (
          <Grid gap={2}>
            {fWrappers.map((fWrapper, idx) => (
              <UploadItem
                key={idx}
                fWrapper={fWrapper}
                onCancelUpload={() => onDeleteFile(fWrapper.id)}
                onShow={() => onPreviewFile(fWrapper)}
              />
            ))}
          </Grid>
        ) : (
          <FlashCard
            variant="error"
            message={formatMessage(messages.noUploadedDocsMessage)}
          />
        )}
      </Spacing>
      <Grid xs={2} gap={4}>
        <Button
          center
          full
          variant="gray"
          onClick={onCancel}
          label={formatMessage(messages.cancelLabel)}
        />
        <Button
          disabled={isUploading || !allDocsValid || !allDocsValidated}
          center
          full
          onClick={onSave}
          label={formatMessage(messages.uploadLabel)}
        />
      </Grid>
    </>
  );
};

Upload.propTypes = propTypes;
Upload.defaultProps = defaultProps;

export default Upload;
