import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { allPass, length, lte, pipe, test } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

import createPasswordMessages from '../../settings/ChangePasswordForm/messages';
import PasswordValidations from '../../settings/ChangePasswordForm/passwordValidations';
import FlashCard from '../../../components/cards/FlashCard/flash';
import Spacing from '../../../components/layout/Spacing';
import { resetPasswordSelector } from './selectors';
import Loader from '../../../components/Loader/loader';
import Paragraph from '../../../components/text/Paragraph';
import GridSpan from '../../../components/Grid/Span/span';
import Grid from '../../../components/Grid';
import Button from '../../../components/Button';
import TextField from '../../forms/fields/textField';
import messages from './messages';
import { deinit, init, resetPassword } from './actions';

const propTypes = {
  pairingCode: PropTypes.string,
};
const defaultProps = {
  pairingCode: '',
};

const ResetPasswordForm = ({ pairingCode }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const dispatch = useDispatch();
  const state = useSelector(resetPasswordSelector);

  useEffect(() => {
    dispatch(init({ pairingCode }));

    return () => deinit();
  }, [pairingCode]);

  if (!state.isInitialized) return <Loader message="" />;

  if (state.codeExpired)
    return (
      <Paragraph>
        <FormattedMessage {...messages.tokenExpiredError} />
      </Paragraph>
    );

  if (state.codeInvalid)
    return (
      <Paragraph>
        <FormattedMessage {...messages.tokenNotValidErrorText} />
      </Paragraph>
    );

  const runValidations = allPass([
    pipe(length, lte(8)),
    test(/\p{Lu}/u),
    test(/\p{Ll}/u),
    test(/[0-9]/),
    test(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\\/?~]/),
  ]);

  const isValid = (values) => {
    if (isNilOrEmpty(values.password)) {
      return false;
    }

    if (!runValidations(values.password)) {
      return false;
    }

    return true;
  };

  return (
    <Spacing>
      <Formik
        initialValues={{ password: '' }}
        onSubmit={(values) =>
          dispatch(resetPassword({ pairingCode, password: values.password }))
        }
      >
        {(formik) => (
          <Form noValidate>
            {state.passwordAlreadyUsed && (
              <Spacing variant="header">
                <FlashCard
                  message={intl.formatMessage(messages.passwordAlreadyUsed)}
                  variant="warning"
                />
              </Spacing>
            )}
            <GridSpan xs={12}>
              <Grid gap={8}>
                <TextField
                  required
                  autoFocus
                  name="password"
                  type="password"
                  label={formatMessage(createPasswordMessages.newPasswordLabel)}
                />
              </Grid>
              <Spacing>
                <Paragraph color="gray">
                  {formatMessage(createPasswordMessages.newPasswordSpecsText)}
                </Paragraph>
                <PasswordValidations value={formik.values.password} />
              </Spacing>
            </GridSpan>
            <Spacing>
              <Button
                center
                type="submit"
                disabled={state.isSubmitting || !isValid(formik.values)}
                label={formatMessage(messages.submitButtonLabel)}
              />
            </Spacing>
          </Form>
        )}
      </Formik>
    </Spacing>
  );
};

ResetPasswordForm.propTypes = propTypes;
ResetPasswordForm.defaultProps = defaultProps;

export default ResetPasswordForm;
