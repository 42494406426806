import { handleActions } from 'redux-actions';

import {
  GET_PHONE_STATUS,
  GET_PHONE_STATUS_ERROR,
  LOGIN_DEINIT,
  LOGIN_INIT,
} from '../actions';

const initialState = {
  isNotValidFormat: false,
  isNotRegistered: false,
  phoneNumberPrefix: null,
  phoneNumber: null,
  updateTS: null,
};

export default handleActions(
  {
    [LOGIN_INIT]: () => ({ ...initialState }),
    [LOGIN_DEINIT]: () => ({ ...initialState }),
    [GET_PHONE_STATUS]: (state, { payload }) => ({
      ...state,
      phoneNumberPrefix: payload.phoneNumberPrefix,
      phoneNumber: payload.phoneNumber,
    }),
    [GET_PHONE_STATUS_ERROR]: (state, { payload }) => ({
      ...state,
      isNotValidFormat: payload.isNotValidFormat,
      isNotRegistered: payload.isNotRegistered,
      updateTS: Date.now(),
    }),
  },
  initialState
);
