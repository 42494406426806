import { applySpec, lensIndex, over, pathOr, pipe, propEq, unary } from 'ramda';
import {
  catchError,
  exhaustMap,
  map as rxMap,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';

import {
  VERIFY_PASSWORD,
  verifyPasswordSuccess,
  verifyPasswordError,
  setOtpId,
  setServerHasError,
} from '../actions';
import { usernameSelector } from '../selectors';

const parse = unary(
  pipe(
    pathOr(null, ['response']),
    applySpec({
      otpId: pathOr(null, ['otpId']),
      remainingAttempts: pathOr(null, ['remainingAttempts']),
      lockedUntil: pathOr(null, ['lockedUntil']),
      secondsToNextAttempt: pathOr(null, ['secondsToNextAttempt']),
      passwordVerificationIsBlocked: propEq('code', '002'),
    })
  )
);

export default (actions$, state$) =>
  actions$.pipe(
    ofType(VERIFY_PASSWORD),
    exhaustMap(({ payload }) =>
      of({ payload }).pipe(
        withLatestFrom(state$),
        rxMap(unary(over(lensIndex(1), unary(usernameSelector)))),
        mergeMap(
          ([
            {
              payload: { password },
            },
            username,
          ]) =>
            ajax({
              url: '/gateway-server/accounts/login',
              method: 'POST',
              headers: {
                Authorization: `Basic ${btoa('test:test')}`,
              },
              body: { username, password, grant_type: 'password' },
            })
        ),
        rxMap(pipe(parse, unary(verifyPasswordSuccess))),
        mergeMap((a) => of(a, setOtpId(a.payload))),
        // catchError(unary(pipe(parse, unary(verifyPasswordError), unary(of))))
        catchError((e) => {
          const actions = [pipe(parse, unary(verifyPasswordError))(e)];

          if (e.status >= 500) {
            actions.push(setServerHasError());
          }

          return of(...actions);
        })
      )
    )
  );
