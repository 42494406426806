import { handleActions } from 'redux-actions';

import {
  UPLOAD_DOCUMENTS,
  UPLOAD_DOCUMENTS_ERROR,
  UPLOAD_DOCUMENTS_FILE_ERRORS,
  UPLOAD_DOCUMENTS_SUCCESS,
  UPLOAD_FEEDBACK_CLOSE,
  UPLOAD_FEEDBACK_OPEN,
} from './actions';

const initialState = {
  isUploading: false,
  uploadHasServerError: false,
  uploadFileErrors: [],
  uploadFeedback: {
    show: false,
    type: null,
  },
};

export default handleActions(
  {
    [UPLOAD_DOCUMENTS]: () => ({
      ...initialState,
      isUploading: true,
    }),
    [UPLOAD_DOCUMENTS_ERROR]: (state) => ({
      ...state,
      isUploading: false,
      uploadHasServerError: true,
    }),
    [UPLOAD_DOCUMENTS_FILE_ERRORS]: (state, { payload }) => ({
      ...state,
      isUploading: false,
      uploadFileErrors: payload,
      uploadHasServerError: false,
    }),
    [UPLOAD_DOCUMENTS_SUCCESS]: (state) => ({
      ...state,
      isUploading: false,
      uploadHasServerError: false,
    }),
    [UPLOAD_FEEDBACK_OPEN]: (state, { payload }) => ({
      ...state,
      uploadFeedback: { type: payload.type, show: true },
    }),
    [UPLOAD_FEEDBACK_CLOSE]: (state) => ({
      ...state,
      uploadFeedback: { ...initialState.uploadFeedback },
    }),
  },
  initialState
);
