import { ofType } from 'redux-observable';
import { switchMap, withLatestFrom, map as rxMap, catchError } from 'rxjs';
import { of, pipe, prop, unary } from 'ramda';

import {
  fromApiDisability,
  fromApiHospitalization,
  fromApiTakingCare,
  fromApiTempDisability,
  fromApiUnemployment,
} from '../../mappers';
import {
  SUBMIT_RISK_TYPE_FORM_SUCCESS,
  refetchRiskDetailSuccess,
} from '../../actions';
import { contextSelector } from '../../selectors';

const riskFragmentByType = {
  H: {
    fragment: 'hospitalization',
    mapper: pipe(prop('response'), unary(fromApiHospitalization)),
  },
  TPD: {
    fragment: 'total-perm-disability',
    mapper: pipe(prop('response'), unary(fromApiDisability)),
  },
  PPD: {
    fragment: 'part-perm-disability',
    mapper: pipe(prop('response'), unary(fromApiDisability)),
  },
  TD: {
    fragment: 'temp-disability',
    mapper: pipe(prop('response'), unary(fromApiTempDisability)),
  },
  TAC: {
    fragment: 'care',
    mapper: pipe(prop('response'), unary(fromApiTakingCare)),
  },
  IU: {
    fragment: 'unemployment',
    mapper: pipe(prop('response'), unary(fromApiUnemployment)),
  },
};

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(SUBMIT_RISK_TYPE_FORM_SUCCESS),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { riskType },
        },
        state,
      ]) => {
        const { activeJourneyId } = contextSelector(state);
        const { fragment, mapper } = riskFragmentByType[riskType];

        return ajax({
          useAuth: true,
          url: `/gateway-server/journeys/${activeJourneyId}/${fragment}`,
        }).pipe(
          rxMap(unary(mapper)),
          catchError(() => of(null)),
          rxMap(unary(refetchRiskDetailSuccess))
        );
      }
    )
  );
