import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_insuranceContracts';

export default defineMessages({
  noContractsFound: {
    id: `${namespace}_noContractsFound`,
    defaultMessage: 'Nebyly nalezeny žádné smlouvy',
  },
  contractsHeading: {
    id: `${namespace}_heading`,
    defaultMessage: 'Novou škodní událost nahlásíte pro tyto pojistné smlouvy',
  },
  partner: {
    id: `${namespace}_partner`,
    defaultMessage: 'Partner',
  },
  contractNumber: {
    id: `${namespace}_contractNumber`,
    defaultMessage: 'Číslo smlouvy',
  },
  risk: {
    id: `${namespace}_risk`,
    defaultMessage: 'Riziko',
  },
  continueButtonLabel: {
    id: `${namespace}_continueButtonLabel`,
    defaultMessage: 'Pokračovat',
  },
  loaderText: {
    id: `${namespace}_continueButtonLabel`,
    defaultMessage: 'Prosíme o strpení',
  },
});
