import { defineMessages } from 'react-intl';

export default defineMessages({
  message: {
    id: 'npsQuestionnaireLink_message',
    defaultMessage:
      'Dobrý den,\n\nděkujeme, že jste pro nahlášení události využil/a náš online portál.\n\nBudeme rádi, pokud nám vyplníte krátký průzkum spokojenosti, abychom mohli naše služby i nadále zlepšovat.\n\nDěkujeme. Tým zákaznické spokojenosti.\n\nBNP Paribas Cardif Pojišťovna',
  },
  button_close: {
    id: 'npsQuestionnaireLink_button_close',
    defaultMessage: 'Zavřít',
  },
  button_fillQuest: {
    id: 'npsQuestionnaireLink_button_fillQuest',
    defaultMessage: 'Vyplnit',
  },
});
