import {
  always,
  constructN,
  lensProp,
  over,
  pipe,
  propSatisfies,
  tryCatch,
  unary,
  when,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

export const storageEncode = pipe(unary(JSON.stringify), unary(btoa));
export const storageDecode = tryCatch(
  pipe(
    unary(atob),
    unary(JSON.parse),
    when(
      propSatisfies(isNotNilOrEmpty, 'tokenExpiresAt'),
      over(lensProp('tokenExpiresAt'), constructN(1, Date))
    )
  ),
  always(null)
);
