import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_riskDetails_disability';

export default defineMessages({
  sinceLabel: {
    id: `${namespace}_sinceLabel`,
    defaultMessage: 'Od',
  },
  hasUntilLabel: {
    id: `${namespace}_hasUntilLabel`,
    defaultMessage: 'Zadat datum ukončení',
  },
  untilLabel: {
    id: `${namespace}_untilLabel`,
    defaultMessage: 'Do',
  },
  reasonLabel: {
    id: `${namespace}_reasonLabel`,
    defaultMessage: 'Hlásíte událost kvůli',
  },
  disability_sinceHelperText: {
    id: `${namespace}_sinceHelperText`,
    defaultMessage: 'Datum začátku invalidity II. stupně',
  },
  disability_untilHelperText: {
    id: `${namespace}_untilHelperText`,
    defaultMessage: 'Datum ukončení invalidity',
  },
  disability_disabilitySinceHelperText: {
    id: `${namespace}_disabilitySinceHelperText`,
    defaultMessage: 'Od kterého data je čerpán invalidní důchod',
  },
  disability_descriptionHelperText: {
    id: `${namespace}_descriptionHelperText`,
    defaultMessage: 'Uveďte, prosím, adresu útvaru, který událost řešil',
  },
  examinatedHeading: {
    id: `${namespace}_examinatedHeading`,
    defaultMessage: 'Absolvoval/a jste:',
  },
  undergoneSpecialExamLabel: {
    id: `${namespace}_undergoneSpecialExamLabel`,
    defaultMessage: 'Vyšetření lékařem/specialistou',
  },
  undergoneImagingExamLabel: {
    id: `${namespace}_undergoneImagingExamLabel`,
    defaultMessage: 'Zobrazovací vyšetření (např. RTG, MRI, CT, SONO)',
  },
  undergoneSurgeryOrHospitalisedLabel: {
    id: `${namespace}_undergoneSurgeryOrHospitalisedLabel`,
    defaultMessage: 'Operaci nebo jste byl/a hospitalizován/a',
  },
  isInvalidLabel: {
    id: `${namespace}_isInvalidLabel`,
    defaultMessage: 'Byl vám v minulosti přiznán invalidní důchod?',
  },
  levelOfDisabilityLabel: {
    id: `${namespace}_levelOfDisabilityLabel`,
    defaultMessage: 'Stupeň invalidity',
  },
  investigatedByPoliceLabel: {
    id: `${namespace}_investigatedByPoliceLabel`,
    defaultMessage: 'Šetřila událost Policie ČR?',
  },
  descriptionLabel: {
    id: `${namespace}_descriptionLabel`,
    defaultMessage: 'Popis',
  },
});
