import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Grid from '../../components/Grid';
import Button from '../../components/Button';
import Container from '../../components/layout/Container';
import Heading from '../../components/text/Heading';
import Paragraph from '../../components/text/Paragraph';
import HomePageFAQ from '../../modules/faq';
import Spacing from '../../components/layout/Spacing';
import messages from './messages';
import { activeJourneySelector } from '../../modules/journeys/ActiveJourneyProvider/selectors';
import StepCardsHome from '../../modules/StepCardsHome';

const HomePage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { isJourneyCreating } = useSelector(activeJourneySelector);

  return (
    <>
      <Container>
        <Heading>
          <FormattedMessage {...messages.title} />
        </Heading>
        <Paragraph center>
          <FormattedMessage {...messages.slogan} />
        </Paragraph>
        <Spacing variant="content">
          <div className="w-full flex flex-wrap items-center justify-center">
            <Button
              disabled={isJourneyCreating}
              label={intl.formatMessage(messages.createClaim)}
              onClick={() => navigate('/journeys/new')}
              extraClass="mx-2 my-2 xs:my-0"
            />
            <Button
              variant="gray"
              disabled={isJourneyCreating}
              label={intl.formatMessage(messages.myListOfNols)}
              onClick={() => navigate('/nols')}
              extraClass="mx-2 my-2 sm:my-0"
            />
          </div>
        </Spacing>
        <Spacing>
          <Paragraph size="sm" center>
            <FormattedMessage {...messages.howItWorks} />
          </Paragraph>
          <StepCardsHome />
        </Spacing>
      </Container>
      <Container bgColor="gray">
        <Spacing>
          <Heading variant="subtitle">
            <FormattedMessage {...messages.faqTitle} />
          </Heading>
          <Grid gap={3} extraClass="py-4">
            <HomePageFAQ />
          </Grid>
        </Spacing>
      </Container>
    </>
  );
};

export default HomePage;
