/* eslint-disable no-unused-vars */
import { format } from 'date-fns';

import { PROD_HOST } from '../../constants';

// eslint-disable-next-line import/prefer-default-export
export const createNPSQLinkURL = (dataNOL) => {
  const { userPersonalData: dataUser, claims: dataContract } = dataNOL;
  const sParam = new URLSearchParams();

  sParam.append(
    'cmpid',
    window.location.host === PROD_HOST
      ? '60e77d1204648067a839'
      : 'a681da5b1c4db449d125'
  );
  sParam.append('clang', navigator.language);
  sParam.append('cntct', `+${dataUser.phoneNumber}`);
  sParam.append('cid', dataUser.birthNumber);
  sParam.append('eid', 57); // Entity (fixed value)
  sParam.append('rid', 6389); // Resource (fixed value)
  sParam.append('nolid', dataNOL.nolId);
  sParam.append(
    'nolitem_id',
    dataContract.length ? dataContract[0].nolItemId : ''
  );
  sParam.append('jmeno', dataUser.firstName);
  sParam.append('prijmeni', dataUser.lastName);
  sParam.append('email', dataUser.email);
  sParam.append(
    'datum_hlaseni',
    format(dataNOL.reportedOn, 'dd-MM-yyyy hh:mm')
  );
  sParam.append(
    'partner_name',
    dataContract.length ? dataContract[0].partnerName : ''
  );

  return `https://bnpparibascardif-instantsurvey.com/w/c/11624a9f8a5ebe11de93/ec18ed53edf699a576c5?${sParam.toString()}`;
};
