import { applySpec, pipe, prop, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map as rxMap,
  withLatestFrom,
} from 'rxjs/operators';

import { RESEND_OTP, resendOtpSuccess, resendOtpError } from '../actions';
import { usernameSelector } from '../selectors';

const parseResponse = unary(
  pipe(
    prop('response'),
    applySpec({ otpId: prop('uuid') }),
    unary(resendOtpSuccess)
  )
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(RESEND_OTP),
    withLatestFrom(state$),
    exhaustMap(([, state]) =>
      ajax({
        errorPassthrough: true,
        method: 'POST',
        url: '/gateway-server/otp/api/v2/otp',
        body: {
          phoneNumber: usernameSelector(state),
        },
      }).pipe(
        rxMap(parseResponse),
        catchError((e) => of(resendOtpError(e)))
      )
    )
  );
