/* eslint-disable prettier/prettier */
import createIcon from './createIcon';

export { default as ColoredIcon } from './coloredIcon';
export const ConversationIcon = createIcon('ConversationIcon', '');
export const SearchIcon = createIcon('SearchIcon', '', 60, 2/3);
export const DocumentsIcon = createIcon('DocumentsIcon', '', 60, 5/6);
export const MultipleCardsIcon = createIcon('MultipleCardsIcon', '', 60, 5/6);
export const CloudUploadIcon = createIcon('CloudUploadIcon', '', 48);
export const FingerprintIcon = createIcon('FingerprintIcon', '', 70);
export const ShowPasswordIcon = createIcon('ShowPasswordIcon', '', 20);
export const HidePasswordIcon = createIcon('HidePasswordIcon', '', 20);
export const MailIcon = createIcon('MailIcon', '', 40, 3/4);
export const HamburgerIcon = createIcon('HamburgerIcon', '', 40, 0.55);
export const SuccessIcon = createIcon('SuccessIcon', '', 40);
export const LoaderIcon = createIcon('LoaderIcon', '', 20);
export const MailReadIcon = createIcon('MailReadIcon', '', 40);
export const CardIcon = createIcon('CardIcon', '', 40);
export const ClockIcon = createIcon('ClockIcon', '', 20);
export const PersonIcon = createIcon('PersonIcon', '', 70);
export const LocationIcon = createIcon('LocationIcon', '', 70);
export const PhonendoscopeIcon = createIcon('PhonendoscopeIcon', '', 70);
export const BlockIcon = createIcon('BlockIcon', '', 70);
export const ShieldIcon = createIcon('ShieldIcon', '', 70);
export const WarningIcon = createIcon('WarningIcon', '', 20);
export const ArrowUpIcon = createIcon('ArrowUpIcon', '', 9);
export const CallIcon = createIcon('CallIcon', '', 70);
export const StarsIcon = createIcon('StarsIcon', '', 40);
