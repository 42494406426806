import { applySpec, constructN, pathOr, pipe, prop, when, unary } from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  exhaustMap,
  mergeMap,
  withLatestFrom,
  map as rxMap,
  catchError,
} from 'rxjs/operators';

import {
  REFRESH_TOKENS,
  refreshTokensError,
  refreshTokensSuccess,
} from '../actions';
import { refreshTokenSelector } from '../selectors';

const parse = pipe(
  prop('response'),
  applySpec({
    accessToken: pathOr(null, ['access_token']),
    refreshToken: pathOr(null, ['refresh_token']),
    tokenExpiresAt: pipe(
      pathOr(null, ['expires_in']),
      when(
        isNotNil,
        pipe((v) => v * 1000 + Date.now(), constructN(1, Date))
      )
    ),
    tokenType: pathOr(null, ['token_type']),
    registrationCompleted: pathOr(null, ['registrationCompleted']),
  })
);

export default (action$, state$) =>
  action$.pipe(
    ofType(REFRESH_TOKENS),
    exhaustMap((action) =>
      of(action).pipe(
        withLatestFrom(state$),
        mergeMap(([, state]) =>
          ajax({
            url: '/gateway-server/accounts/login',
            method: 'POST',
            headers: {
              Authorization: `Basic ${btoa('test:test')}`,
            },
            body: {
              refresh_token: refreshTokenSelector(state),
              grant_type: 'refresh_token',
            },
          })
        ),
        rxMap(unary(pipe(parse, unary(refreshTokensSuccess)))),
        catchError((e) => of(refreshTokensError(e)))
      )
    )
  );
