import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { init } from './actions';

const GlobalDataLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(init());
  }, []);

  return null;
};

export default GlobalDataLoader;
