import { ofType } from 'redux-observable';
import {
  map as rxMap,
  catchError,
  mergeAll,
  mergeMap,
  takeLast,
} from 'rxjs/operators';
import {
  always,
  applySpec,
  converge,
  identity,
  ifElse,
  pipe,
  prop,
  propEq,
  reject,
  unapply,
  unary,
} from 'ramda';
import { of } from 'rxjs';
import { isNilOrEmpty } from 'ramda-adjunct';

import {
  SUBMIT_CONTACT_DATA_FORM,
  submitContactDataFormError,
  submitContactDataFormSuccess,
} from '../actions';

const toApiPermanentAddress = pipe(
  prop('payload'),
  applySpec({
    addressType: always('permanent'),
    city: prop('city'),
    zipCode: prop('zipCode'),
    street: prop('street'),
    countryCode: prop('countryCode'),
  })
);

const toApiContactAddress = pipe(
  prop('payload'),
  ifElse(
    propEq('hasDifferentContactAddress', true),
    applySpec({
      addressType: always('contact'),
      street: prop('contactStreet'),
      city: prop('contactCity'),
      zipCode: prop('contactPostalCode'),
      countryCode: prop('contactCountry'),
    }),
    always(null)
  )
);

const toApiAddresses = pipe(
  converge(unapply(identity), [toApiPermanentAddress, toApiContactAddress]),
  reject(isNilOrEmpty)
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(SUBMIT_CONTACT_DATA_FORM),
    rxMap(unary(toApiAddresses)),
    mergeAll(),
    mergeMap((body) =>
      ajax({
        body,
        useAuth: true,
        method: 'POST',
        url: '/gateway-server/accounts/address',
      }).pipe(
        takeLast(1),
        rxMap(() => submitContactDataFormSuccess()),
        catchError((e) => of(submitContactDataFormError(e)))
      )
    )
  );
