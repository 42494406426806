import React from 'react';
import PropTypes from 'prop-types';

import PageArrow from '../../../components/PageArrow/pageArrow';
import PageArrowSpacer from '../../../components/PageArrow/pageArrowSpacer';

const propTypes = {
  page: PropTypes.number.isRequired,
  totPages: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired,
};

const Paginator = ({ totPages, page, changePage }) => (
  <div className="flex flex-row items-center justify-center space-x-2 p-3">
    {page > 1 ? (
      <PageArrow
        variant="prev"
        size="sm"
        onClick={() => changePage(page - 1)}
      />
    ) : (
      <PageArrowSpacer size="sm" />
    )}
    <span>
      Stránka {page} z {totPages}
    </span>
    {page < totPages ? (
      <PageArrow
        variant="next"
        size="sm"
        onClick={() => changePage(page + 1)}
      />
    ) : (
      <PageArrowSpacer size="sm" />
    )}
  </div>
);

Paginator.propTypes = propTypes;

export default Paginator;
