import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit']),
  block: PropTypes.bool,
  center: PropTypes.bool,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['green', 'gray']),
  extraClass: PropTypes.string,
};
const defaultProps = {
  onClick: null,
  type: 'button',
  block: false,
  center: false,
  full: false,
  variant: 'green',
  disabled: false,
  extraClass: '',
};

const Button = ({
  label,
  onClick,
  block,
  center,
  type,
  variant,
  full,
  disabled,
  extraClass,
}) => (
  <button
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={cx(
      'rounded-md min-w-[140px] transition-colors ease-in-out duration-200',
      {
        block,
        'text-white bg-cardif-green-light hover:bg-cardif-green-dark':
          variant === 'green',
        'text-cardif-gray-dark bg-cardif-gray-button hover:bg-cardif-gray-button-hover':
          variant === 'gray',
        'px-5 py-6': type === 'button',
        'px-10 py-6 text-sm': type === 'submit',
        'mx-auto block': center,
        'w-full': full,
        'opacity-50 pointer-events-none': disabled,
      },
      extraClass
    )}
  >
    {label}
  </button>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
