import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  switchMap,
  withLatestFrom,
  filter as rxFilter,
  retry,
  catchError,
} from 'rxjs/operators';
import { pipe, prop, propEq, unary } from 'ramda';

import { createAccountError, VERIFY_OTP_SUCCESS } from '../actions';
import {
  loginMetaSelector,
  otpIdSelector,
  usernameSelector,
} from '../selectors';

const isRegistration = unary(
  pipe(prop(1), unary(loginMetaSelector), propEq('flowVariant', 'register'))
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(VERIFY_OTP_SUCCESS),
    withLatestFrom(state$),
    rxFilter(isRegistration),
    switchMap(([, state]) =>
      ajax({
        method: 'POST',
        url: '/gateway-server/accounts',
        body: {
          otpId: otpIdSelector(state),
          phoneNumber: usernameSelector(state),
        },
      }).pipe(
        retry({ count: 2, delay: 500 }),
        catchError((e) => of(createAccountError(e)))
      )
    )
  );
