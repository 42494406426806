import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { isNilOrEmpty } from 'ramda-adjunct';

import Button from '../../../components/Button';
import Spacing from '../../../components/layout/Spacing';
import TextField from '../../forms/fields/textField';
import messages from './messages';
import commonMessages from '../../i18n/common.messages';
import DropCard from '../../../components/cards/DropCard/drop';
import Paragraph from '../../../components/text/Paragraph/paragraph';
import Container from '../../../components/layout/Container';
import { changeEmailFormSelector } from '../selectors';
import Grid from '../../../components/Grid/grid';
import { changeEmail } from '../actions';
import FlashCard from '../../../components/cards/FlashCard';

const EmailForm = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { isSubmitting, initialValues, submitSuccess, hasError } = useSelector(
    changeEmailFormSelector
  );
  const [showConfirm, setShowConfirm] = useState(false);

  const onSubmit = (values) => {
    if (!showConfirm) {
      setShowConfirm(true);
    } else {
      dispatch(changeEmail(values));
      setShowConfirm(false);
    }
  };

  return (
    <DropCard title={formatMessage(messages.cardTitle)}>
      {!showConfirm && (
        <Paragraph center color="gray">
          {formatMessage(messages.cardText)}
        </Paragraph>
      )}
      <Spacing>
        <Container variant="form" bgColor="gray">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formik) => (
              <Form noValidate>
                {!showConfirm && (
                  <>
                    {hasError && (
                      <FlashCard
                        variant="warning"
                        message={formatMessage(
                          commonMessages.backendInternalServerError
                        )}
                      />
                    )}
                    <Spacing variant="item" />
                    <TextField
                      required
                      name="email"
                      label={formatMessage(messages.emailLabel)}
                    />
                    <Spacing variant="item" />
                    <Button
                      center
                      type="submit"
                      disabled={
                        isSubmitting ||
                        isNilOrEmpty(formik.values.email) ||
                        formik.values.email === initialValues.email
                      }
                      label={formatMessage(messages.saveButtonLabel)}
                    />
                  </>
                )}
                {showConfirm && (
                  <>
                    <Paragraph center color="gray">
                      <FormattedMessage {...messages.confirmText} />
                    </Paragraph>
                    <Spacing variant="item" />
                    <Paragraph center color="gray">
                      <FormattedMessage
                        {...messages.confirmNewEmail}
                        values={{ email: formik.values.email }}
                      />
                    </Paragraph>
                    <Spacing>
                      <Grid md={2} gap={4}>
                        <Button
                          variant="gray"
                          label={formatMessage(messages.cancelButtonLabel)}
                          onClick={() => setShowConfirm(false)}
                        />
                        <Button
                          label={formatMessage(messages.saveButtonLabel)}
                          type="submit"
                        />
                      </Grid>
                    </Spacing>
                  </>
                )}
                {submitSuccess && (
                  <Grid gap={4}>
                    <Spacing variant="item" />
                    <FlashCard
                      variant="success"
                      message={formatMessage(messages.saveSuccessNewEmail)}
                    />
                    <Paragraph center color="gray">
                      {formatMessage(messages.checkEmailSpam)}
                    </Paragraph>
                  </Grid>
                )}
              </Form>
            )}
          </Formik>
        </Container>
      </Spacing>
    </DropCard>
  );
};

export default EmailForm;
