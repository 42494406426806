import { handleActions } from 'redux-actions';

import {
  FINALIZE_ACCOUNT,
  FINALIZE_ACCOUNT_ERROR,
  FINALIZE_ACCOUNT_SUCCESS,
  SUBMIT_CONTACT_DATA_FORM,
  SUBMIT_CONTACT_DATA_FORM_ERROR,
  SUBMIT_CONTACT_DATA_FORM_SUCCESS,
  SUBMIT_PERSONAL_DATA_FORM,
  SUBMIT_PERSONAL_DATA_FORM_ERROR,
  SUBMIT_PERSONAL_DATA_FORM_SUCCESS,
} from './actions';

const initialState = {
  personalDataFormSubmitting: false,
  personalDataFormError: false,
  contactDataFormSubmitting: false,
  contactDataFormError: false,
  accountFinalizing: false,
  personalDataErrors: [],
};

export default handleActions(
  {
    [SUBMIT_PERSONAL_DATA_FORM]: (state) => ({
      ...state,
      personalDataFormSubmitting: true,
    }),
    [SUBMIT_PERSONAL_DATA_FORM_SUCCESS]: (state) => ({
      ...state,
      personalDataFormSubmitting: false,
      personalDataFormError: false,
      personalDataErrors: [],
    }),
    [SUBMIT_PERSONAL_DATA_FORM_ERROR]: (state, { payload }) => ({
      ...state,
      personalDataFormSubmitting: false,
      personalDataFormError: true,
      personalDataErrors: payload,
    }),
    [SUBMIT_CONTACT_DATA_FORM]: (state) => ({
      ...state,
      personalDataFormSubmitting: true,
    }),
    [SUBMIT_CONTACT_DATA_FORM_SUCCESS]: (state) => ({
      ...state,
      personalDataFormSubmitting: false,
      personalDataFormError: false,
    }),
    [SUBMIT_CONTACT_DATA_FORM_ERROR]: (state) => ({
      ...state,
      personalDataFormSubmitting: false,
      personalDataFormError: true,
    }),
    [FINALIZE_ACCOUNT]: (state) => ({ ...state, accountFinalizing: true }),
    [FINALIZE_ACCOUNT_SUCCESS]: (state) => ({
      ...state,
      accountFinalizing: false,
    }),
    [FINALIZE_ACCOUNT_ERROR]: (state) => ({
      ...state,
      accountFinalizing: false,
    }),
  },
  initialState
);
