import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { contextSelector } from '../../modules/journeys/ActiveJourneyProvider/selectors';
import { openUnfinishedModal } from '../../modules/journeys/ActiveJourneyModal/actions';
import {
  continueJourney,
  createJourney,
} from '../../modules/journeys/ActiveJourneyProvider/actions';

export const propTypes = {};
const defaultProps = {};

const JourneyNewPage = () => {
  const dispatch = useDispatch();
  const { activeJourneyId } = useSelector(contextSelector);
  const { state } = useLocation();

  useEffect(() => {
    if (activeJourneyId && !state?.isRegistrationJourney) {
      dispatch(
        openUnfinishedModal({
          onDismissAction: createJourney(),
          onNextAction: continueJourney(),
        })
      );
    } else {
      dispatch(createJourney({ redirectTo: state?.redirectTo }));
    }
  }, []);

  return null;
};

JourneyNewPage.propTypes = propTypes;
JourneyNewPage.defaultProps = defaultProps;

export default JourneyNewPage;
