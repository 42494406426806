import { format } from 'date-fns/fp';
import {
  always,
  applySpec,
  constructN,
  ifElse,
  pipe,
  prop,
  propEq,
  propOr,
  propSatisfies,
  when,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

export const fromApiUnemployment = applySpec({
  involuntaryUnemploymentFrom: pipe(
    propOr('', 'involuntaryUnemploymentFrom'),
    when(isNotNilOrEmpty, constructN(1, Date))
  ),
  hasEnded: propSatisfies(isNotNilOrEmpty, 'involuntaryUnemploymentTo'),
  involuntaryUnemploymentTo: pipe(
    propOr('', 'involuntaryUnemploymentTo'),
    when(isNotNilOrEmpty, constructN(1, Date))
  ),
  unemploymentDueToMedicalReason: propOr(
    false,
    'unemploymentDueToMedicalReason'
  ),
  employer: pipe(
    propOr({}, 'employer'),
    applySpec({
      name: propOr('', 'name'),
      street: propOr('', 'street'),
      city: propOr('', 'city'),
      postalCode: propOr('', 'postalCode'),
      country: propOr('203', 'country'),
    })
  ),
  description: propOr('', 'description'),
});

export const toApiUnemployment = applySpec({
  involuntaryUnemploymentFrom: pipe(
    prop('involuntaryUnemploymentFrom'),
    format('yyyy-MM-dd')
  ),
  involuntaryUnemploymentTo: ifElse(
    propEq('hasEnded', true),
    pipe(prop('involuntaryUnemploymentTo'), format('yyyy-MM-dd')),
    always('')
  ),
  unemploymentDueToMedicalReason: prop('unemploymentDueToMedicalReason'),
  employer: prop('employer'),
  description: prop('description'),
});
