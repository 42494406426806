import React from 'react';
import { useIntl } from 'react-intl';

import JourneyPersonalDataForm from '../../modules/journeys/forms/PersonalDataForm/PersonalDataForm';
import JourneyLayout from '../../modules/journeys/JourneyLayout/JourneyLayout';
import messages from './messages';
import { PersonIcon } from '../../components/icons';

const JourneysPersonalDataPage = () => {
  const { formatMessage } = useIntl();

  return (
    <JourneyLayout
      step="personal"
      heading={formatMessage(messages.stepOne_title)}
      text={formatMessage(messages.stepOne_text)}
      icon={<PersonIcon />}
    >
      <JourneyPersonalDataForm />
    </JourneyLayout>
  );
};

export default JourneysPersonalDataPage;
