import { ofType } from 'redux-observable';
import { catchError, exhaustMap, mergeMap } from 'rxjs/operators';
import { applySpec, constructN, pathOr, pipe, prop, when } from 'ramda';
import { of } from 'rxjs';
import { isNotNil } from 'ramda-adjunct';

import {
  CREATE_PASSWORD,
  createPasswordError,
  createPasswordSuccess,
  fetchTokensSuccess,
  setServerHasError,
} from '../actions';

// create password request
// method: POST
// url: /gateway-server/accounts/password
// payload: {"phoneNumber":"<phoneNumber>","password":"<password>"}
// response contains access and refresh tokens

const remapResponse = pipe(
  prop('response'),
  applySpec({
    accessToken: pathOr(null, ['access_token']),
    refreshToken: pathOr(null, ['refresh_token']),
    tokenExpiresAt: pipe(
      pathOr(null, ['expires_in']),
      when(
        isNotNil,
        pipe((v) => v * 1000 + Date.now(), constructN(1, Date))
      )
    ),
    tokenType: pathOr(null, ['token_type']),
    registrationCompleted: pathOr(null, ['registrationCompleted']),
  })
);

export default (actions$, state$, { ajax }) =>
  actions$.pipe(
    ofType(CREATE_PASSWORD),
    exhaustMap(({ payload }) =>
      ajax({
        method: 'POST',
        url: '/gateway-server/accounts/password',
        body: {
          phoneNumber: `+${payload.phoneNumberPrefix}${payload.phoneNumber}`,
          password: payload.newPassword,
        },
      }).pipe(
        mergeMap((result) =>
          of(createPasswordSuccess(), fetchTokensSuccess(remapResponse(result)))
        ),
        catchError((e) => {
          const actions = [createPasswordError(e)];

          if (e.status >= 500) {
            actions.push(setServerHasError());
          }

          return of(...actions);
        })
      )
    )
  );
