import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_bankAccountForm';

export default defineMessages({
  bankAccountOwnerInfo: {
    id: `${namespace}_bankAccountOwnerInfo`,
    defaultMessage: 'Údaje o vlastníkovi účtu',
  },
  bankAccountNumberLabel: {
    id: `${namespace}_bankAccountNumberLabel`,
    defaultMessage: 'Číslo účtu',
  },
  bankCodeLabel: {
    id: `${namespace}_bankCodeLabel`,
    defaultMessage: 'Kód banky',
  },
  bankAccountNumberHelpInfo: {
    id: `${namespace}_bankAccountNumberHelpInfo`,
    defaultMessage:
      'Číslo bankovního účtu obsahující předčíslí uveďte prosím ve tvaru XXXXXX-XXXXXXXXXX',
  },
  notOwnerLabel: {
    id: `${namespace}_notOwnerLabel`,
    defaultMessage: 'Vlastníkem účtu je jiná osoba',
  },
  firstNameLabel: {
    id: `${namespace}_firstNameLabel`,
    defaultMessage: 'Jméno',
  },
  lastNameLabel: {
    id: `${namespace}_lastNameLabel`,
    defaultMessage: 'Příjmení',
  },
  birthDateLabel: {
    id: `${namespace}_birthDateLabel`,
    defaultMessage: 'Datum narození',
  },
  nationalityLabel: {
    id: `${namespace}_nationalityLabel`,
    defaultMessage: 'Státní příslušnost',
  },
  addressHeading: {
    id: `${namespace}_addressHeading`,
    defaultMessage: 'Trvalá adresa vlastníka účtu',
  },
  streetLabel: {
    id: `${namespace}_streetLabel`,
    defaultMessage: 'Ulice a č.p.',
  },
  cityLabel: {
    id: `${namespace}_cityLabel`,
    defaultMessage: 'Město',
  },
  postalCodeLabel: {
    id: `${namespace}_postalCodeLabel`,
    defaultMessage: 'PSČ',
  },
  countryLabel: {
    id: `${namespace}_countryLabel`,
    defaultMessage: 'Stát',
  },
  stepFive_backButtonLabel: {
    id: `${namespace}_backButtonLabel`,
    defaultMessage: 'Zpět',
  },
  stepFive_continueButtonLabel: {
    id: `${namespace}_continueButtonLabel`,
    defaultMessage: 'Pokračovat',
  },
});
