import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import HospitalizationForm from './H';
import PPDForm from './PPD';
import TPDForm from './TPD';
import TDForm from './TD';
import TACForm from './TAC';
import IUForm from './IU';
import Spacing from '../../../../components/layout/Spacing';
import Loader from '../../../../components/Loader/loader';
import { contextSelector } from '../../ActiveJourneyProvider/selectors';
import { refetchRiskContext } from '../../ActiveJourneyProvider/actions';

const RiskDetailsForm = () => {
  const { isInitialized, riskType, riskDetailRefetching } =
    useSelector(contextSelector);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!riskType) {
      if (state.riskType) dispatch(refetchRiskContext(state));
      else navigate('../risk-type');
    }
  }, []);

  if (!isInitialized || riskDetailRefetching)
    return (
      <Spacing>
        <Loader message="" />
      </Spacing>
    );

  return (
    <>
      {riskType === 'H' && <HospitalizationForm />}
      {riskType === 'PPD' && <PPDForm />}
      {riskType === 'TPD' && <TPDForm />}
      {riskType === 'TD' && <TDForm />}
      {riskType === 'IU' && <IUForm />}
      {riskType === 'TAC' && <TACForm />}
    </>
  );
};

export default RiskDetailsForm;
