import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CloudUploadIcon } from '../icons';

const propTypes = {
  title: PropTypes.string,
  info: PropTypes.node,
  inModal: PropTypes.bool,
};
const defaultProps = {
  inModal: false,
  title: null,
  info: null,
};

const UploadBox = ({ title, info, inModal }) => (
  <div
    className={cx(
      'font-cardif-light items-center flex gap-2 md:gap-4 justify-between px-9 py-5 border-dashed border-gray-200',
      {
        'flex-col border-4': inModal,
        'border-2': !inModal,
      }
    )}
  >
    <div
      className={cx({
        'flex flex-col grow order-1': !inModal,
        'order-2': inModal,
      })}
    >
      <h3 className={cx('font-normal', { hidden: inModal })}>{title}</h3>
      <div
        className={cx('font-extralight text-sm text-gray-500 pt-2 pb-2', {
          hidden: inModal,
        })}
      >
        {info}
      </div>
      <button className="self-end lg:self-start rounded-md px-4 py-1 bg-cardif-green-light text-white text-sm hover:bg-cardif-green-dark transition-colors ease-in-out duration-200">
        Nahrát
      </button>
    </div>
    <div
      className={cx('shrink-0 text-center', {
        'order-1 text-gray-300': inModal,
        'hidden lg:block order-2 text-gray-500': !inModal,
      })}
    >
      <CloudUploadIcon />
      <p className="text-cardif-gray-dark text-sm">Přetáhnout sem</p>
    </div>
    <span
      className={cx(
        'text-cardif-gray-dark text-center font-cardif-light order-3',
        {
          hidden: !inModal,
        }
      )}
    >
      jpg, jpeg, png, pdf, doc(x)
    </span>
  </div>
);

UploadBox.propTypes = propTypes;
UploadBox.defaultProps = defaultProps;

export default UploadBox;
