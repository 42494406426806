/* eslint-disable no-unused-vars */
import { EMPTY, of } from 'rxjs';
import { ofType, combineEpics } from 'redux-observable';
import {
  switchMap,
  map as rxMap,
  tap as rxTap,
  catchError,
  withLatestFrom,
  ignoreElements,
} from 'rxjs/operators';

import { authorizationHeaderSelector } from '../auth/storage/selectors';
import {
  FETCH_NOL_DOC,
  fetchNolDocSuccess,
  fetchNolDocError,
  CONVERT_NOL_DOC,
  convertNolDoc,
  convertNolDocSuccess,
  convertNolDocError,
  downloadNolDoc,
  DOWNLOAD_NOL_DOC,
} from './actions';
import { createFWrapper } from '../upload/utils';

const createBody = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

const fetchDoc = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_NOL_DOC),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { doc, action },
        },
        state,
      ]) =>
        ajax({
          useAuth: true,
          url: `/gateway-server/documents/${doc.documentId}/download`,
          responseType: 'blob',
        }).pipe(
          rxMap((res) => {
            const blob = res.response;
            const fWrapper = createFWrapper(
              new File([blob], doc.originalName, { type: blob.type }),
              authorizationHeaderSelector(state),
              () => new Date().getTime(),
              false
            );
            if (action === 'download') return downloadNolDoc({ doc, blob });
            if (fWrapper.isImage() || fWrapper.isPdf())
              return fetchNolDocSuccess(fWrapper);
            return convertNolDoc(fWrapper);
          }),
          catchError((e) => of(fetchNolDocError(e.status)))
        )
    )
  );

const downloadDoc = (action$, state$) =>
  action$.pipe(
    ofType(DOWNLOAD_NOL_DOC),
    switchMap(({ payload: { doc, blob } }) => {
      const href = document.getElementById(`download_${doc.documentId}`);
      const hrefURL = URL.createObjectURL(blob);
      href.setAttribute('download', doc.originalName);
      href.setAttribute('target', '_blank');
      href.href = hrefURL;
      href.click();
      URL.revokeObjectURL(hrefURL);
      return EMPTY;
    })
  );

const convertDoc = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(CONVERT_NOL_DOC),
    switchMap(({ payload: fWrapper }) =>
      ajax({
        useAuth: true,
        method: 'POST',
        body: createBody(fWrapper.file),
        url: '/gateway-server/documents/convert',
        responseType: 'blob',
      }).pipe(
        rxMap((res) =>
          convertNolDocSuccess({ ...fWrapper, fileView: res.response })
        ),
        catchError((e) => of(convertNolDocError(e.status)))
      )
    )
  );

export default combineEpics(fetchDoc, downloadDoc, convertDoc);
