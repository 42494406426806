import {
  assoc,
  complement,
  constructN,
  filter,
  invoker,
  isNil,
  lensProp,
  map,
  over,
  partition,
  path,
  pathEq,
  pathOr,
  pathSatisfies,
  pipe,
  prop,
  propSatisfies,
  reject,
  reverse,
  sortBy,
} from 'ramda';
import { createSelector } from 'reselect';
import { concatAll, isNilOrEmpty, lengthGt } from 'ramda-adjunct';

import { nolDetailSelector } from '../selectors';

const isNotNil = complement(isNil);

export const nolDetailNolSelector = pipe(nolDetailSelector, prop('nol'));
export const nolDetailIsLoadingSelector = pipe(
  nolDetailNolSelector,
  prop('isLoading')
);
export const nolDetailIsInitializedSelector = pipe(
  nolDetailNolSelector,
  prop('isInitialized')
);
export const nolDetailClientDetailSelector = pipe(
  nolDetailNolSelector,
  prop('userPersonalData')
);
export const nolDetailClienAddressFormattedSelector = pipe(
  nolDetailNolSelector,
  path(['userPersonalData', 'permanentAddress']),
  ({ streetAddress1, city, postalCode, country }) =>
    `${streetAddress1}, ${city}, ${postalCode}, ${country}`
);
export const nolDetailHistorySelector = pipe(
  nolDetailNolSelector,
  pathOr([], ['history'])
);

export const sortedNolDetailHistorySelector = createSelector(
  nolDetailHistorySelector,
  sortBy(
    pipe(
      prop('dateOfOccurrence'),
      constructN(1, Date),
      invoker(0, 'toISOString')
    )
  )
);

export const nolRequirementsSelector = pipe(
  nolDetailNolSelector,
  pathOr([], ['requirements'])
);

export const nolUnmetRequirementsSelector = createSelector(
  nolRequirementsSelector,
  pipe(
    filter(pathSatisfies(isNilOrEmpty, ['documents'])),
    partition(pathEq(['documentType', 'code'], 171)), // Libovolny dokument
    reverse,
    concatAll
  )
);

export const nolMetRequirementsSelector = createSelector(
  nolRequirementsSelector,
  pipe(
    reject(pathSatisfies(isNilOrEmpty, ['documents'])),
    partition(pathEq(['documentType', 'code'], 171)), // Libovolny dokument
    reverse,
    concatAll
  )
);

// Below eliminates the Req's with Null Documents (received by another channel)
export const nolMetRequirementsSelectorDocNotNull = createSelector(
  nolMetRequirementsSelector,
  pipe(
    map(
      over(lensProp('documents'), filter(propSatisfies(isNotNil, 'documentId')))
    ),
    reject(pathSatisfies(isNilOrEmpty, ['documents']))
  )
);

export const nolHasUnmetRequirementsSelector = createSelector(
  nolUnmetRequirementsSelector,
  lengthGt(0)
);

export const nolContractsSelector = pipe(nolDetailNolSelector, prop('claims'));

const addRisk = (contracts, riskType) =>
  map(assoc('riskType', riskType))(contracts);

export const nolContractsSelectorWRType = createSelector(
  nolContractsSelector,
  pipe(nolDetailNolSelector, prop('riskType')),
  addRisk
);
