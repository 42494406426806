// Flow:
// verifyOtp:
// method: PUT
// url: /gateway-server/otp/api/v2/otp/<otpId>
// payload: {"code":"1234","otpId":"<id>"}
//
// create account immediatelly after sucessful otp
// method: POST
// url: /gateway-server/accounts
// payload: {"otpId":"<otpId>","phoneNumber":"<phoneNumber>"}
//
// show createPasswordForm
// create password request
// method: POST
// url: /gateway-server/accounts/password
// payload: {"phoneNumber":"<phoneNumber>","password":"<password>"}
// response contains access and refresh tokens

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import { allPass, length, lte, pipe, test } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

import TextField from '../../fields/textField';
import Spacing from '../../../../components/layout/Spacing';
import Paragraph from '../../../../components/text/Paragraph/paragraph';
import PasswordValidations from '../../../settings/ChangePasswordForm/passwordValidations';
import Grid from '../../../../components/Grid/grid';
import createPasswordMessages from '../../../settings/ChangePasswordForm/messages';
import CheckboxField from '../../fields/checkbox';
import GDPRModal from './gdprModal';
import TOCModal from './tocModal';
import messages from '../messages';
import GridSpan from '../../../../components/Grid/Span/span';
import Link from '../../../../components/Link/link';
import FlashCard from '../../../../components/cards/FlashCard/flash';

export const propTypes = {
  currentStep: PropTypes.oneOf([
    'phone',
    'password',
    'otp',
    'createPassword',
    'confirm',
    'phoneReset',
    'otpReset',
    'resetConfirm',
  ]).isRequired,
  state: PropTypes.shape({
    hasError: PropTypes.bool.isRequired,
  }).isRequired,
  onSetSubmitDisabled: PropTypes.func.isRequired,
};
const defaultProps = {};

const runValidations = allPass([
  pipe(length, lte(8)),
  test(/\p{Lu}/u),
  test(/\p{Ll}/u),
  test(/[0-9]/),
  test(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\\/?~]/),
]);

const isValid = (values) => {
  if (isNilOrEmpty(values.newPassword)) {
    return false;
  }

  if (!runValidations(values.newPassword)) {
    return false;
  }

  if (values.newPassword !== values.newPasswordRepeat) {
    return false;
  }

  if (!values.agreeToc || !values.agreeGdpr) {
    return false;
  }

  return true;
};

const CreatePasswordFragment = ({ currentStep, onSetSubmitDisabled }) => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext();
  const [gdprModalOpen, setGdprModalOpen] = useState(false);
  const [tocModalOpen, setTocModalOpen] = useState(false);

  const newRepeatPasswordMatch = () =>
    formik.values.newPassword === formik.values.newPasswordRepeat;

  const newRepeatPasswordNotEmpty = () =>
    formik.values.newPassword && formik.values.newPasswordRepeat;

  useEffect(() => {
    if (currentStep === 'createPassword' && !isValid(formik.values)) {
      onSetSubmitDisabled(true);
      // TODO: Set titles
    } else {
      onSetSubmitDisabled(false);
      // TODO: Reset titles
    }
  }, [formik.values, currentStep]);

  if (currentStep !== 'createPassword') return null;

  return (
    <GridSpan xs={12}>
      <Grid gap={8}>
        <TextField
          required
          autoFocus
          name="newPassword"
          type="password"
          label={formatMessage(createPasswordMessages.newPasswordLabel)}
        />
        <TextField
          required
          name="newPasswordRepeat"
          type="password"
          label={formatMessage(createPasswordMessages.confirmNewPasswordLabel)}
        />
        {newRepeatPasswordNotEmpty() && !newRepeatPasswordMatch() && (
          <FlashCard
            variant="warning"
            message={formatMessage(messages.newPasswordRepeatNotMatch)}
          />
        )}
      </Grid>
      <Spacing>
        <Paragraph color="gray">
          {formatMessage(createPasswordMessages.newPasswordSpecsText)}
        </Paragraph>
        <PasswordValidations value={formik.values.newPassword} />
      </Spacing>
      <Grid gap={6}>
        <CheckboxField
          name="agreeGdpr"
          label={
            <FormattedMessage
              {...messages.gdprAgreeLabel}
              values={{
                modalButton: (
                  <Link
                    inline
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      setGdprModalOpen(true);
                    }}
                    to="#"
                    label={formatMessage(messages.gdprModalOpenButton)}
                    component="a"
                  />
                ),
              }}
            />
          }
        />
        <CheckboxField
          name="agreeToc"
          label={
            <FormattedMessage
              {...messages.tocAgreeLabel}
              values={{
                modalButton: (
                  <Link
                    inline
                    size="sm"
                    onClick={(e) => {
                      e.preventDefault();
                      setTocModalOpen(true);
                    }}
                    to="#"
                    label={formatMessage(messages.tocModalOpenButton)}
                    component="a"
                  />
                ),
              }}
            />
          }
        />
      </Grid>
      <TOCModal
        isOpen={tocModalOpen}
        onClose={() => setTocModalOpen(false)}
        onConfirm={() => {
          formik.setFieldValue('agreeToc', true, true);
          setTocModalOpen(false);
        }}
      />
      <GDPRModal
        isOpen={gdprModalOpen}
        onClose={() => setGdprModalOpen(false)}
        onConfirm={() => {
          formik.setFieldValue('agreeGdpr', true, true);
          setGdprModalOpen(false);
        }}
      />
    </GridSpan>
  );
};

CreatePasswordFragment.propTypes = propTypes;
CreatePasswordFragment.defaultProps = defaultProps;

export default CreatePasswordFragment;
