import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'eventDetail_title',
    defaultMessage: 'Seznam událostí',
  },
  button: {
    id: 'eventDetail_button',
    defaultMessage: 'Nahlásit novou škodní událost',
  },
  listTitle_inProgress: {
    id: 'eventDetail_listTitle_inProgress',
    defaultMessage: 'V řešení',
  },
  listTitle_completed: {
    id: 'eventDetail_listTitle_completed',
    defaultMessage: 'Vyřešeno',
  },
  eventCard_title_one: {
    id: 'eventDetail_eventCard_title_one',
    defaultMessage: 'Zaměstnání',
  },
  eventCard_title_two: {
    id: 'eventDetail_eventCard_title_two',
    defaultMessage: 'Pracovní neschopnost',
  },
  npsLink: {
    id: 'npsLink',
    defaultMessage:
      'Byli jste spokojeni s nahlášením škodní události přes online portál?\nOdpovězte, prosím, na krátký dotazník.',
  },
});
