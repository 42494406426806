import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['default', 'header', 'small', 'tiny']),
  size: PropTypes.oneOf(['base', 'sm', 'xs']),
  center: PropTypes.bool,
  inline: PropTypes.bool,
  onClick: PropTypes.func,
  component: PropTypes.any,
};

const defaultProps = {
  variant: 'default',
  size: 'base',
  center: false,
  inline: false,
  onClick: null,
  component: 'a',
};

const getLinkHref = (comp, to) => {
  switch (comp) {
    case 'a':
      return { href: to };

    default:
      return { to };
  }
};

const Link = ({
  to,
  label,
  variant,
  size,
  center,
  inline,
  onClick,
  component: Component,
  ...rest
}) => {
  const linkProps = useMemo(() => getLinkHref(Component, to), [Component]);

  return (
    <Component
      className={cx(
        'transition-colors ease-in-out duration-200 whitespace-pre-line',
        {
          'text-cardif-green-light hover:text-cardif-green-dark underline hover:no-underline':
            variant === 'default',
          'text-black text-sm hover:text-cardif-green-light':
            variant === 'header',
          'block text-center': center,
          'text-sm': size === 'sm',
          'text-xs': size === 'xs',
          'cursor-pointer': !!onClick,
          inline,
        }
      )}
      onClick={onClick}
      {...linkProps}
      {...rest}
    >
      {label}
    </Component>
  );
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
