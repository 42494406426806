import { ofType } from 'redux-observable';
import { of, switchMap, withLatestFrom } from 'rxjs';
import { find, pipe, propEq } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

import { navigate } from '../../../../navigation/actions';
import {
  SUBMIT_RISK_TYPE_FORM,
  submitRiskTypeFormSuccess,
} from '../../actions';
import { coveragesSelector } from '../../selectors';
import { CALL_ME_BACK_TYPES_GENERIC } from '../../../../callMeBack/constants';
import { accountPhonePartsSelector } from '../../../../auth/account/selectors';

export const isCovered = (state, riskType) => {
  const { coveredRisksGroups } = coveragesSelector(state);

  return pipe(
    find(propEq('name', riskType)),
    isNotNilOrEmpty
  )(coveredRisksGroups);
};

export default (action$, state$) =>
  action$.pipe(
    ofType(SUBMIT_RISK_TYPE_FORM),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { riskType },
        },
        state,
      ]) => {
        if (isCovered(state, riskType)) {
          return of(submitRiskTypeFormSuccess({ riskType }));
        }
        return of(
          navigate({
            to: '/call-me-back',
            options: {
              state: {
                reason: CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_DECLARATION,
                meta: {
                  ...accountPhonePartsSelector(state),
                  risk: riskType,
                },
              },
            },
          })
        );
      }
    )
  );
