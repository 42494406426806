import React from 'react';

const Info = () => (
  <div className="mx-auto px-2.5 max-w-3xl lg:max-w-5xl xl:max-w-6xl w-full text-cardif-gray-dark">
    <div className="-mx-2.5 pt-10 px-5">
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            Informace o zpracování osobních údajů
          </h2>
          <p className="mb-5">
            Tato informace nahrazuje dosavadní ustanovení všeobecných pojistných
            podmínek nebo rámcových pojistných smluv, která obsahují informace
            pro klienty týkající se zpracování jejich osobních údajů.
          </p>
          <p className="mb-5">
            Ochrana osobních údajů je pro skupinu <strong>BNP Paribas</strong>{' '}
            důležitým tématem, a proto přijala obecné zásady zohledňující
            ochranu soukromí v celé skupině. Tato Informace o zpracování
            osobních údajů poskytuje detailní informace týkající se ochrany
            vašich osobních údajů, kterou zavedla BNP Paribas Cardif. Správcem
            osobních údajů je{' '}
            <strong>BNP Paribas Cardif Pojišťovna, a.s.</strong>, IČO 25080954,
            se sídlem Boudníkova 2506, Libeň, 180 00 Praha 8, Česká republika,
            vedená v obchodním rejstříku u Městského soudu v Praze pod spisovou
            značkou B 4327 (dále jen „my“).
          </p>
          <p className="mb-5">
            Za zpracování vašich osobních údajů v souvislosti s naší činností
            neseme odpovědnost my jako správce osobních údajů. Účelem tohoto
            dokumentu je informovat vás o tom, jaké osobní údaje zpracováváme,
            proč je zpracováváme, jak dlouho je uchováváme, jaká jsou vaše práva
            a jak je můžete uplatňovat.
          </p>
          <p className="mb-5">
            Další informace vám mohou být případně poskytnuty v souvislosti s
            konkrétním pojistným produktem.
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            1. Jaké osobní údaje zpracováváme
          </h2>
          <p className="mb-5">
            Vaše osobní údaje shromažďujeme a zpracováváme pouze v rozsahu
            nezbytném pro naši činnost a k zajištění vysokého standardu našich
            služeb.
          </p>
          <p className="mb-5">
            S ohledem na typ pojistného produktu, který vám poskytujeme, můžeme
            zpracovávat různé druhy osobních údajů, a to zejména:
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              <strong>Identifikační údaje</strong> (např. jméno, příjmení,
              titul, rodné číslo, bylo-li přiděleno, jinak datum narození, místo
              a stát narození, adresa trvalého pobytu, státní příslušnost, číslo
              a platnost průkazu totožnosti, pohlaví, status politicky
              exponované osoby, obchodní firma, místo podnikání či identifikační
              číslo podnikající fyzické osoby);
            </li>
            <li>
              <strong>Kontaktní údaje</strong> (např. kontaktní poštovní adresa,
              telefonní číslo, e-mailová adresa);
            </li>
            <li>
              <strong>Údaje o rodině</strong> (např. rodinný stav, identifikační
              údaje vašeho manžela/manželky, registrovaného partnera/partnerky,
              počet a věk dětí);
            </li>
            <li>
              <strong>Bankovní, finanční a obchodní údaje</strong> (např.
              informace o bankovním účtu nebo produktu, k nimž se pojištění
              vztahuje, bankovní údaje související s platbou pojistného);
            </li>
            <li>
              <strong>Údaje o vzdělání a práci</strong> (např. dosažený stupeň
              vzdělání, zaměstnání, název zaměstnavatele, plat/mzda);
            </li>
            <li>
              <strong>Údaje týkající se pojištění</strong> (např. identifikační
              číslo klienta, platební metoda, délka pojištění, výše poplatku za
              pojištění);
            </li>
            <li>
              <strong>Údaje týkající se posouzení pojistného rizika</strong>{' '}
              (např. umístění domácnosti, informace o pojištěném majetku);
            </li>
            <li>
              <strong>Údaje týkající se škodních událostí </strong>(např.
              historie škodních/pojistných událostí, včetně poskytnutých
              pojistných plnění a odborných analýz, informace o poškozených);
            </li>
            <li>
              <strong>
                Údaje nezbytné pro boj proti pojišťovacím podvodům, praní
                špinavých peněz nebo terorismu;
              </strong>
            </li>
            <li>
              <strong>Údaje o vašich návycích a preferencích:</strong>
              <ul className="list-circle pl-10 mb-5">
                <li>
                  údaje týkající se vašich návyků (např. zájmy, sportovní
                  aktivity),
                </li>
                <li>
                  údaje týkající se vašeho využívání našich pojistných produktů
                  a služeb v souvislosti s bankovními, finančními a obchodními
                  údaji,
                </li>
                <li>
                  údaje o vašich kontaktech s námi: prostřednictvím poboček
                  našich partnerů, přes webové stránky, aplikace, stránky na
                  sociálních médiích, ale i na osobních schůzkách, telefonicky,
                  prostřednictvím chatu, e-mailu, osobních rozhovorů.{' '}
                </li>
              </ul>
            </li>
            <li>
              <strong>Údaje o připojení</strong> (např. IP adresa, cookies).
            </li>
          </ul>
          <p className="mb-5">
            Ve výjimečných případech můžeme zpracovávat také např. tyto údaje:
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>Údaje z kamerových systémů;</li>
            <li>
              Údaje týkající se vaší účasti v soutěžích o ceny, loteriích a
              propagačních kampaních.
            </li>
          </ul>
          <p className="mb-5">
            S ohledem na typ pojistného produktu, který vám poskytujeme, můžeme
            zpracovávat následující{' '}
            <strong>zvláštní kategorie osobních údajů</strong> (tzv. citlivé
            údaje):
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              <strong>Údaje o zdravotním stavu</strong>, kterými se rozumí údaje
              o vašem tělesném a duševním zdraví, včetně údajů o poskytnutí
              zdravotních služeb vypovídajících o vašem zdravotním stavu.
            </li>
          </ul>
          <p className="mb-5">
            Osobní údaje týkající se rasy a etnického původu, politických
            názorů, náboženského vyznání, filozofického přesvědčení, členství v
            odborech, genetické údaje nebo údaje týkající se vašeho sexuálního
            života či orientace zpracováváme pouze v případě, že to vyžaduje
            zákon nebo je to nezbytné pro námi poskytované produkty a služby.
          </p>
          <p className="mb-5">
            Osobní údaje získáváme buď přímo od vás, nebo je za účelem ověření
            vámi sdělených informací můžeme získat z těchto zdrojů:
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              databáze vedené příslušnými úřady (např. veřejné rejstříky);
            </li>
            <li>naši obchodní partneři nebo poskytovatelé služeb;</li>
            <li>
              třetí osoby, jako jsou např. úvěrové referenční agentury, agentury
              na prevenci podvodů nebo zprostředkovatelé údajů v souladu s
              právními předpisy o ochraně osobních údajů;
            </li>
            <li>
              webové stránky/sociální sítě obsahující vámi zveřejněné informace
              (např. vaše vlastní webové stránky nebo sociální média);
            </li>
            <li>veřejně dostupné databáze spravované třetími osobami.</li>
          </ul>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            2. Specifické případy shromažďování osobních údajů, včetně
            zprostředkovaného shromažďování
          </h2>
          <p className="mb-5">
            Z určitých důvodů můžeme také shromažďovat vaše osobní údaje, aniž
            byste s námi měli přímý vztah. To se může stát např. tehdy, když nám
            váš zaměstnavatel nebo náš obchodní partner poskytne informace o vás
            nebo když nám některý z našich klientů poskytne vaše kontaktní údaje
            v případě, že jste například
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              rodinný příslušník (na něhož se pojištění nebo škodní událost
              vztahuje);
            </li>
            <li>spoludlužník/ručitel;</li>
            <li>právní zástupce (s plnou mocí);</li>
            <li>obmyšlená osoba;</li>
            <li>konečný skutečný majitel;</li>
            <li>akcionář nebo společník obchodní společnosti;</li>
            <li>
              představitel právnické osoby, která je naším obchodním partnerem
              nebo dodavatelem;
            </li>
            <li>zaměstnanec poskytovatele služeb nebo obchodního partnera.</li>
          </ul>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            3. Proč osobní údaje zpracováváme a co nás k tomu opravňuje
          </h2>
          <p className="mb-5">
            V rámci pojišťovací činnosti (tzn. zejména zpracování nabídky
            pojištění, posouzení přijatelnosti do pojištění, přijetí do
            pojištění, správy a ukončení pojištění, šetření škodní události a
            zajištění) zpracováváme vaše osobní údaje, a to z následujících
            důvodů a pro následující účely.
          </p>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Na základě vašeho souhlasu uděleného dle občanského zákoníku a pro
            určení, výkon nebo obhajobu právních nároků
          </h3>
          <p className="mb-5">
            Na základě vašeho souhlasu zpracováváme údaje o vašem zdravotním
            stavu, a to pouze u těch pojistných produktů, které kryjí pojistné
            nebezpečí související s vaším zdravotním stavem. Nejedná se však o
            souhlas ve smyslu obecného nařízení o ochraně osobních údajů, nýbrž
            o souhlas dle občanského zákoníku, což znamená, že je po sjednání
            pojištění neodvolatelný. Po sjednání pojištění totiž zpracováváme
            údaje o vašem zdravotním stavu pro účely určení, výkonu nebo
            obhajoby právních nároků vyplývajících ze sjednaného pojištění.
          </p>
          <p className="mb-5">Vaše údaje tedy potřebujeme pro:</p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              <strong>Posouzení pojistného rizika</strong>, tj. posouzení
              možnosti posouzení přijatelnosti do pojištění a případné sjednání
              pojištění;
            </li>
            <li>
              <strong>Správu a ukončení pojištění</strong>, tj. abychom i po
              sjednání pojištění mohli aktualizovat vaše údaje o zdravotním
              stavu, případně vás z důvodu zvýšení pojistného rizika nebo z
              důvodu uvedení nepravdivých informací ze seznamu pojištěných
              vypustit;
            </li>
            <li>
              <strong>Šetření škodní události</strong>, tj. abychom mohli při
              šetření škodní události posoudit informace a zdravotní
              dokumentaci, kterou nám dodáte; zpracování údajů o zdravotním
              stavu je nezbytné pro uplatnění vašeho nároku na pojistné plnění;
            </li>
            <li>
              <strong>Zajištění</strong>, tj. abychom mohli údaje o vašem
              zdravotním stavu předat zajistiteli, tedy společnosti, se kterou
              jsme si rozdělili pojistné riziko a která v případě pojistné
              události ponese část výdajů na pojistné plnění; vaše osobní údaje
              však předáváme zajistiteli pouze v nezbytně nutných případech,
              vyžadují-li to okolnosti zajištění.
            </li>
          </ul>
          <p className="mb-5">
            Krom vámi sdělených údajů o zdravotním stavu zpracováváme též údaje
            získané na základě zpráv a zdravotnické dokumentace vyžádaných námi
            nebo osobou provozující zdravotnické zařízení, kterou jsme k tomu
            pověřili, od vašich ošetřujících lékařů, a v případě potřeby i
            prohlídkou nebo vyšetřením provedeným zdravotnickým zařízením.
          </p>
          <p className="mb-5">
            Vzhledem k tomu, že se jedná o speciální souhlas dle občanského
            zákoníku a nikoli dle obecného nařízení o ochraně osobních údajů,
            lze jej odvolat pouze do okamžiku sjednání pojištění. Odvoláním
            souhlasu není dotčena zákonnost zpracování údajů o zdravotním stavu
            do okamžiku odvolání.
          </p>
          <p className="mb-5">
            <strong>Odvolání můžete provést následujícími způsoby</strong>
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>Telefon: 234 240 234</li>
            <li>
              E-mail:{' '}
              <a
                className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
                href="mailto:souhlasy@cardif.cz"
              >
                souhlasy@cardif.cz
              </a>
            </li>
            <li>
              Poštovní adresa: Zákaznický servis, BNP Paribas Cardif Pojišťovna,
              a.s, Boudníkova 2506, Libeň, 180 00 Praha 8, Česká republika{' '}
            </li>
          </ul>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Za účelem plnění našich právních povinností (zákonných i smluvních)
          </h3>
          <p className="mb-5">
            Vaše osobní údaje zpracováváme z důvodu plnění právních povinností,
            které nám vyplývají především z
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              zákona č. 253/2008 Sb., o některých opatřeních proti legalizaci
              výnosů z trestné činnosti (tento zákon ukládá povinnost provádět
              identifikaci a kontrolu klientů);
            </li>
            <li>
              zákona č. 69/2006 Sb., o provádění mezinárodních sankcí (tento
              zákon ukládá povinnost prověřovat, že klient není subjektem
              mezinárodních sankcí);{' '}
            </li>
            <li>
              zákona č. 277/2009 Sb., o pojišťovnictví (tento zákon ukládá
              povinnost pojišťovnám vzájemně se informovat o skutečnostech
              týkajících se pojištění a osobách na pojištění se podílejících, a
              to za účelem prevence a odhalování pojistného podvodu; na jeho
              základě můžeme předávat{' '}
              <strong>
                potřebné osobní údaje, včetně údajů o zdravotním stavu
              </strong>
              , dalším pojišťovnám, a to i prostřednictvím systému provozovaného
              Českou asociací pojišťoven);
            </li>
            <li>
              rámcových pojistných smluv (tzv.{' '}
              <strong>skupinové pojištění</strong>) sjednaných s našimi
              obchodními partnery, k nimž jste jako pojištění přistoupili;
            </li>
            <li>žádostí příslušných orgánů veřejné moci nebo soudů.</li>
          </ul>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Za účelem uzavření a plnění uzavřené smlouvy nebo na základě vaší
            žádosti za účelem provedení potřebných kroků před uzavřením smlouvy
          </h3>
          <p className="mb-5">
            V případě tzv. individuálního pojištění zpracováváme vaše osobní
            údaje při uzavírání a plnění individuálních pojistných smluv,
            například abychom
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>posoudili vaše pojistné riziko;</li>
            <li>
              řádně nastavili a plnili smluvní povinnosti vyplývající z tzv.
              individuálního pojištění, včetně šetření škodních událostí;
            </li>
            <li>vás informovali o našich produktech a službách;</li>
            <li>vám pomohli a odpověděli na vaše dotazy;</li>
            <li>
              vyhodnotili, zda a za jakých podmínek vám můžeme nabídnout
              příslušný pojistný produkt nebo službu;
            </li>
            <li>
              řádně poskytovali sjednanou pojistnou ochranu a související
              služby.
            </li>
          </ul>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Za účelem ochrany našich oprávněných zájmů
          </h3>
          <p className="mb-5">
            Vaše osobní údaje zpracováváme též z důvodu ochrany našich
            oprávněných zájmů. Našimi oprávněnými zájmy jsou
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>Evidence a doklad o zaplacení úhrady za pojištění;</li>
            <li>
              Řádné nastavení a plnění smluvních vztahů vyplývajících z tzv.
              skupinového pojištění, tedy abychom zaevidovali vaše přistoupení k
              rámcové pojistné smlouvě a mohli vám plnit v případě pojistné
              události;
            </li>
            <li>
              Zamezení škodám, které nám mohou vzniknout v důsledku páchání
              pojistných podvodů;
            </li>
            <li>
              Obhajoba vlastních právních nároků v soudním, mimosoudním nebo
              vykonávacím řízení (v případě tohoto účelu zpracováváme také údaje
              o vašem zdravotním stavu);
            </li>
            <li>
              Zajištění správného vedení vnitřních evidencí a správy IT, včetně
              správy infrastruktury (např. sdílené platformy), zachování
              kontinuity činnosti a bezpečnost IT;
            </li>
            <li>
              Vytváření individuálních statistických modelů za účelem posouzení
              vašeho pojistného rizika;
            </li>
            <li>
              Rozložení rizika a ochrana naší solventnosti (v tomto případě může
              docházet i k předávání údajů o vašem zdravotním stavu zajistiteli,
              tedy společnosti, se kterou jsme si rozdělili vaše pojistné riziko
              a která v případě pojistné události ponese část výdajů na pojistné
              plnění);
            </li>
            <li>
              Vytváření souhrnných statistik, testů a modelů, pro potřeby
              výzkumu a vývoje, pro zlepšování řízení rizik v naší skupině nebo
              vylepšování již existujících produktů a služeb či vytváření
              nových;
            </li>
            <li>
              Zavádění preventivních kampaní, např. v souvislosti s přírodními
              katastrofami;
            </li>
            <li>
              Školení našeho personálu nebo personálu našich obchodních partnerů
              s pomocí záznamů telefonátů do našich call center.
            </li>
            <li>
              Přizpůsobení nabídky prostřednictvím:
              <ul className="list-circle pl-10 mb-5">
                <li>zlepšování kvality našich pojistných produktů;</li>
                <li>
                  nabízení našich pojistných produktů, které odpovídají vaší
                  situaci a profilu.
                </li>
                <li>
                  Toho lze dosáhnout
                  <ul className="list-square pl-10 mb-5">
                    <li>
                      segmentací našich potenciálních a stávajících klientů;
                    </li>
                    <li>
                      analýzou vašich návyků a preferencí podle produktů našich
                      obchodních partnerů, které využíváte; a
                    </li>
                    <li>
                      shodou s údaji z vašich smluv uzavřených s našimi
                      obchodními partnery, které jste již podepsali nebo u
                      kterých jste obdrželi nabídku (např. žádáte anebo již máte
                      úvěr, ale nikoli pojištění schopnosti jej splácet);
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="list-circle pl-10 mb-5">
                <li>
                  organizování soutěží o ceny, loterií a propagačních kampaní.
                </li>
              </ul>
            </li>
          </ul>
          <p className="mb-5">
            V tomto případě máte právo vznést{' '}
            <strong>námitku proti zpracování </strong>blíže popsanou v sekci 7.
          </p>
          <p className="mb-5">
            Vaše údaje mohou být zahrnuty do anonymizovaných statistik, které
            mohou být nabízeny subjektům skupiny <strong>BNP Paribas</strong> za
            účelem rozvoje jejich podnikání. V takovém případě nebudou vaše
            osobní údaje nikdy zpřístupněny a ti, kteří dostávají tyto anonymní
            statistiky, nebudou schopni zjistit vaši totožnost.
          </p>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Na základě vašeho souhlasu uděleného dle obecného nařízení o ochraně
            osobních údajů
          </h3>
          <p className="mb-5">
            V následujících případech můžeme vaše osobní údaje zpracovávat pouze
            s vaším souhlasem. Pokud taková situace nastane, budeme vás o tom
            informovat a vyžádáme si váš souhlas
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              pokud výše uvedené účely povedou k automatizovanému rozhodování,
              které se vás bude dotýkat a nebude existovat jiný právní důvod pro
              takové zpracování. V takovém případě vás budeme informovat o
              použitém postupu, jakož i o významu a předpokládaných důsledcích
              takového zpracování;
            </li>
            <li>
              pokud budeme potřebovat provést další zpracování za jiným účelem,
              než je uvedeno výše, budeme vás o tom informovat a případně si
              vyžádáme váš souhlas.
            </li>
          </ul>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            4. S kým vaše osobní údaje sdílime
          </h2>
          <p className="mb-5">
            K naplnění výše uvedených cílů zpracování můžeme vaše osobní údaje
            poskytnout následujícím osobám
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              našim zaměstnancům, kteří jsou odpovědní za správu vašich
              pojistných produktů;
            </li>
            <li>
              osobám majícím právo na pojistné plnění (např. v případě vinkulace
              pojistného plnění);
            </li>
            <li>
              obchodním partnerům, jejichž prostřednictvím sjednáváte naše
              pojištění;
            </li>
            <li>
              poskytovatelům služeb, kteří zajišťují výkon některých našich
              činností (tzv. outsourcing);
            </li>
            <li>
              nezávislým zástupcům, zprostředkovatelům či makléřům, pokud pro
              nás zprostředkovávají sjednání pojištění;
            </li>
            <li>
              správním orgánům, soudům nebo veřejným institucím, a to na
              vyžádání a v rozsahu stanoveném zákonem;
            </li>
            <li>
              některým regulovaným profesím, jako jsou právníci, notáři,
              auditoři nebo exekutoři v případě, kdy zpracovávají osobní údaje
              za účelem ochrany našich právních nároků;
            </li>
            <li>
              smluvním lékařům v případě, kdy zpracovávají osobní údaje o
              zdravotním stavu pro účely posouzení přijatelnosti do pojištění
              nebo šetření škodních událostí;
            </li>
            <li>
              soupojistitelům a zajistitelům v případě, že pro nás zajišťují
              pojištění a osobní údaje klientů jsou pro tuto činnost potřebné.
            </li>
          </ul>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            5. Předávání osobních údajů mimo evropský hospodářský prostor
          </h2>
          <p className="mb-5">
            V případě mezinárodního předávání osobních údajů ze zemí Evropského
            hospodářského prostoru (EHP) mimo tento prostor tam, kde Evropská
            komise uznala zemi, která není zemí EHP, za zemi poskytující
            odpovídající úroveň ochrany, budou vaše osobní údaje předány na
            tomto základě. Tento druh předávání nevyžaduje zvláštní oprávnění.
          </p>
          <p className="mb-5">
            V případě předávání osobních údajů do zemí mimo Evropský hospodářský
            prostor, jejichž úroveň ochrany nebyla Evropskou komisí uznána,
            budeme vycházet buď z odchylky vztahující se na konkrétní situaci
            (např. pokud je předání nezbytné k provedení naší smlouvy s vámi,
            např. mezinárodní platba) nebo zavedeme jedno z následujících
            ochranných opatření, které zajistí ochranu vašich osobních údajů
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>standardní smluvní doložky schválené Evropskou komisí;</li>
            <li>
              případně závazná korporátní pravidla (pro vnitropodnikové
              transfery).
            </li>
          </ul>
          <p className="mb-5">
            Chcete-li získat kopii těchto záruk nebo podrobnosti o tom, kde jsou
            k dispozici, můžete zaslat písemnou žádost na kontaktní údaje
            uvedené v sekci 9.
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            6. Jak dlouho vaše osobní údaje zpracováváme
          </h2>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Pokud jste potenciální klient
          </h3>
          <p className="mb-5">
            Vaše osobní údaje zpracováváme{' '}
            <strong>po dobu trvání vašeho souhlasu</strong>, nejdéle však po
            dobu 1 roku ode dne posledního kontaktu s vámi.
          </p>
          <p className="mb-5">
            Osobní údaje, včetně údajů o vašem zdravotním stavu, získané pro
            účely posouzení vaší přijatelnosti do pojištění zpracováváme{' '}
            <strong>po dobu trvání vašeho souhlasu</strong>, nejdéle však po
            dobu trvání procesu přijetí do pojištění a dalších maximálně 18
            měsíců.
          </p>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Pokud jste klient
          </h3>
          <p className="mb-5">
            Vaše osobní údaje, včetně údajů o vašem zdravotním stavu,
            zpracováváme po dobu
            <strong>
              {' '}
              trvání našeho smluvního vztahu a dále po dobu trvání promlčecí
              doby
            </strong>{' '}
            nároků vyplývajících z pojištění, popř. prodlouženou o dobu šetření
            škodní události anebo dořešení všech nároků, které se vyskytly před
            uplynutím promlčecí doby, avšak trvají i po jejím uplynutí.
          </p>
          <p className="mb-5">
            Osobní údaje zpracovávané na základě plnění zákonných povinností
            uchováváme{' '}
            <strong>
              po dobu, po kterou nám jejich zpracování ukládají příslušné právní
              předpisy
            </strong>
            .
          </p>
          <p className="mb-5">
            Bankovní údaje týkající se úhrady pojistného, pokud jsou
            zpracovávány, uchováváme dobu trvání našeho smluvního vztahu a dále
            po dobu trvání promlčecí doby nároků vyplývajících z pojištění,
            popř. prodlouženou o dobu šetření škodní události anebo dořešení
            všech nároků, které se vyskytly před uplynutím promlčecí doby, avšak
            trvají i po jejím uplynutí (kromě kódu CVC, který neuchováváme).
          </p>
          <p className="mb-5">
            Identifikační údaje poskytnuté v souvislosti s žádostí o uplatnění
            jednoho z práv uvedených v sekci 7 této Informace o zpracování
            osobních údajů uchováváme po dobu 1 až 3 let v závislosti na tom, o
            jaké právo se jednalo.
          </p>
          <p className="mb-5">
            Soubory <strong>cookies </strong>a jiná data o připojení k našim
            webovým stránkám jsou uchovávána po dobu 13 měsíců od data jejich
            sběru.
          </p>
          <p className="mb-5">
            Zvukové záznamy sloužící pro účely zkvalitňování našich služeb a
            školení našich zaměstnanců, pokud jsou pro tyto účely zpracovávány,
            jsou uchovávány po dobu 6 měsíců. Zvukové záznamy týkající se vašeho
            pojištění jsou však pro účely plnění povinností vyplývajících z
            tohoto pojištění uchovávány po dobu trvání našeho smluvního vztahu a
            dále po dobu trvání promlčecí doby nároků vyplývajících z pojištění,
            popř. prodlouženou o dobu šetření škodní události anebo dořešení
            všech nároků, které se vyskytly před uplynutím promlčecí doby, avšak
            trvají i po jejím uplynutí.
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            7. JAKÁ JSOU VAŠE PRÁVA A JAK JE MŮŽETE UPLATNIT
          </h2>
          <p className="mb-5">
            <br />
            Obecné nařízení o ochraně osobních údajů vám dává následující práva
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              <strong>Právo na přístup</strong>: Máte právo vědět, jaké údaje a
              které kategorie údajů o vás zpracováváme, za jakým účelem, po
              jakou dobu, komu je předáváme, kdo je mimo nás zpracovává a jaká
              máte další práva související se zpracováním vašich osobních údajů.
              To vše jste se dozvěděl/a v tomto Informačním listu. Pokud si však
              nejste jist/a, které osobní údaje o vás zpracováváme, můžete nás
              požádat o potvrzení, zda osobní údaje, které se vás týkají, jsou
              či nejsou z naší strany zpracovávány, a pokud tomu tak je, máte
              právo získat přístup k těmto osobním údajům. V rámci práva na
              přístup nás můžete požádat také o kopii zpracovávaných osobních
              údajů.
            </li>
            <li>
              <strong>Právo na opravu</strong>: Pokud se domníváte, že osobní
              údaje, které o vás zpracováváme, jsou nepřesné nebo neúplné, máte
              právo na to, abychom je bez zbytečného odkladu opravili, popř.
              doplnili.
            </li>
            <li>
              <strong>Právo na výmaz</strong>: V některých případech máte právo,
              abychom vaše osobní údaje vymazali. Vaše osobní údaje bez
              zbytečného odkladu vymažeme, pokud je splněn některý z
              následujících důvodů:
              <ul className="list-circle pl-10 mb-5">
                <li>
                  údaje již nepotřebujeme pro účely, pro které jsme je
                  zpracovávali,
                </li>
                <li>osobní údaje zpracováváme protiprávně,</li>
                <li>
                  využijete svého práva vznést námitku proti zpracování (viz
                  níže Právo vznést námitku proti zpracování), nebo{' '}
                </li>
                <li>
                  pokud odvoláte svůj souhlas se zpracováním osobních údajů.
                </li>
                <li>
                  Právo na výmaz se nicméně neuplatní v případě, že zpracování
                  vašich osobních údajů je i nadále nezbytné pro splnění našich
                  právních povinností, účely archivace, vědeckého či
                  historického výzkumu či pro statistické účely nebo určení,
                  výkon nebo obhajobu našich právních nároků.
                </li>
              </ul>
            </li>
            <li>
              <strong>Právo na omezení zpracování</strong>: V některých
              případech můžete kromě práva na výmaz využít právo na omezené
              zpracování osobních údajů. Toto právo vám umožňuje požadovat, aby
              došlo k označení vašich osobních údajů a tyto údaje nebyly po
              omezenou dobu předmětem žádných dalších operací zpracování. Toto
              je možno požadovat v následujících situacích
              <ul className="list-circle pl-10 mb-5">
                <li>
                  popíráte přesnost osobních údajů, než bude ověřeno, jaké údaje
                  jsou správné,
                </li>
                <li>
                  vaše osobní údaje zpracováváme bez dostatečného právního
                  základu (např. nad rámec toho, co zpracovávat musíme), ale vy
                  namísto výmazu takových údajů upřednostňujete pouze jejich
                  omezení (např. pokud očekáváte, že byste nám v budoucnu takové
                  údaje stejně poskytl/a),
                </li>
                <li>
                  vaše osobní údaje již nepotřebujeme pro shora uvedené účely
                  zpracování, ale vy je požadujete pro určení, výkon nebo
                  obhajobu svých právních nároků, nebo{' '}
                </li>
                <li>
                  pokud jste vznesl/a námitku proti zpracování, a to dokud
                  nebude ověřeno, zda naše oprávněné důvody převažují nad vašimi
                  oprávněnými důvody.
                </li>
              </ul>
            </li>
            <li>
              <strong>Právo vznést námitku proti zpracování</strong>
              <ul className="list-circle pl-10 mb-5">
                <li>
                  Námitku můžete vznést, jsou-li vaše osobní údaje zpracovávány:
                  <ul className="list-square pl-10 mb-5">
                    <li>za účelem ochrany našich oprávněných zájmů,</li>
                    <li>
                      pro účely přímého marketingu, včetně přímého marketingu
                      využívajícího profilování,
                    </li>
                    <li>
                      pro účely vědeckého či historického výzkumu nebo pro
                      statistické účely.
                    </li>
                  </ul>
                </li>
                <li>
                  V případě obdržení námitky omezíme zpracování vašich údajů a
                  provedeme věcné posouzení vaší námitky za účelem zjištění
                  oprávněných důvodů zpracování. V případě prokázání, že
                  neexistují oprávněné důvody k takovému zpracování, musíme vaše
                  osobní údaje bez zbytečného odkladu přestat zpracovávat.
                </li>
              </ul>
            </li>
            <li>
              <strong>Právo na odvolání souhlasu</strong>: Pokud jste poskytl/a
              svůj souhlas se zpracováním osobních údajů, máte právo tento
              souhlas kdykoliv odvolat, s výjimkou souhlasu uděleného dle
              občanského zákoníku pro zpracovávání údajů o vašem zdravotním
              stavu, došlo-li již ke sjednání pojištění.
            </li>
            <li>
              <strong>Právo na přenositelnost</strong>: Pokud vaše osobní údaje
              zpracováváme na základě vašeho souhlasu nebo za účelem plnění
              smlouvy, máte právo od nás získat anebo požadovat, abychom předali
              jinému správci, všechny vaše osobní údaje, které jste nám
              poskytl/a a které zpracováváme, a to ve strukturovaném, běžně
              používaném a strojově čitelném formátu. Abychom mohli na vaši
              žádost příslušné údaje snadno převést, může se jednat pouze o
              údaje, které zpracováváme automatizovaně v našich elektronických
              databázích. Touto formou vám tedy nemůžeme přenést vždy a za všech
              okolností všechny údaje, které jste vyplnil v našich formulářích
              (například Váš vlastnoruční podpis). Tímto právem není dotčeno
              právo na výmaz.
            </li>
          </ul>
          <p className="mb-5">
            Chcete-li využít některé z výše uvedených práv, můžete kontaktovat
            naše pověřence pro ochranu osobních údajů, a to kterýmkoli z
            následujících prostředků:
          </p>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Lokální pověřenec pro ochranu osobních údajů
          </h3>
          <p className="mb-5">
            Poštovní adresa: Pověřenec pro ochranu osobních údajů, BNP Paribas
            Cardif Pojišťovna, a.s. Boudníkova 2506, Libeň, 180 00 Praha 8,
            Česká republika
            <br />
            E-mail:{' '}
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="mailto:czpoverenec@cardif.com"
            >
              czpoverenec@cardif.com
            </a>
          </p>
          <p className="mb-5">
            Z důvodu ověření vaší totožnosti, přiložte, prosím, kopii vašeho
            občanského průkazu, popř. cestovního pasu.
          </p>
          <p className="mb-5">
            V případě, že se domníváte, že vaše osobní údaje zpracováváme
            neoprávněně nebo v rozporu s obecně závaznými právními předpisy,
            můžete vedle výše uvedených práv podat stížnost{' '}
            <strong>Úřadu pro ochranu osobních údajů</strong> (
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="http://www.uoou.cz"
            >
              www.uoou.cz
            </a>
            ).
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            8. Jak se dozvíte o změnách tohoto dokumentu
          </h2>
          <p className="mb-5">
            Ve světě neustálých technologických změn je možné, že budeme muset
            obsah tohoto dokumentu pravidelně upravovat.
          </p>
          <p className="mb-5">
            Doporučujeme vám, abyste se on-line seznámil/a s nejaktuálnější
            verzí tohoto dokumentu na našich stránkách{' '}
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="http://www.cardif.cz"
            >
              www.cardif.cz
            </a>
            . O podstatných změnách jeho obsahu vás budeme vždy informovat
            prostřednictvím našich webových stránek nebo jiných obvyklých
            komunikačních kanálů.
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            9. Jak nás můžete kontaktovat
          </h2>
          <p className="mb-5">
            Máte-li jakékoli dotazy týkající se zpracování vašich osobních údajů
            podle této Informace o zpracování osobních údajů, obraťte se,
            prosím, naše pověřence pro ochranu osobních údajů, kteří vám rádi
            odpovědí:
          </p>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            Lokální pověřenec pro ochranu osobních údajů
          </h3>
          <p className="mb-5">
            Poštovní adresa: Pověřenec pro ochranu osobních údajů, BNP Paribas
            Cardif Pojišťovna, a.s. Boudníkova 2506, Libeň, 180 00 Praha 8,
            Česká republika
            <br />
            E-mail:{' '}
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="mailto:czpoverenec@cardif.com"
            >
              czpoverenec@cardif.com
            </a>
            <br />
            Telefon: 234 240 234
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            10. Soubory cookies
          </h2>
          <p className="mb-5">
            Abychom dokázali lépe pochopit návštěvníky našich stránek, používáme
            službu Google Analytics, poskytovanou společností Google, Inc. (dále
            jen &quot;Google&quot;). Pro větší transparentnost, vám chceme
            přiblížit, co všechno tato skutečnost znamená pro Vás, naše
            návštěvníky.
          </p>
          <p className="mb-5">
            Služba Google Analytics používá souborů &quot;cookies&quot;, které
            umožňující analýzu způsobu užívání této stránky jejími uživateli
            jako celku. Anonymizované Informace vygenerované souborem cookie o
            užívání stránky budou společností Google přeneseny a uloženy na
            serverech ve Spojených státech.
          </p>
          <p className="mb-5">
            Google bude užívat tyto informace pro účely vyhodnocování užívání
            stránky a vytváření zpráv o její aktivitě, určených pro její
            provozovatele, a pro poskytování dalších služeb týkajících se
            činností na stránce a užívání internetu vůbec. Google může také
            poskytnout tyto informace třetím osobám, bude-li to požadováno
            zákonem nebo budou-li takovéto třetí osoby zpracovávat tyto
            informace pro Google. Google nebude spojovat vaši IP adresu s
            jakýmikoli jinými daty, která má k dispozici. Používáním této
            stránky souhlasíte se zpracováváním údajů o vás společností Google,
            a to způsobem a k účelu shora uvedeným.
          </p>
          <p className="mb-5">
            Detailní informace o Google Analytics a ochraně osobních údajů
            naleznete na{' '}
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="http://www.google.com/intl/cs/privacy/privacy-policy.html"
              target="_blank"
              rel="noreferrer"
            >
              http://www.google.com/intl/cs/privacy/privacy-policy.html
            </a>
            .
          </p>
          <p className="mb-5">
            Pokud chcete zabránit sledování, můžete instalovat doplněk do vašeho
            webového prohlížeče (
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="http://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noreferrer"
            >
              http://tools.google.com/dlpage/gaoptout
            </a>
            ).
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default Info;
