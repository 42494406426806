import React from 'react';
import { useIntl } from 'react-intl';

import messages from './homepage.messages';
import Link from '../../components/Link/link';
import List from '../../components/List';
import ListItem from '../../components/List/Item';

export const propTypes = {};
const defaultProps = {};

const EventTypeLinks = () => {
  const intl = useIntl();

  return (
    <List variant="ordered" type="decimal">
      <ListItem type="faq">
        <Link
          label={intl.formatMessage(messages.eventType_1)}
          to="https://cardif.cz/prakticke-informace/chci-nahlasit-udalost/jsem-v-pracovni-neschopnosti"
          target="_blank"
        />
      </ListItem>
      <ListItem type="faq">
        <Link
          label={intl.formatMessage(messages.eventType_2)}
          to="https://cardif.cz/prakticke-informace/chci-nahlasit-udalost/jsem-bez-prace"
          target="_blank"
        />
      </ListItem>
      <ListItem type="faq">
        <Link
          label={intl.formatMessage(messages.eventType_3)}
          to="https://cardif.cz/cs/web/czech-republic/zruseni-zivnosti"
          target="_blank"
        />
      </ListItem>
      <ListItem type="faq">
        <Link
          label={intl.formatMessage(messages.eventType_4)}
          to="https://cardif.cz/prakticke-informace/chci-nahlasit-udalost/invalidita-iii-stupne"
          target="_blank"
        />
      </ListItem>
      <ListItem type="faq">
        <Link
          label={intl.formatMessage(messages.eventType_5)}
          to="https://cardif.cz/prakticke-informace/chci-nahlasit-udalost/umrti-pojisteneho"
          target="_blank"
        />
      </ListItem>
      <ListItem type="faq">
        <Link
          label={intl.formatMessage(messages.eventType_6)}
          to="https://cardif.cz/staram-se-o-nemocneho-clena-rodiny"
          target="_blank"
        />
      </ListItem>
      <ListItem type="faq">
        <Link
          label={intl.formatMessage(messages.eventType_7)}
          to="https://cardif.cz/prakticke-informace/chci-nahlasit-udalost/hospitalizace"
          target="_blank"
        />
      </ListItem>
    </List>
  );
};

EventTypeLinks.propTypes = propTypes;
EventTypeLinks.defaultProps = defaultProps;

export default EventTypeLinks;
