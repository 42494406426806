import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  height: PropTypes.string,
  step: PropTypes.number,
};
const defaultProps = {
  height: 80,
  step: 0,
};

const StepImg = ({ step, height }) => (
  <img
    style={{ height }}
    className="inline-block"
    src={`/img/steps/${step}.png`}
    alt="step image"
  />
);

StepImg.propTypes = propTypes;
StepImg.defaultProps = defaultProps;

export default StepImg;
