import React from 'react';

import succesIconSrc from '../../assets/upload-success.svg';
import errorIconSrc from '../../assets/upload-error.svg';

export const SuccessIcon = () => (
  <img alt="upload success icon" className="w-25 h-25" src={succesIconSrc} />
);

export const ErrorIcon = () => (
  <img alt="upload error icon" className="w-25 h-25" src={errorIconSrc} />
);
