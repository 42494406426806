import { filter, length, lt, pathEq, pipe, prop, reject } from 'ramda';
import { createSelector } from 'reselect';

import { nolListSelector } from '../selectors';

export const nolListNolsSelector = pipe(nolListSelector, prop('nols'));
export const nolListIsLoadingSelector = pipe(
  nolListNolsSelector,
  prop('isLoading')
);
export const nolListIsInitializedSelector = pipe(
  nolListNolsSelector,
  prop('isInitialized')
);

export const nolListPendingSelector = createSelector(
  nolListNolsSelector,
  filter(pathEq(['status'], 'PENDING'))
);

export const nolListHasPendingSelector = createSelector(
  nolListPendingSelector,
  pipe(length, lt(0))
);

export const nolListResolvedSelector = createSelector(
  nolListNolsSelector,
  reject(pathEq(['status'], 'PENDING'))
);

export const nolListHasResolvedSelector = createSelector(
  nolListResolvedSelector,
  pipe(length, lt(0))
);
