import { format } from 'date-fns/fp';
import {
  always,
  applySpec,
  concat,
  constructN,
  converge,
  evolve,
  ifElse,
  path,
  pipe,
  prop,
  propOr,
  propEq,
  propSatisfies,
  splitAt,
  zipObj,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

export const fromApiPersonalData = pipe(
  applySpec({
    firstName: prop('firstName'),
    lastName: prop('lastName'),
    email: prop('email'),
    birthNumber: prop('birthNumber'),
    birthDate: pipe(prop('birthDate'), constructN(1, Date)),
    nationality: prop('nationality'),
    foreigner: prop('foreigner'),
    isSelfEmployed: propSatisfies(isNotNilOrEmpty, 'businessId'),
    businessId: propOr('', 'businessId'),
    journeyId: prop('journeyId'),
  }),
  evolve({ birthNumber: pipe(splitAt(6), zipObj(['number', 'suffix'])) })
);

export const toApiPersonalData = applySpec({
  firstName: prop('firstName'),
  lastName: prop('lastName'),
  email: prop('email'),
  birthNumber: converge(concat, [
    path(['birthNumber', 'number']),
    path(['birthNumber', 'suffix']),
  ]),
  birthDate: pipe(prop('birthDate'), format('yyyy-MM-dd')),
  nationality: prop('nationality'),
  foreigner: prop('foreigner'),
  businessId: ifElse(
    propEq('isSelfEmployed', true),
    prop('businessId'),
    always(null)
  ),
});
