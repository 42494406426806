import { always } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  switchMap,
  withLatestFrom,
  map as rxMap,
  catchError,
  mergeMap,
} from 'rxjs/operators';

import { authorizationHeaderSelector } from '../../auth/storage/selectors';
import {
  changeEmailError,
  changeEmailSuccess,
  CHANGE_EMAIL,
  getSettings,
} from '../actions';

export default (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_EMAIL),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      ajax({
        method: 'PUT',
        url: '/gateway-server/accounts/settings/email',
        headers: {
          authorization: authorizationHeaderSelector(state),
        },
        body: payload,
      }).pipe(
        rxMap(always(changeEmailSuccess(payload))),
        catchError((e) => of(changeEmailError(e))),
        mergeMap((a) => of(a, getSettings()))
      )
    )
  );
