import { handleActions } from 'redux-actions';

import {
  CHECK_TOKEN_STATUS_ERROR,
  CHECK_TOKEN_STATUS_SUCCESS,
  DEINIT,
  INIT,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
} from './actions';

const initialState = {
  isInitialized: false,
  codeExpired: false,
  codeInvalid: false,
  codeValid: false,
  hasServerError: false,
  pairingCode: null,
  isSubmitting: false,
  passwordAlreadyUsed: false,
};

export default handleActions(
  {
    [INIT]: (state, { payload }) => ({
      ...state,
      pairingCode: payload.pairingCode,
    }),
    [DEINIT]: () => ({ ...initialState }),
    [CHECK_TOKEN_STATUS_SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      isInitialized: true,
    }),
    [CHECK_TOKEN_STATUS_ERROR]: (state) => ({
      ...state,
      isInitialized: true,
      hasServerError: true,
    }),
    [RESET_PASSWORD]: (state) => ({ ...state, isSubmitting: true }),
    [RESET_PASSWORD_ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      isSubmitting: false,
    }),
  },
  initialState
);
