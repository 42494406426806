import { defineMessages } from 'react-intl';

const namespace = 'crdf_callMeBack';

export default defineMessages({
  title: {
    id: `${namespace}_title`,
    defaultMessage: 'Zavolejte mi zpět',
  },
  weWillCall: {
    id: `${namespace}_weWillCall`,
    defaultMessage: 'Zavoláme vám',
  },
  genericMessage: {
    id: `${namespace}_genericMessage`,
    defaultMessage:
      'Pokud se vám nedaří něco vyřešit, tak vám zavoláme a podíváme se na to společně.',
  },
  noPolicyFoundFragment: {
    id: `${namespace}_noPolicyFoundFragment`,
    defaultMessage:
      'Bohužel se nám nepodařilo dohledat, že máte sjednanou pojistnou smlouvu, která by pokrývala vámi vybrané riziko.',
  },
  unableToContinueFragment: {
    id: `${namespace}_unableToContinueFragment`,
    defaultMessage: 'V nahlášení události online proto nelze pokračovat.',
  },
  weWillCallYouFragment: {
    id: `${namespace}_weWillCallYouFragment`,
    defaultMessage: 'Zavoláme Vám v pracovní den mezi 8-18 hodinou.',
  },
  ifCertainFragment: {
    id: `${namespace}_ifCertainFragment`,
    defaultMessage:
      'Pokud jste si jisti, že vybrané riziko máte v některé sjednané smlouvě kryté, nahlášení události vám pomůžeme vyřešit telefonicky na našem zákaznickém centru.',
  },
  noPolicyByNameFragment: {
    id: `${namespace}_noPolicyByNameFragment`,
    defaultMessage:
      'Bohužel jsme nedohledali žádné pojistné smlouvy na Vaše jméno. Můžeme tuto nepříjemnost spolu vyřešit telefonicky?',
  },
  submitNotice: {
    id: `${namespace}_submitNotice`,
    defaultMessage:
      'Budeme vás co nejdříve kontaktovat na čísle, které jste zadal/a +{phonePrefix}{phoneNumber}',
  },
  formPhoneLabel: {
    id: `${namespace}_formPhoneLabel`,
    defaultMessage: 'Telefonní číslo',
  },
  formSubmitLabel: {
    id: `${namespace}_formSubmitLabel`,
    defaultMessage: 'Zavolejte mi',
  },
  thankYouBtnLabel: {
    id: `${namespace}_thankYouBtnLabel`,
    defaultMessage: 'Děkuji',
  },
});
