import {
  assocPath,
  converge,
  curryN,
  ifElse,
  invoker,
  pipe,
  prop,
  propSatisfies,
  unary,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { EMPTY, catchError, throwError, retry, interval } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { authorizationHeaderSelector } from '../../modules/auth/storage/selectors';
import {
  authorizationError,
  clearTokens,
} from '../../modules/auth/storage/actions';

const createRequestConfig = ifElse(
  propSatisfies(isNotNilOrEmpty, 'useAuth'),
  converge(assocPath(['headers', 'authorization']), [
    pipe(
      prop('store'),
      invoker(0, 'getState'),
      unary(authorizationHeaderSelector)
    ),
    prop('config'),
  ]),
  prop('config')
);

export default curryN(2, (store, { useAuth, errorPassthrough, ...config }) =>
  ajax(createRequestConfig({ store, useAuth, config })).pipe(
    retry({
      count: 3,
      delay: (error) => {
        if (error.status === 500) {
          return interval(200);
        }

        return throwError(() => error);
      },
    }),
    catchError((errorResponse) => {
      if (
        !errorPassthrough &&
        (errorResponse.status === 401 || errorResponse.status === 403)
      ) {
        store.dispatch(authorizationError());

        return EMPTY;
      }
      if (errorResponse.status === 500) {
        store.dispatch(clearTokens());
      }

      return throwError(() => errorResponse);
    })
  )
);
