import { defineMessages } from 'react-intl';

const namespace = 'crdf_nol_detail_nolDetail_statusRenderer';

export default defineMessages({
  WAITING_FOR_DOCUMENTS: {
    id: `${namespace}_WAITING_FOR_DOCUMENTS`,
    defaultMessage: 'Čekáme na doklady',
  },
  PROCESSING: {
    id: `${namespace}_PROCESSING`,
    defaultMessage: 'Probíhá šetření události',
  },
  ACCEPTED: {
    id: `${namespace}_ACCEPTED`,
    defaultMessage: 'Máte nárok na pojistné plnění',
  },
  REJECTED: {
    id: `${namespace}_REJECTED`,
    defaultMessage: 'Nevznikl Vám nárok na pojistné plnění',
  },
  DELETED: {
    id: `${namespace}_DELETED`,
    defaultMessage: 'Nevznikl Vám nárok na pojistné plnění',
  },
});
