import React from 'react';

import CookieModal from '../../modules/modals/cookie/cookie';

const Info = () => (
  <div className="mx-auto px-2.5 max-w-3xl lg:max-w-5xl xl:max-w-6xl w-full text-cardif-gray-dark">
    <div className="-mx-2.5 pt-10 px-5">
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <p className="mb-5">
            Skupina BNP Paribas je odhodlána poskytovat vám ty nejlepší možné
            služby při zachování důvěry, kterou v nás vkládáte.
          </p>
          <p className="mb-[5px]">
            V rámci toho jsou ve skupině zavedeny významné zásady, které slouží
            k ochraně vašich osobních údajů.
          </p>
          <p className="mb-5">
            Proto bychom vám rádi poskytli informace o tom, jak při vašem
            používání naší webové stránky{' '}
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="/"
            >
              www.online.cardif.cz
            </a>{' '}
            (dále „web“) umisťujeme, používáme a ukládáme na vašem zařízení
            soubory cookies, a nabídli vám možnost jejich správy a smazání.
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            1. Co je to cookie?
          </h2>
          <p className="mb-[5px]">
            Cookies jsou malé textové, obrazové nebo softwarové soubory, které
            se umístí automaticky na vaše zařízení, když navštívíte webovou
            stránku. Slovem „zařízení“ se rozumí zejména počítače, chytré
            telefony, tablety a veškerá další zařízení používaná a umožňující k
            přístupu na internet.
          </p>
          <p className="mb-5">Cookies jsou dle doby trvání:</p>
          <ol className="list-lower-roman pl-10 mb-2.5">
            <li>
              pouze pro danou relaci, což znamená, že po skončení relace a
              uzavření prohlížeče jsou ze zařízení automaticky smazány; nebo
            </li>
            <li>
              trvalé/dlouhodobé, což znamená, že na vašem zařízení zůstanou až
              do jejich odstranění nebo než vyprší doba jejich expirace.
            </li>
          </ol>
          <p className="mb-5">
            Cookies plní řadu užitečných funkcí, například:
          </p>
          <ul className="list-disc pl-10 mb-5">
            <li>
              <strong>Ověřují a identifikují</strong> vás na naší webové
              stránce, abychom vám mohli poskytovat vámi požadované služby;
            </li>
            <li>
              <strong>Zvyšují bezpečnost</strong> webové stránky, například
              zamezují zneužití přihlašovacích údajů a chrání údaje uživatele
              před přístupem nepovolaných stran;
            </li>
            <li>
              <strong>Sledují vaše používání</strong> naší webové stránky za
              účelem <strong>jejich vylepšení</strong>;
            </li>
            <li>
              <strong>Zlepšují uživatelské pohodlí</strong> přizpůsobením obsahu
              webové stránky vašim zájmům a poskytování relevantnějších reklam a
              obsahu společnosti BNP Paribas na jiných webových stránkách;
            </li>
            <li>
              <strong>Pamatují si informace</strong>, které jste nám poskytli
              (např. k automatickému vyplnění formulářů informacemi, které jste
              nám dříve poskytli, takže se můžete přihlašovat rychleji);
            </li>
            <li>
              <strong>Sledují vaše preference a nastavení</strong> při používání
              naší webové stránky (např. časové pásmo apod.).
            </li>
          </ul>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            2. Jaké typy informací mohou být v cookie uloženy?
          </h2>
          <p className="mb-5">
            Informace uložené v cookies na vašem zařízení se mohou v závislosti
            na jejich době platnosti vztahovat k následujícímu:
          </p>
          <ul className="mb-2.5">
            <li>
              <span className="pl-4 pr-2">&mdash;</span> webové stránky
              navštívené na tomto zařízení;
            </li>
            <li>
              <span className="pl-4 pr-2">&mdash;</span> reklamy, na které jste
              klikli;
            </li>
            <li>
              <span className="pl-4 pr-2">&mdash;</span> používaný typ
              prohlížeče;
            </li>
            <li>
              <span className="pl-4 pr-2">&mdash;</span> IP adresa;
            </li>
            <li>
              <span className="pl-4 pr-2">&mdash;</span> a veškeré další
              informace, které jste na naší webové stránce poskytli.
            </li>
          </ul>
          <p className="mb-5">
            Cookies mohou obsahovat osobní údaje. V případě, že používáme
            cookies, které shromažďují vaše osobní údaje, řídí se jejich
            zpracování našimi zásadami ochrany osobních údajů{' '}
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="https://cardif.cz/ochrana-osobnich-udaju"
            >
              https://cardif.cz/ochrana-osobnich-udaju
            </a>
            .
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            3. Jaké typy cookies používáme v Cardifu a za jakým účelem?
          </h2>
          <p className="mb-4">
            Cookies používané na našich webových stránkách se rozdělují do
            různých kategorií.
          </p>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            3.1 Zcela nezbytné/technické (povinné) cookies
          </h3>
          <p className="mb-2.5">
            Tyto cookies jsou nezbytné pro řádné fungování stránky. Patří mezi
            ně například cookies, které shromažďují identifikační údaje o relaci
            a ověřovací údaje. Do této kategorie spadají rovněž cookies, které
            nám umožňují plnit naše zákonné povinnosti včetně zajištění
            bezpečného online prostředí (například detekováním opakovaných
            neúspěšných pokusů o přihlášení a zamezení neoprávněným osobám v
            přístupu k vašemu účtu).
          </p>
          <table className="mb-2.5 border-collapse">
            <tbody>
              <tr className="odd:bg-gray-50">
                <td>
                  <strong>Název cookie</strong>
                </td>
                <td>
                  <strong>Účel</strong>
                </td>
                <td>
                  <strong>Retenční doba</strong>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>TSxxxxxxxxxx</td>
                <td>
                  Cookie nezbytné pro správné fungování webubezpečné fungování
                  našeho webu.
                </td>
                <td>
                  <em>Trvalé</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>
                  BIGipServerP_159.50.204.196_80_vip-asm-p-online-cardif-cz.fr.net.intra
                </td>
                <td>Cookie nezbytné pro správné fungování webu</td>
                <td>
                  <em>Po dobu trvání relace</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>core_token_refresh</td>
                <td>Cookie nezbytné pro správné fungování webu</td>
                <td>
                  <em>Po dobu trvání relace</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>COOKIE_SUPPORT</td>
                <td>Cookie nezbytné pro správné fungování webu</td>
                <td>
                  <em>Trvalé</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>LFR_SESSION_STATE_ZZZZZ</td>
                <td>Cookie nezbytné pro správné fungování webu</td>
                <td>
                  <em>Trvalé</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>core_token</td>
                <td>Cookie nezbytné pro správné fungování webu</td>
                <td>
                  <em>Trvalé</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>BIGipServerPOOL_CECOP_PROD_ONLINE-CARDIF-CZ</td>
                <td>Cookie nezbytné pro správné fungování webu</td>
                <td>
                  <em>Po dobu trvání relace</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>JSESSIONID</td>
                <td>Cookie nezbytné pro správné fungování webu</td>
                <td>
                  <em>Po dobu trvání relace</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>f5avraaaaaaaaaaaaaaaa_session_</td>
                <td>Cookie nezbytné pro správné fungování webu</td>
                <td>
                  <em>Po dobu trvání relace</em>
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            3.2 Preferenční cookies (podléhají vašemu souhlasu)
          </h3>
          <p className="mb-2.5">
            Tyto cookies nám umožňují personalizovat a nabízet obsah a funkce na
            stránce (zejména pak zobrazování našich produktů a služeb).
            Používají se k vylepšování našeho webu a vaší uživatelské zkušenosti
            nebo se jedná o cookies, které umožňují personalizaci rozhraní
            stránky (například volbu jazyka nebo prezentace služby).
          </p>
          <table className="mb-2.5 border-collapse">
            <tbody>
              <tr className="odd:bg-gray-50">
                <td>
                  <strong>Název cookie</strong>
                </td>
                <td>
                  <strong>Účel</strong>
                </td>
                <td>
                  <strong>Retenční doba</strong>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>GUEST_LANGUAGE_ID</td>
                <td>
                  Cookie zajišťující správné zobrazení zvoleného jazyka webu.
                </td>
                <td>
                  <em>Trvalé</em>
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="text-2xl text-black font-medium mb-2.5">
            3.3 Analytické cookies třetích stran (s výjimkou některých případů
            podléhají vašemu souhlasu)
          </h3>
          <p className="mb-2.5">
            Tyto cookies pomáhají porozumět tomu, odkud uživatelé přichází na
            naši stránku, měřit počet návštěvníků naší stránky, a také
            analyzovat, jak uživatelé prochází stránku a/nebo obnovují toto
            procházení. Na naše stránky instaluje analytické cookies společnost
            Google.
          </p>
          <p className="mb-2.5">
            To společnosti Google pomáhá vylepšit způsob fungování naší stránky
            například zajištěním toho, aby uživatelé snáze našli, co hledají.
          </p>
          <table className="border-collapse">
            <tbody>
              <tr className="odd:bg-gray-50">
                <td>
                  <strong>Název cookie</strong>
                </td>
                <td>
                  <strong>Účel</strong>
                </td>
                <td>
                  <strong>Retenční doba</strong>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>_ga</td>
                <td>
                  Tyto cookie pomáhají porozumět tomu, odkud uživatelé přichází
                  na naši stránku, měřit počet návštěvníků naší stránky, a také
                  analyzovat, jak uživatelé prochází stránku a/nebo obnovují
                  toto procházení.
                </td>
                <td>
                  <em>13 měsíců</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>_gid</td>
                <td>
                  Tyto cookie pomáhají porozumět tomu, odkud uživatelé přichází
                  na naši stránku, měřit počet návštěvníků naší stránky, a také
                  analyzovat, jak uživatelé prochází stránku a/nebo obnovují
                  toto procházení.
                </td>
                <td>
                  <em>24 hodin</em>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            4. Kdo umisťuje cookies na vaše zařízení?
          </h2>
          <p className="mb-2.5">
            Když si zvolíte, které typy cookies na svém zařízení povolíte,
            můžeme tyto cookies ukládat přímo my nebo je může ukládat 3. strana.
          </p>
          <p className="mb-2.5">
            Z praktického hlediska to znamená, že pokud na svém zařízení
            povolíte instalaci některých tzv. cookies „třetích stran“, budou i
            oni mít přístup k informacím, které obsahují (jako jsou například
            statistiky o vašem procházení, počet návštěvníků na našem webu, jaká
            zařízení návštěvníci našeho webu využívají, pokud povolíte
            analytické cookies), v souladu s našimi zásadami i zásadami třetích
            stran pro zpracování ochraně osobních údajů.
          </p>
          <table className="border-collapse">
            <tbody>
              <tr className="odd:bg-gray-50">
                <td>
                  <strong>Název cookie</strong>
                </td>
                <td>
                  <strong>Účel</strong>
                </td>
                <td>
                  <strong>Retenční doba</strong>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>_ga</td>
                <td>
                  Tyto cookie nám pomáhají porozumět tomu, odkud uživatelé
                  přichází na naši stránku, měřit počet návštěvníků naší
                  stránky, a také analyzovat, jak uživatelé prochází stránku
                  a/nebo obnovují toto procházení. Na naše stránky instaluje
                  analytické cookies také společnost Google.
                </td>
                <td>
                  <em>13 měsíců</em>
                </td>
              </tr>
              <tr className="odd:bg-gray-50">
                <td>_gid</td>
                <td>
                  Tyto cookie nám pomáhají porozumět tomu, odkud uživatelé
                  přichází na naši stránku, měřit počet návštěvníků naší
                  stránky, a také analyzovat, jak uživatelé prochází stránku
                  a/nebo obnovují toto procházení. Na naše stránky instaluje
                  analytické cookies také společnost Google.
                </td>
                <td>
                  <em>24 hodin</em>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <h2 className="text-3xl font-light mb-5 antialiased font-cardif-light">
            5. Jak můžete spravovat cookies?
          </h2>
          <p className="mb-5">
            K zobrazení různých kategorií cookies, které na stránce používáme, a
            k nastavení svých voleb můžete využít modul pro správu cookies
            dostupný v dolní části internetové stránky. Své preference můžete
            kdykoli upravit a kdykoli můžete stáhnout nebo znovu poskytnout
            souhlas.
          </p>
          <p className="mb-5">
            Berte prosím na vědomí, že používání cookies, které jsou zcela
            nezbytné pro řádné fungování stránky, nevyžaduje váš souhlas. Z toho
            důvodu je možnost „zcela nezbytné cookies“ v našem nástroji pro
            správu cookies předem zaškrtnuta a není volitelná.{' '}
          </p>
          <p className="mb-5">
            Odmítnete-li některé typy cookies (například preferenční cookies),
            nebudeme schopni optimalizovat vaši uživatelskou zkušenost na naší
            stránce a některé její části nemusí správně fungovat.{' '}
          </p>
          <p className="mb-5">
            Ve výchozím nastavení ukládáme vaše volby pro cookies na zařízení po
            dobu maximálně 13 měsíců. Změníte-li názor ohledně svých preferencí
            v oblasti cookies, můžete své volby kdykoli aktualizovat na{' '}
            <a
              className="text-cardif-green-light underline hover:text-cardif-green-dark hover:no-underline"
              href="/cookie-policy"
            >
              https://online.cardif.cz/cookie-policy
            </a>
            . O tuto volbu vás znovu požádáme každých 6 měsíců.
          </p>
        </div>
      </div>
      <div className="-mx-2.5 mb-2.5 bg-white">
        <div className="w-10/12 lg:w-8/12 mx-auto pt-10 pb-7.5">
          <CookieModal
            button={
              <span className="text-xs text-[#68b631] underline hover:text-white border border-[#68b631]">
                Přizpůsobit
              </span>
            }
          />
        </div>
      </div>
    </div>
  </div>
);

export default Info;
