import { combineEpics } from 'redux-observable';

import submitBankAccount from './submitBankAccount';
import submitContact from './submitContact';
import submitRiskDetail from './submitRiskDetail';
import submitRiskType from './submitRiskType';
import submitPersonal from './submitPersonal';
import progress from './progress';
import finalizeJourney from './finalizeJourney';

export default combineEpics(
  submitPersonal,
  submitContact,
  submitRiskType,
  submitRiskDetail,
  submitBankAccount,
  progress,
  finalizeJourney
);
