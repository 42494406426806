import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};
const defaultProps = {
  isActive: false,
};

const TabLabel = ({ label, onSelect, isActive }) => (
  <button
    onClick={onSelect}
    className={cx('px-4 md:px-6 lg:px-12 py-1 md:py-2 font-cardif-regular', {
      'border-cardif-green-light border-b-3': isActive,
    })}
  >
    {label}
  </button>
);

TabLabel.propTypes = propTypes;
TabLabel.defaultProps = defaultProps;

export default TabLabel;
