import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Grid from '../../../../../../components/Grid';
import Span from '../../../../../../components/Grid/Span';
import { getCountryByCode } from '../../../../../countries';
import { nolDetailClientDetailSelector } from '../../../selectors';
import SimpleTable from '../../../../../SimpleTable/SimpleTable';
import commonMessages from '../../../../../i18n/common.messages';
import rowDefinitions from './rowDefinitions';

// TODO: wrap in localization messages

const ClientInfoCard = () => {
  const data = useSelector(nolDetailClientDetailSelector);
  const { nameCurrentValue: citizenship } = useMemo(
    () => getCountryByCode(data.citizenship),
    [data]
  );

  const remappedData = useMemo(
    () => ({
      ...data,
      citizenship,
      name: `${data.firstName} ${data.lastName}`,
    }),
    [data, citizenship]
  );

  return (
    <Grid xs={3}>
      <Span xs={3} lg={2}>
        <SimpleTable
          rows={rowDefinitions}
          data={remappedData}
          messages={commonMessages}
        />
      </Span>
    </Grid>
  );
};

export default ClientInfoCard;
