import { curryN, propEq } from 'ramda';

import runValidations, {
  isRequired,
  hasLengthMax,
  hasOnlyDigits,
  hasLength,
} from '../../../forms/validators';

const validations = {
  street: { validate: [isRequired(), hasLengthMax(100)] },
  city: { validate: [isRequired(), hasLengthMax(100)] },
  countryCode: { validate: [isRequired()] },
  zipCode: { validate: [isRequired(), hasOnlyDigits(), hasLength(5)] },
  contactStreet: {
    when: propEq('hasDifferentContactAddress', true),
    validate: [isRequired(), hasLengthMax(100)],
  },
  contactCity: {
    when: propEq('hasDifferentContactAddress', true),
    validate: [isRequired(), hasLengthMax(100)],
  },
  contactCountry: {
    when: propEq('hasDifferentContactAddress', true),
    validate: [isRequired()],
  },
  contactPostalCode: {
    when: propEq('hasDifferentContactAddress', true),
    validate: [isRequired(), hasOnlyDigits(), hasLength(5)],
  },
};

export default curryN(2, (deps, values) =>
  runValidations(validations, deps, values)
);
