import { createAction } from 'redux-actions';

const namespace = 'crdf.auth.resetPassword';

export const INIT = `${namespace}.init`;
export const DEINIT = `${namespace}.deinit`;

export const CHECK_TOKEN_STATUS = `${namespace}.checkTokenStatus`;
export const CHECK_TOKEN_STATUS_SUCCESS = `${namespace}.checkTokenStatusSuccess`;
export const CHECK_TOKEN_STATUS_ERROR = `${namespace}.checkTokenStatusError`;

export const RESET_PASSWORD = `${namespace}.resetPassword`;
export const RESET_PASSWORD_SUCCESS = `${namespace}.resetPasswordSuccess`;
export const RESET_PASSWORD_ERROR = `${namespace}.resetPasswordError`;

export const init = createAction(INIT);
export const deinit = createAction(DEINIT);

export const checkTokenStatus = createAction(CHECK_TOKEN_STATUS);
export const checkTokenStatusSuccess = createAction(CHECK_TOKEN_STATUS_SUCCESS);
export const checkTokenStatusError = createAction(CHECK_TOKEN_STATUS_ERROR);

export const resetPassword = createAction(RESET_PASSWORD);
export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS);
export const resetPasswordError = createAction(RESET_PASSWORD_ERROR);
