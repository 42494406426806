import React from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Grid from '../../../components/Grid';
import Button from '../../../components/Button';
import TextField from '../../forms/fields/textField';
import CheckboxField from '../../forms/fields/checkbox';
import Spacing from '../../../components/layout/Spacing';
import CountrySelectField from '../../forms/fields/countrySelect';
import { registrationSelector } from '../selectors';
import { submitContactDataForm } from '../actions';
import messages from './messages';
import validator from './validators';

const initialValues = {
  street: '',
  city: '',
  zipCode: '',
  countryCode: '203',
  hasDifferentContactAddress: false,
  contactStreet: '',
  contactCity: '',
  contactPostalCode: '',
  contactCountry: '203',
};

const ContactDataForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { formatMessage } = intl;
  const { contactDataFormSubmitting, accountFinalizing } =
    useSelector(registrationSelector);

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validate={validator({ intl })}
      onSubmit={(values) => dispatch(submitContactDataForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <Grid gap={8}>
            <TextField
              required
              name="street"
              label={formatMessage(messages.streetLabel)}
            />
            <TextField
              required
              name="city"
              label={formatMessage(messages.cityLabel)}
            />
            <TextField
              required
              name="zipCode"
              filterPattern={/^[0-9]+$/}
              label={formatMessage(messages.postalCodeLabel)}
            />
            <CountrySelectField
              required
              name="countryCode"
              label={formatMessage(messages.countryLabel)}
            />
            <CheckboxField
              name="hasDifferentContactAddress"
              label={formatMessage(messages.hasDifferentContactAddressLabel)}
            />
            {formik.values.hasDifferentContactAddress && (
              <>
                <TextField
                  required
                  name="contactStreet"
                  label={formatMessage(messages.streetLabel)}
                />
                <TextField
                  required
                  name="contactCity"
                  label={formatMessage(messages.cityLabel)}
                />
                <TextField
                  required
                  name="contactPostalCode"
                  filterPattern={/^[0-9]+$/}
                  label={formatMessage(messages.postalCodeLabel)}
                />
                <CountrySelectField
                  required
                  name="contactCountry"
                  label={formatMessage(messages.countryLabel)}
                />
              </>
            )}
          </Grid>
          <Spacing variant="item" />
          <Grid xs={2} gap={4}>
            <Button
              full
              disabled={contactDataFormSubmitting || accountFinalizing}
              variant="gray"
              onClick={() => navigate('../personal')}
              label={formatMessage(messages.stepTwo_backButtonLabel)}
            />
            <Button
              full
              type="submit"
              label={formatMessage(messages.stepTwo_continueButtonLabel)}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ContactDataForm;
