import { createAction } from 'redux-actions';

export const GET_SETTINGS = 'crfd.settings.getSettings';
export const GET_SETTINGS_SUCCESS = 'crfd.settings.getSettingsSuccess';
export const GET_SETTINGS_ERROR = 'crfd.settings.getSettingsError';

export const RESET_STATE = 'crfd.settings.resetState';

export const CHANGE_EMAIL = 'crfd.settings.changeEmail';
export const CHANGE_EMAIL_SUCCESS = 'crfd.settings.changeEmailSuccess';
export const CHANGE_EMAIL_ERROR = 'crfd.settings.changeEmailError';

export const CHANGE_PASSWORD = 'crfd.settings.changePassword';
export const CHANGE_PASSWORD_SUCCESS = 'crfd.settings.changePasswordSuccess';
export const CHANGE_PASSWORD_ERROR = 'crfd.settings.changePasswordError';

export const CHANGE_NOTIFICATIONS = 'crfd.settings.changeNotifications';
export const CHANGE_NOTIFICATIONS_SUCCESS =
  'crfd.settings.changeNotificationsSuccess';
export const CHANGE_NOTIFICATIONS_ERROR =
  'crfd.settings.changeNotificationsError';

export const getSettings = createAction(GET_SETTINGS);
export const getSettingsSuccess = createAction(GET_SETTINGS_SUCCESS);
export const getSettingsError = createAction(GET_SETTINGS_ERROR);

export const resetState = createAction(RESET_STATE);

export const changeEmail = createAction(CHANGE_EMAIL);
export const changeEmailSuccess = createAction(CHANGE_EMAIL_SUCCESS);
export const changeEmailError = createAction(CHANGE_EMAIL_ERROR);

export const changePassword = createAction(CHANGE_PASSWORD);
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS);
export const changePasswordError = createAction(CHANGE_PASSWORD_ERROR);

export const changeNotifications = createAction(CHANGE_NOTIFICATIONS);
export const changeNotificationsSuccess = createAction(
  CHANGE_NOTIFICATIONS_SUCCESS
);
export const changeNotificationsError = createAction(
  CHANGE_NOTIFICATIONS_ERROR
);
