import {
  always,
  constructN,
  lensProp,
  over,
  pipe,
  propSatisfies,
  tryCatch,
  unary,
  when,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { of } from 'rxjs';
import { map as rxMap } from 'rxjs/operators';

import { initDone } from '../actions';
import { TOKEN_STORAGE_KEY } from '../constants';

const storageDecode = tryCatch(
  pipe(
    unary(atob),
    unary(JSON.parse),
    when(
      propSatisfies(isNotNilOrEmpty, 'tokenExpiresAt'),
      over(lensProp('tokenExpiresAt'), constructN(1, Date))
    )
  ),
  always(null)
);

const fetchStoredToken = ({ window }) =>
  storageDecode(window.sessionStorage.getItem(TOKEN_STORAGE_KEY));

export default (action$, state$, { window }) =>
  of(fetchStoredToken({ window })).pipe(rxMap(unary(initDone)));
