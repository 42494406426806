/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import Container from '../../components/layout/Container';
import Link from '../../components/Link';
import messages from '../nol/list/NolList/messages';
import { openNpsQuestionnaire } from './actions';
import { nolListNolsSelector } from '../nol/list/selectors';
import { npsSelector } from './selectors';

const NPSLink = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isNPSApplicable } = useSelector(npsSelector);
  const nols = useSelector(nolListNolsSelector);
  const [hasFinishedNols, setHasFinishedNols] = useState(false);
  const [firstFinishedNolId, setFirstFinishedNolId] = useState();

  useEffect(() => {
    const finishedNols = nols.filter((n) => n.numberOfRequirements === 0);
    setHasFinishedNols(finishedNols.length > 0);
    if (finishedNols.length > 0) setFirstFinishedNolId(finishedNols[0].uuid);
  }, [nols]);

  if (!isNPSApplicable || !hasFinishedNols) return null;

  return (
    <Container>
      <div className="pb-4 md:pb-8">
        <Link
          center
          to="#"
          onClick={() => dispatch(openNpsQuestionnaire(firstFinishedNolId))}
          label={intl.formatMessage(messages.npsLink)}
          component={RouterLink}
          rel="noreferrer"
        />
      </div>
    </Container>
  );
};

export default NPSLink;
