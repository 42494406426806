import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  error: PropTypes.string,
  extraClass: PropTypes.string,
};
const defaultProps = {
  error: null,
  extraClass: '',
};

const FormError = ({ error, extraClass }) => (
  <div
    className={cx('min-h-[0.8em]', {
      'text-xs text-red-600 px-4': !!error,
      [extraClass]: !!extraClass && !!error,
      invisible: !error,
    })}
  >
    {error}
  </div>
);

FormError.propTypes = propTypes;
FormError.defaultProps = defaultProps;

export default FormError;
