import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { CallIcon, ColoredIcon } from '../../components/icons';
import Paragraph from '../../components/text/Paragraph';
import Heading from '../../components/text/Heading';
import Button from '../../components/Button';
import Spacing from '../../components/layout/Spacing';
import messages from './messages';
import { CALL_ME_BACK_TYPES_GENERIC } from './constants';
import { clearTokens } from '../auth/storage/actions';

const propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  phonePrefix: PropTypes.string.isRequired,
  reason: PropTypes.string,
};
const defaultProps = {};

const WeWillCallStep = ({ phoneNumber, phonePrefix, reason }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleOnclick = () => {
    switch (reason) {
      case CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_REGISTRATION:
        dispatch(clearTokens());
        navigate('/');
        break;
      case CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_DECLARATION:
        navigate('/nols');
        break;
      case CALL_ME_BACK_TYPES_GENERIC.LOGIN:
        navigate('/');
        break;
      default:
        navigate('/');
    }
  };

  return (
    <>
      <Heading icon={<ColoredIcon icon={<CallIcon />} color="green" />}>
        <FormattedMessage {...messages.weWillCall} />
      </Heading>
      <Paragraph center>
        <FormattedMessage
          {...messages.submitNotice}
          values={{ phonePrefix, phoneNumber }}
        />
      </Paragraph>
      <Spacing variant="item" />
      <Button
        center
        label={formatMessage(messages.thankYouBtnLabel)}
        onClick={handleOnclick}
      />
    </>
  );
};

WeWillCallStep.propTypes = propTypes;
WeWillCallStep.defaultProps = defaultProps;

export default WeWillCallStep;
