import { ofType } from 'redux-observable';
import { switchMap, map as rxMap, catchError, mergeMap } from 'rxjs/operators';
import {
  __,
  always,
  applySpec,
  concat,
  converge,
  ifElse,
  map,
  path,
  pathOr,
  pipe,
  prop,
  propEq,
  unary,
  defaultTo,
} from 'ramda';
import { of } from 'rxjs';
import { format } from 'date-fns/fp';

import {
  SUBMIT_PERSONAL_DATA_FORM,
  submitPersonalDataFormError,
  submitPersonalDataFormSuccess,
} from '../actions';
import { navigate } from '../../navigation/actions';
import { fetchAccount } from '../../auth/account/actions';

const toApiPersonalData = pipe(
  prop('payload'),
  applySpec({
    firstName: prop('firstName'),
    lastName: prop('lastName'),
    email: prop('email'),
    birthNumber: converge(concat, [
      path(['birthNumber', 'number']),
      path(['birthNumber', 'suffix']),
    ]),
    birthDate: pipe(prop('birthDate'), format('yyyy-MM-dd')),
    nationality: prop('nationality'),
    foreigner: prop('foreigner'),
    businessId: ifElse(
      propEq('isSelfEmployed', true),
      prop('businessId'),
      always(null)
    ),
  })
);

const fieldMapping = {
  birthNumber: 'birthNumber.number',
};

const fromApiError = pipe(
  pathOr([], ['response', 'violations']),
  map(
    applySpec({
      field: converge(defaultTo, [
        prop('field'),
        pipe(prop('field'), prop(__, fieldMapping)),
      ]),
      message: prop('message'),
    })
  )
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(SUBMIT_PERSONAL_DATA_FORM),
    rxMap(unary(toApiPersonalData)),
    switchMap((body) =>
      ajax({
        body,
        useAuth: true,
        method: 'POST',
        url: '/gateway-server/accounts/details',
      }).pipe(
        mergeMap(() =>
          of(
            submitPersonalDataFormSuccess(),
            fetchAccount(),
            navigate({ to: '/register/contact' })
          )
        ),
        catchError((e) => of(submitPersonalDataFormError(fromApiError(e))))
      )
    )
  );
