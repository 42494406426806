import React from 'react';
import PropTypes from 'prop-types';

import HospitalizationInfoCards from './cards/hospitalization';
import TakingCareInfoCards from './cards/takingCare';
import TemporaryDisabilityInfoCards from './cards/temporaryDisability';
import DisabilityInfoCards from './cards/disability';
import UnemploymentInfoCards from './cards/unemployment';

const propTypes = {
  nol: PropTypes.shape({
    riskType: PropTypes.string.isRequired,
  }).isRequired,
};
const defaultProps = {};

const NolDetailInfoTab = ({ nol }) => {
  if (nol.riskType === 'HOSPITALIZATION') return <HospitalizationInfoCards />;
  if (nol.riskType === 'TAKING_CARE') return <TakingCareInfoCards />;
  if (nol.riskType === 'TEMPORARY_DISABILITY')
    return <TemporaryDisabilityInfoCards />;
  if (nol.riskType === 'TOTAL_PERMANENT_DISABILITY')
    return <DisabilityInfoCards />;
  if (nol.riskType === 'PARTIAL_PERMANENT_DISABILITY')
    return <DisabilityInfoCards />;
  if (nol.riskType === 'INVOLUNTARY_UNEMPLOYMENT')
    return <UnemploymentInfoCards />;

  // the below case should not happen as all the known risk types were listed above...
  return null;
};

NolDetailInfoTab.propTypes = propTypes;
NolDetailInfoTab.defaultProps = defaultProps;

export default NolDetailInfoTab;
