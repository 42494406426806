import React from 'react';
import { useIntl } from 'react-intl';

import JourneyLayout from '../../modules/journeys/JourneyLayout/JourneyLayout';
import { ShieldIcon } from '../../components/icons';
import messages from './messages';
import InsuranceContracts from '../../modules/journeys/insuranceContracts';

const JourneyContractsPage = () => {
  const { formatMessage } = useIntl();

  return (
    <JourneyLayout
      step="contracts"
      heading={formatMessage(messages.stepSix_title)}
      text=""
      icon={<ShieldIcon />}
      containerVariant="smaller-content"
    >
      <InsuranceContracts />
    </JourneyLayout>
  );
};

export default JourneyContractsPage;
