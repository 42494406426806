import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'homepage_title',
    defaultMessage: 'Online řešení škodních událostí',
  },
  slogan: {
    id: 'homepage_slogan',
    defaultMessage:
      'Své škodní události můžete řešit online z pohodlí domova přes naši aplikaci.',
  },
  createClaim: {
    id: 'homepage_createClaim',
    defaultMessage: 'Nahlásit škodní událost',
  },
  createClaimThirdParty: {
    id: 'homepage_createClaimTrirdParty',
    defaultMessage: 'Nahlásit za jinou osobu',
  },
  myListOfNols: {
    id: 'homepage_myListOfNols',
    defaultMessage: 'Moje nahlášené události',
  },
  howItWorks: {
    id: 'homepage_howItWorks',
    defaultMessage: 'Jak probíhá nahlášení škodní události?',
  },
  howItWorks_one: {
    id: 'homepage_howItWorks_one',
    defaultMessage: '1. Popíšete nám, co se vám stalo',
  },
  howItWorks_two: {
    id: 'homepage_howItWorks_two',
    defaultMessage: '2. Vyhledáme smlouvy, u kterých můžete pojištění uplatnit',
  },
  howItWorks_three: {
    id: 'homepage_howItWorks_three',
    defaultMessage: '3. Dodáte nám potřebné dokumenty',
  },
  howItWorks_four: {
    id: 'homepage_howItWorks_four',
    defaultMessage: '4. Posoudíme vaši žádost',
  },
  howItWorks_step_0: {
    id: 'homepage_howItWorks_step_0',
    defaultMessage: 'Stala se vám pojistná událost',
  },
  howItWorks_step_1: {
    id: 'homepage_howItWorks_step_1',
    defaultMessage: 'Připravte si všechny potřebné doklady',
  },
  howItWorks_step_2: {
    id: 'homepage_howItWorks_step_2',
    defaultMessage:
      'Dejte nám vědět, co se stalo a k nahlášení přiložte i všechny doklady',
  },
  howItWorks_step_3: {
    id: 'homepage_howItWorks_step_3',
    defaultMessage: 'Co nejdříve vám potvrdíme registraci vaší události',
  },
  howItWorks_step_4: {
    id: 'homepage_howItWorks_step_4',
    defaultMessage: 'Dostanete naše rozhodnutí',
  },
  faqTitle: {
    id: 'homepage_faqTitle',
    defaultMessage: 'Nejčastější dotazy',
  },
});
