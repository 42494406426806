import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  button: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  hasCross: PropTypes.bool,
  bgColor: PropTypes.oneOf(['white', 'gray']),
};
const defaultProps = {
  isOpen: false,
  hasCross: false,
  bgColor: 'white',
  button: null,
};

const Modal = ({
  children,
  button,
  isOpen,
  onClose,
  onOpen,
  hasCross,
  bgColor,
}) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflowY = 'hidden';
    return () => {
      const noModals = document.getElementsByClassName('modal').length;
      const noModalsClosed =
        document.getElementsByClassName('modal hidden').length;
      if (noModals === noModalsClosed) document.body.style.overflowY = 'scroll';
    };
  }, [isOpen]);

  return (
    <>
      {!!button && (
        <button className="w-full text-start" onClick={onOpen}>
          {button}
        </button>
      )}
      <div
        className={cx(
          'modal fixed inset-0 p-2 z-50 bg-black bg-opacity-50 flex justify-center items-center',
          {
            hidden: !isOpen,
          }
        )}
      >
        <div
          className={cx(
            'relative overflow-y-auto rounded-md scrollbar px-4 py-3 sm:px-8 sm:py-6 md:px-20 md:py-16',
            {
              'bg-white': bgColor === 'white',
              'bg-cardif-gray-light': bgColor === 'gray',
            }
          )}
        >
          {hasCross && (
            <span
              className="absolute top-3 right-4 cursor-pointer text-lg text-black hover:text-cardif-gray-dark"
              onClick={onClose}
            >
              &#10005;
            </span>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
