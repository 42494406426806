import React from 'react';
// import PropTypes from 'prop-types';

import List from '../../../../../../../../components/List';
import ListItem from '../../../../../../../../components/List/Item';

const propTypes = {};
const defaultProps = {};

const BewareCard = () => (
  <List variant="unordered" type="disc">
    <ListItem>
      V sekci <span className="font-bold">Výluky</span> v podmínkách pojištění
      najdete situace, kdy nemůžeme uznat nárok na pojistné plnění.
    </ListItem>
    <ListItem>
      <span className="font-bold">
        Speciální pozornost věnujte těmto výlukám:
      </span>
      <List variant="unordered" type="circle" inList>
        <ListItem>
          k ukončení pracovního poměru došlo v průběhu zkušební doby,
        </ListItem>
        <ListItem>
          výpověď z pracovního poměru jste podal/a sám/sama ze svého vlastního
          rozhodnutí,
        </ListItem>
        <ListItem>pracovní poměr skončil uplynutím doby určité,</ListItem>
        <ListItem>
          výpověď vám dal zaměstnavatel kvůli nesplnění požadavků pro výkon
          pozice nebo porušování povinností zaměstnance.
        </ListItem>
      </List>
    </ListItem>
  </List>
);

BewareCard.propTypes = propTypes;
BewareCard.defaultProps = defaultProps;

export default BewareCard;
