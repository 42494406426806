import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  title: PropTypes.string.isRequired,
  dateOfReport: PropTypes.string.isRequired,
  docsNeeded: PropTypes.number,
  onClick: PropTypes.func,
};
const defaultProps = {
  docsNeeded: 0,
  onClick: null,
};

const EventCard = ({ title, dateOfReport, docsNeeded, onClick }) => (
  <div
    onClick={onClick}
    className={cx(
      'rounded-sm hover:shadow-faq bg-white px-5 py-4 font-cardif-regular flex flex-col sm:flex-row gap-4 justify-between items-center hover:text-cardif-green-light transition-all ease-out duration-200',
      { 'cursor-pointer': !!onClick }
    )}
  >
    <div>
      <h3 className="font-medium">{title}</h3>
      <span className="font-cardif-light text-cardif-gray-dark text-sm">
        Nahlášeno {dateOfReport}
      </span>
    </div>
    {docsNeeded ? (
      <span>
        Potřebné dokumenty{' '}
        <span className="rounded-full bg-gray-800 px-1.5 text-xs leading-3 w-4.5 h-4.5 text-white inline-flex justify-center items-center">
          {docsNeeded}
        </span>
      </span>
    ) : null}
  </div>
);

EventCard.propTypes = propTypes;
EventCard.defaultProps = defaultProps;

export default EventCard;
