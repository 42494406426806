import React from 'react';
import PropTypes from 'prop-types';

import { ColoredIcon, LoaderIcon } from '../icons';

const propTypes = {
  message: PropTypes.string,
};
const defaultProps = {
  message: 'Načítání',
};

const Loader = ({ message }) => (
  <div className="flex flex-col gap-1 items-center text-center">
    <div className="h-10 animate-spin-slow delay-100">
      <ColoredIcon icon={<LoaderIcon size={40} />} color="green" />
    </div>
    <span>{message}...</span>
  </div>
);

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
