import React from 'react';

import UpdateBankAccountModal from '../../../../../modals/updateBankAccount/updateBankAccount';
import {
  addDefaultFormatters,
  basicLocalize,
} from '../../../../../SimpleTable/fieldFormatters';

export default addDefaultFormatters([
  { name: 'partnerName' },
  { name: 'policyNumber' },
  { name: 'riskType', valueFormatter: basicLocalize },
  {
    name: 'accountNumber',
    renderValue: ({ value, data }) => (
      <>
        <b>{value} </b>
        <div className="inline-block">
          <UpdateBankAccountModal contractId={data.bankAccountUuid} />
        </div>
      </>
    ),
  },
  { name: 'nolItemId' },
]);
