import {
  always,
  applySpec,
  converge,
  identity,
  ifElse,
  pathOr,
  pipe,
  prop,
  propEq,
  propSatisfies,
  reject,
  unapply,
} from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';

export const fromApiContactData = applySpec({
  city: pathOr('', ['permanent', 'city']),
  countryCode: pathOr('', ['permanent', 'countryCode']),
  street: pathOr('', ['permanent', 'street']),
  zipCode: pathOr('', ['permanent', 'zipCode']),
  hasDifferentContactAddress: propSatisfies(isNotNilOrEmpty, ['contact']),
  contactStreet: pathOr('', ['contact', 'street']),
  contactCity: pathOr('', ['contact', 'city']),
  contactPostalCode: pathOr('', ['contact', 'zipCode']),
  contactCountry: pathOr('203', ['contact', 'countryCode']),
});

export const toApiPermanentAddress = applySpec({
  addressType: always('permanent'),
  city: prop('city'),
  zipCode: prop('zipCode'),
  street: prop('street'),
  countryCode: prop('countryCode'),
});

export const toApiContactAddress = ifElse(
  propEq('hasDifferentContactAddress', true),
  applySpec({
    addressType: always('contact'),
    street: prop('contactStreet'),
    city: prop('contactCity'),
    zipCode: prop('contactPostalCode'),
    countryCode: prop('contactCountry'),
  }),
  always(null)
);

export const toApiAddresses = pipe(
  converge(unapply(identity), [toApiPermanentAddress, toApiContactAddress]),
  reject(isNilOrEmpty)
);
