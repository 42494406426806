import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Container from '../../../components/layout/Container';
import Button from '../../../components/Button';
import Spacing from '../../../components/layout/Spacing';
import Heading from '../../../components/text/Heading';
import NoContractFoundCard from './cards/noContractFound';
import riskGroupsMessages from '../../nol/riskGroups.messages';
import ContractCard from './cards/contract';
import messages from './messages';
import {
  contextSelector,
  // currentRiskMetaSelector,
} from '../ActiveJourneyProvider/selectors';
import { insuranceContractsSelector } from './selectors';
import Loader from '../../../components/Loader/loader';
import { fetchContracts } from './actions';
import Grid from '../../../components/Grid/grid';
import { finalizeJourney } from '../ActiveJourneyProvider/actions';

const InsuranceContracts = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { bankAccount } = useSelector(contextSelector);
  const { isInitialized, isSubmitting, policies } = useSelector(
    insuranceContractsSelector
  );

  useEffect(() => {
    dispatch(fetchContracts());
  }, []);

  if (!bankAccount) return <Navigate to="../bank-account" />;
  if (!isInitialized)
    return <Loader message={formatMessage(messages.loaderText)} />;

  return (
    <Container variant="smaller-content" bgColor="gray">
      {policies.length ? (
        <>
          <Heading variant="subtitle">
            {formatMessage(messages.contractsHeading)}
          </Heading>
          <Grid gap={3}>
            {policies.map((policy) => (
              <ContractCard
                key={policy.id}
                partner={policy.partnerName}
                contractNumber={policy.contractNumber}
                risk={formatMessage(riskGroupsMessages[policy.riskName])}
              />
            ))}
          </Grid>
        </>
      ) : (
        <NoContractFoundCard />
      )}
      <Spacing variant="item" />
      {!isSubmitting && (
        <Button
          center
          onClick={() => dispatch(finalizeJourney())}
          label={formatMessage(messages.continueButtonLabel)}
        />
      )}
      {isSubmitting && <Loader message={formatMessage(messages.loaderText)} />}
    </Container>
  );
};

export default InsuranceContracts;
