import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_ActiveJourneyModal';

export default defineMessages({
  title: {
    id: `${namespace}_title`,
    defaultMessage: 'Máte rozpracované nahlášení škodní události',
  },
  message: {
    id: `${namespace}_message`,
    defaultMessage:
      'Pozor pokud začnete znovu, údaje z rozpracované žádosti budou ztraceny',
  },
  button_reset: {
    id: `${namespace}_button_reset`,
    defaultMessage: 'Začít znovu',
  },
  button_continue: {
    id: `${namespace}_button_continue`,
    defaultMessage: 'Pokračovat',
  },
});
