import {
  T,
  always,
  assocPath,
  binary,
  call,
  converge,
  curryN,
  filter,
  flip,
  head,
  ifElse,
  lensIndex,
  map,
  over,
  pathOr,
  pathSatisfies,
  pipe,
  prop,
  propOr,
  reduce,
  reject,
  split,
  toPairs,
} from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

const filterConditionalValidations = curryN(2, (values, validation) =>
  pipe(pathOr(T, [1, 'when']), flip(binary(call))(values))(validation)
);

const runSingleValidation = curryN(2, (kv, { validate, message }) =>
  ifElse(validate, always(null), always(message))(kv)
);

const runAllFieldValidations = curryN(2, (values, [key, validations]) =>
  over(
    lensIndex(1),
    pipe(map(runSingleValidation({ values, key })), reject(isNilOrEmpty), head)
  )([key, validations])
);

const errorReducer = curryN(2, (acc, [key, value]) =>
  converge(assocPath, [
    pipe(prop('key'), split('.')),
    prop('value'),
    prop('acc'),
  ])({ acc, key, value })
);

export default curryN(3, (validations, deps, values) =>
  pipe(
    toPairs,
    filter(filterConditionalValidations(values)),
    map(
      over(
        lensIndex(1),
        pipe(
          propOr([], 'validate'),
          map((r) => r.run(deps))
        )
      )
    ),
    map(runAllFieldValidations(values)),
    reject(pathSatisfies(isNilOrEmpty, [1])),
    reduce(errorReducer, {})
  )(validations)
);
