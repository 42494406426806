import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { accountSelector } from './selectors';

export const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
const defaultProps = {};

const OnlyRegistered = ({ children }) => {
  const { isLoading, isRegistered } = useSelector(accountSelector);

  if (isLoading) return null;

  if (!isRegistered) return <Navigate to="/register" replace />;

  return <>{children}</>;
};

OnlyRegistered.propTypes = propTypes;
OnlyRegistered.defaultProps = defaultProps;

export default OnlyRegistered;
