import { defineMessages } from 'react-intl';

const namespace = 'crdf_journey';

export default defineMessages({
  stepPersonal: {
    id: `${namespace}_step_Personal`,
    defaultMessage: 'Osobní informace',
  },
  stepContact: {
    id: `${namespace}_step_Contact`,
    defaultMessage: 'Adresa',
  },
  stepRiskType: {
    id: `${namespace}_step_RiskType`,
    defaultMessage: 'Výběr události',
  },
  stepRiskDetails: {
    id: `${namespace}_step_RiskDetails`,
    defaultMessage: 'Dopňující informace',
  },
  stepBankAccount: {
    id: `${namespace}_step_BankAccount`,
    defaultMessage: 'Účet pro výplatu',
  },
  stepContracts: {
    id: `${namespace}_step_Contracts`,
    defaultMessage: 'Pojistné smlouvy',
  },
  stepUploads: {
    id: `${namespace}_step_Uploads`,
    defaultMessage: 'Nahrání dokumentů',
  },
});
