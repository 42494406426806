import React from 'react';
// import PropTypes from 'prop-types';

import List from '../../../../../../../../components/List';
import ListItem from '../../../../../../../../components/List/Item';

const propTypes = {};
const defaultProps = {};

const BewareCard = () => (
  <List variant="unordered" type="disc">
    <ListItem>
      V sekci <span className="font-bold">Výluky</span> v podmínkách pojištění
      najdete situace, kdy nemůžeme nárok na pojistné plnění uznat.
    </ListItem>
    <ListItem>
      <span className="font-bold">
        Speciální pozornost věnujte těmto výlukám:
      </span>
      <List variant="unordered" type="circle" inList>
        <ListItem>
          nemoc nebo úraz, které vznikly již před sjednáním pojištění,
        </ListItem>
        <ListItem>
          onemocnění a bolesti zad, jejich následky a komplikace,
        </ListItem>
        <ListItem>psychická onemocnění.</ListItem>
      </List>
    </ListItem>
    <ListItem>
      <span className="font-bold">Podmínky pojištění</span> najdete ve
      všeobecných pojistných podmínkách a pojistné smlouvě na webu společnosti,
      u které jste si pojištění sjednal/a.
    </ListItem>
  </List>
);

BewareCard.propTypes = propTypes;
BewareCard.defaultProps = defaultProps;

export default BewareCard;
