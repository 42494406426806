import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  variant: PropTypes.oneOf(['content', 'footer', 'header', 'item', 'modal']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const defaultProps = {
  variant: 'content',
};

const getClasses = (variant) =>
  cx({
    'pt-2 pb-2 md:pt-4.5 md:pb-4': variant === 'header',
    'pt-4 pb-3 md:pt-8 md:pb-6': variant === 'footer',
    'pt-4 pb-3 px-2': variant === 'item',
    'py-4 md:py-8': variant === 'content',
    'p-4': variant === 'modal',
  });

const Spacing = ({ children, variant }) => (
  <div className={getClasses(variant)}>{children}</div>
);

Spacing.propTypes = propTypes;
Spacing.defaultProps = defaultProps;

export default Spacing;
