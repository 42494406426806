import { ofType } from 'redux-observable';
import { catchError, exhaustMap, map as rxMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { always, ifElse, pathEq, unary } from 'ramda';

import {
  FETCH_ACTIVE_JOURNEYS,
  fetchActiveJourneysSuccess,
  fetchActiveJourneysError,
} from '../../actions/context.actions';

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_ACTIVE_JOURNEYS),
    exhaustMap(() =>
      ajax({
        url: '/gateway-server/journeys',
        useAuth: true,
      }).pipe(
        rxMap((result) =>
          fetchActiveJourneysSuccess({
            activeJourneyId: result.response.journeyId,
          })
        ),
        catchError(
          unary(
            ifElse(
              pathEq(['status'], 404),
              always(of(fetchActiveJourneysSuccess({ activeJourneyId: null }))),
              unary(throwError)
            )
          )
        ),
        catchError((e) => of(fetchActiveJourneysError(e)))
      )
    )
  );
