import React from 'react';
import PropTypes from 'prop-types';

import { SuccessIcon, WarningIcon, ColoredIcon, LoaderIcon } from '../../icons';
import Paragraph from '../../text/Paragraph';

const propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'wait']),
};
const defaultProps = {
  variant: 'success',
};

const FlashCard = ({ message, variant }) => (
  <div className="bg-white max-w-full shadow-faq flex gap-4 py-6 px-8 justify-center items-center rounded-md">
    {variant === 'success' && (
      <ColoredIcon icon={<SuccessIcon size={20} />} color="green" />
    )}
    {variant === 'warning' && (
      <ColoredIcon icon={<WarningIcon />} color="orange" />
    )}
    {variant === 'error' && <ColoredIcon icon={<WarningIcon />} color="red" />}
    {variant === 'wait' && (
      <ColoredIcon
        icon={<LoaderIcon size={30} />}
        color="green"
        className="animate-spin-slow"
      />
    )}
    <Paragraph center>{message}</Paragraph>
  </div>
);

FlashCard.propTypes = propTypes;
FlashCard.defaultProps = defaultProps;

export default FlashCard;
