import { defineMessages } from 'react-intl';

const namespace = 'crdf_pages_journeys';

export default defineMessages({
  stepOne_title: {
    id: `${namespace}_stepOne_title`,
    defaultMessage: 'Uveďte vaše osobní informace',
  },
  stepOne_text: {
    id: `${namespace}_stepOne_text`,
    defaultMessage:
      'Vámi zadané údaje nám slouží pro dodatečné ověření vaší osoby.',
  },
  stepTwo_title: {
    id: `${namespace}_stepTwo_title`,
    defaultMessage: 'Uveďte vaši trvalou adresu',
  },
  stepThree_title: {
    id: `${namespace}_stepThree_title`,
    defaultMessage: 'Jakou škodní událost chcete nahlásit?',
  },
  stepFour_title: {
    id: `${namespace}_stepFour_title`,
    defaultMessage: 'Doplňující informace k události',
  },
  stepFour_text: {
    id: `${namespace}_stepFour_text`,
    defaultMessage: 'Novou škodní událost nahlásíte pro tyto pojistné smlouvy',
  },
  stepFive_title: {
    id: `${namespace}_stepFive_title`,
    defaultMessage: 'Účet pro výplatu pojistného plnění',
  },
  stepSix_title: {
    id: `${namespace}_stepSix_title`,
    defaultMessage: 'Pojistné smlouvy',
  },
});
