import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string,
  height: PropTypes.number,
  srcFileName: PropTypes.string.isRequired,
  className: PropTypes.string,
};
const defaultProps = {
  title: 'image',
  height: 40,
  className: '',
};

const Image = ({ srcFileName, title, height, className }) => (
  <img
    style={{ height }}
    src={`/img/${srcFileName}`}
    alt={title}
    title={title}
    className={`inline-block ${className}`}
  />
);

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
