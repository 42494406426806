import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import Container from '../../../components/layout/Container';
import Modal from '../../../components/Modal';
import Heading from '../../../components/text/Heading';
import { docViewSelector } from '../selectors';
import { closeDocViewModal } from '../actions';
import messages from './messages';
import DocView from './docView';
import FlashCard from '../../../components/cards/FlashCard/flash';

const propTypes = {};
const defaultProps = {};

const DocViewModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const {
    isDocReady,
    isDocLoading,
    isDocConverting,
    isDocViewOpen,
    fileWrapper,
    errorLoading,
    errorConverting,
  } = useSelector(docViewSelector);

  if (
    !fileWrapper &&
    !isDocLoading &&
    !isDocConverting &&
    !errorLoading &&
    !errorConverting
  )
    return null;

  return (
    <Modal
      onClose={() => dispatch(closeDocViewModal())}
      onOpen={() => {}}
      isOpen={isDocViewOpen}
      hasCross
    >
      <Container variant="content" extraClass="flex flex-col items-center">
        <Heading variant="modal" extraClass="mt-0 mb-6">
          {formatMessage(messages.title)}
        </Heading>
        {isDocLoading && (
          <FlashCard
            variant="wait"
            message={formatMessage(messages.fileLoading)}
          />
        )}
        {isDocConverting && (
          <FlashCard
            variant="wait"
            message={formatMessage(messages.docConverting)}
          />
        )}
        {errorLoading && (
          <FlashCard
            variant="error"
            message={formatMessage(messages.fileLoadingError)}
          />
        )}
        {errorConverting && (
          <FlashCard
            variant="error"
            message={formatMessage(messages.fileConvertingError)}
          />
        )}
        {isDocReady && fileWrapper && <DocView fileWrapper={fileWrapper} />}
      </Container>
    </Modal>
  );
};

DocViewModal.propTypes = propTypes;
DocViewModal.defaultProps = defaultProps;

export default DocViewModal;
