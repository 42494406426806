import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import CountrySelect from '../common/CountrySelect';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  variant: PropTypes.oneOf(['phone', 'country']),
};
const defaultProps = {
  variant: 'country',
  label: null,
};

const CountrySelectField = ({ name, variant, label, ...props }) => {
  const [field] = useField({ name });

  return (
    <CountrySelect
      {...props}
      name={name}
      label={label}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      variant={variant}
    />
  );
};

CountrySelectField.propTypes = propTypes;
CountrySelectField.defaultProps = defaultProps;

export default CountrySelectField;
