import { applySpec, defaultTo, map, pipe, prop } from 'ramda';
import { createSelector } from 'reselect';

export const globalDataSelector = prop('globalData');
export const bankCodesSelector = pipe(globalDataSelector, prop('bankCodes'));
export const bankCodesSelectSelector = createSelector(
  bankCodesSelector,
  pipe(
    defaultTo([]),
    map(
      applySpec({
        value: prop('code'),
        label: prop('code'),
      })
    )
  )
);
