import { handleActions } from 'redux-actions';

import {
  SUBMIT_CALL_ME_BACK,
  SUBMIT_CALL_ME_BACK_SUCCESS,
  SUBMIT_CALL_ME_BACK_ERROR,
  CALL_ME_BACK_RESET,
} from './actions';

const initialState = {
  isSubmitting: false,
  step: 'phone', // phone | weWillCall
};

export default handleActions(
  {
    [SUBMIT_CALL_ME_BACK]: (state) => ({ ...state, isSubmitting: true }),
    [SUBMIT_CALL_ME_BACK_SUCCESS]: (state) => ({
      ...state,
      isSubmitting: false,
      step: 'weWillCall',
    }),
    [SUBMIT_CALL_ME_BACK_ERROR]: (state) => ({ ...state, isSubmitting: false }),
    [CALL_ME_BACK_RESET]: () => ({ ...initialState }),
  },
  initialState
);
