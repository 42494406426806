import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { CardIcon } from '../../../components/icons';
import Container from '../../../components/layout/Container';
import Modal from '../../../components/Modal';
import Heading from '../../../components/text/Heading';
import BankAccountForm from './Form';
import messages from './messages';
import { updateBankAccount } from './actions';

const propTypes = {
  contractId: PropTypes.string.isRequired,
};
const defaultProps = {};

const UpdateBankAccountModal = ({ contractId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  return (
    <Modal
      bgColor="gray"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      isOpen={open}
      hasCross={true}
      button={
        <span className="text-cardif-green-light underline text-sm">
          {intl.formatMessage(messages.modalButton)}
        </span>
      }
    >
      <Container
        bgColor="gray"
        variant="modal"
        extraClass="max-h-[70vh] overflow-y-auto scrollbar"
      >
        <Heading variant="subtitle" icon={<CardIcon />}>
          {intl.formatMessage(messages.title)}
        </Heading>
        <BankAccountForm
          onSubmit={(values) => {
            dispatch(updateBankAccount({ contractId, data: values }));
            setOpen(false);
          }}
          onClose={() => setOpen(false)}
        />
      </Container>
    </Modal>
  );
};

UpdateBankAccountModal.propTypes = propTypes;
UpdateBankAccountModal.defaultProps = defaultProps;

export default UpdateBankAccountModal;
