import { identity, unary } from 'ramda';
import {
  distinctUntilChanged,
  filter as rxFilter,
  map as rxMap,
} from 'rxjs/operators';

import { isAuthenticatedSelector } from '../../storage/selectors';
import { fetchAccount } from '../actions';

export default (action$, state$) =>
  state$.pipe(
    rxMap(unary(isAuthenticatedSelector)),
    distinctUntilChanged(),
    rxFilter(unary(identity)),
    rxMap(() => fetchAccount())
  );
