import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'docView_title',
    defaultMessage: 'Náhled dokumentu',
  },
  messageConverted: {
    id: 'docView_messageConverted',
    defaultMessage:
      'Dokumenty MS Word a Excel byly převedeny na PDF\n - mohou se tedy zobrazovat v pozměněném formátu.',
  },
  docConverting: {
    id: 'docView_docConverting',
    defaultMessage: 'Dokument se převádí na PDF...',
  },
  fileLoading: {
    id: 'docView_fileLoading',
    defaultMessage: 'Soubor se stahuje...',
  },
  fileContentError: {
    id: 'docView_fileContentError',
    defaultMessage: 'Chybný obsah nebo struktura souboru.',
  },
  fileLoadingError: {
    id: 'docView_fileLoadingError',
    defaultMessage: 'Nepodařilo se stáhnout soubor.',
  },
  fileConvertingError: {
    id: 'docView_fileConvertingError',
    defaultMessage: 'Nepodařilo se převést soubor na PDF.',
  },
  button: {
    id: 'docView_button',
    defaultMessage: 'Zavřít náhled',
  },
});
