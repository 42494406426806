import { createAction } from 'redux-actions';

const namespace = 'crdf.registration';

export const SUBMIT_PERSONAL_DATA_FORM = `${namespace}.submitPersonalDataForm`;
export const SUBMIT_PERSONAL_DATA_FORM_SUCCESS = `${namespace}.submitPersonalDataFormSuccess`;
export const SUBMIT_PERSONAL_DATA_FORM_ERROR = `${namespace}.submitPersonalDataFormError`;

export const SUBMIT_CONTACT_DATA_FORM = `${namespace}.submitContactDataForm`;
export const SUBMIT_CONTACT_DATA_FORM_SUCCESS = `${namespace}.submitContactDataFormSuccess`;
export const SUBMIT_CONTACT_DATA_FORM_ERROR = `${namespace}.submitContactDataFormError`;

export const FINALIZE_ACCOUNT = `${namespace}.finalizeAccount`;
export const FINALIZE_ACCOUNT_SUCCESS = `${namespace}.finalizeAccountSuccess`;
export const FINALIZE_ACCOUNT_ERROR = `${namespace}.finalizeAccountError`;

export const submitPersonalDataForm = createAction(SUBMIT_PERSONAL_DATA_FORM);
export const submitPersonalDataFormSuccess = createAction(
  SUBMIT_PERSONAL_DATA_FORM_SUCCESS
);
export const submitPersonalDataFormError = createAction(
  SUBMIT_PERSONAL_DATA_FORM_ERROR
);

export const submitContactDataForm = createAction(SUBMIT_CONTACT_DATA_FORM);
export const submitContactDataFormSuccess = createAction(
  SUBMIT_CONTACT_DATA_FORM_SUCCESS
);
export const submitContactDataFormError = createAction(
  SUBMIT_CONTACT_DATA_FORM_ERROR
);

export const finalizeAccount = createAction(FINALIZE_ACCOUNT);
export const finalizeAccountSuccess = createAction(FINALIZE_ACCOUNT_SUCCESS);
export const finalizeAccountError = createAction(FINALIZE_ACCOUNT_ERROR);
