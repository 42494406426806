import { handleActions } from 'redux-actions';
import { always, ifElse, pathEq, unary } from 'ramda';

import { splitPrefix } from './utils';
import { GET_SETTINGS_SUCCESS, RESET_STATE } from '../actions';
import {
  CHANGE_PHONE,
  CHANGE_PHONE_ERROR,
  CHANGE_PHONE_SUCCESS,
  VERIFY_PHONE,
  VERIFY_PHONE_ERROR,
  VERIFY_PHONE_SUCCESS,
} from './actions';

const initialState = {
  isSubmitting: false,
  phone: null,
  hasServerError: false,
  submitSuccess: false,
  meta: {
    step: 'phone', // phone | otp,
    otpId: '',
    phoneNotValid: false,
    phoneInUse: false,
    otpError: false,
  },
  initialValues: {
    code: '',
    phonePrefix: '',
    phoneNumber: '',
  },
};

// phone errors
// code: 015 -> in use
// rest -> invalid

const getPhoneError = unary(
  ifElse(
    pathEq(['response', 'code'], '015'),
    always({ phoneInUse: true, phoneNotValid: false }),
    always({ phoneNotValid: true, phoneInUse: false })
  )
);

export default handleActions(
  {
    [RESET_STATE]: (state) => ({
      ...state,
      submitSuccess: false,
    }),
    [GET_SETTINGS_SUCCESS]: (state, { payload }) => ({
      ...state,
      hasServerError: false,
      phone: payload.phone,
      initialValues: { ...state.initialValues, ...splitPrefix(payload.phone) },
    }),
    [VERIFY_PHONE]: (state) => ({ ...state, isSubmitting: true }),
    [VERIFY_PHONE_SUCCESS]: (state, { payload }) => ({
      ...state,
      isSubmitting: false,
      hasServerError: false,
      meta: {
        ...state.meta,
        ...payload,
        step: 'otp',
        phoneNotValid: false,
        phoneInUse: false,
      },
    }),
    [VERIFY_PHONE_ERROR]: (state, { payload }) => ({
      ...state,
      isSubmitting: false,
      hasServerError: payload.status >= 500,
      meta: { ...state.meta, ...getPhoneError(payload) },
    }),
    [CHANGE_PHONE]: (state) => ({ ...state, isSubmitting: true }),
    [CHANGE_PHONE_SUCCESS]: (state, { payload }) => ({
      ...initialState,
      hasServerError: false,
      submitSuccess: true,
      phone: `+${payload.phonePrefix + payload.phoneNumber}`,
      initialValues: {
        code: '',
        phonePrefix: payload.phonePrefix,
        phoneNumber: payload.phoneNumber,
      },
    }),
    [CHANGE_PHONE_ERROR]: (state, { payload }) => ({
      ...state,
      isSubmitting: false,
      hasServerError: payload.status >= 500,
      meta: { ...state.meta, otpError: true },
    }),
  },
  initialState
);
