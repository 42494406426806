import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  switchMap,
  withLatestFrom,
  catchError,
  mergeMap,
} from 'rxjs/operators';
import { always, cond, equals, pipe } from 'ramda';

import {
  toApiHospitalization,
  toApiDisability,
  toApiTakingCare,
  toApiTempDisability,
  toApiUnemployment,
} from '../../mappers';
import {
  genericSubmit,
  genericSubmitSuccess,
  genericSubmitError,
  SUBMIT_RISK_DETAIL_FORM,
  submitRiskDetailFormSuccess,
  submitRiskDetailFormError,
} from '../../actions';
import { contextSelector } from '../../selectors';
import { riskDetailURL } from '../../constants';

const createUrl = (state) => {
  const { activeJourneyId, riskType } = contextSelector(state);

  return `/gateway-server/journeys/${activeJourneyId}/${riskDetailURL[riskType]}`;
};

const toApiRiskDetailData = (state, values) => {
  const { riskType } = contextSelector(state);

  return cond([
    [pipe(always(riskType), equals('H')), toApiHospitalization],
    [pipe(always(riskType), equals('TPD')), toApiDisability],
    [pipe(always(riskType), equals('PPD')), toApiDisability],
    [pipe(always(riskType), equals('TAC')), toApiTakingCare],
    [pipe(always(riskType), equals('TD')), toApiTempDisability],
    [pipe(always(riskType), equals('IU')), toApiUnemployment],
  ])(values);
};

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(SUBMIT_RISK_DETAIL_FORM),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      merge(
        of(genericSubmit()),
        ajax({
          useAuth: true,
          method: 'POST',
          url: createUrl(state),
          body: toApiRiskDetailData(state, payload),
        }).pipe(
          mergeMap(() =>
            of(submitRiskDetailFormSuccess(payload), genericSubmitSuccess())
          ),
          catchError((e) =>
            of(submitRiskDetailFormError(e), genericSubmitError())
          )
        )
      )
    )
  );
