import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  button: PropTypes.node.isRequired,
  origin: PropTypes.oneOf(['tr']),
  width: PropTypes.number,
  maxHeight: PropTypes.number,
  count: PropTypes.number,
  disableOnClickClose: PropTypes.bool,
};
const defaultProps = {
  origin: 'tr',
  width: 300,
  maxHeight: 500,
  count: 0,
  disableOnClickClose: false,
};

const Dropdown = ({
  children,
  button,
  origin,
  width,
  maxHeight,
  count,
  disableOnClickClose,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="relative inline-block">
        <button
          className={cx({ relative: !!count })}
          onClick={() => setOpen(!open)}
        >
          {!!count && (
            <span className="absolute top-0 -right-1 text-xs rounded-full bg-red-600 text-white px-1 flex items-center justify-center">
              {count}
            </span>
          )}
          {button}
        </button>
        <div
          style={{ maxHeight, width, maxWidth: '75vw' }}
          className={cx(
            'absolute z-20 rounded-sm shadow-faq-hover overflow-y-auto scrollbar bg-white py-1 flex flex-col',
            {
              hidden: !open,
              'right-0': origin === 'tr',
            }
          )}
        >
          <div onClick={!disableOnClickClose ? () => setOpen(false) : null}>
            {children}
          </div>
        </div>
      </div>
      <div
        className={cx('fixed inset-0 z-10', { hidden: !open })}
        onClick={() => setOpen(false)}
      />
    </>
  );
};

Dropdown.propTypes = propTypes;
Dropdown.defaultProps = defaultProps;

export default Dropdown;
