export const CLAIM_FINISHED_MODAL_ID = 'CLAIM_FINISHED_MODAL_ID';
export const DOCUMENT_UPLOAD_MODAL_ID = 'DOCUMENT_UPLOAD_MODAL_ID';
export const UPLOAD_STATUS_MODAL_ID = 'UPLOAD_STATUS_MODAL_ID';

export const ENABLED_MIME_TYPES_IMAGES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bmp',
  // 'image/gif' - removed per TFS ticket 130216
];

export const ENABLED_MIME_TYPES_PDF = ['application/pdf'];

export const ENABLED_MIME_TYPES_DOCS = [
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  // 'application/vnd.ms-excel', // xls - - removed per TFS ticket 130216 (same below)
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
  ...ENABLED_MIME_TYPES_PDF,
];

export const ENABLED_MIME_TYPES = [
  ...ENABLED_MIME_TYPES_DOCS,
  ...ENABLED_MIME_TYPES_IMAGES,
];

export const FILE_VALIDATION_STATUS_PENDING = 'FILE_VALIDATION_STATUS_PENDING';
export const FILE_VALIDATION_STATUS_ERROR = 'FILE_VALIDATION_STATUS_ERROR';
export const FILE_VALIDATION_STATUS_SUCCESS = 'FILE_VALIDATION_STATUS_SUCCESS';

export const FILE_VALIDATION_STATUS_ERROR_TYPES = {
  WRONG_MIME_TYPE: 'WRONG_MIME_TYPE',
  FILE_IS_TOO_BIG: 'FILE_IS_TOO_BIG',
  IMAGE_IS_TOO_BIG_RESIZING_IS_USELESS: 'IMAGE_IS_TOO_BIG_RESIZING_IS_USELESS',
  CANNOT_RESIZE_IMAGE: 'CANNOT_RESIZE_IMAGE',
  CANNOT_UPLOAD_FILE: 'CANNOT_UPLOAD_FILE',
  CANNOT_CONVERT_DOC: 'CANNOT_CONVERT_DOC',
};

export const FILES_VALIDATION_ERROR_TYPES = {
  SIZE_COUNT_IS_INVALID: 'sizeCountIsInvalid',
  FILE_COUNT_INVALID: 'fileCountIsInvalid',
};

export const UPLOAD_SIZE_LIMIT = 4194304; // 4 MB
export const UPLOAD_SIZE_LIMIT_SEND = 104857600; // 100Mb

export const COMPRESS_QUALITY_INITIAL = 0.9;

export const FREE_DOCUMENT_TYPE_CODE = 171;
