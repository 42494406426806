import React from 'react';
import PropTypes from 'prop-types';

import Container from './Container';
import Spacing from './Spacing';

const propTypes = {
  links: PropTypes.arrayOf(PropTypes.node).isRequired,
  logo: PropTypes.node.isRequired,
};

const Footer = ({ links, logo }) => (
  <div>
    <Container bgColor="gray" variant="layout">
      <Spacing variant="footer">
        <div className="flex flex-col md:flex-row items-center gap-4">
          {logo}
          <div className="grow flex text-sm justify-end gap-4">
            {links.map((link) => (
              <React.Fragment key={link.key}>{link}</React.Fragment>
            ))}
          </div>
        </div>
      </Spacing>
    </Container>
  </div>
);

Footer.propTypes = propTypes;

export default Footer;
