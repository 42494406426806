import { ofType } from 'redux-observable';
import { filter as rxFilter, map as rxMap, withLatestFrom } from 'rxjs';
import { pipe, prop, unary } from 'ramda';

import {
  CREATE_JOURNEY_SUCCESS,
  fetchActiveJourneys,
  RESET,
} from '../../actions';
import { isAuthenticatedSelector } from '../../../../auth/storage/selectors';

export default (action$, state$) =>
  action$.pipe(
    ofType(CREATE_JOURNEY_SUCCESS, RESET),
    withLatestFrom(state$),
    rxFilter(unary(pipe(prop(1), unary(isAuthenticatedSelector)))),
    rxMap(() => fetchActiveJourneys())
  );
