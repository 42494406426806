import { defineMessages } from 'react-intl';

const namespace = 'pages_changeEmail';

export default defineMessages({
  title: {
    id: `${namespace}_title`,
    defaultMessage: 'Potvrzení e-mailové adresy',
  },
  pending: {
    id: `${namespace}_pending`,
    defaultMessage: 'Potvrzujeme váš e-mail',
  },
  success: {
    id: `${namespace}_success`,
    defaultMessage: 'E-mail úspěšně změněn',
  },
  error: {
    id: `${namespace}_error`,
    defaultMessage: 'Nepodařilo se potvrdit nový e-mail',
  },
});
