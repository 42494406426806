import React from 'react';
import { useSearchParams } from 'react-router-dom';

import Container from '../../components/layout/Container';
import ResetPasswordForm from '../../modules/auth/resetPassword/Form';

const ResetPasswordPage = () => {
  const [params] = useSearchParams();

  return (
    <Container variant="form" bgColor="gray">
      <ResetPasswordForm pairingCode={params.get('pairingCode')} />
    </Container>
  );
};

export default ResetPasswordPage;
