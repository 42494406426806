import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import Container from '../../components/layout/Container';
import Spacing from '../../components/layout/Spacing';
import Grid from '../../components/Grid/grid';
import Span from '../../components/Grid/Span';
import TextField from '../forms/fields/textField';
import CountrySelectField from '../forms/fields/countrySelect';
import Button from '../../components/Button/button';
import messages from './messages';
import { CALL_ME_BACK_TYPES_GENERIC } from './constants';
import Paragraph from '../../components/text/Paragraph';
import validators from './validators';
import Heading from '../../components/text/Heading';
import { CallIcon, ColoredIcon } from '../../components/icons';

export const propTypes = {
  phonePrefix: PropTypes.string,
  phoneNumber: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  reason: PropTypes.oneOf(Object.keys(CALL_ME_BACK_TYPES_GENERIC)),
  isSubmitting: PropTypes.bool,
};

const defaultProps = {
  phonePrefix: '420',
  phoneNumber: '',
  isSubmitting: false,
  reason: CALL_ME_BACK_TYPES_GENERIC.LOGIN,
};

const CallMeBackForm = ({
  phoneNumber,
  phonePrefix,
  reason,
  onSubmit,
  isSubmitting,
}) => {
  const intl = useIntl();

  return (
    <>
      <Heading icon={<ColoredIcon icon={<CallIcon />} color="green" />}>
        <FormattedMessage {...messages.title} />
      </Heading>
      {reason === CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_DECLARATION && (
        <>
          <Spacing>
            <Paragraph center>
              <FormattedMessage {...messages.noPolicyFoundFragment} />
            </Paragraph>
          </Spacing>
          <Spacing>
            <Paragraph center>
              <FormattedMessage {...messages.unableToContinueFragment} />
            </Paragraph>
          </Spacing>
          <Spacing>
            <Paragraph center>
              <FormattedMessage {...messages.ifCertainFragment} />
            </Paragraph>
          </Spacing>
        </>
      )}

      {reason === CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_REGISTRATION && (
        <Spacing>
          <Paragraph center>
            <FormattedMessage {...messages.noPolicyByNameFragment} />
          </Paragraph>
        </Spacing>
      )}

      {reason === CALL_ME_BACK_TYPES_GENERIC.LOGIN && (
        <Spacing>
          <Paragraph center>
            <FormattedMessage {...messages.genericMessage} />
          </Paragraph>
        </Spacing>
      )}

      {reason !== CALL_ME_BACK_TYPES_GENERIC.LOGIN && (
        <Spacing>
          <Paragraph center>
            <FormattedMessage {...messages.weWillCallYouFragment} />
          </Paragraph>
        </Spacing>
      )}
      <Formik
        validate={validators({ intl })}
        initialValues={{ phoneNumber, phonePrefix }}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form noValidate>
            <Container bgColor="gray" variant="form">
              <Spacing>
                <Grid xs={11} gap={4}>
                  <Span xs={4}>
                    <CountrySelectField name="phonePrefix" variant="phone" />
                  </Span>
                  <Span xs={7}>
                    <TextField
                      required
                      name="phoneNumber"
                      label={intl.formatMessage(messages.formPhoneLabel)}
                    />
                  </Span>
                </Grid>
              </Spacing>
            </Container>
            <Button
              center
              disabled={!formik.isValid || isSubmitting}
              type="submit"
              label={intl.formatMessage(messages.formSubmitLabel)}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

CallMeBackForm.propTypes = propTypes;
CallMeBackForm.defaultProps = defaultProps;

export default CallMeBackForm;
