import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Error from '../Error';

const propTypes = {
  radios: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  dir: PropTypes.oneOf(['vertical', 'horizontal']),
  error: PropTypes.string,
  label: PropTypes.string,
};
const defaultProps = {
  dir: 'vertical',
  error: null,
  label: null,
};

const Radios = ({ radios, name, dir, error, label, ...rest }) => (
  <div>
    {label && <span>{label}</span>}
    <div
      className={cx('py-1 flex gap-x-3 gap-y-4', {
        'flex-col': dir === 'vertical',
        'flex-row': dir === 'horizontal',
      })}
    >
      {radios.map((radio) => (
        <label className="flex items-center cursor-pointer" key={radio.value}>
          <input
            {...rest}
            type="radio"
            name={name}
            value={radio.value}
            checked={radio.value === rest.value}
            className="pointer-events-none relative before:absolute before:content-[''] before:inset-0 before:w-2.5 before:h-2.5 before:rounded-full before:bg-transparent before:checked:bg-cardif-green-light before:checked:hover:bg-cardif-green-dark checked:hover:ring-cardif-green-dark mr-2.5 border-3 checked:bg-none checked:bg-transparent checked:focus:bg-transparent checked:hover:bg-transparent ring-2 focus:ring-offset-0 ring-offset-0 ring-gray-200 checked:ring-cardif-green-light focus:ring-cardif-green-light border-transparent w-4 h-4 outline-none focus:outline-none"
          />
          {radio.label}
        </label>
      ))}
    </div>
    <Error error={error} />
  </div>
);

Radios.propTypes = propTypes;
Radios.defaultProps = defaultProps;

export default Radios;
