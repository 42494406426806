import { always, pipe, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  switchMap,
  withLatestFrom,
  map as rxMap,
  catchError,
  mergeMap,
} from 'rxjs/operators';

import { authorizationHeaderSelector } from '../../auth/storage/selectors';
import {
  changeNotificationsError,
  changeNotificationsSuccess,
  CHANGE_NOTIFICATIONS,
  getSettings,
} from '../actions';

const createParser = (payload) =>
  pipe(always(payload), unary(changeNotificationsSuccess));

export default (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_NOTIFICATIONS),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      ajax({
        url: '/gateway-server/accounts/settings/notification',
        method: 'PUT',
        body: payload,
        headers: {
          authorization: authorizationHeaderSelector(state),
        },
      }).pipe(
        rxMap(createParser(payload)),
        catchError((e) => of(changeNotificationsError(e))),
        mergeMap((a) => of(a, getSettings()))
      )
    )
  );
