import { combineEpics } from 'redux-observable';

import loginEpics from './login/epics';
import storageEpics from './storage/epics';
import accountEpics from './account/epics';
import resetPasswordEpics from './resetPassword/epics';
import inactivityEpic from './inactivity/epics';

export default combineEpics(
  loginEpics,
  storageEpics,
  accountEpics,
  resetPasswordEpics,
  inactivityEpic
);
