import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '../../../../../components/Grid/grid';
import Spacing from '../../../../../components/layout/Spacing';
import Button from '../../../../../components/Button/button';
import DatePickerField from '../../../../forms/fields/datePicker';
import CheckboxField from '../../../../forms/fields/checkbox';
import SelectField from '../../../../forms/fields/select';
import Paragraph from '../../../../../components/text/Paragraph/paragraph';
import TextAreaField from '../../../../forms/fields/textarea';
import {
  createLevelsOfDisabilityOptions,
  createReportReasonOptions,
} from '../common/createLocalizedSelectValues';
import commonMessages from '../common/messages';
import {
  formMount,
  formUnmount,
  submitRiskDetailForm,
} from '../../../ActiveJourneyProvider/actions';
import {
  contextSelector,
  formSelector,
  riskDetailsSelector,
} from '../../../ActiveJourneyProvider/selectors';
import messages from './messages';
import validate from './validators';

const TDForm = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isInitialized } = useSelector(contextSelector);
  const riskDetails = useSelector(riskDetailsSelector);
  const { isSubmitting } = useSelector(formSelector);

  const reasonOpts = useMemo(() => createReportReasonOptions(intl), [intl]);
  const levelOfDisabilityOptions = useMemo(
    () => createLevelsOfDisabilityOptions(intl),
    [intl]
  );

  useEffect(() => {
    dispatch(formMount());

    return () => dispatch(formUnmount());
  }, []);

  if (!isInitialized) return null;

  return (
    <Formik
      validate={validate({ intl })}
      validateOnBlur
      validateOnChange
      initialValues={riskDetails}
      onSubmit={(values) => dispatch(submitRiskDetailForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <Grid gap={8}>
            <DatePickerField
              name="temporaryDisabilityFrom"
              label={formatMessage(messages.sinceLabel)}
              helperText={formatMessage(
                messages.workDisability_sinceHelperText
              )}
            />
            <CheckboxField
              name="hasEnded"
              label={formatMessage(messages.hasUntilLabel)}
            />
            {formik.values.hasEnded && (
              <DatePickerField
                name="temporaryDisabilityTo"
                label={formatMessage(messages.untilLabel)}
                helperText={formatMessage(
                  messages.workDisability_untilHelperText
                )}
              />
            )}
            <TextAreaField
              rows={5}
              name="description"
              label={formatMessage(messages.descriptionLabel)}
              helperText={formatMessage(
                messages.workDisability_illnessDescriptionHelperText
              )}
            />
            <SelectField
              name="reportedIncident"
              options={reasonOpts}
              label={formatMessage(messages.reasonLabel)}
            />
            <Paragraph center bold>
              {formatMessage(messages.examinatedHeading)}
            </Paragraph>
            <CheckboxField
              name="undergoneSpecialistExamination"
              label={formatMessage(messages.undergoneSpecialExamLabel)}
            />
            <CheckboxField
              name="undergoneImagingExamination"
              label={formatMessage(messages.undergoneImagingExamLabel)}
            />
            <CheckboxField
              name="undergoneSurgeryOrHospitalised"
              label={formatMessage(
                messages.undergoneSurgeryOrHospitalisedLabel
              )}
            />
            <div className="pt-4 mb-4 mx-20 border-b-2 border-cardif-gray-400 border-dashed" />
            <CheckboxField
              name="accidentAtWork"
              label={formatMessage(messages.workAccidentLabel)}
            />
            <CheckboxField
              name="inRetirement"
              label={formatMessage(messages.inRetirementLabel)}
            />
            {formik.values.inRetirement && (
              <DatePickerField
                name="retirementPensionFrom"
                label={formatMessage(messages.sinceLabel)}
              />
            )}
            <CheckboxField
              name="isInvalid"
              label={formatMessage(messages.isInvalidLabel)}
            />
            {formik.values.isInvalid && (
              <>
                <SelectField
                  name="levelOfDisability"
                  options={levelOfDisabilityOptions}
                  label={formatMessage(messages.levelOfDisabilityLabel)}
                />
                <DatePickerField
                  name="disabilityFrom"
                  label={formatMessage(messages.sinceLabel)}
                  helperText={formatMessage(
                    messages.workDisability_disabilitySinceHelperText
                  )}
                />
                <CheckboxField
                  name="hasDisabilityUntil"
                  label={formatMessage(messages.hasUntilLabel)}
                />
                {formik.values.hasDisabilityUntil && (
                  <DatePickerField
                    name="disabilityTo"
                    label={formatMessage(messages.untilLabel)}
                  />
                )}
              </>
            )}
            <CheckboxField
              name="investigatedByPolice"
              label={formatMessage(messages.investigatedByPoliceLabel)}
            />
            {formik.values.investigatedByPolice && (
              <TextAreaField
                rows={5}
                name="policeInvestigation"
                label={formatMessage(messages.descriptionLabel)}
                helperText={formatMessage(
                  messages.workDisability_descriptionHelperText
                )}
              />
            )}
          </Grid>
          <Spacing variant="item" />
          <Grid xs={2} gap={4}>
            <Button
              full
              variant="gray"
              onClick={() => navigate('../risk-type')}
              label={formatMessage(commonMessages.stepFour_backButtonLabel)}
            />
            <Button
              full
              disabled={isSubmitting}
              type="submit"
              label={formatMessage(commonMessages.stepFour_continueButtonLabel)}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default TDForm;
