import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';

import TextGroup from '../../components/text/Group/group';

export const propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      nameFormatter: PropTypes.func.isRequired,
      valueFormatter: PropTypes.func.isRequired,
      renderValue: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.shape().isRequired,
  messages: PropTypes.shape().isRequired,
};
const defaultProps = {};

const SimpleTable = ({ rows, data, messages }) => {
  const intl = useIntl();

  const computedRows = useMemo(
    () =>
      rows
        .map((row) => ({
          renderValue: row.renderValue,
          name: row.nameFormatter(row.name).run({ intl, messages }),
          value: isNilOrEmpty(data[row.name])
            ? null
            : row.valueFormatter(data[row.name]).run({ intl, messages }),
        }))
        .filter(({ value }) => isNotNilOrEmpty(value)),
    [rows, data, messages]
  );

  return (
    <>
      {computedRows.map((row, i) => (
        <TextGroup
          key={i}
          name={row.name}
          value={row.value}
          renderValue={row.renderValue}
          data={data}
        />
      ))}
    </>
  );
};

SimpleTable.propTypes = propTypes;
SimpleTable.defaultProps = defaultProps;

export default SimpleTable;
