import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf(['title', 'subtitle', 'modal']),
  icon: PropTypes.node,
  uppercase: PropTypes.bool,
  extraClass: PropTypes.string,
};
const defaultProps = {
  variant: 'title',
  icon: null,
  uppercase: false,
  extraClass: '',
};

const getClass = (variant, uppercase, extraClass) =>
  cx(
    'text-center my-5',
    {
      'font-cardif-cond text-5xl font-normal text-cardif-gray-dark':
        variant === 'title',
      'font-cardif-light text-3xl text-gray-600': variant === 'subtitle',
      'font-cardif-light text-3xl text-cardif-green-light': variant === 'modal',
      uppercase,
    },
    extraClass
  );

const Heading = ({ children, variant, icon, uppercase, extraClass }) => {
  const Component = variant === 'title' ? 'h1' : 'h2';

  return (
    <Component className={getClass(variant, uppercase, extraClass)}>
      <span className="mb-5 block">{icon}</span>
      {children}
    </Component>
  );
};

Heading.propTypes = propTypes;
Heading.defaultProps = defaultProps;

export default Heading;
