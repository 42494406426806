import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};
const defaultProps = {
  alt: 'list image',
};

const ListImage = ({ src, alt }) => (
  <img src={src} className="w-15 h-15" alt={alt} />
);

ListImage.propTypes = propTypes;
ListImage.defaultProps = defaultProps;

export default ListImage;
