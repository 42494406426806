import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { STEPS } from './constants';
import Container from '../../../components/layout/Container';
import Heading from '../../../components/text/Heading/heading';
import Paragraph from '../../../components/text/Paragraph/paragraph';
import Spacing from '../../../components/layout/Spacing';
import ProgressBar from '../../../components/ProgressBar/progressBar';
import messages from './messages';

export const propTypes = {
  step: PropTypes.oneOf(Object.keys(STEPS)).isRequired,
  heading: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  containerVariant: PropTypes.string,
};
const defaultProps = {
  containerVariant: 'form',
};

const JourneyLayout = ({
  step,
  heading,
  text,
  icon,
  containerVariant,
  children,
}) => {
  const { formatMessage } = useIntl();

  const arrMessages = Object.entries(messages).map(([, obj]) =>
    formatMessage(obj)
  );

  return (
    <>
      <div className="w-full py-5 m-4 xsm:mt-20 xsm:mb-14 sm:mt-6 md:mt-4 max-w-xl md:max-w-xl lg:max-w-2xl xl:max-w-3xl mx-auto">
        <ProgressBar
          step={STEPS[step]}
          stepsTotal={Object.keys(STEPS).length}
          stepsTexts={arrMessages}
          stepNumber={false}
          textPlaces="xsm:cross sm:bottom"
          textClasses="text-xs font-bold"
        />
      </div>
      <Container bgColor="gray">
        <Heading icon={icon}>{heading}</Heading>
      </Container>
      <Container bgColor="gray" variant={containerVariant}>
        {!!text && (
          <>
            <Paragraph center color="gray">
              {text}
            </Paragraph>
            <Spacing variant="item" />
          </>
        )}
        {children}
      </Container>
    </>
  );
};

JourneyLayout.propTypes = propTypes;
JourneyLayout.defaultProps = defaultProps;

export default JourneyLayout;
