import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/Button';
import Grid from '../../../components/Grid';
import Container from '../../../components/layout/Container';
import Spacing from '../../../components/layout/Spacing';
import Modal from '../../../components/Modal';
import Heading from '../../../components/text/Heading';
import Paragraph from '../../../components/text/Paragraph';
import messages from './messages';
import { activeJourneyModalSelector } from './selectors';
import { closeUnfinishedModal } from './actions';

const ActiveJourneyUnfinishedModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isOpen, onNextAction, onDismissAction } = useSelector(
    activeJourneyModalSelector
  );

  return (
    <Modal
      onClose={() => dispatch(closeUnfinishedModal())}
      onOpen={() => {}}
      isOpen={isOpen}
    >
      <Container variant="modal">
        <Heading variant="modal">{intl.formatMessage(messages.title)}</Heading>
        <Paragraph center>{intl.formatMessage(messages.message)}</Paragraph>
        <Spacing>
          <Grid xs={2} gap={4}>
            <Button
              center
              full
              variant="gray"
              onClick={() => {
                if (onDismissAction) dispatch(onDismissAction);

                dispatch(closeUnfinishedModal());
              }}
              label={intl.formatMessage(messages.button_reset)}
            />
            <Button
              center
              full
              onClick={() => {
                if (onNextAction) dispatch(onNextAction);
                dispatch(closeUnfinishedModal());
              }}
              label={intl.formatMessage(messages.button_continue)}
            />
          </Grid>
        </Spacing>
      </Container>
    </Modal>
  );
};

export default ActiveJourneyUnfinishedModal;
