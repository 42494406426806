import { combineEpics } from 'redux-observable';

import closeAfterUploadEpic from './closeAfterUpload.epic';
import feedbackModalEpic from './feedbackModal.epic';
import refetchNolDetailEpic from './refetchNolDetail.epic';
import uploadFilesEpic from './uploadFiles.epic';

export default combineEpics(
  uploadFilesEpic,
  refetchNolDetailEpic,
  feedbackModalEpic,
  closeAfterUploadEpic
);
