/* eslint-disable no-new */
import Compressor from 'compressorjs';

import {
  ENABLED_MIME_TYPES,
  ENABLED_MIME_TYPES_PDF,
  ENABLED_MIME_TYPES_IMAGES,
  FILE_VALIDATION_STATUS_ERROR,
  FILE_VALIDATION_STATUS_SUCCESS,
  FILE_VALIDATION_STATUS_PENDING,
  FILE_VALIDATION_STATUS_ERROR_TYPES,
  UPLOAD_SIZE_LIMIT,
  COMPRESS_QUALITY_INITIAL,
} from './constants';

export const isImage = (fw) =>
  ENABLED_MIME_TYPES_IMAGES.indexOf(fw.file.type) > -1;

export const isPdf = (fw) => ENABLED_MIME_TYPES_PDF.indexOf(fw.file.type) > -1;

class FileWrapper {
  constructor({ file, authToken, setLastFileValidationTime, bProcess = true }) {
    this.id = Math.random().toString(36).substring(7);
    this.file = file;
    this.status = FILE_VALIDATION_STATUS_PENDING;
    this.error = null;

    this.setLastFileValidationTime = setLastFileValidationTime;
    this.quality = COMPRESS_QUALITY_INITIAL;

    this.authToken = authToken;
    this.bProcess = bProcess;
    this.validateFile();
  }

  validateFile() {
    if (!this.hasEnabledType()) {
      this.setErrorStatus(FILE_VALIDATION_STATUS_ERROR_TYPES.WRONG_MIME_TYPE);
      return;
    }

    if (this.file.size >= UPLOAD_SIZE_LIMIT && !this.isImage()) {
      this.setErrorStatus(FILE_VALIDATION_STATUS_ERROR_TYPES.FILE_IS_TOO_BIG);
      return;
    }

    if (
      this.file.status !== FILE_VALIDATION_STATUS_SUCCESS &&
      this.file.status === FILE_VALIDATION_STATUS_PENDING
    ) {
      this.setErrorStatus(
        FILE_VALIDATION_STATUS_ERROR_TYPES.CANNOT_UPLOAD_FILE
      );
      return;
    }

    if (this.bProcess) {
      this.status = FILE_VALIDATION_STATUS_PENDING;
      if (this.isPdf()) this.setSuccessStatus(); // - can preview directly
      else if (this.isImage()) this.compressImage(); // - can preview directly
      else this.convertFile(); // is one of doc/docx/xls/xlsx/csv - will convert to pdf to preview
    } else this.setSuccessStatus();
  }

  convertFile() {
    const formData = new FormData();
    const blob = new Blob([this.file], { type: this.file.type });
    const actualFile = new File([blob], this.file.name, {
      type: this.file.type,
    });
    formData.append('file', actualFile);

    const reqOptions = {
      method: 'POST',
      headers: { Authorization: this.authToken },
      body: formData,
    };

    fetch('/gateway-server/documents/convert', reqOptions)
      .then((response) => {
        if (response.ok) {
          return response.blob();
        }
        throw new Error({
          error: response.status,
          errorText: response.statusText,
        });
      })
      .then(
        (myBlob) => {
          this.fileView = myBlob;
          this.setSuccessStatus();
        },
        // eslint-disable-next-line no-unused-vars
        (error) => {
          this.setErrorStatus(
            FILE_VALIDATION_STATUS_ERROR_TYPES.CANNOT_CONVERT_DOC
          );
        }
      );
  }

  compressImage() {
    if (this.quality < 0.3) {
      this.setErrorStatus(
        FILE_VALIDATION_STATUS_ERROR_TYPES.IMAGE_IS_TOO_BIG_RESIZING_IS_USELESS
      );
      return;
    }

    new Compressor(this.file, {
      quality: this.quality,
      convertSize: 10,
      mimeType: 'image/jpeg',
      success: (result) => {
        if (result.size >= UPLOAD_SIZE_LIMIT) {
          this.quality -= 0.1;
          this.compressImage();
          return;
        }
        this.file = result;
        this.setSuccessStatus();
      },
      error: () => {
        this.setErrorStatus(
          FILE_VALIDATION_STATUS_ERROR_TYPES.CANNOT_RESIZE_IMAGE
        );
      },
    });
  }

  hasEnabledType() {
    return ENABLED_MIME_TYPES.indexOf(this.file.type) > -1;
  }

  isImage() {
    return ENABLED_MIME_TYPES_IMAGES.indexOf(this.file.type) > -1;
  }

  isPdf() {
    return ENABLED_MIME_TYPES_PDF.indexOf(this.file.type) > -1;
  }

  setSuccessStatus() {
    this.status = FILE_VALIDATION_STATUS_SUCCESS;
    this.setLastFileValidationTime();
  }

  setErrorStatus(errorType) {
    this.status = FILE_VALIDATION_STATUS_ERROR;
    this.error = { type: errorType };
    this.setLastFileValidationTime();
  }
}

export default FileWrapper;
