import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/Button';
import { SuccessIcon } from '../../../components/icons';
import Container from '../../../components/layout/Container';
import Spacing from '../../../components/layout/Spacing';
import Modal from '../../../components/Modal';
import Heading from '../../../components/text/Heading';
import messages from './messages';
import { closeNolCreatedModal } from './actions';
import { nolCreatedModalSelector } from './selectors';

const NolCreatedModal = () => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(nolCreatedModalSelector);
  const intl = useIntl();

  return (
    <Modal
      onClose={() => dispatch(closeNolCreatedModal())}
      onOpen={() => {}}
      isOpen={isOpen}
    >
      <Container variant="modal">
        <Heading variant="modal" icon={<SuccessIcon />}>
          {intl.formatMessage(messages.title)}
        </Heading>
        <Spacing>
          <Button
            center
            onClick={() => dispatch(closeNolCreatedModal())}
            label={intl.formatMessage(messages.button)}
          />
        </Spacing>
      </Container>
    </Modal>
  );
};

export default NolCreatedModal;
