import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  icon: PropTypes.node,
  type: PropTypes.string,
  extraClass: PropTypes.string,
  disablePadding: PropTypes.bool,
};
const defaultProps = {
  icon: null,
  disablePadding: false,
};

const ListItem = ({ children, icon, type, disablePadding, extraClass }) => (
  <div className={cx({ 'flex items-start gap-8': !!icon })}>
    {icon}
    <li
      className={cx('ml-4 pr-4 grow w-full', {
        'py-3': !disablePadding,
        'py-0 pt-1 ml-6 sm:ml-10 w-11/12': type === 'faq',
        [extraClass]: !!extraClass,
      })}
    >
      {children}
    </li>
  </div>
);

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
