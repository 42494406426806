import { handleActions } from 'redux-actions';

import { CLEAR_TOKENS } from '../storage/actions';
import { PROLONG_SESSION, TRIGGER_INACTIVITY_TIMER } from './actions';

const initialState = {
  inactivityPopupActive: false,
};

export default handleActions(
  {
    [TRIGGER_INACTIVITY_TIMER]: (state) => ({
      ...state,
      inactivityPopupActive: true,
    }),
    [PROLONG_SESSION]: (state) => ({
      ...state,
      inactivityPopupActive: false,
    }),
    [CLEAR_TOKENS]: (state) => ({
      ...state,
      inactivityPopupActive: false,
    }),
  },
  initialState
);
