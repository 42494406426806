import { handleActions } from 'redux-actions';

import { OPEN_UNFINISHED_MODAL, CLOSE_UNFINISHED_MODAL } from './actions';

const initialState = {
  isOpen: false,
  onNextAction: null,
  onDismissAction: null,
};

export default handleActions(
  {
    [OPEN_UNFINISHED_MODAL]: (
      state,
      { payload: { onNextAction, onDismissAction } }
    ) => ({
      isOpen: true,
      onNextAction,
      onDismissAction,
    }),
    [CLOSE_UNFINISHED_MODAL]: () => ({ ...initialState }),
  },
  initialState
);
