import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Spacing from '../../../../../../components/layout/Spacing';
import Grid from '../../../../../../components/Grid';
import Link from '../../../../../../components/Link';
import Upload from '../../../../../upload';
import { nolUnmetRequirementsSelector } from '../../../selectors';
import findMessageByCode from './findMessageByCode';
import messages from './messages';

const propTypes = {};
const defaultProps = {};

const NolDetailUploadTab = () => {
  const { formatMessage } = useIntl();
  const unmetRequirements = useSelector(nolUnmetRequirementsSelector);

  return (
    <Spacing>
      <Grid gap={2}>
        {unmetRequirements.map((ur) => (
          <Upload
            key={ur.requirementId}
            requirementId={ur.requirementId}
            title={ur.documentType.name}
            info={
              <p>
                {formatMessage(findMessageByCode(ur.documentType.code))}{' '}
                {ur.templateName && (
                  <Link
                    download
                    onClick={(e) => e.stopPropagation()}
                    to={ur.templateName}
                    label={formatMessage(messages.downloadTemplate)}
                  />
                )}
              </p>
            }
          />
        ))}
      </Grid>
    </Spacing>
  );
};

NolDetailUploadTab.propTypes = propTypes;
NolDetailUploadTab.defaultProps = defaultProps;

export default NolDetailUploadTab;
