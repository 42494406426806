/* eslint-disable import/prefer-default-export */
import {
  F,
  T,
  always,
  applySpec,
  cond,
  groupBy,
  map,
  prop,
  propEq,
} from 'ramda';

import countries from './countries.json';
import countriesByNumber from './countriesByNumber.json';

const getCountryLabel = (country, selectedCountry) => {
  if (country.unselectable) return '-';
  if (country.callingCode === selectedCountry) return `+${country.callingCode}`;

  return `+${country.callingCode} (${country.name})`;
};

export const getCountriesPhones = (selectedCountry) =>
  countries.map((country) => ({
    label: getCountryLabel(country, selectedCountry),
    value: country.callingCode,
    unselectable: country.unselectable,
  }));

export const getCountries = () =>
  countries.map((country) => ({
    label: country.unselectable ? '-' : country.name,
    value: country.name,
    unselectable: country.unselectable,
  }));

const getPriorityByCode = cond([
  [propEq('value', '203'), always(1)],
  [propEq('value', '703'), always(2)],
  [T, always(3)],
]);

export const getCountriesWithNumbers = () => {
  const remapped = map(
    applySpec({
      label: prop('nameCurrentValue'),
      value: prop('number'),
      unselectable: F,
    }),
    countriesByNumber
  );
  const grouped = groupBy(getPriorityByCode, remapped);
  const unselectable = {
    label: '-',
    value: '',
    unselectable: true,
  };

  return [...grouped[1], ...grouped[2], unselectable, ...grouped[3]];
};

export const getFlagByCallingCode = (code) => {
  const selectedCountry = countries.find(
    (country) => country.callingCode === code
  ) || { code: '' };

  return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/flags/4x3/${selectedCountry.code.toLowerCase()}.svg`;
};
