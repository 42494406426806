import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { authorizationHeaderSelector } from '../auth/storage/selectors';
import UploadDocumentModal from './modal/uploadDocument';
import {
  allDocumentsValid,
  allDocumentsValidated,
  createFWrappers,
  deleteFWrapperUtil,
} from './utils';
import DropZone from './dropzone';

const propTypes = {
  requirementId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.node,
};
const defaultProps = {
  info: null,
};

const Upload = ({ title, info, requirementId }) => {
  const [fWrappers, setFWrappers] = useState([]);
  const [docsValidation, setDocsValidation] = useState({});
  const [uTime, setUTime] = useState(0);

  const authToken = useSelector(authorizationHeaderSelector);

  useEffect(() => {
    if (fWrappers.length) {
      setDocsValidation({
        allDocsValid: allDocumentsValid(fWrappers),
        allDocsValidated: allDocumentsValidated(fWrappers),
      });
    } else {
      setDocsValidation({});
    }
  }, [uTime, fWrappers]);

  const onDrop = useCallback(
    (fileToUpload) => {
      const fWrappersNew = createFWrappers(fileToUpload, authToken, () =>
        setUTime(new Date().getTime())
      );
      setFWrappers(fWrappers.concat(fWrappersNew));
    },
    [fWrappers]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const deleteFWrapper = useCallback(
    (id) => setFWrappers(deleteFWrapperUtil(fWrappers, id)),
    [fWrappers]
  );

  const clearFiles = useCallback(() => {
    setFWrappers([]);
  }, []);

  return (
    <>
      <DropZone
        title={title}
        info={info}
        rootProps={{ ...getRootProps() }}
        inputProps={{ ...getInputProps() }}
      />
      <UploadDocumentModal
        requirementId={requirementId}
        heading={title}
        fWrappers={fWrappers}
        deleteFile={deleteFWrapper}
        clearFiles={clearFiles}
        onUpload={onDrop}
        docsValidation={docsValidation}
        rootProps={{ ...getRootProps() }}
        inputProps={{ ...getInputProps() }}
      />
    </>
  );
};

Upload.propTypes = propTypes;
Upload.defaultProps = defaultProps;

export default Upload;
