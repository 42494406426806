import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form } from 'formik';
import { isNilOrEmpty } from 'ramda-adjunct';

import Button from '../../../components/Button';
import Span from '../../../components/Grid/Span';
import Grid from '../../../components/Grid';
import Spacing from '../../../components/layout/Spacing';
import TextField from '../../forms/fields/textField';
import CountrySelectField from '../../forms/fields/countrySelect';
import messages from './messages';
import commonMessages from '../../i18n/common.messages';
import DropCard from '../../../components/cards/DropCard/drop';
import Paragraph from '../../../components/text/Paragraph/paragraph';
import Container from '../../../components/layout/Container';
import { phoneNumberFormSelector } from '../selectors';
import { deinitForm, initForm, updateForm } from './actions';
import Link from '../../../components/Link/link';
import FlashCard from '../../../components/cards/FlashCard';

// TODO: handle OTP RESEND
// TODO: show flash messages

const PhoneNumberForm = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    isSubmitting,
    phone,
    initialValues,
    meta,
    hasServerError,
    submitSuccess,
  } = useSelector(phoneNumberFormSelector);

  const [messageObj, setMessageObj] = useState({
    variant: 'warning',
    message: '',
  });

  useEffect(() => {
    dispatch(initForm());

    return () => dispatch(deinitForm());
  }, []);

  useEffect(() => {
    if (submitSuccess) {
      setMessageObj({
        variant: 'success',
        message: formatMessage(messages.savePhoneSuccess),
      });
    }

    if (hasServerError) {
      setMessageObj({
        variant: 'warning',
        message: formatMessage(commonMessages.backendInternalServerError),
      });
    }
  }, [submitSuccess, hasServerError]);

  const getLocalizedPhoneNumberError = () => {
    if (meta.phoneInUse) return formatMessage(messages.phoneInUseError);
    if (meta.phoneNotValid) return formatMessage(messages.phoneNotValidError);

    return null;
  };

  return (
    <DropCard title={formatMessage(messages.cardTitle)}>
      <Paragraph center color="gray">
        {formatMessage(messages.cardText)}
      </Paragraph>
      <Spacing>
        <Container variant="form" bgColor="gray">
          <Formik
            key={phone}
            initialValues={initialValues}
            onSubmit={(values) => dispatch(updateForm(values))}
          >
            {(formik) => (
              <Form noValidate>
                {(hasServerError || submitSuccess) && (
                  <FlashCard
                    variant={messageObj.variant}
                    message={messageObj.message}
                  />
                )}
                <Spacing variant="item" />
                <Grid xs={11} gap={4}>
                  <Span xs={4}>
                    <CountrySelectField
                      disabled={meta.step !== 'phone'}
                      name="phonePrefix"
                      variant="phone"
                    />
                  </Span>
                  <Span xs={7}>
                    <TextField
                      required
                      error={getLocalizedPhoneNumberError()}
                      disabled={meta.step !== 'phone'}
                      name="phoneNumber"
                      type="number"
                      label={formatMessage(messages.phoneNumberLabel)}
                    />
                  </Span>
                </Grid>
                <Spacing variant="item" />
                {meta.step === 'otp' && (
                  <>
                    <Span xs={3}>
                      <TextField
                        required
                        name="code"
                        error={
                          meta.otpError
                            ? formatMessage(messages.otpNotValidError)
                            : null
                        }
                        label={formatMessage(messages.otpLabel)}
                      />
                      <Paragraph variant="caption">
                        <FormattedMessage {...messages.otpSent} />{' '}
                        <Link
                          label={formatMessage(messages.resendOtpLink)}
                          to="#"
                          size="xs"
                        />
                      </Paragraph>
                    </Span>
                    <Spacing variant="item" />
                  </>
                )}
                <Button
                  center
                  type="submit"
                  disabled={
                    isSubmitting ||
                    isNilOrEmpty(formik.values.phoneNumber) ||
                    formik.values.phoneNumber.toString() ===
                      initialValues.phoneNumber
                  }
                  label={formatMessage(messages.saveButtonLabel)}
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Spacing>
    </DropCard>
  );
};

export default PhoneNumberForm;
