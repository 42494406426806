import { defineMessages } from 'react-intl';

export default defineMessages({
  deliveredAt: {
    id: 'notifications_deliveredAt',
    defaultMessage: 'Doručeno {date} v {time}',
  },
  readButtonLabel: {
    id: 'notifications_readButtonLabel',
    defaultMessage: 'Ano, přečetl jsem',
  },
  remindMeLaterButtonLabel: {
    id: 'notifications_remindMeLaterButtonLabel',
    defaultMessage: 'Připomenout později',
  },
  attachementsLabel: {
    id: 'notifications_attachementsLabel',
    defaultMessage: 'Přílohy',
  },
});
