import { handleActions } from 'redux-actions';

import { FETCH_BANK_CODES_SUCCESS } from './actions';

const initialState = {
  bankCodes: null,
};

export default handleActions(
  {
    [FETCH_BANK_CODES_SUCCESS]: (state, { payload }) => ({
      ...state,
      bankCodes: payload,
    }),
  },
  initialState
);
