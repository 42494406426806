import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_validationMessages';

export default defineMessages({
  isDivisibleBy: {
    id: `${namespace}_isDivisibleBy`,
    defaultMessage: 'Číslo musí být dělitelné {divider}',
  },
  isEmail: {
    id: `${namespace}_isEmail`,
    defaultMessage: 'Neplatný e-mail',
  },
  isInteger: {
    id: `${namespace}_isInteger`,
    defaultMessage: 'Neplatné číslo',
  },
  hasLengthInInterval: {
    id: `${namespace}_hasLengthInInterval`,
    defaultMessage: 'Špatný počet znaků ({min} do {max})',
  },
  hasValueInInterval: {
    id: `${namespace}_hasValueInInterval`,
    defaultMessage: 'Hodnota musí být mezi {min} a {max}',
  },
  isNumber: {
    id: `${namespace}_isNumber`,
    defaultMessage: 'Hodnota musí být číslo',
  },
  isPhoneNumber: {
    id: `${namespace}_isPhoneNumber`,
    defaultMessage: 'Neplatné telefonní číslo',
  },
  isPositiveNumber: {
    id: `${namespace}_isPositiveNumber`,
    defaultMessage: 'Hodnota musí být kladné číslo',
  },
  isRequired: {
    id: `${namespace}_isRequired`,
    defaultMessage: 'Toto pole je povinné',
  },
  hasLengthMax: {
    id: `${namespace}_hasLengthMax`,
    defaultMessage: 'Překročen maximální počet znaků ({max})',
  },
  hasLengthMin: {
    id: `${namespace}_hasLengthMin`,
    defaultMessage: 'Minimální poček znaků je {min}',
  },
  hasValueMin: {
    id: `${namespace}_hasValueMin`,
    defaultMessage: 'Minimální možná hodnota je {min}',
  },
  hasValueMax: {
    id: `${namespace}_hasValueMax`,
    defaultMessage: 'Maximální možná hodnota je {max}',
  },
  hasOnlyDigits: {
    id: `${namespace}_hasOnlyDigits`,
    defaultMessage: 'Musí obsahovat pouze číslice',
  },
  hasNoSpecialSymbols: {
    id: `${namespace}_hasNoSpecialSymbols`,
    defaultMessage: 'Nesmí obsahovat speciální znaky',
  },
  hasLength: {
    id: `${namespace}_hasLength`,
    defaultMessage: 'Musí obsahovat přesně {length} znaků',
  },
  startsWith: {
    id: `${namespace}_startsWith`,
    defaultMessage: 'Hodnota musí začínat - {string}',
  },
  hasMinAge: {
    id: `${namespace}_hasMinAge`,
    defaultMessage: 'Minimální věk je {minAge} let',
  },
  isValidIban: {
    id: `${namespace}_isValidIban`,
    defaultMessage: 'Neplatný IBAN',
  },
  hasAgeInInterval: {
    id: `${namespace}_hasAgeInInterval`,
    defaultMessage: 'Věk musí být mezi {minAge} a {maxAge}',
  },
  hasCorrectRc: {
    id: `${namespace}_hasCorrectRc`,
    defaultMessage: 'Neodpovídá kontrolní součet',
  },
  emptyMessage: {
    id: `${namespace}_emptyMessage`,
    defaultMessage: ' ',
  },
  hasNoWhiteSpace: {
    id: `${namespace}_hasNoWhiteSpace`,
    defaultMessage: 'Nesmí obsahovat mezery',
  },
  startEndSpace: {
    id: `${namespace}_startEndSpace`,
    defaultMessage: 'Toto pole nesmí začínat, ani končit, mezerou.',
  },
  isNotInFuture: {
    id: `${namespace}_isNotInFuture`,
    defaultMessage: 'Datum nemůže být v budoucnu',
  },
  isChronological: {
    id: `${namespace}_isChronological`,
    defaultMessage: 'Data mají být v chronologickém pořadí',
  },
  isBirthNumberValid: {
    id: `${namespace}_isBirthNumberValid`,
    defaultMessage: 'Neodpovídá kontrolní součet',
  },
  isBankAccountValid: {
    id: `${namespace}_isBankAccountValid`,
    defaultMessage: 'Formát není validní.',
  },
});
