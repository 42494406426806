import { ofType } from 'redux-observable';
import {
  switchMap,
  map as rxMap,
  takeUntil,
  filter as rxFilter,
  distinctUntilChanged,
} from 'rxjs/operators';
import {
  applySpec,
  constructN,
  not,
  pipe,
  prop,
  propOr,
  unary,
  when,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

import { FETCH_ACCOUNT, fetchAccountSuccess } from '../actions';
import { isAuthenticatedSelector } from '../../storage/selectors';

const fromApiAccount = pipe(
  prop('response'),
  applySpec({
    isRegistered: prop('registrationCompleted'),
    phoneNumber: prop('phone'),
    firstName: propOr(null, 'firstName'),
    lastName: propOr(null, 'lastName'),
    birthNumber: propOr(null, 'birthNumber'),
    birthDate: pipe(
      propOr(null, 'birthDate'),
      when(isNotNilOrEmpty, constructN(1, Date))
    ),
    nationality: propOr(null, 'nationality'),
    email: propOr(null, 'email'),
    foreigner: propOr(false, 'foreigner'),
    npsConfirmed: propOr(null, 'npsConfirmed'),
  }),
  unary(fetchAccountSuccess)
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_ACCOUNT),
    switchMap(() =>
      ajax({
        useAuth: true,
        url: '/gateway-server/accounts',
      }).pipe(
        rxMap(unary(fromApiAccount)),
        takeUntil(
          state$.pipe(
            rxMap(unary(isAuthenticatedSelector)),
            distinctUntilChanged(),
            rxFilter(unary(not))
          )
        )
      )
    )
  );
