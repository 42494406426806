import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authenticationStatusSelector } from '../auth/storage/selectors';

export const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
const defaultProps = {};

const OnlyAnonnymous = ({ children }) => {
  const { isInitialized, isAuthenticated } = useSelector(
    authenticationStatusSelector
  );

  if (!isInitialized) return null;
  if (isInitialized && isAuthenticated) return <Navigate to="/nols" replace />;

  return children;
};

OnlyAnonnymous.propTypes = propTypes;
OnlyAnonnymous.defaultProps = defaultProps;

export default OnlyAnonnymous;
