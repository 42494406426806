import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map as rxMap,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { applySpec, ifElse, pipe, prop, propEq, unary } from 'ramda';
import { ajax } from 'rxjs/ajax';

import {
  VERIFY_OTP,
  verifyOtpSuccess,
  verifyOtpError,
  setServerHasError,
} from '../actions';
import { otpIdSelector } from '../selectors';

const doParse = pipe(
  prop('response'),
  applySpec({
    otpValid: prop('verified'),
  })
);

const parse = unary(
  pipe(
    doParse,
    ifElse(
      propEq('otpValid', true),
      unary(verifyOtpSuccess),
      unary(verifyOtpError)
    )
  )
);

export default (actions$, state$) =>
  actions$.pipe(
    ofType(VERIFY_OTP),
    exhaustMap((action) =>
      of(action).pipe(
        withLatestFrom(state$),
        mergeMap(([{ payload }, state]) =>
          ajax({
            method: 'PUT',
            url: `/gateway-server/otp/api/v2/otp/${otpIdSelector(state)}`,
            body: { otpId: otpIdSelector(state), code: payload.otp },
          })
        ),
        rxMap(parse),
        catchError((e) => {
          const actions = [
            verifyOtpError({ otpValid: false, otpDiscarded: e.status === 403 }),
          ];

          if (e.status >= 500) {
            actions.push(setServerHasError());
          }

          return of(...actions);
        })
      )
    )
  );
