import { ofType } from 'redux-observable';
import {
  switchMap,
  filter,
  take,
  mergeMap,
  map as rxMap,
  catchError,
  take as rxTake,
} from 'rxjs/operators';
import {
  applySpec,
  converge,
  isEmpty,
  mergeRight,
  path,
  pathEq,
  pipe,
  unary,
} from 'ramda';
import { of } from 'rxjs';

import {
  FETCH_INSURANCE_CONTRACTS,
  fetchContractsError,
  fetchContractsSuccess,
} from '../actions';
import {
  activePeriodContractsUrlSelector,
  contextSelector,
  currentRiskMetaRiskSelector,
} from '../../ActiveJourneyProvider/selectors';
import { navigate } from '../../../navigation/actions';
import { accountPhonePartsSelector } from '../../../auth/account/selectors';
import { CALL_ME_BACK_TYPES_GENERIC } from '../../../callMeBack/constants';
import { destroyJourney } from '../../ActiveJourneyProvider/actions/create.actions';

const navigateToCMB$ = (state$) =>
  state$.pipe(
    rxTake(1),
    rxMap(
      unary(
        converge(mergeRight, [
          applySpec({
            risk: unary(currentRiskMetaRiskSelector),
          }),
          unary(accountPhonePartsSelector),
        ])
      )
    ),
    mergeMap((meta) =>
      of(
        navigate({
          to: '/call-me-back',
          options: {
            state: {
              meta,
              reason: CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_DECLARATION,
            },
          },
        }),
        destroyJourney()
      )
    )
  );

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_INSURANCE_CONTRACTS),
    switchMap(() =>
      state$.pipe(
        filter(
          unary(
            pipe(
              unary(contextSelector),
              pathEq(['coverages', 'isLoading'], false)
            )
          )
        ),
        take(1),
        mergeMap((state) =>
          ajax({
            useAuth: true,
            url: activePeriodContractsUrlSelector(state),
          }).pipe(
            rxMap(unary(path(['response', 'policies']))),
            mergeMap((policies) => {
              if (isEmpty(policies)) {
                return navigateToCMB$(state$);
              }

              return of(fetchContractsSuccess(policies));
            }),
            catchError((e) => of(fetchContractsError(e)))
          )
        )
      )
    )
  );
