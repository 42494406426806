import React from 'react';
import PropTypes from 'prop-types';

import pdfDocSrc from './doc.png';

const propTypes = {
  height: PropTypes.number,
  className: PropTypes.string,
};
const defaultProps = {
  height: 40,
  className: '',
};

const PdfDoc = ({ height, className }) => (
  <img
    style={{ height }}
    src={pdfDocSrc}
    alt="document image"
    className={`inline-block ${className}`}
  />
);

PdfDoc.propTypes = propTypes;
PdfDoc.defaultProps = defaultProps;

export default PdfDoc;
