import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  title: PropTypes.string,
};
const defaultProps = {
  title: null,
};

const TextEllipsis = ({ children, title }) => (
  <p title={title} className="overflow-hidden text-ellipsis whitespace-nowrap">
    {children}
  </p>
);

TextEllipsis.propTypes = propTypes;
TextEllipsis.defaultProps = defaultProps;

export default TextEllipsis;
