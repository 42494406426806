import { defineMessages } from 'react-intl';
// ?pairingCode=4075025e-6ba1-4f9d-b2b4-fd9973da29c7
const namespace = 'crdf_resetPasswordPage';

export default defineMessages({
  unknownErrorText: {
    id: `${namespace}_unknownErrorText`,
    defaultMessage:
      'Při požadavku na změnu hesla došlo k chybě. Prosím zkuste to znovu později.',
  },
  tokenNotValidErrorText: {
    id: `${namespace}_tokenNotValidErrorText`,
    defaultMessage: 'Tento odkaz na změnu hesla byl již použit.',
  },
  tokenExpiredError: {
    id: `${namespace}_tokenExpiredError`,
    defaultMessage:
      'Platnost odkazu vypršela. Prosím, požádejte si o změnu hesla znovu.',
  },
  passwordAlreadyUsed: {
    id: `${namespace}_passwordAlreadyUsed`,
    defaultMessage: 'Heslo již bylo použito v minulosti',
  },
  submitButtonLabel: {
    id: `${namespace}_submitButtonLabel`,
    defaultMessage: 'Pokračovat',
  },
});
