import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_riskDetails_iu';

export default defineMessages({
  sinceLabel: {
    id: `${namespace}_sinceLabel`,
    defaultMessage: 'Od',
  },
  jobLoss_sinceHelperText: {
    id: 'newEvent_jobLoss_sinceHelperText',
    defaultMessage: 'Datum rozvázání pracovního poměru',
  },
  jobLoss_hasUntilLabel: {
    id: `${namespace}_jobLoss_hasUntilLabel`,
    defaultMessage:
      'Skončila vaše evidence na úřadu práce? Pokud ano, uveďte datum ukončení',
  },
  untilLabel: {
    id: `${namespace}_untilLabel`,
    defaultMessage: 'Do',
  },
  jobLossDueToIllness: {
    id: `${namespace}_jobLossDueToIllness`,
    defaultMessage:
      'Došlo k rozvázání pracovního poměru ze zdravotního důvodu?',
  },
  jobLossEmployerInfo: {
    id: `${namespace}_jobLossEmployerInfo`,
    defaultMessage: 'Informace o posledním zaměstnavateli',
  },
  jobLossEmployerNameLabel: {
    id: `${namespace}_jobLossEmployerNameLabel`,
    defaultMessage: 'Název zaměstnavatele',
  },
  descriptionLabel: {
    id: `${namespace}_descriptionLabel`,
    defaultMessage: 'Popis',
  },
  jobLoss_descriptionHelperText: {
    id: `${namespace}_descriptionHelperText`,
    defaultMessage: 'Popiště vaše onemocnění nebo úraz',
  },
  streetLabel: {
    id: `${namespace}_streetLabel`,
    defaultMessage: 'Ulice a č.p.',
  },
  cityLabel: {
    id: `${namespace}_cityLabel`,
    defaultMessage: 'Město',
  },
  postalCodeLabel: {
    id: `${namespace}_postalCodeLabel`,
    defaultMessage: 'PSČ',
  },
  countryLabel: {
    id: `${namespace}_countryLabel`,
    defaultMessage: 'Stát',
  },
});
