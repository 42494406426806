import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  switchMap,
  withLatestFrom,
  map as rxMap,
  takeUntil,
  mergeAll as rxMergeAll,
} from 'rxjs/operators';

import { authorizationHeaderSelector } from '../../../auth/storage/selectors';
import { getSettings } from '../../actions';
import { phoneNumberFormSelector } from '../../selectors';
import {
  DEINIT_FORM,
  changePhoneError,
  changePhoneSuccess,
  CHANGE_PHONE,
} from '../actions';

const toApiPayload = (payload, state) => ({
  code: payload.code,
  otpId: phoneNumberFormSelector(state).meta.otpId,
  phoneNumber: `+${payload.phonePrefix + payload.phoneNumber}`,
});

export default (action$, state$) =>
  action$.pipe(
    ofType(CHANGE_PHONE),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      ajax({
        method: 'PUT',
        url: '/gateway-server/accounts/settings/phone',
        headers: {
          authorization: authorizationHeaderSelector(state),
        },
        body: toApiPayload(payload, state),
      }).pipe(
        rxMap(() => [
          changePhoneSuccess({
            phonePrefix: payload.phonePrefix,
            phoneNumber: payload.phoneNumber,
          }),
          getSettings(),
        ]),
        rxMergeAll(),
        catchError((error) => of(changePhoneError(error))),
        takeUntil(action$.pipe(ofType(DEINIT_FORM)))
      )
    )
  );
