import { combineEpics } from 'redux-observable';

import initEpic from './init.epic';
import persistTokensEpic from './persistTokens.epic';
import refreshTokensEpic from './refreshTokens.epic';
import tokenRefreshTimerEpic from './tokenRefreshTimer.epic';

export default combineEpics(
  initEpic,
  tokenRefreshTimerEpic,
  persistTokensEpic,
  refreshTokensEpic
);
