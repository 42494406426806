import { unary } from 'ramda';
import { ofType } from 'redux-observable';
import subSeconds from 'date-fns/subSeconds';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import {
  switchMap,
  withLatestFrom,
  filter as rxFilter,
  delay as rxDelay,
  takeUntil as rxTakeUntil,
  map as rxMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

import { isAuthenticatedSelector, tokenExpiresAtSelector } from '../selectors';
import { FETCH_TOKENS_SUCCESS } from '../../login/actions';
import {
  refreshTokens,
  INIT_DONE,
  REFRESH_TOKENS_SUCCESS,
  CLEAR_TOKENS,
} from '../actions';

const randomIntBetween = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

const getRefreshTimer = (expires) => {
  const desiredTickDateTime = subSeconds(expires, randomIntBetween(15, 150));
  const secondsFromNow = differenceInSeconds(desiredTickDateTime, new Date());

  if (secondsFromNow < 5) {
    return 0; // Execute immediately
  }

  return desiredTickDateTime;
};

// TODO:  update so it can handle cancelation and rescheduling based on events
//        comming from another tab/window

export default (acton$, state$) =>
  acton$.pipe(
    ofType(FETCH_TOKENS_SUCCESS, INIT_DONE, REFRESH_TOKENS_SUCCESS),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      of(state).pipe(
        rxFilter(unary(isAuthenticatedSelector)),
        rxDelay(getRefreshTimer(tokenExpiresAtSelector(state))),
        rxMap(() => refreshTokens()),
        rxTakeUntil(acton$.pipe(ofType(CLEAR_TOKENS)))
      )
    )
  );
