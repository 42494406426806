import React from 'react';

import ListItem from '../../../../../../../../components/List/Item';
import List from '../../../../../../../../components/List';

const propTypes = {};
const defaultProps = {};

const InsuranceBenefitsCard = () => (
  <List variant="unordered" type="disc">
    <ListItem>O výsledku šetření vás budeme informovat.</ListItem>
    <ListItem>
      Pojistné plnění vyplácíme:
      <List inList variant="unordered" type="circle">
        <ListItem>
          <span className="font-bold">na bankovní účet</span>, který jste nám
          vyplnil/a nebo
        </ListItem>
        <ListItem>
          <span className="font-bold">
            dle podmínek pojištění na váš úvěrový účet v bance
          </span>
          , u které bylo vaše pojištění sjednáno. Případný přeplatek banka zašle
          na váš běžný účet. S dotazy se proto obracejte na svou banku.
        </ListItem>
      </List>
    </ListItem>
    <ListItem>
      V případě trvající pracovní neschopnosti nezapomeňte pravidelně dokládat:
      <List inList variant="unordered" type="circle">
        <ListItem>
          měsíčně <span className="font-bold">doklad o trvání PN,</span>
        </ListItem>
        <ListItem>
          každý druhý měsíc <span className="font-bold">lékařskou zprávu.</span>
        </ListItem>
      </List>
    </ListItem>
  </List>
);

InsuranceBenefitsCard.propTypes = propTypes;
InsuranceBenefitsCard.defaultProps = defaultProps;

export default InsuranceBenefitsCard;
