import { curryN, prop, propEq, propOr } from 'ramda';

import runValidations, {
  isRequired,
  isNotFuture,
  isChronologicalAfter,
  hasLengthMax,
  isBirthNumberValid,
  isTrimmedBothSides,
} from '../../../../forms/validators';
import { PatientRole } from '../common/constants';

const validations = {
  nurseName: {
    validate: [isRequired(), isTrimmedBothSides()],
  },
  nurseSurname: {
    validate: [isRequired()],
  },
  nurseId: {
    validate: [isRequired(), isBirthNumberValid(propOr('', 'nurseId'))],
  },
  patientRole: {
    validate: [isRequired()],
  },
  otherText: {
    when: propEq('patientRole', PatientRole.OTHER),
    validate: [isRequired(), hasLengthMax(255)],
  },
  patientName: {
    validate: [isRequired()],
  },
  patientSurname: {
    validate: [isRequired()],
  },
  patientBirthNumber: {
    validate: [
      isRequired(),
      isBirthNumberValid(propOr('', 'patientBirthNumber')),
    ],
  },
  takingCareFrom: {
    validate: [isRequired(), isNotFuture()],
  },
  takingCareTo: {
    when: propEq('hasEnded', true),
    validate: [
      isRequired(),
      isNotFuture(),
      isChronologicalAfter(prop('takingCareFrom')),
    ],
  },
  levelOfDisability: {
    when: propEq('isInvalid', true),
    validate: [isRequired()],
  },
  disabilityFrom: {
    when: propEq('isInvalid', true),
    validate: [isRequired(), isNotFuture()],
  },
  policeInvestigation: {
    when: propEq('investigatedByPolice', true),
    validate: [isRequired(), hasLengthMax(500)],
  },
};

export default curryN(2, (deps, values) =>
  runValidations(validations, deps, values)
);
