import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { isEmptyString } from 'ramda-adjunct';

import TextInput from '../../../components/form/TextInput';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password', 'number']),
  filterPattern: PropTypes.shape(),
  required: PropTypes.bool,
  error: PropTypes.string,
};
const defaultProps = {
  placeholder: null,
  type: 'text',
  required: false,
  filterPattern: null,
  error: null,
};

const TextField = ({
  name,
  label,
  placeholder,
  type,
  required,
  filterPattern,
  error,
  ...rest
}) => {
  const [field, meta] = useField({ name });

  return (
    <TextInput
      {...rest}
      name={name}
      label={label}
      placeholder={placeholder}
      type={type}
      required={required}
      error={error || (meta.touched && meta.error ? meta.error : null)}
      onChange={(e) => {
        if (filterPattern) {
          if (
            filterPattern.test(e.target.value) ||
            isEmptyString(e.target.value)
          )
            field.onChange(e);
        } else {
          field.onChange(e);
        }
      }}
      onBlur={field.onBlur}
      value={field.value}
    />
  );
};

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
