import { createAction } from 'redux-actions';

const namespace = 'crdf.auth.storage';

export const INIT = `${namespace}.init`;
export const INIT_DONE = `${namespace}.initDone`;
export const SET_TOKENS = `${namespace}.persisTokens`;
export const CLEAR_TOKENS = `${namespace}.clearTokens`;
export const REFRESH_TOKENS = `${namespace}.refreshTokens`;
export const REFRESH_TOKENS_SUCCESS = `${namespace}.refreshTokensSuccess`;
export const REFRESH_TOKENS_ERROR = `${namespace}.refreshTokensError`;
export const AUTHORIZATION_ERROR = `${namespace}.authorizationError`;

export const init = createAction(INIT);
export const initDone = createAction(INIT_DONE);
export const setTokens = createAction(SET_TOKENS);
export const clearTokens = createAction(CLEAR_TOKENS);
export const refreshTokens = createAction(REFRESH_TOKENS);
export const refreshTokensSuccess = createAction(REFRESH_TOKENS_SUCCESS);
export const refreshTokensError = createAction(REFRESH_TOKENS_ERROR);
export const authorizationError = createAction(AUTHORIZATION_ERROR);
