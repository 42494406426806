import { applySpec, lensIndex, over, pipe, prop, unary } from 'ramda';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map as rxMap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  getPhoneStatusError,
  requestPasswordResetError,
  requestPasswordResetSuccess,
  REQUEST_PASSWORD_RESET,
} from '../actions';
import { usernameSelector } from '../selectors';

const parseResponse = unary(
  pipe(
    prop('response'),
    applySpec({ otpId: prop('otpId'), phoneNumber: prop('phoneNumber') }),
    unary(requestPasswordResetSuccess)
  )
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(REQUEST_PASSWORD_RESET),
    withLatestFrom(state$),
    rxMap(unary(over(lensIndex(1), unary(usernameSelector)))),
    exhaustMap(([, phoneNumber]) =>
      ajax({
        method: 'DELETE',
        url: `/gateway-server/accounts/${phoneNumber}/password`,
      }).pipe(
        rxMap(parseResponse),
        catchError((e) => {
          if (e.status === 404) {
            return of(
              getPhoneStatusError({
                isNotValidFormat: false,
                isNotRegistered: true,
                otpId: null,
              })
            );
          }
          return of(requestPasswordResetError());
        })
      )
    )
  );
