import {
  always,
  applySpec,
  curryN,
  path,
  pipe,
  prop,
  propEq,
  unary,
} from 'ramda';
import { ofType } from 'redux-observable';
import {
  switchMap,
  take,
  withLatestFrom,
  map as rxMap,
  mergeAll,
  filter as rxFilter,
  mergeMap,
} from 'rxjs';

import { navigate } from '../../../navigation/actions';
import { destroyJourney } from '../../ActiveJourneyProvider/actions/create.actions';
import { riskTypeAndDateSelector } from '../../ActiveJourneyProvider/selectors';
import { FETCH_INSURANCE_CONTRACTS_ERROR } from '../actions';

const extractDateOfOccurrence = curryN(3, (detailKey, dateKey, data) =>
  path([detailKey, dateKey])(data)
);

const getOccurrenceDateByRiskType = {
  H: extractDateOfOccurrence('hospitalization', 'hospitalizationFrom'),
  TPD: extractDateOfOccurrence('permanentDisability', 'disabilityFrom'),
  PPD: extractDateOfOccurrence('partialPermanentDisability', 'disabilityFrom'),
  TD: extractDateOfOccurrence('temporaryDisability', 'temporaryDisabilityFrom'),
  TAC: extractDateOfOccurrence('takingCare', 'takingCareFrom'),
  IU: extractDateOfOccurrence('unemployment', 'involuntaryUnemploymentFrom'),
};

const parseNolDetail = curryN(3, (id, riskType, detail) =>
  applySpec({
    id: always(id),
    occurrenceDate: getOccurrenceDateByRiskType[riskType],
  })(detail)
);

const fetchNolList = (ajax) =>
  ajax({
    useAuth: true,
    url: '/gateway-server/nol/preview',
  }).pipe(rxMap(unary(pipe(prop('response')))));

const fetchNolDetail = (ajax, id) =>
  ajax({ useAuth: true, url: `/gateway-server/nol/${id}/preview` }).pipe(
    rxMap(unary(prop('response')))
  );

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_INSURANCE_CONTRACTS_ERROR),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const meta = riskTypeAndDateSelector(state);

      return fetchNolList(ajax).pipe(
        mergeAll(),
        rxFilter(unary(propEq('riskGroupStringCode', meta.riskType))),
        mergeMap(({ uuid }) =>
          fetchNolDetail(ajax, uuid).pipe(
            rxMap(unary(parseNolDetail(uuid, meta.riskType)))
          )
        ),
        rxFilter(unary(propEq('occurrenceDate', meta.riskDate))),
        take(1),
        rxMap(({ id }) => [
          destroyJourney({ ignoreAuthError: true }),
          navigate({
            to: `/nols/${id}`,
            options: { state: { isDuplicitNav: true } },
          }),
        ]),
        mergeAll()
      );
    })
  );
