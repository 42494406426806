import React from 'react';
import PropTypes from 'prop-types';

const getStyles = (scaler, size) => {
  const fontSize = size * scaler;
  const margin = (size - fontSize) / 2;

  return {
    fontSize: `${fontSize}px`,
    lineHeight: `${fontSize}px`,
    marginBottom: `${margin}px`,
    marginTop: `${margin}px`,
  };
};

export default (name, content, defaultSize = 60, scaler = 1) => {
  const Icon = ({ size, disableScaler }) => {
    const actualScaler = disableScaler ? 1 : scaler;
    return (
      <i
        style={getStyles(actualScaler, size)}
        className="inline-block font-cardif-icons not-italic"
      >
        {content}
      </i>
    );
  };

  Icon.propTypes = {
    size: PropTypes.number,
    disableScaler: PropTypes.bool,
  };
  Icon.defaultProps = {
    size: defaultSize,
    disableScaler: false,
  };
  Icon.displayName = name;

  return Icon;
};
