import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import logoSrc from './bnp_paribas_logo.png';

const propTypes = {
  variant: PropTypes.oneOf(['footer', 'default']),
};

const defaultProps = {
  variant: 'default',
};

const Logo = ({ variant }) => (
  <img
    className={cx('max-w-full max-h-full', {
      'max-w-[120px]': variant === 'footer',
      'max-h-[36px]': variant === 'default',
    })}
    src={logoSrc}
    alt="BNP Paribas Logo"
    data-ver={`${process.env.REACT_APP_VERSION}`}
  />
);

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
