import { combineReducers } from 'redux';

import activeJourney from './ActiveJourneyProvider/reducers';
import activeJourneyModal from './ActiveJourneyModal/reducers';
import insuranceContracts from './insuranceContracts/reducers';
import nolCreatedModal from './NolCreatedModal/reducers';

export default combineReducers({
  activeJourney,
  activeJourneyModal,
  insuranceContracts,
  nolCreatedModal,
});
