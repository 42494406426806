import { defineMessages } from 'react-intl';

const namespace = 'crdf_journeys_forms_riskDetails_tac';

export default defineMessages({
  carerIsLabel: {
    id: `${namespace}_carerIsLabel`,
    defaultMessage: 'Ošetřovatel je',
  },
  carerFirstNameLabel: {
    id: `${namespace}_carerFirstNameLabel`,
    defaultMessage: 'Jméno ošetřovatele',
  },
  carerLastNameLabel: {
    id: `${namespace}_carerLastNameLabel`,
    defaultMessage: 'Příjmení ošetřovatele',
  },
  carerBirthNumberLabel: {
    id: `${namespace}_carerBirthNumberLabel`,
    defaultMessage: 'Rodné číslo ošetřovatele',
  },
  caredForIsLabel: {
    id: `${namespace}_caredForIsLabel`,
    defaultMessage: 'Ošetřovaná osoba je',
  },
  caredForIsOtherLabel: {
    id: `${namespace}_caredForIsOtherLabel`,
    defaultMessage: 'V jakém vztahu jste s ošetřovanou osobou?',
  },
  caredForFirstNameLabel: {
    id: `${namespace}_caredForFirstNameLabel`,
    defaultMessage: 'Jméno ošetřovaného',
  },
  caredForLastNameLabel: {
    id: `${namespace}_caredForLastNameLabel`,
    defaultMessage: 'Přijmení ošetřovaného',
  },
  caredForBirthNumberLabel: {
    id: `${namespace}_caredForBirthNumberLabel`,
    defaultMessage: 'Rodné číslo ošetřovaného',
  },
  caredForStartDateLabel: {
    id: `${namespace}_caredForStartDateLabel`,
    defaultMessage: 'Datum začátku ošetřovaní',
  },
  caredForEndDateLabel: {
    id: `${namespace}_caredForEndDateLabel`,
    defaultMessage: 'Datum konce ošetřovaní',
  },
  hasUntilLabel: {
    id: `${namespace}_hasUntilLabel`,
    defaultMessage: 'Zadat datum ukončení',
  },
  closeCare_examinatedHeading: {
    id: `${namespace}_closeCare_examinatedHeading`,
    defaultMessage: 'Absolvoval ošetřovaný:',
  },
  eventReportedLabel: {
    id: `${namespace}_eventReportedLabel`,
    defaultMessage: 'Hlásíte událost kvůli',
  },
  undergoneSpecialExamLabel: {
    id: `${namespace}_undergoneSpecialExamLabel`,
    defaultMessage: 'Vyšetření lékařem/specialistou',
  },
  undergoneImagingExamLabel: {
    id: `${namespace}_undergoneImagingExamLabel`,
    defaultMessage: 'Zobrazovací vyšetření (např. RTG, MRI, CT, SONO)',
  },
  undergoneSurgeryOrHospitalisedLabel: {
    id: `${namespace}_undergoneSurgeryOrHospitalisedLabel`,
    defaultMessage: 'Operaci nebo jste byl/a hospitalizován/a',
  },
  contributionRequestLabel: {
    id: `${namespace}_contributionRequestLabel`,
    defaultMessage: ' Žádal/a jste o příspěvek na péči?',
  },
  usedTimeOffWorkLabel: {
    id: `${namespace}_usedTimeOffWorkLabel`,
    defaultMessage: 'Čerpal/a jste pracovní volno kvůli ošetřování?',
  },
  endedWorkLabel: {
    id: `${namespace}_endedWorkLabel`,
    defaultMessage: 'Skončil/a jste pracovní poměr kvůli ošetřování?',
  },
  isInvalidLabel: {
    id: `${namespace}_isInvalidLabel`,
    defaultMessage: 'Byla nebo je ošetřovaná osoba uznána invalidní?',
  },
  levelOfDisabilityLabel: {
    id: `${namespace}_levelOfDisabilityLabel`,
    defaultMessage: 'Stupeň invalidity',
  },
  investigatedByPoliceLabel: {
    id: `${namespace}_investigatedByPoliceLabel`,
    defaultMessage: 'Šetřila událost Policie ČR?',
  },
  descriptionLabel: {
    id: `${namespace}_descriptionLabel`,
    defaultMessage: 'Popis',
  },
  closeCare_disabilitySinceHelperText: {
    id: `${namespace}_disabilitySinceHelperText`,
    defaultMessage: 'Od kterého data je čerpán invalidní důchod',
  },
  closeCare_descriptionHelperText: {
    id: `${namespace}_descriptionHelperText`,
    defaultMessage: 'Uveďte, prosím, adresu útvaru, který událost řešil',
  },
  sinceLabel: {
    id: `${namespace}_sinceLabel`,
    defaultMessage: 'Od',
  },
});
