import { ofType } from 'redux-observable';
import { switchMap, map as rxMap, catchError } from 'rxjs/operators';
import { applySpec, pipe, prop, propEq, unary } from 'ramda';
import { of } from 'rxjs';

import {
  INIT,
  checkTokenStatusSuccess,
  checkTokenStatusError,
} from '../actions';

// const linkStatuses = ['EXPIRED', 'NOT_AVAILABLE', 'VALID'];

const parseResponse = unary(
  pipe(
    prop('response'),
    applySpec({
      codeValid: propEq('linkStatus', 'VALID'),
      codeExpired: propEq('linkStatus', 'EXPIRED'),
      codeInvalid: propEq('linkStatus', 'NOT_AVAILABLE'),
    }),
    unary(checkTokenStatusSuccess)
  )
);

const parseError = (e) => {
  if (e.status >= 500) return of(checkTokenStatusError(e));

  return of(
    checkTokenStatusSuccess({
      codeValid: false,
      codeExpired: false,
      codeInvalid: true,
    })
  );
};

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(INIT),
    switchMap(({ payload }) =>
      ajax({
        method: 'POST',
        url: '/gateway-server/accounts/password/reset-link-status',
        body: {
          pairingCode: payload.pairingCode,
        },
      }).pipe(rxMap(parseResponse), catchError(parseError))
    )
  );
