import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  ClockIcon,
  SuccessIcon,
  WarningIcon,
} from '../../../../../../components/icons';
import statusMessgaes from './status.messgaes';

export const propTypes = {
  status: PropTypes.oneOf([
    'ACCEPTED',
    'WAITING_FOR_DOCUMENTS',
    'PROCESSING',
    'REJECTED',
    'DELETED',
  ]).isRequired,
};
const defaultProps = {};

const StatusRenderer = ({ status }) => {
  const statusMap = useMemo(
    () => ({
      ACCEPTED: {
        color: 'green',
        icon: <SuccessIcon size={14} />,
      },
      WAITING_FOR_DOCUMENTS: {
        color: 'orange',
        icon: <ClockIcon size={14} />,
      },
      PROCESSING: {
        color: 'orange',
        icon: <ClockIcon size={14} />,
      },
      REJECTED: {
        color: 'red',
        icon: <WarningIcon size={14} />,
      },
      DELETED: {
        color: 'red',
        icon: <WarningIcon size={14} />,
      },
    }),
    []
  );

  return (
    <div>
      <div
        className={cx('flex items-center gap-1 text-sm', {
          'text-cardif-orange': statusMap[status].color === 'orange',
          'text-cardif-green-dark': statusMap[status].color === 'green',
          'text-red-600': statusMap[status].color === 'red',
        })}
      >
        {statusMap[status].icon}{' '}
        <FormattedMessage {...statusMessgaes[status]} />
      </div>
    </div>
  );
};

StatusRenderer.propTypes = propTypes;
StatusRenderer.defaultProps = defaultProps;

export default StatusRenderer;
