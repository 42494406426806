import { defineMessages } from 'react-intl';

export default defineMessages({
  whoIsBNP_question: {
    id: 'faq_homepage_whoIsBNP_question',
    defaultMessage: 'Kdo je BNP Paribas Cardif Pojišťovna?',
  },
  whoIsBNP_answer: {
    id: 'faq_homepage_whoIsBNP_answer',
    defaultMessage: `\
BNP Paribas Cardif Pojišťovna pojistí mě, mé blízké, ale i můj osobní majetek a mé finanční závazky.
Pojistné produkty nabízí BNP Paribas Cardif Pojišťovna ve spolupráci s řadou finančních institucí - domácích i nadnárodních partnerů - nejčastěji s bankami, leasingovými společnostmi a společnostmi poskytujícími nákupy na splátky, osobní půjčky nebo úvěrové karty. Dále také spolupracuje s předními společnostmi z jiných oborů - například retailu (elektro), telekomunikací nebo dodavatelů energií.
Na českém trhu působí již od roku 1996, kdy jako první spustila produkt „Pojištění schopnosti splácet finanční závazek“. Ten mi pomůže v případě nepředvídatelných životních situací, které mohou ohrozit mou schopnost splácet úvěr, hypotéku nebo např. leasing.
V současné době mi může BNP Paribas Cardif Pojišťovna nabídnout pojistné produkty, které mi pomohou udržet vyrovnaný rodinný rozpočet např. v případě nemoci, ztráty zaměstnání nebo odcizení mého majetku.\
  `,
  },
  howToReport_question: {
    id: 'faq_homepage_howToReport_question',
    defaultMessage: 'Jak mám nahlásit škodní událost?',
  },
  howToReport_answer: {
    id: 'faq_homepage_howToReport_answer',
    defaultMessage:
      'Sepsali jsme pro vás souhrn nejdůležitějších bodů, díky kterým můžeme vaši událost vyřídit co nejrychleji. Než tedy začnete se samotným hlášením, seznamte se prosím s uvedenými kroky.',
  },
  howToReport_answer_bullet_1: {
    id: 'faq_homepage_howToReport_answer_bullet_1',
    defaultMessage:
      'Pro vyřízení události budeme potřebovat doklady prokazující vznik Vaší události',
  },
  howToReport_answer_bullet_2: {
    id: 'faq_homepage_howToReport_answer_bullet_2',
    defaultMessage:
      'Pojistnou událost doporučujeme nahlásit až budete mít všechny dokumenty k dispozici',
  },
  howToReport_answer_bullet_3: {
    id: 'faq_homepage_howToReport_answer_bullet_3',
    defaultMessage: 'Jak probíhá samotné šetření události, naleznete {link}',
  },
  howToReport_answer_2: {
    id: 'faq_homepage_howToReport_answer_2',
    defaultMessage: 'Jakou událost nám chcete nahlásit?',
  },
  reportForOther_question: {
    id: 'faq_homepage_reportForOther_question',
    defaultMessage: 'Můžu nahlásit škodní událost za někoho jiného?',
  },
  reportForOther_answer: {
    id: 'faq_homepage_reportForOther_answer',
    defaultMessage:
      'Pro nahlášení škodní události za jinou osobu můžete využít formulář Oznámení škodní události, který najdete na našem webu. Jakou událost nám chcete nahlásit?',
  },
  howToReportDeceased_question: {
    id: 'faq_homepage_howToReportDeceased_question',
    defaultMessage: 'Jak mám nahlásit škodní událost za zemřelého?',
  },
  howToReportDeceased_answer_one: {
    id: 'faq_homepage_howToReportDeceased_answer_one',
    defaultMessage:
      'Musím doložit formulář {link} a další dokumenty, které jsou ve formuláři uvedené.',
  },
  howToReportDeceased_answer_one_linktext: {
    id: 'faq_homepage_howToReportDeceased_answer_one_linktext',
    defaultMessage: 'Oznámení škodní události - úmrtí pojištěného',
  },
  howToReportDeceased_answer_two: {
    id: 'faq_homepage_howToReportDeceased_answer_two',
    defaultMessage:
      'Pokud mám možnost tisku, všechny potřebné dokumenty vytisknu, vyplním a podepsané zašlu:',
  },
  howToReportDeceased_answer_two_a: {
    id: 'faq_homepage_howToReportDeceased_answer_two_a',
    defaultMessage:
      'elektronicky na e-mail {link} (šetření škodní události tím urychlím).',
  },
  howToReportDeceased_answer_two_b: {
    id: 'faq_homepage_howToReportDeceased_answer_two_b',
    defaultMessage:
      'poštou na BNP Paribas Cardif Pojišťovna, a.s., Boudníkova 2506/1, 180 00 Praha 8.',
  },
  howToReportDeceased_answer_three: {
    id: 'faq_homepage_howToReportDeceased_answer_three',
    defaultMessage:
      'Pokud nemám možnost tisku, kontaktuju Klientské centrum na tel. čísle 234 240 448 nebo na e-mailu {link}, kde sdělím číslo smlouvy, ke které je sjednané pojištění a požádám o zaslání všech potřebných dokumentů.',
  },
  canIReportPastEvents_question: {
    id: 'faq_homepage_canIReportPastEvents_question',
    defaultMessage: 'Můžu nahlásit škodní událost zpětně?',
  },
  canIReportPastEvents_answer: {
    id: 'faq_homepage_canIReportPastEvents_answer',
    defaultMessage: `Ano, škodní událost můžu nahlásit zpětně, pokud jsem v době, kdy se mi škodní událost stala, měl/a platné pojištění.
Škodní událost můžu nahlásit až do 4 let od jejího vzniku. V případě úmrtí je možné nahlášení škodní události ve lhůtě až 11 let.`,
  },
  whenCanIReportEvent_question: {
    id: 'faq_homepage_whenCanIReportEvent_question',
    defaultMessage: 'Kdy můžu nahlásit škodní událost?',
  },
  whenCanIReportEvent_answer: {
    id: 'faq_homepage_whenCanIReportEvent_answer',
    defaultMessage: `\
Škodní událost můžete nahlásit jak v jejím průběhu, tak po jejím skončení. Doporučujeme vám však nahlásit událost co nejdříve, abychom mohli posoudit nárok na pojistné plnění. Před samotným hlášením si vždy zkontrolujte splnění všech podmínek pojištění, například minimální doby trvání události u hlášení ztráty zaměstnání nebo dočasné pracovní neschopnosti. Tuto informaci vždy naleznete ve smlouvě nebo se podívejte na sekci „Jak mám nahlásit škodní událost?“.
V případě uznání nároku Vám pojištění pomůže již v průběhu události, kdy může dojít ke snížení příjmu.\
    `,
  },
  inactiveInsurance_question: {
    id: 'faq_homepage_inactiveInsurance_question',
    defaultMessage:
      'Můžu nahlásit škodní událost, ke které už nemám aktivní pojištění?',
  },
  inactiveInsurance_answer: {
    id: 'faq_homepage_inactiveInsurance_answer',
    defaultMessage:
      'Ano můžu, pokud jsem v době, kdy se mi škodní událost stala, měl/a platné pojištění.',
  },
  fullfillment_question: {
    id: 'faq_homepage_fullfillment_question',
    defaultMessage: 'Kdy můžu očekávat pojistné plnění?',
  },
  fullfillment_answer: {
    id: 'faq_homepage_fullfillment_answer',
    defaultMessage: `\
Pokud mi vznikne na pojistné plnění nárok, odchází peníze na můj osobní nebo úvěrový účet (podle pojistné smlouvy) do 15 dnů po ukončení šetření.
Protože je každá událost posuzována individuálně, nemůžu dopředu očekávat, že mi nárok na pojistné plnění vznikne.
Aby mohla pojišťovna mou událost vůbec začít šetřit, musím jí dodat všechny požadované dokumenty, které jsou uvedené ve formuláři Oznámení škodní události. O výsledku šetření mě musí pojišťovna informovat podle zákona maximálně do 90 dnů (v praxi to bývá většinou do 30 dnů) od dodání všech požadovaných dokumentů.\
    `,
  },
  toc_question: {
    id: 'faq_homepage_toc_question',
    defaultMessage: 'Kde najdu detailní podmínky pojištění?',
  },
  toc_answer: {
    id: 'faq_homepage_toc_answer',
    defaultMessage: `\
a) Najdu je v pojistné smlouvě a všeobecných pojistných podmínkách, které jsem dostal/a při sjednání.
b) Najdu je na stránkách společnosti, u které jsem si pojištění sjednal/a.
c) Požádám o ně přes Klientské centrum Cardif Pojišťovny na tel. čísle 234 240 448 nebo na e‑mailu {link}.\
    `,
  },
  eventType_1: {
    id: 'faq_homepage_eventType_1',
    defaultMessage: 'Pracovní neschopnost',
  },
  eventType_2: {
    id: 'faq_homepage_eventType_2',
    defaultMessage: 'Ztráta zaměstnání',
  },
  eventType_3: {
    id: 'faq_homepage_eventType_3',
    defaultMessage: 'Zrušení živnosti',
  },
  eventType_4: {
    id: 'faq_homepage_eventType_4',
    defaultMessage: 'Invalidita',
  },
  eventType_5: {
    id: 'faq_homepage_eventType_5',
    defaultMessage: 'Úmrtí blízké osoby',
  },
  eventType_6: {
    id: 'faq_homepage_eventType_6',
    defaultMessage: 'Ošetřování člena rodiny',
  },
  eventType_7: {
    id: 'faq_homepage_eventType_7',
    defaultMessage: 'Hospitalizace',
  },
});
