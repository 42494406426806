import { filter, length, pipe, prop, propEq } from 'ramda';
import { createSelector } from 'reselect';

export const notificationsSelector = prop('notifications');

export const notificationsLoadingSelector = pipe(
  notificationsSelector,
  prop('isLoading')
);

export const notificationsInitializedSelector = pipe(
  notificationsSelector,
  prop('isInitialized')
);

export const notificationMessagesSelector = pipe(
  notificationsSelector,
  prop('messages')
);

export const unreadMessagesCountSelector = createSelector(
  notificationMessagesSelector,
  pipe(filter(propEq('status', 'CREATED')), length)
);
