import { ofType } from 'redux-observable';
import {
  exhaustMap,
  withLatestFrom,
  map as rxMap,
  mergeMap,
  catchError,
  take,
} from 'rxjs/operators';
import { pipe, prop, unary } from 'ramda';
import { EMPTY, of } from 'rxjs';

import {
  SUBMIT_CONTACT_DATA_FORM_SUCCESS,
  finalizeAccountSuccess,
} from '../actions';
import { authStorageTokensSelector } from '../../auth/storage/selectors';
import { fetchAccount } from '../../auth/account/actions';
import { navigate } from '../../navigation/actions';
import { CALL_ME_BACK_TYPES_GENERIC } from '../../callMeBack/constants';
import { accountPhonePartsSelector } from '../../auth/account/selectors';

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(SUBMIT_CONTACT_DATA_FORM_SUCCESS),
    withLatestFrom(state$),
    rxMap(unary(pipe(prop(1), unary(authStorageTokensSelector)))),
    exhaustMap(({ refreshToken }) =>
      ajax({
        useAuth: true,
        method: 'POST',
        url: '/gateway-server/accounts/finalize',
        body: { refreshToken },
      }).pipe(
        mergeMap(() =>
          of(
            fetchAccount(),
            finalizeAccountSuccess(),
            navigate({
              to: '/journeys/new',
              options: {
                state: {
                  redirectTo: '/journeys/risk-type',
                  isRegistrationJourney: true,
                },
              },
            })
          )
        ),
        catchError((e) => {
          if (e.status === 400 && e.response?.code === '009') {
            return state$.pipe(
              take(1),
              rxMap(unary(accountPhonePartsSelector)),
              rxMap((phone) =>
                navigate({
                  to: '/call-me-back',
                  options: {
                    state: {
                      reason:
                        CALL_ME_BACK_TYPES_GENERIC.NO_POLICIES_REGISTRATION,
                      meta: phone,
                    },
                  },
                })
              )
            );
          }

          return EMPTY;
        })
      )
    )
  );
