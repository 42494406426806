/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import FlatPickr from 'react-flatpickr';
import { Czech } from 'flatpickr/dist/l10n/cs';

import TextInput from '../TextInput';
import './custom.css';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  error: PropTypes.string,
};
const defaultProps = {
  label: '',
  onBlur: null,
  value: null,
  error: null,
};

const DatePicker = ({
  error,
  value,
  label,
  onChange,
  name,
  onBlur,
  ...rest
}) => (
  <FlatPickr
    options={{
      dateFormat: 'd.m.Y',
      locale: Czech,
      wrap: true,
      allowInput: true,
      disableMobile: true,
      defaultDate: value,
      onClose: ([selectedDate]) => {
        onChange(selectedDate);
      },
      onReady: (dates, dateStr, instance) => {
        if (dateStr) {
          instance.input.dispatchEvent(new Event('input', { bubbles: true }));
        }
      },
    }}
  >
    <TextInput
      {...rest}
      label={label}
      name={name}
      error={error}
      data-input
      autoComplete="off"
    />
  </FlatPickr>
);

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;
