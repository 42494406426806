import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';

const propTypes = {
  button: PropTypes.node.isRequired,
};
const defaultProps = {};

const CookieModal = ({ button }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      hasCross
      button={button}
      isOpen={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      children
    </Modal>
  );
};

CookieModal.propTypes = propTypes;
CookieModal.defaultProps = defaultProps;

export default CookieModal;
