import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
};

const Layout = ({ children, header, footer }) => (
  <div className="min-h-screen flex flex-col grow font-cardif-regular bg-cardif-gray-light">
    <div>{header}</div>
    <div className="flex-1 flex flex-col items-stretch">{children}</div>
    <div>{footer}</div>
  </div>
);

Layout.propTypes = propTypes;

export default Layout;
