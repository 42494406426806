import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Formik, Form } from 'formik';

import Spacing from '../../../components/layout/Spacing';
import Button from '../../../components/Button';
import RadiosField from '../../forms/fields/radios';
import messages from './messages';
import commonMessages from '../../i18n/common.messages';
import DropCard from '../../../components/cards/DropCard/drop';
import Paragraph from '../../../components/text/Paragraph/paragraph';
import Container from '../../../components/layout/Container';
import { notificationsFormSelector } from '../selectors';
import { changeNotifications } from '../actions';
import FlashCard from '../../../components/cards/FlashCard';

const NotificationForm = () => {
  const { formatMessage } = useIntl();
  const { initialState, isSubmitting, hasError, submitSuccess } = useSelector(
    notificationsFormSelector
  );
  const dispatch = useDispatch();

  const radios = useMemo(
    () => [
      { label: formatMessage(messages.smsLabel), value: 'SMS' },
      { label: formatMessage(messages.emailLabel), value: 'EMAIL' },
      { label: formatMessage(messages.smsEmailLabel), value: 'EMAIL_AND_SMS' },
    ],
    []
  );

  const [messageObj, setMessageObj] = useState({
    variant: 'warning',
    message: '',
  });

  useEffect(() => {
    if (submitSuccess) {
      setMessageObj({
        variant: 'success',
        message: formatMessage(messages.saveNotificationsSuccess),
      });
    }

    if (hasError) {
      setMessageObj({
        variant: 'warning',
        message: formatMessage(commonMessages.backendInternalServerError),
      });
    }
  }, [submitSuccess, hasError]);

  return (
    <DropCard title={formatMessage(messages.cardTitle)}>
      <Paragraph center color="gray">
        {formatMessage(messages.cardText)}
      </Paragraph>
      <Spacing>
        <Container variant="form" bgColor="gray">
          <Formik
            key={initialState.notificationMethod}
            initialValues={initialState}
            onSubmit={(values) => dispatch(changeNotifications(values))}
          >
            {(formik) => (
              <Form noValidate>
                {(submitSuccess || hasError) && (
                  <FlashCard
                    variant={messageObj.variant}
                    message={messageObj.message}
                  />
                )}
                <Spacing variant="item" />
                <RadiosField name="notificationMethod" radios={radios} />
                <Spacing variant="item" />
                <Button
                  center
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !formik.values.notificationMethod ||
                    formik.values.notificationMethod ===
                      initialState.notificationMethod
                  }
                  label={formatMessage(messages.saveButtonLabel)}
                />
              </Form>
            )}
          </Formik>
        </Container>
      </Spacing>
    </DropCard>
  );
};

export default NotificationForm;
