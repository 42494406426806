import { combineReducers } from 'redux';

import settingsReducer from './Settings/reducers';
import notificationsFormReducer from './NotificationForm/reducers';
import phoneNumberFormReducer from './PhoneNumberForm/reducers';
import changeEmailFormReducer from './EmailForm/reducer';
import changePasswordFormReducer from './ChangePasswordForm/reducers';
import changeEmailLandingReducer from './changeEmail/reducer';

export default combineReducers({
  settings: settingsReducer,
  notificationsForm: notificationsFormReducer,
  phoneNumberForm: phoneNumberFormReducer,
  changeEmailForm: changeEmailFormReducer,
  changePasswordForm: changePasswordFormReducer,
  changeEmailLanding: changeEmailLandingReducer,
});
