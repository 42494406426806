import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { noop } from 'ramda-adjunct';

import Button from '../../../../components/Button';
import Container from '../../../../components/layout/Container';
import Spacing from '../../../../components/layout/Spacing';
import Modal from '../../../../components/Modal';
import Heading from '../../../../components/text/Heading';
import Paragraph from '../../../../components/text/Paragraph';
import messages from '../messages';

const propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
const defaultProps = {
  isOpen: false,
};

const TOCModal = ({ isOpen, onConfirm, onClose }) => {
  const intl = useIntl();

  return (
    <Modal hasCross onClose={onClose} onOpen={noop} isOpen={isOpen}>
      <Container variant="modal">
        <Heading variant="modal">
          {intl.formatMessage(messages.tocModalTitle)}
        </Heading>
        <Paragraph center>
          {intl.formatMessage(messages.tocModalBody)}
        </Paragraph>
        <Spacing>
          <Button
            center
            onClick={() => {
              onConfirm();
            }}
            label={intl.formatMessage(messages.tocModalConfirm)}
          />
        </Spacing>
      </Container>
    </Modal>
  );
};

TOCModal.propTypes = propTypes;
TOCModal.defaultProps = defaultProps;

export default TOCModal;
