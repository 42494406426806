import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  color: PropTypes.oneOf(['green', 'inherit']),
  underline: PropTypes.bool,
};
const defaultProps = {
  color: 'inherit',
  underline: false,
};

const FormattedDate = ({ date, color, underline }) => {
  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  return (
    <span
      className={cx({
        underline,
        'text-cardif-green-light text-sm': color === 'green',
      })}
    >
      {dateFormatter.format(date)}
    </span>
  );
};

FormattedDate.propTypes = propTypes;
FormattedDate.defaultProps = defaultProps;

export default FormattedDate;
