import React from 'react';
import PropTypes from 'prop-types';

import StepImg from '../../images/StepImg';

const propTypes = {
  step: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};
const defaultProps = {};

const StepCard = ({ step, text }) => (
  <div className="py-3 text-center text-cardif-gray-dark max-w-[90px] inline-block">
    <StepImg step={step} height="80px" />
    <p className="font-cardif-cond text-lg leading-5 pt-2">{text}</p>
  </div>
);

StepCard.propTypes = propTypes;
StepCard.defaultProps = defaultProps;

export default StepCard;
