import { ofType } from 'redux-observable';
import {
  switchMap,
  withLatestFrom,
  catchError,
  mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';

import {
  DESTROY_JOURNEY,
  destroyJourneyError,
  destroyJourneySuccess,
  reset,
} from '../actions';
import { contextSelector } from '../selectors';

const createUrl = (state) => {
  const { activeJourneyId } = contextSelector(state);

  return `/gateway-server/journeys/${activeJourneyId}`;
};

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(DESTROY_JOURNEY),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      ajax({
        useAuth: true,
        errorPassthrough: payload?.ignoreAuthError,
        method: 'DELETE',
        url: createUrl(state),
      }).pipe(
        mergeMap(() => of(reset(), destroyJourneySuccess())),
        catchError((e) => of(destroyJourneyError(e)))
      )
    )
  );
