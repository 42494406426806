import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClick: PropTypes.func,
};
const defaultProps = {
  onClick: null,
};

const DropdownItem = ({ children, onClick }) => (
  <div
    onClick={onClick}
    className={cx(
      'hover:bg-cardif-gray-light px-4 py-2 w-full flex gap-2 items-center',
      {
        'cursor-pointer': !!onClick,
      }
    )}
  >
    {children}
  </div>
);

DropdownItem.propTypes = propTypes;
DropdownItem.defaultProps = defaultProps;

export default DropdownItem;
