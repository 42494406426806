import { createAction } from 'redux-actions';

const namespace = 'crdf.settings.phoneNumberForm';

export const INIT_FORM = `${namespace}.init`;
export const DEINIT_FORM = `${namespace}.deinit`;
export const UPDATE_FORM = `${namespace}.updateForm`;

export const VERIFY_PHONE = `${namespace}.verifyPhone`;
export const VERIFY_PHONE_SUCCESS = `${namespace}.verifyPhoneSuccess`;
export const VERIFY_PHONE_ERROR = `${namespace}.verifyPhoneError`;

export const CHANGE_PHONE = `${namespace}.changePhone`;
export const CHANGE_PHONE_SUCCESS = `${namespace}.changePhoneSuccess`;
export const CHANGE_PHONE_ERROR = `${namespace}.changePhoneError`;

export const initForm = createAction(INIT_FORM);
export const deinitForm = createAction(DEINIT_FORM);
export const updateForm = createAction(UPDATE_FORM);

export const verifyPhone = createAction(VERIFY_PHONE);
export const verifyPhoneSuccess = createAction(VERIFY_PHONE_SUCCESS);
export const verifyPhoneError = createAction(VERIFY_PHONE_ERROR);

export const changePhone = createAction(CHANGE_PHONE);
export const changePhoneSuccess = createAction(CHANGE_PHONE_SUCCESS);
export const changePhoneError = createAction(CHANGE_PHONE_ERROR);
