import { handleActions } from 'redux-actions';

import {
  SET_OTP_ID,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  LOGIN_INIT,
  LOGIN_DEINIT,
  REQUEST_PASSWORD_RESET_SUCCESS,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_ERROR,
} from '../actions';

const initialState = {
  otpId: null,
  hasError: false,
  isDiscarded: false,
  isVerified: false,
  refreshBlocked: false,
  refreshSent: false,
};

export default handleActions(
  {
    [LOGIN_INIT]: () => ({ ...initialState }),
    [LOGIN_DEINIT]: () => ({ ...initialState }),
    [SET_OTP_ID]: (state, { payload }) => ({ ...state, otpId: payload.otpId }),
    [REQUEST_PASSWORD_RESET_SUCCESS]: (state, { payload }) => ({
      ...state,
      otpId: payload.otpId,
    }),
    [VERIFY_OTP_SUCCESS]: (state) => ({ ...state, isVerified: true }),
    [VERIFY_OTP_ERROR]: (state, { payload }) => ({
      ...state,
      isVerified: false,
      hasError: true,
      isDiscarded: payload.otpDiscarded,
    }),
    [RESEND_OTP_SUCCESS]: (state, { payload }) => ({
      ...state,
      otpId: payload.otpId,
      refreshSent: true,
      isDiscarded: false,
    }),
    [RESEND_OTP_ERROR]: (state, { payload }) =>
      payload.status === 403 ? { ...state, refreshBlocked: true } : state,
  },
  initialState
);
