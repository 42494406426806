import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ArrowUpIcon } from '../../icons';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  title: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};
const defaultProps = {
  isOpen: false,
};

const FAQItem = ({ title, children, isOpen, onOpen, onClose }) => (
  <div className="shadow-faq">
    <div
      onClick={isOpen ? onClose : onOpen}
      className={cx(
        'px-4 sm:px-8 py-3 sm:py-6 flex justify-between items-center gap-4 cursor-pointer bg-white rounded-md transition-shadow ease-in-out duration-200 hover:shadow-faq-hover',
        {
          'rounded-b-none border-b border-slate-100': isOpen,
        }
      )}
    >
      {title}
      <span
        className={cx(
          'rounded-full shrink-0 w-5 h-5 leading-5 flex justify-center items-center bg-cardif-green-light text-white',
          {
            'rotate-0': isOpen,
            'rotate-180': !isOpen,
          }
        )}
      >
        <ArrowUpIcon />
      </span>
    </div>
    <div
      className={cx(
        'text-[0.96em] px-4 sm:px-8 rounded-b-md bg-transparent transition-all duration-200 ease-in-out',
        {
          'h-0 py-0 sm:py-0 overflow-hidden': !isOpen,
          'h-auto py-3 sm:py-6': isOpen,
        }
      )}
    >
      {children}
    </div>
  </div>
);

FAQItem.propTypes = propTypes;
FAQItem.defaultProps = defaultProps;

export default FAQItem;
