import { createAction } from 'redux-actions';

const namespace = 'crdf.settings.changePasswordForm';

export const CHANGE_PASSWORD = `${namespace}.changePassword`;
export const CHANGE_PASSWORD_SUCCESS = `${namespace}.changePasswordSuccess`;
export const CHANGE_PASSWORD_ERROR = `${namespace}.changePasswordError`;

export const changePassword = createAction(CHANGE_PASSWORD);
export const changePasswordSuccess = createAction(CHANGE_PASSWORD_SUCCESS);
export const changePasswordError = createAction(CHANGE_PASSWORD_ERROR);
