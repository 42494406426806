import React, { Children, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ListItem from './Item/item';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  variant: PropTypes.oneOf(['ordered', 'unordered']).isRequired,
  type: PropTypes.oneOf(['decimal', 'lower-latin', 'disc', 'circle'])
    .isRequired,
  inList: PropTypes.bool,
  disablePadding: PropTypes.bool,
};
const defaultProps = {
  inList: false,
  disablePadding: false,
};

const getClass = (type, inList, disablePadding) =>
  cx('font-cardif-regular font-light text-gray-700', {
    'pl-4': inList,
    'pt-3': inList && !disablePadding,
    'list-decimal': type === 'decimal',
    'list-lower-latin': type === 'lower-latin',
    'list-disc': type === 'disc',
    'list-circle': type === 'circle',
  });

const List = ({ variant, children, type, inList, disablePadding }) => {
  useEffect(() => {
    Children.forEach(children, (child) => {
      if (child.type.name !== ListItem.name)
        throw new Error(
          'List component children must be an instance of ListItem'
        );
    });
  }, [children]);

  if (variant === 'unordered')
    return (
      <ul className={getClass(type, inList, disablePadding)}>{children}</ul>
    );
  if (variant === 'ordered')
    return (
      <ol className={getClass(type, inList, disablePadding)}>{children}</ol>
    );

  return null;
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
