import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Error from '../Error';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      unselectable: PropTypes.bool,
    })
  ),
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  selectedIcon: PropTypes.node,
};
const defaultProps = {
  options: [],
  value: '',
  label: null,
  selectedIcon: null,
  error: null,
};

const SelectInput = ({
  options,
  selectedIcon,
  value,
  onSelect,
  name,
  label,
  error,
  ...rest
}) => (
  <div>
    <div
      className={cx('relative bg-white shadow-faq rounded-md', {
        'cursor-not-allowed': rest.disabled,
      })}
    >
      {selectedIcon && (
        <span className="absolute top-6 left-3 w-5">{selectedIcon}</span>
      )}
      {label && (
        <label
          className={cx(
            'absolute text-xs left-3 top-1.5 pointer-events-none transition-all ease-out duration-200',
            {
              'text-gray-900': !error,
              'text-red-600': !!error,
            }
          )}
        >
          {label}
          {rest.required ? '*' : ''}
        </label>
      )}
      <select
        {...rest}
        name={name}
        className={cx(
          'w-full pr-9 border-b-4 border-l-0 rounded-t-md rounded-b-sm border-r-0 border-t-0 focus:ring-0 outline-none focus:border-cardif-green-light border-cardif-green-light',
          { 'pl-9': !!selectedIcon, 'py-5': !label, 'mt-7': !!label }
        )}
        value={value}
        onChange={onSelect}
      >
        {options.map((option) => (
          <option
            disabled={option.unselectable}
            key={`${option.value}-${option.label}`}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
    <Error error={error} />
  </div>
);

SelectInput.propTypes = propTypes;
SelectInput.defaultProps = defaultProps;

export default SelectInput;
