import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './custom.css';

const propTypes = {
  step: PropTypes.number.isRequired,
  stepsTotal: PropTypes.number.isRequired,
  stepsTexts: PropTypes.arrayOf(PropTypes.string),
  textPlaces: PropTypes.string,
  stepNumber: PropTypes.bool,
  textClasses: PropTypes.string,
  wrapClasses: PropTypes.string,
};
const defaultProps = {
  stepNumber: true,
  stepsTexts: [],
  textPlaces: 'none sm:cross md:bottom',
  textClasses: '',
  wrapClasses: '',
};

const ProgressBar = ({
  step,
  stepsTotal,
  stepsTexts,
  textPlaces, // Placement of the text based on the tw Res Points
  stepNumber, // show number in the selected step - or just a circle ?
  textClasses, // format res:bottom/top/cross
  wrapClasses,
}) => {
  const textTop = '[&>.top]';
  const textBottom = '[&>.bottom]';

  const addClass = (textSum, text) => {
    const arr = text.split(':');
    let [res, display] = ['', ''];
    if (arr.length > 1) [res, display] = [`${arr[0]}:`, arr[1]];
    else [res, display] = ['', arr[0]];

    switch (display) {
      case 'none':
        return `${textSum}${res}${textTop}:odd:hidden ${res}${textTop}:even:hidden ${res}${textBottom}:odd:hidden ${res}${textBottom}:even:hidden `;
      case 'top':
        return `${textSum}${res}${textTop}:odd:block ${res}${textTop}:even:block ${res}${textBottom}:odd:hidden ${res}${textBottom}:even:hidden `;
      case 'bottom':
        return `${textSum}${res}${textTop}:odd:hidden ${res}${textTop}:even:hidden ${res}${textBottom}:odd:block ${res}${textBottom}:even:block `;
      case 'cross':
        return `${textSum}${res}${textTop}:odd:hidden ${res}${textTop}:even:block ${res}${textBottom}:odd:block ${res}${textBottom}:even:hidden `;
      default:
        return textSum;
    }
  };

  const sDisplayClass = textPlaces.split(' ').reduce(addClass, '');

  return (
    <div className={`wrapper ${wrapClasses} w-full`}>
      {Array.from(Array(stepsTotal).keys()).map((index) => {
        const label =
          stepsTexts[index] !== 'undefined' ? stepsTexts[index] : '';

        return (
          <div
            key={index}
            className={`step-div ${sDisplayClass} relative flex-1 flex flex-col items-center justify-center`}
          >
            <div className={cx('step-text top hidden', textClasses)}>
              {label}
            </div>
            <span
              className={cx(
                'step ',
                index < step
                  ? 'border-cardif-green-light bg-cardif-green-light'
                  : 'border-cardif-gray-medium',
                {
                  selected: step === index + 1,
                  'before:bg-cardif-gray-medium': index > step - 1 && index > 0,
                  'after:bg-cardif-gray-medium':
                    index >= step - 1 && index < stepsTotal - 1,
                  'before:bg-cardif-green-light': index < step && index > 0,
                  'after:bg-cardif-green-light':
                    index < step - 1 && index < stepsTotal - 1,
                  "before:content-['']": index > 0,
                  "after:content-['']": index < stepsTotal - 1,
                }
              )}
            >
              {<img title={label} className="md:hidden" />}
              {stepNumber && step === index + 1 ? (
                index + 1
              ) : (
                <span className="content">
                  {index < step - 1 && <div className="checkmark"></div>}
                </span>
              )}
            </span>
            <div className={cx('step-text bottom hidden', textClasses)}>
              {label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
