import React from 'react';
import { useIntl } from 'react-intl';

import Container from '../../components/layout/Container';
import Spacing from '../../components/layout/Spacing';
import Heading from '../../components/text/Heading';
import Info from './info';
import messages from './messages';

const LegalInfoPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Container bgColor="white">
        <Heading>{formatMessage(messages.heading)}</Heading>
        <Spacing />
      </Container>
      <Info />
    </>
  );
};

export default LegalInfoPage;
