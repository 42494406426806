import { defineMessages } from 'react-intl';

export default defineMessages({
  cardTitle: {
    id: 'settings_forms_notifications_cardTitle',
    defaultMessage: 'Notifikace',
  },
  cardText: {
    id: 'settings_forms_notifications_cardText',
    defaultMessage: 'Vyberte, jakým způsobem si přejete upozornění zasílat:',
  },
  smsLabel: {
    id: 'settings_forms_notifications_smsLabel',
    defaultMessage: 'SMS',
  },
  emailLabel: {
    id: 'settings_forms_notifications_emailLabel',
    defaultMessage: 'E-mail',
  },
  smsEmailLabel: {
    id: 'settings_forms_notifications_smsEmailLabel',
    defaultMessage: 'E-mail a SMS',
  },
  saveButtonLabel: {
    id: 'settings_forms_notifications_saveButtonLabel',
    defaultMessage: 'Uložit',
  },
  saveNotificationsSuccess: {
    id: 'settings_forms_notifications_saveNotificationsSuccess',
    defaultMessage: 'Způsob oznámení byl uložen',
  },
});
