import { defineMessages } from 'react-intl';

const namespace = 'crdf_nols_riskGroups';

export default defineMessages({
  PPD: {
    id: `${namespace}_PPD`,
    defaultMessage: 'Invalidita II. stupně',
  },
  TPD: {
    id: `${namespace}_TPD`,
    defaultMessage: 'Invalidita III. stupně',
  },
  TD: {
    id: `${namespace}_TD`,
    defaultMessage: 'Pracovní neschopnost',
  },
  IU: {
    id: `${namespace}_IU`,
    defaultMessage: 'Ztráta zaměstnání',
  },
  H: {
    id: `${namespace}_H`,
    defaultMessage: 'Hospitalizace',
  },
  TAC: {
    id: `${namespace}_TAC`,
    defaultMessage: 'Ošetřování osoby blízké',
  },
});
