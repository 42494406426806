import { createAction } from 'redux-actions';

const namespace = 'crdf.docView';

export const OPEN_DOCVIEW_MODAL = `${namespace}.openModal`;
export const CLOSE_DOCVIEW_MODAL = `${namespace}.closeModal`;

export const openDocViewModal = createAction(OPEN_DOCVIEW_MODAL);
export const closeDocViewModal = createAction(CLOSE_DOCVIEW_MODAL);

export const DOWNLOAD_NOL_DOC = `${namespace}.downloadNolDoc`;
export const downloadNolDoc = createAction(DOWNLOAD_NOL_DOC);

export const FETCH_NOL_DOC = `${namespace}.fetchNolDoc`;
export const FETCH_NOL_DOC_ERROR = `${namespace}.fetchNolDocError`;
export const FETCH_NOL_DOC_SUCCESS = `${namespace}.fetchNolDocSuccess`;

export const fetchNolDoc = createAction(FETCH_NOL_DOC);
export const fetchNolDocError = createAction(FETCH_NOL_DOC_ERROR);
export const fetchNolDocSuccess = createAction(FETCH_NOL_DOC_SUCCESS);

export const CONVERT_NOL_DOC = `${namespace}.convertNolDoc`;
export const CONVERT_NOL_DOC_ERROR = `${namespace}.convertNolDocError`;
export const CONVERT_NOL_DOC_SUCCESS = `${namespace}.convertNolDocSuccess`;

export const convertNolDoc = createAction(CONVERT_NOL_DOC);
export const convertNolDocError = createAction(CONVERT_NOL_DOC_ERROR);
export const convertNolDocSuccess = createAction(CONVERT_NOL_DOC_SUCCESS);
