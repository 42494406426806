import React from 'react';
import { useIntl } from 'react-intl';

import JourneyLayout from '../../modules/journeys/JourneyLayout/JourneyLayout';
import messages from './messages';
import { BlockIcon } from '../../components/icons';
import RiskDetailsForm from '../../modules/journeys/forms/RiskDetailsForm/RiskDetailsForm';

const JourneyRiskDetailsPage = () => {
  const { formatMessage } = useIntl();

  return (
    <JourneyLayout
      step="risk-details"
      heading={formatMessage(messages.stepFour_title)}
      text=""
      icon={<BlockIcon />}
    >
      <RiskDetailsForm />
    </JourneyLayout>
  );
};

export default JourneyRiskDetailsPage;
