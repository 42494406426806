import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'loginPage_title',
    defaultMessage: 'Ověření identity',
  },
  text: {
    id: 'loginPage_text',
    defaultMessage:
      'Nejprve si potřebujeme ověřit vaši totožnost. Zadejte telefonní kontakt, který jste uvedli při sjednání pojistné smlouvy.',
  },
});
