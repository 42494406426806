import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { FingerprintIcon } from '../../components/icons';
import Container from '../../components/layout/Container';
import Spacing from '../../components/layout/Spacing';
import Heading from '../../components/text/Heading';
import Paragraph from '../../components/text/Paragraph';
import messages from './messages';
import LoginForm from '../../modules/forms/LoginForm';

const LoginPage = () => {
  const { formatMessage } = useIntl();
  const [titleText, setTitleText] = useState(formatMessage(messages.title));
  const [titleIcon, setTitleIcon] = useState(<FingerprintIcon />);
  const [subtitleText, setSubtitleText] = useState(
    formatMessage(messages.text)
  );

  const setPageTitles = ({ title, subtitle, icon }) => {
    if (icon) setTitleIcon(icon);
    if (titleText) setTitleText(title);
    if (subtitle) setSubtitleText(subtitle);
  };

  const resetPageTitles = () => {
    setTitleIcon(<FingerprintIcon />);
    setTitleText(formatMessage(messages.title));
    setSubtitleText(formatMessage(messages.text));
  };

  return (
    <Container variant="form" bgColor="gray">
      <Heading variant="title" icon={titleIcon}>
        {titleText}
      </Heading>
      <Paragraph center>{subtitleText}</Paragraph>
      <Spacing variant="item" />
      <LoginForm
        setPageTitles={setPageTitles}
        resetPageTitles={resetPageTitles}
        setTitleIcon={setTitleIcon}
      />
    </Container>
  );
};

export default LoginPage;
