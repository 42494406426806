import { combineEpics } from 'redux-observable';

import submitPersonalFormEpic from './submitPersonalForm.epic';
import submitContactDataFormEpic from './submitContactDataForm.epic';
import finalizeRegistrationEpic from './finalizeRegistrationEpic';

export default combineEpics(
  submitPersonalFormEpic,
  submitContactDataFormEpic,
  finalizeRegistrationEpic
);
