import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '../../../components/Grid/grid';
import Container from '../../../components/layout/Container';
import Spacing from '../../../components/layout/Spacing';
import { getSettings, resetState } from '../actions';
import ChangePasswordForm from '../ChangePasswordForm';
import EmailForm from '../EmailForm';
import NotificationForm from '../NotificationForm';
import PhoneNumberForm from '../PhoneNumberForm';
import { settingsSettingsSelector } from '../selectors';

const SettingsView = () => {
  const distpatch = useDispatch();
  const { isInitialized } = useSelector(settingsSettingsSelector);

  useEffect(() => {
    distpatch(getSettings());

    return () => distpatch(resetState());
  }, []);

  if (!isInitialized) return null;

  return (
    <Container bgColor="gray">
      <Spacing>
        <Grid gap={3}>
          <NotificationForm />
          <PhoneNumberForm />
          <EmailForm />
          <ChangePasswordForm />
        </Grid>
      </Spacing>
    </Container>
  );
};

export default SettingsView;
