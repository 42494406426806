import { defineMessages } from 'react-intl';

const namespace = 'crdf_auth_loginForm';

export default defineMessages({
  phoneLabel: {
    id: `${namespace}_phoneLabel`,
    defaultMessage: 'Telefonní číslo',
  },
  passwordLabel: {
    id: `${namespace}_passwordLabel`,
    defaultMessage: 'Heslo',
  },
  passwordCreated: {
    id: `${namespace}_passwordCreated`,
    defaultMessage: 'Uživatelský účet máte již vytvořený.',
  },
  passwordResetLink: {
    id: `${namespace}_passwordResetLink`,
    defaultMessage: 'Zapomněli jste heslo?',
  },
  otpLabel: {
    id: `${namespace}_otpLabel`,
    defaultMessage: 'Kód z ověřovací SMS',
  },
  otpError: {
    id: `${namespace}_otpError`,
    defaultMessage: 'Byl zadán špatný potvrzovací kód. Zkuste to prosím znovu.',
  },
  otpDiscardedError: {
    id: `${namespace}_otpDiscardedError`,
    defaultMessage:
      'Zadaný jednorázový kód již není platný. Prosím, vyžádejte si nový.',
  },
  otpSent: {
    id: `${namespace}_otpSent`,
    defaultMessage:
      'Ověřovací SMS jsme právě odeslali na zadané telefonní číslo.',
  },
  resendOtpLink: {
    id: `${namespace}_resendOtpLink`,
    defaultMessage: 'Odeslat ověřovací SMS znovu?',
  },
  submitButtonLabel: {
    id: `${namespace}_submitButtonLabel`,
    defaultMessage: 'Pokračovat',
  },
  phoneNotFound: {
    id: `${namespace}_phoneNotFound`,
    defaultMessage:
      'Zadané telefonní číslo nebylo v naší databázi klientů nalezeno. Pravděpodobně jste při uzavírání pojistné smlouvy uvedli číslo jiné nebo jste se překlepli.',
  },
  phoneNotValid: {
    id: `${namespace}_phoneNotValid`,
    defaultMessage:
      'Toto telefonní číslo není možné zvolit. Prosím zadejte jiné.',
  },
  retryFormWithLink: {
    id: `${namespace}_retryFormWithLink`,
    defaultMessage: 'Zkuste telefonní číslo {link}.',
  },
  retryFormLinkLabel: {
    id: `${namespace}_retryFormLinkLabel`,
    defaultMessage: 'zadat ještě jednou',
  },
  requestCallText: {
    id: `${namespace}_requestCallText`,
    defaultMessage:
      'Pokud se vám stále nedaří přihlásit, zavoláme vám z našeho klientského centra a společně to vyřešíme.',
  },
  backButtonLabel: {
    id: `${namespace}_backButtonLabel`,
    defaultMessage: 'Zpět',
  },
  requestCallButtonLabel: {
    id: `${namespace}_requestCallButtonLabel`,
    defaultMessage: 'Zavolejte mi',
  },
  gdprModalTitle: {
    id: `${namespace}_gdprModalTitle`,
    defaultMessage: 'Informace o zpracování osobních údajů',
  },
  gdprModalBody: {
    id: `${namespace}_gdprModalBody`,
    defaultMessage:
      'Vaše osobní údaje, včetně údajů o zdravotním stavu, které nám prostřednictvím této aplikace sdělíte, jsou u nás v bezpečí. Budeme je zpracovávat pro účely šetření oznámené škodní události, a to z důvodu plnění právní povinnosti, resp. určení, výkonu a obhajoby právních nároků. V případě nutnosti je předáme osobám, které s námi na šetření pojistné události spolupracují. Podrobnou Informaci o zpracování osobních údajů naleznete {link}.',
  },
  gdprModalLink: {
    id: `${namespace}_gdprModalLink`,
    defaultMessage: 'zde',
  },
  gdprModalConfirm: {
    id: `${namespace}_gdprModalConfirm`,
    defaultMessage: 'Potvrzuji',
  },
  tocModalTitle: {
    id: `${namespace}_tocModalTitle`,
    defaultMessage: 'Pravidla používaní této aplikace',
  },
  tocModalBody: {
    id: `${namespace}_tocModalBody`,
    defaultMessage:
      'Veškerá korespondence mezi námi a Vámi týkající se oznámené škodní události bude probíhat pouze elektronicky, a to prostřednictvím této aplikace a prostřednictvím elektronických médií, která si zvolíte v této aplikaci (e-mail, sms). Ke komunikaci prostřednictvím této aplikace nebo zvolených elektronických médií není třeba elektronický podpis a dokument je doručen dnem jeho nahrání do této aplikace. V pochybnostech se má za to, že byl doručen dnem jeho odeslání odesílatelem.',
  },
  tocModalConfirm: {
    id: `${namespace}_tocModalConfirm`,
    defaultMessage: 'Potvrzuji',
  },
  tocAgreeLabel: {
    id: `${namespace}_tocAgreeLabel`,
    defaultMessage: 'Souhlasím s {modalButton}',
  },
  gdprAgreeLabel: {
    id: `${namespace}_gdprAgreeLabel`,
    defaultMessage: 'Potvrzuji, že jsem se seznámil/a s {modalButton}',
  },
  tocModalOpenButton: {
    id: `${namespace}_tocModalOpenButton`,
    defaultMessage: 'Pravidly používání této aplikace',
  },
  gdprModalOpenButton: {
    id: `${namespace}_gdprModalOpenButton`,
    defaultMessage: 'informacemi o zpracování osobních údajů',
  },
  accountLockedError: {
    id: `${namespace}_accountLockedError`,
    defaultMessage:
      'Ověřování hesla je zablokované, prosím zkuste za {minutesToNextAttempt} minut.',
  },
  passwordNotValidError: {
    id: `${namespace}_passwordNotValidError`,
    defaultMessage:
      'Zadané heslo není správné. Zbývá vám {remainingAttempts} pokusů',
  },
  hasServerErrorMessage: {
    id: `${namespace}_hasServerErrorMessage`,
    defaultMessage:
      'K tomuto účtu se momentálně nelze přihlásit, zkuste to, prosím, později.',
  },
  resetPasswordTitle: {
    id: `${namespace}_resetPasswordTitle`,
    defaultMessage: 'Obnovení hesla',
  },
  resetConfirmPar1: {
    id: `${namespace}_resetConfirmPar1`,
    defaultMessage: 'Odeslali jsme Vám odkaz pro obnovení hesla na e-mail:',
  },
  resetConfirmPar2: {
    id: `${namespace}_resetConfirmPar2`,
    defaultMessage:
      'V případě, že nemůžete nalézt příslušný e-mail, zkontrolujte prosím složku SPAM nebo počkejte.',
  },
  otpRefreshSentFlash: {
    id: `${namespace}_otpRefreshSentFlash`,
    defaultMessage: 'Ověřovací SMS byla odeslána.',
  },
  refreshBlocked: {
    id: `${namespace}_refreshBlocked`,
    defaultMessage:
      'Požadavky na tvorbu jednorázových hesel pro dané číslo jsou dočasně zablokované, prosím počkejte 1 minutu.',
  },
  newPasswordRepeatNotMatch: {
    id: `${namespace}_newPasswordRepeatNotMatch`,
    defaultMessage: 'Hesla se neshodují',
  },
  createPasswordTitle: {
    id: `${namespace}_createPasswordTitle`,
    defaultMessage: 'Nastavte si heslo',
  },
  createPasswordText: {
    id: `${namespace}_createPasswordText`,
    defaultMessage:
      'Pro přihlášení ke svému uživatelskému účtu budete potřebovat uživatelské jméno (telefonní číslo) a heslo. Zvolte si heslo, kterým se chcete přihlašovat',
  },
  resetConfirmParSMS1: {
    id: `${namespace}_resetConfirmParSMS1`,
    defaultMessage:
      'Odeslali jsme Vám odkaz pro obnovení hesla SMS zprávou na telefon:',
  },
  logInAfterResettingPassSMS: {
    id: `${namespace}_logInAfterResettingPassSMS`,
    defaultMessage: 'Po obnově hesla se prosím znovu přihlašte',
  },
});
