import { curryN, fromPairs, lensIndex, map, over, pipe, toPairs } from 'ramda';

import {
  LevelsOfDisability as LevelsOfDisabilityEnum,
  NurseRole as NurseRoleEnum,
  PatientRole as PatientRoleEnum,
  ReportReason as ReportReasonEnum,
  ReportReasonCare as ReportReasonCareEnum,
} from './constants';
import messages from './messages';

const getMessage = curryN(2, (prefix, key) => messages[prefix + key]);

const valuesToMessages = curryN(2, (prefix, enu) =>
  pipe(toPairs, map(over(lensIndex(1), getMessage(prefix))), fromPairs)(enu)
);

export const levelsOfDisability = valuesToMessages(
  'levelOfDisability_',
  LevelsOfDisabilityEnum
);
export const NurseRole = valuesToMessages('nurseRole_', NurseRoleEnum);
export const PatientRole = valuesToMessages('patientRole_', PatientRoleEnum);
export const ReportReason = valuesToMessages(
  'reportedIncident_',
  ReportReasonEnum
);
export const ReportReasonCare = valuesToMessages(
  'reportedIncident_',
  ReportReasonCareEnum
);
