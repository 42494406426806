import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ArrowUpIcon } from '../../icons';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  title: PropTypes.string.isRequired,
  startsOpen: PropTypes.bool,
};
const defaultProps = {
  startsOpen: false,
};

const DropCard = ({ title, children, startsOpen }) => {
  const [open, setOpen] = useState(startsOpen);

  return (
    <div className="shadow-faq">
      <div
        onClick={() => setOpen(!open)}
        className={cx(
          'font-cardif-light px-8 py-6 flex justify-between items-center bg-white rounded-md transition-shadow ease-in-out duration-200 hover:shadow-faq-hover',
          {
            'rounded-b-none border-b border-slate-100': open,
          }
        )}
      >
        {title}

        <span
          className={cx(
            'rounded-full shrink-0 w-5 h-5 flex leading-5 justify-center items-center bg-cardif-green-light text-white',
            {
              'rotate-0': open,
              'rotate-180': !open,
            }
          )}
        >
          <ArrowUpIcon />
        </span>
      </div>
      <div
        className={cx('px-4 sm:px-8 py-3 sm:py-6 rounded-b-md bg-transparent', {
          'h-0 py-0 sm:py-0 overflow-hidden': !open,
        })}
      >
        {children}
      </div>
    </div>
  );
};

DropCard.propTypes = propTypes;
DropCard.defaultProps = defaultProps;

export default DropCard;
