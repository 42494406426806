import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import createAjax from './deps/ajax';
import rootReducer from './rootReducer';
import rootEpic from './rootEpic';

export default () => {
  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const epicMiddleware = createEpicMiddleware();

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run((action$, state$) =>
    rootEpic(action$, state$, { window, ajax: createAjax(store) })
  );

  return store;
};
