import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import Grid from '../../../components/Grid/grid';
import Span from '../../../components/Grid/Span/span';
import TextField from '../../forms/fields/textField';
import CheckboxField from '../../forms/fields/checkbox';
import DatePickerField from '../../forms/fields/datePicker';
import CountrySelectField from '../../forms/fields/countrySelect';
import Heading from '../../../components/text/Heading/heading';
import Spacing from '../../../components/layout/Spacing';
import Button from '../../../components/Button/button';
import Slash from '../../../components/Slash/slash';
import messages from './messages';
import validate from './validators';
import SelectField from '../../forms/fields/select';
import { bankCodesSelectSelector } from '../../globalDataLoader/selectors';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

const defaultProps = {
  isSubmitting: false,
};

const BankAccountForm = ({ onSubmit, onClose, isSubmitting }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const bankCodes = useSelector(bankCodesSelectSelector);

  return (
    <Formik
      validateOnBlur
      validateOnChange
      validate={validate({ intl })}
      initialValues={{
        accountNumber: '',
        bankCode: '0100',
        isThirdParty: false,
        thirdParty: {
          firstName: '',
          lastName: '',
          birthDate: null,
          citizenShip: '203',
          street: '',
          city: '',
          postalCode: '',
          country: '203',
        },
      }}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form noValidate>
          <Grid gap={8}>
            <Grid xs={9}>
              <Span xs={5}>
                <TextField
                  required
                  name="accountNumber"
                  label={formatMessage(messages.bankAccountNumberLabel)}
                />
              </Span>
              <Slash />
              <Span xs={3}>
                <SelectField
                  required
                  name="bankCode"
                  options={bankCodes}
                  label={formatMessage(messages.bankCodeLabel)}
                />
              </Span>
              <Span xs={9}>
                <span className="text-gray-500 px-4 pt-2 block text-sm">
                  {formatMessage(messages.bankAccountNumberHelpInfo)}
                </span>
              </Span>
            </Grid>
            <CheckboxField
              name="isThirdParty"
              label={formatMessage(messages.notOwnerLabel)}
            />
            {formik.values.isThirdParty && (
              <>
                <Heading variant="subtitle">
                  {formatMessage(messages.bankAccountOwnerInfo)}
                </Heading>
                <TextField
                  name="thirdParty.firstName"
                  label={formatMessage(messages.firstNameLabel)}
                />
                <TextField
                  name="thirdParty.lastName"
                  label={formatMessage(messages.lastNameLabel)}
                />
                <DatePickerField
                  name="thirdParty.birthDate"
                  label={formatMessage(messages.birthDateLabel)}
                />
                <CountrySelectField
                  name="thirdParty.citizenShip"
                  label={formatMessage(messages.nationalityLabel)}
                />
                <Heading variant="subtitle">
                  {formatMessage(messages.addressHeading)}
                </Heading>
                <TextField
                  name="thirdParty.street"
                  label={formatMessage(messages.streetLabel)}
                />
                <TextField
                  name="thirdParty.city"
                  label={formatMessage(messages.cityLabel)}
                />
                <TextField
                  name="thirdParty.postalCode"
                  filterPattern={/^[0-9]+$/}
                  label={formatMessage(messages.postalCodeLabel)}
                />
                <CountrySelectField
                  name="thirdParty.country"
                  label={formatMessage(messages.countryLabel)}
                />
              </>
            )}
          </Grid>
          <Spacing variant="item" />
          <Grid xs={2} gap={4}>
            <Button
              full
              variant="gray"
              onClick={onClose}
              label={formatMessage(messages.stepFive_backButtonLabel)}
            />
            <Button
              full
              disabled={!formik.isValid || isSubmitting}
              type="submit"
              label={formatMessage(messages.stepFive_continueButtonLabel)}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

BankAccountForm.propTypes = propTypes;
BankAccountForm.defaultProps = defaultProps;

export default BankAccountForm;
