import { binary, both, curryN, flip, gte, lte, pipe } from 'ramda';
import differenceInYears from 'date-fns/fp/differenceInYears';

import { fetchValue } from './util';

export default curryN(3, (min, max, value) =>
  pipe(
    fetchValue,
    flip(binary(differenceInYears))(new Date()),
    both(lte(min), gte(max))
  )(value)
);
