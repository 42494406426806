import { defineMessages } from 'react-intl';

const namespace = 'crdf_registration_personalDataForm';

export default defineMessages({
  firstNameLabel: {
    id: `${namespace}_firstNameLabel`,
    defaultMessage: 'Křestní jméno',
  },
  lastNameLabel: {
    id: `${namespace}_lastNameLabel`,
    defaultMessage: 'Přijmení',
  },
  birthNumberLabel: {
    id: `${namespace}_birthNumberLabel`,
    defaultMessage: 'Rodné číslo',
  },
  birthNumberPostfixLabel: {
    id: `${namespace}_birthNumberPostfixLabel`,
    defaultMessage: 'Koncovka',
  },
  isForeignerLabel: {
    id: `${namespace}_isForeignerLabel`,
    defaultMessage: 'Jsem cizinec / I do not have a Czech birth number',
  },
  birthDateLabel: {
    id: `${namespace}_birthDateLabel`,
    defaultMessage: 'Datum narození',
  },
  nationalityLabel: {
    id: `${namespace}_nationalityLabel`,
    defaultMessage: 'Státní příslušnost',
  },
  isSelfEmployedLabel: {
    id: `${namespace}_isSelfEmployedLabel`,
    defaultMessage: 'Jsem osoba samostatně výdělečně činná (OSVČ)',
  },
  businessIdLabel: {
    id: `${namespace}_businessIdLabel`,
    defaultMessage: 'IČO',
  },
  stepOne_continueButtonLabel: {
    id: `${namespace}_stepOne_continueButtonLabel`,
    defaultMessage: 'Pokračovat',
  },
});
