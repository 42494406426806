import { handleActions } from 'redux-actions';

import { GET_SETTINGS_SUCCESS } from '../actions';
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
} from './actions';

const initialState = {
  isSubmitting: false,
  submitSucceeded: false,
  originalPasswordInvalid: false,
  newPasswordUsedInPast: false,
  hasServerError: false,
  changeTS: Date.now(),
  initialValues: {
    newPassword: '',
    newPasswordRepeat: '',
    originalPassword: '',
  },
};

export default handleActions(
  {
    [GET_SETTINGS_SUCCESS]: (state) => ({
      ...state,
      ...initialState,
    }),
    [CHANGE_PASSWORD]: (state) => ({ ...state, isSubmitting: true }),
    [CHANGE_PASSWORD_SUCCESS]: (state) => ({
      ...state,
      isSubmitting: false,
      changeTS: Date.now(),
      originalPasswordInvalid: false,
      newPasswordUsedInPast: false,
      submitSucceeded: true,
      hasServerError: false,
    }),
    [CHANGE_PASSWORD_ERROR]: (state, { payload }) => ({
      ...state,
      isSubmitting: false,
      submitSucceeded: false,
      hasServerError: payload.status >= 500,
      originalPasswordInvalid: !!(
        payload.status === 400 && payload.response?.code === '014'
      ),
      newPasswordUsedInPast: !!(
        payload.status === 400 && payload.response?.code === '006'
      ),
    }),
  },
  initialState
);
