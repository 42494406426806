import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { deinit, init } from './actions';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const ActiveJourneyProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(init());

    return () => dispatch(deinit());
  }, []);

  return <>{children}</>;
};

ActiveJourneyProvider.propTypes = propTypes;

export default ActiveJourneyProvider;
