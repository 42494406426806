import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

import Checkbox from '../../../components/form/Checkbox/checkbox';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
};
const defaultProps = {};

const CheckboxField = ({ name, label, ...rest }) => {
  const [field, meta] = useField({ name });

  return (
    <Checkbox
      {...rest}
      label={label}
      name={name}
      error={meta.touched && meta.error ? meta.error : null}
      value={field.value}
      onChange={field.onChange}
    />
  );
};

CheckboxField.propTypes = propTypes;
CheckboxField.defaultProps = defaultProps;

export default CheckboxField;
