import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { registrationSelector } from '../selectors';

const SubmitErrorWatcher = () => {
  const { setFieldError } = useFormikContext();
  const { personalDataErrors } = useSelector(registrationSelector);

  useEffect(() => {
    personalDataErrors.forEach((e) => setFieldError(e.field, e.message));
  }, [personalDataErrors]);

  return null;
};

export default SubmitErrorWatcher;
