import { createAction } from 'redux-actions';

const namespace = 'crdf.journeys.activeJourney.create';

export const CREATE_JOURNEY = `${namespace}.createJourney`;
export const CREATE_JOURNEY_SUCCESS = `${namespace}.createJourneySuccess`;
export const CREATE_JOURNEY_ERROR = `${namespace}.createJourneyError`;

export const DESTROY_JOURNEY = `${namespace}.destroyJourney`;
export const DESTROY_JOURNEY_SUCCESS = `${namespace}.destroyJourneySuccess`;
export const DESTROY_JOURNEY_ERROR = `${namespace}.destroyJourneyError`;

export const createJourney = createAction(CREATE_JOURNEY);
export const createJourneySuccess = createAction(CREATE_JOURNEY_SUCCESS);
export const createJourneyError = createAction(CREATE_JOURNEY_ERROR);

export const destroyJourney = createAction(DESTROY_JOURNEY);
export const destroyJourneySuccess = createAction(DESTROY_JOURNEY_SUCCESS);
export const destroyJourneyError = createAction(DESTROY_JOURNEY_ERROR);
