import React from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import FlashCard from '../../../../components/cards/FlashCard';
import messages from '../messages';

const propTypes = {};
const defaultProps = {};

const NoContractFoundCard = () => {
  const { formatMessage } = useIntl();

  return (
    <FlashCard
      variant="success"
      message={formatMessage(messages.noContractsFound)}
    />
  );
};

NoContractFoundCard.propTypes = propTypes;
NoContractFoundCard.defaultProps = defaultProps;

export default NoContractFoundCard;
