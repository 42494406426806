import { combineEpics } from 'redux-observable';

import authEpic from '../modules/auth/epics';
import navigationEpic from '../modules/navigation/epics';
import notificationsEpic from '../modules/notifications/epics';
import settingsEpic from '../modules/settings/epics';
import nolsEpic from '../modules/nol/epics';
import npsEpic from '../modules/nps/epics';
import journeysEpic from '../modules/journeys/epics';
import registrationEpic from '../modules/registration/epics';
import uploadEpic from '../modules/upload/epics';
import updateBankAccountModalEpic from '../modules/modals/updateBankAccount/epics';
import callMeBackEpic from '../modules/callMeBack/epic';
import docViewEpic from '../modules/docView/epics';
import globalDataLoaderEpic from '../modules/globalDataLoader/epics';

export default combineEpics(
  authEpic,
  navigationEpic,
  notificationsEpic,
  settingsEpic,
  nolsEpic,
  npsEpic,
  journeysEpic,
  registrationEpic,
  uploadEpic,
  updateBankAccountModalEpic,
  callMeBackEpic,
  docViewEpic,
  globalDataLoaderEpic
);
