import { combineEpics, ofType } from 'redux-observable';
import { filter, map as rxMap } from 'rxjs/operators';
import { pathSatisfies, unary } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

import {
  FETCH_ACTIVE_JOURNEYS_SUCCESS,
  FETCH_JOURNEY_STATE_SUCCESS,
  initSuccess,
} from '../../actions';

const hasJourney$ = (action$) =>
  action$.pipe(
    ofType(FETCH_JOURNEY_STATE_SUCCESS),
    rxMap(() => initSuccess())
  );

const hasNoJourney$ = (action$) =>
  action$.pipe(
    ofType(FETCH_ACTIVE_JOURNEYS_SUCCESS),
    filter(unary(pathSatisfies(isNilOrEmpty, ['payload', 'activeJourneyId']))),
    rxMap(() => initSuccess())
  );

export default combineEpics(hasJourney$, hasNoJourney$);
