import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import createStore from './createStore';

const store = createStore();

export const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

const StoreProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

StoreProvider.propTypes = propTypes;

export default StoreProvider;
