import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Modal from '../../../components/Modal';
import Notification from '../../../components/Notification';
import Heading from '../../../components/text/Heading';
import Paragraph from '../../../components/text/Paragraph';
import { MailReadIcon } from '../../../components/icons';
import Container from '../../../components/layout/Container';
import Grid from '../../../components/Grid/grid';
import Button from '../../../components/Button/button';
import Spacing from '../../../components/layout/Spacing';
import messages from '../messages';
import ModalAttachment from './Attachments';
import { markRead } from '../actions';

const propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape(),
};
const defaultProps = {
  data: {},
};

const NotificationModal = ({ date, title, data }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { formatTime, formatDate, formatMessage } = useIntl();

  return (
    <Modal
      hasCross
      bgColor="gray"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      isOpen={open}
      button={
        <Notification
          isRead={data.status !== 'CREATED'}
          date={date}
          title={title}
        />
      }
    >
      <Container variant="modal" bgColor="gray">
        <Heading variant="subtitle" icon={<MailReadIcon />}>
          {title}
        </Heading>
        <Paragraph center>
          <FormattedMessage
            {...messages.deliveredAt}
            values={{ date: formatDate(date), time: formatTime(date) }}
          />
        </Paragraph>
        <Spacing>
          <Container>
            <Spacing variant="modal">
              <Paragraph>{data.text}</Paragraph>
            </Spacing>
            <ModalAttachment data={data.attachments} />
          </Container>
        </Spacing>
        {data.status === 'CREATED' && (
          <Grid xs={2} gap={4}>
            <Button
              onClick={() => {
                setOpen(false);
                dispatch(markRead({ id: data.uuid }));
              }}
              label={formatMessage(messages.readButtonLabel)}
            />
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="gray"
              label={formatMessage(messages.remindMeLaterButtonLabel)}
            />
          </Grid>
        )}
        {data.status !== 'CREATED' && (
          <div className="text-center">
            <Button
              onClick={() => setOpen(false)}
              label={formatMessage(messages.readButtonLabel)}
            />
          </div>
        )}
      </Container>
    </Modal>
  );
};

NotificationModal.propTypes = propTypes;
NotificationModal.defaultProps = defaultProps;

export default NotificationModal;
