import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';

import messages from './messages';
import Grid from '../../../../components/Grid';
import Span from '../../../../components/Grid/Span';
import TextField from '../../../forms/fields/textField';
import Slash from '../../../../components/Slash/slash';
import CheckboxField from '../../../forms/fields/checkbox';
import DatePickerField from '../../../forms/fields/datePicker';
import CountrySelectField from '../../../forms/fields/countrySelect';
import Button from '../../../../components/Button/button';
import {
  contextSelector,
  formSelector,
} from '../../ActiveJourneyProvider/selectors';
import {
  formMount,
  formUnmount,
  submitPersonalDataForm,
} from '../../ActiveJourneyProvider/actions';
import validate from './validators';

const JourneyPersonalDataForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { formatMessage } = intl;
  const { isInitialized, personalData } = useSelector(contextSelector);
  const { isSubmitting } = useSelector(formSelector);

  useEffect(() => {
    dispatch(formMount());

    return () => dispatch(formUnmount());
  }, []);

  if (!isInitialized) return null;

  return (
    <Formik
      validateOnChange
      validateOnBlur
      initialValues={personalData}
      validate={validate({ intl })}
      onSubmit={(values) => dispatch(submitPersonalDataForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <Grid gap={8} extraClass="pb-8">
            <TextField
              required
              name="firstName"
              label={formatMessage(messages.firstNameLabel)}
            />
            <TextField
              required
              name="lastName"
              label={formatMessage(messages.lastNameLabel)}
            />
            <TextField required disabled name="email" label="E-mail" />
            <Grid xs={7}>
              <Span xs={4}>
                <TextField
                  required
                  disabled
                  name="birthNumber.number"
                  label={formatMessage(messages.birthNumberLabel)}
                />
              </Span>
              <Slash />
              <Span xs={2}>
                <TextField
                  required
                  disabled
                  name="birthNumber.suffix"
                  label={formatMessage(messages.birthNumberPostfixLabel)}
                />
              </Span>
            </Grid>
            <CheckboxField
              name="foreigner"
              label={formatMessage(messages.isForeignerLabel)}
            />
            <DatePickerField
              required
              disabled
              name="birthDate"
              label={formatMessage(messages.birthDateLabel)}
            />
            <CountrySelectField
              name="nationality"
              label={formatMessage(messages.nationalityLabel)}
            />
            <CheckboxField
              name="isSelfEmployed"
              label={formatMessage(messages.isSelfEmployedLabel)}
            />
            {formik.values.isSelfEmployed && (
              <TextField
                required
                name="businessId"
                filterPattern={/^[0-9]+$/}
                label={formatMessage(messages.businessIdLabel)}
              />
            )}
          </Grid>
          <Button
            center
            disabled={isSubmitting}
            type="submit"
            label={formatMessage(messages.stepOne_continueButtonLabel)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default JourneyPersonalDataForm;
