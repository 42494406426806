import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap, map as rxMap, retry } from 'rxjs/operators';

import { fetchBankCodesError, fetchBankCodesSuccess, INIT } from '../actions';

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(INIT),
    switchMap(() =>
      ajax({
        url: '/gateway-server/notice-of-loss/bank-codes',
      }).pipe(
        rxMap((res) => fetchBankCodesSuccess(res.response?.bankCodes)),
        retry(3),
        catchError((e) => of(fetchBankCodesError(e)))
      )
    )
  );
