import {
  applySpec,
  constructN,
  converge,
  evolve,
  identity,
  map,
  mergeAll,
  path,
  pipe,
  unapply,
  unary,
  when,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { ofType } from 'redux-observable';
import { forkJoin, of } from 'rxjs';
import { switchMap, map as rxMap, catchError, takeUntil } from 'rxjs/operators';

import {
  CANCEL_FETCH_NOL_DETAIL,
  fetchNolDetailError,
  fetchNolDetailSuccess,
  FETCH_NOL_DETAIL,
} from './actions';

const dateMaybe = when(isNotNilOrEmpty, constructN(1, Date));

const parse = unary(
  pipe(
    converge(unapply(mergeAll), [
      path(['nolDetail', 'response']),
      pipe(path(['nolHistory', 'response']), applySpec({ history: identity })),
      pipe(
        path(['nolRequirements', 'response']),
        applySpec({ requirements: identity })
      ),
    ]),
    evolve({
      reportedOn: dateMaybe,
      hospitalization: when(
        isNotNilOrEmpty,
        evolve({
          hospitalizationFrom: dateMaybe,
          hospitalizationTo: dateMaybe,
        })
      ),
      userPersonalData: evolve({ birthDate: dateMaybe }),
      history: map(evolve({ dateOfOccurrence: dateMaybe })),
      requirements: map(evolve({ insertionDate: dateMaybe })),
    }),
    unary(fetchNolDetailSuccess)
  )
);

export default (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(FETCH_NOL_DETAIL),
    switchMap(({ payload }) =>
      forkJoin({
        nolDetail: ajax({
          url: `/gateway-server/nol/${payload}/preview`,
          useAuth: true,
        }),
        nolHistory: ajax({
          url: `/gateway-server/nol-history/by-nol-uuid/${payload}`,
          useAuth: true,
        }),
        nolRequirements: ajax({
          url: `/gateway-server/requirements/by-parent/${payload}`,
          useAuth: true,
        }),
      }).pipe(
        rxMap(parse),
        catchError((e) => of(fetchNolDetailError(e))),
        takeUntil(action$.pipe(ofType(CANCEL_FETCH_NOL_DETAIL)))
      )
    )
  );
