import React from 'react';
import { useIntl } from 'react-intl';

import JourneyLayout from '../../modules/journeys/JourneyLayout/JourneyLayout';
import messages from '../journeys/messages';
import { PersonIcon } from '../../components/icons';
import RegistrationPersonalDataForm from '../../modules/registration/PersonalDataForm/PersonalDataForm';

const RegistrationPersonalDataPage = () => {
  const { formatMessage } = useIntl();
  return (
    <JourneyLayout
      step="personal"
      heading={formatMessage(messages.stepOne_title)}
      text={formatMessage(messages.stepOne_text)}
      icon={<PersonIcon />}
    >
      <RegistrationPersonalDataForm />
    </JourneyLayout>
  );
};

export default RegistrationPersonalDataPage;
