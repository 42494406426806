import { allPass, length, lte, pipe, test } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

const conditions = [
  pipe(length, lte(8)),
  test(/\p{Lu}/u),
  test(/\p{Ll}/u),
  test(/[0-9]/),
  test(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\\/?~]/),
];

export default (values) => {
  const errors = {};

  if (isNilOrEmpty(values.originalPassword)) {
    errors.originalPassword = 'Toto pole je povinné';
  }

  if (!allPass(conditions)(values.newPassword)) {
    errors.newPasswordHidden = 'invalid';
  }

  if (values.newPassword !== values.newPasswordRepeat) {
    errors.doNotMatch = true;
  }

  return errors;
};
