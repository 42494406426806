import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { contextSelector } from '../ActiveJourneyProvider/selectors';
import Loader from '../../../components/Loader/loader';
import Spacing from '../../../components/layout/Spacing';

export const propTypes = {};
const defaultProps = {};

const JourneyInitializedRouteGuard = () => {
  const { isInitialized } = useSelector(contextSelector);

  if (!isInitialized)
    return (
      <Spacing>
        <Loader message="" />
      </Spacing>
    );

  return <Outlet />;
};

JourneyInitializedRouteGuard.propTypes = propTypes;
JourneyInitializedRouteGuard.defaultProps = defaultProps;

export default JourneyInitializedRouteGuard;
