import { curryN, identity, ifElse, includes } from 'ramda';

const isNewOrPending = (status) => includes(status, ['NEW', 'PENDING']);

const transoformStatus = (isWaitingForDocs) => () =>
  isWaitingForDocs ? 'WAITING_FOR_DOCUMENTS' : 'PROCESSING';

export default curryN(2, (isWaitingForDocs, status) =>
  ifElse(isNewOrPending, transoformStatus(isWaitingForDocs), identity)(status)
);
