import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';

import messages from './messages';
import Grid from '../../../components/Grid';
import Span from '../../../components/Grid/Span';
import TextField from '../../forms/fields/textField';
import Slash from '../../../components/Slash/slash';
import CheckboxField from '../../forms/fields/checkbox';
import DatePickerField from '../../forms/fields/datePicker';
import CountrySelectField from '../../forms/fields/countrySelect';
import Button from '../../../components/Button/button';
import validate from './validators';
import { submitPersonalDataForm } from '../actions';
import { registrationSelector } from '../selectors';
import DoBAutocomplete from './DoBAutocomplete';
import { accountFormValuesSelector } from '../../auth/account/selectors';
import SubmitErrorWatcher from './SubmitErrorWatcher';

const RegistrationPersonalDataForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { formatMessage } = intl;
  const { personalDataFormSubmitting } = useSelector(registrationSelector);
  const initialValues = useSelector(accountFormValuesSelector);

  return (
    <Formik
      validateOnChange
      validateOnBlur
      initialValues={initialValues}
      validate={validate({ intl })}
      onSubmit={(values) => dispatch(submitPersonalDataForm(values))}
    >
      {(formik) => (
        <Form noValidate>
          <DoBAutocomplete />
          <SubmitErrorWatcher />
          <Grid gap={8} extraClass="pb-8">
            <TextField
              required
              name="firstName"
              label={formatMessage(messages.firstNameLabel)}
            />
            <TextField
              required
              name="lastName"
              label={formatMessage(messages.lastNameLabel)}
            />
            <TextField required name="email" label="E-mail" />
            <Grid xs={7}>
              <Span xs={4}>
                <TextField
                  required
                  name="birthNumber.number"
                  label={formatMessage(messages.birthNumberLabel)}
                />
              </Span>
              <Slash />
              <Span xs={2}>
                <TextField
                  required
                  name="birthNumber.suffix"
                  label={formatMessage(messages.birthNumberPostfixLabel)}
                />
              </Span>
            </Grid>
            <CheckboxField
              name="foreigner"
              label={formatMessage(messages.isForeignerLabel)}
            />
            <DatePickerField
              required
              name="birthDate"
              label={formatMessage(messages.birthDateLabel)}
            />
            <CountrySelectField
              name="nationality"
              label={formatMessage(messages.nationalityLabel)}
            />
            <CheckboxField
              name="isSelfEmployed"
              label={formatMessage(messages.isSelfEmployedLabel)}
            />
            {formik.values.isSelfEmployed && (
              <TextField
                required
                name="businessId"
                filterPattern={/^[0-9]+$/}
                label={formatMessage(messages.businessIdLabel)}
              />
            )}
          </Grid>
          <Button
            center
            disabled={personalDataFormSubmitting || !formik.isValid}
            type="submit"
            label={formatMessage(messages.stepOne_continueButtonLabel)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationPersonalDataForm;
