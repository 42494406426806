import { createAction } from 'redux-actions';

const namespace = 'crdf.upload';

export const UPLOAD_DOCUMENTS = `${namespace}.uploadDocuments`;
export const UPLOAD_DOCUMENTS_SUCCESS = `${namespace}.uploadDocumentsSuccess`;
export const UPLOAD_DOCUMENTS_ERROR = `${namespace}.uploadDocumentsError`;
export const UPLOAD_DOCUMENTS_FILE_ERRORS = `${namespace}.uploadDocumentsFileErrors`;

export const UPLOAD_FEEDBACK_OPEN = `${namespace}.openUploadFeedback`;
export const UPLOAD_FEEDBACK_CLOSE = `${namespace}.closeUploadFeedback`;

export const UPLOAD_MODAL_CLOSE_ON_FINISH = `${namespace}.closeUploadModalOnFinish`;

export const uploadDocuments = createAction(UPLOAD_DOCUMENTS);
export const uploadDocumentsSuccess = createAction(UPLOAD_DOCUMENTS_SUCCESS);
export const uploadDocumentsError = createAction(UPLOAD_DOCUMENTS_ERROR);
export const uploadDocumentsFileErrors = createAction(
  UPLOAD_DOCUMENTS_FILE_ERRORS
);

export const openUploadFeedback = createAction(UPLOAD_FEEDBACK_OPEN);
export const closeUploadFeedback = createAction(UPLOAD_FEEDBACK_CLOSE);

export const closeUploadModalOnFinish = createAction(
  UPLOAD_MODAL_CLOSE_ON_FINISH
);
