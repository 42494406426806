import { handleActions } from 'redux-actions';

import { GET_SETTINGS_SUCCESS } from '../actions';

const initialState = {
  isInitialized: false,
  settings: {},
};

export default handleActions(
  {
    [GET_SETTINGS_SUCCESS]: (state, { payload }) => ({
      isInitialized: true,
      settings: payload,
    }),
  },
  initialState
);
