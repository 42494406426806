import React from 'react';
// import PropTypes from 'prop-types';

const propTypes = {};
const defaultProps = {};

const Slash = () => <span className="flex items-center justify-center">/</span>;

Slash.propTypes = propTypes;
Slash.defaultProps = defaultProps;

export default Slash;
