import {
  applySpec,
  constructN,
  path,
  pathOr,
  pipe,
  prop,
  unary,
  when,
} from 'ramda';
import { isNotNil } from 'ramda-adjunct';
import { ofType } from 'redux-observable';
import {
  combineLatestWith,
  switchMap,
  takeUntil,
  map as rxMap,
  mergeMap,
  take as rxTake,
  catchError,
  of,
  withLatestFrom,
  defer,
} from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { navigate } from '../../../navigation/actions';
import {
  CREATE_PASSWORD,
  LOGIN_DEINIT,
  LOGIN_INIT,
  VERIFY_OTP,
  VERIFY_PASSWORD,
  fetchTokensSuccess,
  fetchTokensError,
  VERIFY_OTP_SUCCESS,
  VERIFY_PASSWORD_SUCCESS,
} from '../actions';
import {
  otpIdSelector,
  usernameSelector,
  afterLoginSelector,
} from '../selectors';

// This flow needs the following information to run:
// 1. Username  => GET_PHONE_STATUS
// 2. Password  => VERIFY_PASSWORD | CREATE_PASSWORD
// 3. OTP ID    => VERIFY_OTP
//
// Once all is collected we can fetch the auth tokens

// takes array of actions and exracts relevant info from each
// CARE! => this function is dependent on the order of parameters and by
//          extension the order of observables in combineLatestWith!
const mapToRequestPayload = unary(
  pipe(
    applySpec({
      username: pipe(path([1]), unary(usernameSelector)),
      password: path([0, 0, 'payload', 'password']),
      otpId: pipe(path([1]), unary(otpIdSelector)),
    })
  )
);

const parse = pipe(
  prop('response'),
  applySpec({
    accessToken: pathOr(null, ['access_token']),
    refreshToken: pathOr(null, ['refresh_token']),
    tokenExpiresAt: pipe(
      pathOr(null, ['expires_in']),
      when(
        isNotNil,
        pipe((v) => v * 1000 + Date.now(), constructN(1, Date))
      )
    ),
    tokenType: pathOr(null, ['token_type']),
    registrationCompleted: pathOr(null, ['registrationCompleted']),
  })
);

export default (action$, state$) =>
  action$.pipe(
    ofType(LOGIN_INIT),
    switchMap(() =>
      action$.pipe(
        ofType(VERIFY_PASSWORD, CREATE_PASSWORD),
        combineLatestWith(
          action$.pipe(ofType(VERIFY_OTP)),
          action$.pipe(ofType(VERIFY_OTP_SUCCESS)), // Has to wait for OTP verified
          action$.pipe(ofType(VERIFY_PASSWORD_SUCCESS)) // Has to wait for password step
        ),
        rxTake(1),
        withLatestFrom(state$),
        rxMap(mapToRequestPayload),
        mergeMap((data) =>
          ajax({
            url: '/gateway-server/accounts/login',
            method: 'POST',
            headers: {
              Authorization: `Basic ${btoa('test:test')}`, // Security FTW *facepalm* => ask backend guys
            },
            body: { ...data, grant_type: 'password' },
          })
        ),
        rxMap(pipe(parse, unary(fetchTokensSuccess))),
        withLatestFrom(state$),
        mergeMap(([a, state]) => {
          const afa = afterLoginSelector(state);
          if (afa) {
            return defer(() => [a, afa]);
          }
          return of(a, navigate({ to: '/nols' }));
        }),
        catchError(pipe(unary(fetchTokensError), of)), // Come up with error handling strategy
        takeUntil(action$.pipe(ofType(LOGIN_DEINIT)))
      )
    )
  );
