import { createAction } from 'redux-actions';

const namespace = 'crdf.modules.inactivity';

export const TRACK_ACTIVITY = `${namespace}.trackActivity`;
export const TRIGGER_INACTIVITY_TIMER = `${namespace}.triggerInactivityTimer`;
export const PROLONG_SESSION = `${namespace}.prolongSession`;

export const trackActivity = createAction(TRACK_ACTIVITY);
export const triggerInactivityTimer = createAction(TRIGGER_INACTIVITY_TIMER);
export const prolongSession = createAction(PROLONG_SESSION);
