import { createAction } from 'redux-actions';

const namespace = 'crdf.nols.detail';

export const FETCH_NOL_DETAIL = `${namespace}.fetchNolDetail`;
export const FETCH_NOL_DETAIL_SUCCESS = `${namespace}.fetchNolDetailSuccess`;
export const FETCH_NOL_DETAIL_ERROR = `${namespace}.fetchNolDetailError`;
export const CANCEL_FETCH_NOL_DETAIL = `${namespace}.cancelFetchNolDetail`;

export const fetchNolDetail = createAction(FETCH_NOL_DETAIL);
export const fetchNolDetailSuccess = createAction(FETCH_NOL_DETAIL_SUCCESS);
export const fetchNolDetailError = createAction(FETCH_NOL_DETAIL_ERROR);
export const cancelFetchNolDetail = createAction(CANCEL_FETCH_NOL_DETAIL);
