import { createAction } from 'redux-actions';

const namespace = 'crdf.auth.login';

export const LOGIN_INIT = `${namespace}.init`;
export const LOGIN_DEINIT = `${namespace}.deinit`;

export const GET_PHONE_STATUS = `${namespace}.getPhoneStatus`;
export const GET_PHONE_STATUS_ERROR = `${namespace}.getPhoneStatusError`;
export const GET_PHONE_STATUS_SUCCESS = `${namespace}.getPhoneStatusSuccess`;

export const VERIFY_PASSWORD = `${namespace}.verifyPassword`;
export const VERIFY_PASSWORD_ERROR = `${namespace}.verifyPasswordError`;
export const VERIFY_PASSWORD_SUCCESS = `${namespace}.verifyPasswordSuccess`;

export const VERIFY_OTP = `${namespace}.verifyOtp`;
export const VERIFY_OTP_ERROR = `${namespace}.verifyOtpError`;
export const VERIFY_OTP_SUCCESS = `${namespace}.verifyOtpSuccess`;

export const CREATE_ACCOUNT_ERROR = `${namespace}.createAccountError`;

export const CREATE_PASSWORD = `${namespace}.createPassword`;
export const CREATE_PASSWORD_ERROR = `${namespace}.createPasswordError`;
export const CREATE_PASSWORD_SUCCESS = `${namespace}.createPasswordSuccess`;

export const FETCH_TOKENS = `${namespace}.fetchTokens`;
export const FETCH_TOKENS_ERROR = `${namespace}.fetchTokensError`;
export const FETCH_TOKENS_SUCCESS = `${namespace}.fetchTokensSuccess`;

export const SET_OTP_ID = `${namespace}.setOtpId`;
export const SET_SERVER_HAS_ERROR = `${namespace}.setServerHasError`;
export const UPDATE_STATE = `${namespace}.updateState`;

export const AFTER_LOGIN_ACTION = `${namespace}.afterLoginAction`;

export const REQUEST_PASSWORD_RESET = `${namespace}.requestPasswordReset`;
export const REQUEST_PASSWORD_RESET_SUCCESS = `${namespace}.requestPasswordResetSuccess`;
export const REQUEST_PASSWORD_RESET_ERROR = `${namespace}.requestPasswordResetError`;

export const VERIFY_PASSWORD_RESET = `${namespace}.verifyPasswordReset`;
export const VERIFY_PASSWORD_RESET_SUCCESS = `${namespace}.verifyPasswordResetSuccess`;
export const VERIFY_PASSWORD_RESET_ERROR = `${namespace}.verifyPasswordResetError`;

export const RESEND_OTP = `${namespace}.resendOtp`;
export const RESEND_OTP_SUCCESS = `${namespace}.resendOtpSuccess`;
export const RESEND_OTP_ERROR = `${namespace}.resendOtpError`;

export const SET_PASSWORD_RESET_STEP = `${namespace}.setPasswordResetStep`;

export const loginInit = createAction(LOGIN_INIT);
export const loginDeinit = createAction(LOGIN_DEINIT);

export const getPhoneStatus = createAction(GET_PHONE_STATUS);
export const getPhoneStatusError = createAction(GET_PHONE_STATUS_ERROR);
export const getPhoneStatusSuccess = createAction(GET_PHONE_STATUS_SUCCESS);

export const verifyPassword = createAction(VERIFY_PASSWORD);
export const verifyPasswordError = createAction(VERIFY_PASSWORD_ERROR);
export const verifyPasswordSuccess = createAction(VERIFY_PASSWORD_SUCCESS);

export const verifyOtp = createAction(VERIFY_OTP);
export const verifyOtpError = createAction(VERIFY_OTP_ERROR);
export const verifyOtpSuccess = createAction(VERIFY_OTP_SUCCESS);

export const createAccountError = createAction(CREATE_ACCOUNT_ERROR);

export const createPassword = createAction(CREATE_PASSWORD);
export const createPasswordError = createAction(CREATE_PASSWORD_ERROR);
export const createPasswordSuccess = createAction(CREATE_PASSWORD_SUCCESS);

export const fetchTokens = createAction(FETCH_TOKENS);
export const fetchTokensError = createAction(FETCH_TOKENS_ERROR);
export const fetchTokensSuccess = createAction(FETCH_TOKENS_SUCCESS);

export const setOtpId = createAction(SET_OTP_ID);
export const setServerHasError = createAction(SET_SERVER_HAS_ERROR);
export const updateState = createAction(UPDATE_STATE);

export const afterLoginAction = createAction(AFTER_LOGIN_ACTION);

export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET);
export const requestPasswordResetSuccess = createAction(
  REQUEST_PASSWORD_RESET_SUCCESS
);
export const requestPasswordResetError = createAction(
  REQUEST_PASSWORD_RESET_ERROR
);

export const verifyPasswordReset = createAction(VERIFY_PASSWORD_RESET);
export const verifyPasswordResetSuccess = createAction(
  VERIFY_PASSWORD_RESET_SUCCESS
);
export const verifyPasswordResetError = createAction(
  VERIFY_PASSWORD_RESET_ERROR
);
export const resendOtp = createAction(RESEND_OTP);
export const resendOtpSuccess = createAction(RESEND_OTP_SUCCESS);
export const resendOtpError = createAction(RESEND_OTP_ERROR);

export const setPasswordResetStep = createAction(SET_PASSWORD_RESET_STEP);
