import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  bgColor: PropTypes.oneOf(['gray', 'white']),
  variant: PropTypes.oneOf([
    'layout',
    'content',
    'form',
    'modal',
    'smaller-content',
  ]),
  stripPadding: PropTypes.bool,
  extraClass: PropTypes.string,
};

const defaultProps = {
  bgColor: 'white',
  variant: 'content',
  stripPadding: false,
  extraClass: '',
};

const Container = ({
  children,
  bgColor,
  variant,
  stripPadding,
  extraClass,
}) => (
  <div
    className={cx({
      'bg-white': bgColor === 'white',
      'bg-cardif-gray-light': bgColor === 'gray',
    })}
  >
    <div
      className={cx(
        'mx-auto w-full',
        {
          'px-4': !stripPadding,
          'max-w-sm': variant === 'form',
          'max-w-[400px]': variant === 'modal',
          'max-w-full md:max-w-xl lg:max-w-2xl xl:max-w-3xl':
            variant === 'content',
          'max-w-xl': variant === 'smaller-content',
          'md:max-w-3xl lg:max-w-5xl xl:max-w-6xl': variant === 'layout',
        },
        extraClass
      )}
    >
      {children}
    </div>
  </div>
);

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
