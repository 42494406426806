import { createAction } from 'redux-actions';

const namespace = 'crdf.modal.updateBankAccount';

export const UPDATE_BANK_ACCOUNT = `${namespace}.updateBankAccount`;
export const UPDATE_BANK_ACCOUNT_SUCCESS = `${namespace}.updateBankAccountSuccess`;
export const UPDATE_BANK_ACCOUNT_ERROR = `${namespace}.updateBankAccountError`;

export const updateBankAccount = createAction(UPDATE_BANK_ACCOUNT);
export const updateBankAccountSuccess = createAction(
  UPDATE_BANK_ACCOUNT_SUCCESS
);
export const updateBankAccountError = createAction(UPDATE_BANK_ACCOUNT_ERROR);
