import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import LayoutDesign from '../../components/layout';
import Header from './Header';
import Footer from './Footer';
import InactivityPopup from '../auth/inactivity/InactivityPopup';

export const propTypes = {
  hideHeaderLinks: PropTypes.bool,
  disableLogoLink: PropTypes.bool,
};
const defaultProps = {
  hideHeaderLinks: false,
  disableLogoLink: false,
};

const Layout = ({ hideHeaderLinks, disableLogoLink }) => (
  <LayoutDesign
    header={
      <Header hideLinks={hideHeaderLinks} disableLogoLink={disableLogoLink} />
    }
    footer={<Footer />}
  >
    <InactivityPopup />
    <Outlet />
  </LayoutDesign>
);

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
