import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '../../../../../../components/Grid';
import Item from './item';
import { sortedNolDetailHistorySelector } from '../../../selectors';

const propTypes = {};
const defaultProps = {};

const HistoryCard = () => {
  const historyItems = useSelector(sortedNolDetailHistorySelector);

  return (
    <Grid>
      {historyItems.map((item, index) => (
        <Item
          key={item.uuid}
          message={item.message}
          date={item.dateOfOccurrence}
          isFirst={index === 0}
          isHighlighted={
            item.objectType === 'NOL' && item.typeOfAction === 'CREATED'
          }
        />
      ))}
    </Grid>
  );
};

HistoryCard.propTypes = propTypes;
HistoryCard.defaultProps = defaultProps;

export default HistoryCard;
