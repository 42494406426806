import React, { useState, Children, useEffect } from 'react';
import PropTypes from 'prop-types';

import TabLabel from './Label';
import Container from '../layout/Container';
import TabItem from './Item/item';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  defaultTab: PropTypes.number,
};
const defaultProps = {
  defaultTab: 0,
};

const Faqs = ({ children, defaultTab }) => {
  const [activeIndex, setActiveIndex] = useState(defaultTab);

  useEffect(() => {
    Children.forEach(children, (child) => {
      if (child.type.name !== TabItem.name)
        throw new Error(
          'Tabs component children must be an instance of TabItem'
        );
    });
  }, [children]);

  return (
    <>
      <Container>
        <div className="flex justify-center gap-2">
          {Children.map(children, (child, index) => (
            <TabLabel
              key={index}
              isActive={activeIndex === index}
              label={child.props.label}
              onSelect={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </Container>
      <Container bgColor="gray">{children[activeIndex]}</Container>
    </>
  );
};

Faqs.propTypes = propTypes;
Faqs.defaultProps = defaultProps;

export default Faqs;
