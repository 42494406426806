import { concat, converge, curryN, path, propEq } from 'ramda';

import runValidations, {
  isRequired,
  hasLengthMax,
  isEmail,
  hasOnlyDigits,
  hasAgeInInterval,
  hasLength,
  isTrimmedBothSides,
  isBirthNumberValid,
} from '../../forms/validators';

const extractBirthNumber = converge(concat, [
  path(['birthNumber', 'number']),
  path(['birthNumber', 'suffix']),
]);

const validations = {
  firstName: {
    validate: [isRequired(), hasLengthMax(100), isTrimmedBothSides()],
  },
  lastName: {
    validate: [isRequired(), hasLengthMax(100), isTrimmedBothSides()],
  },
  email: {
    validate: [isRequired(), isEmail(), hasLengthMax(100)],
  },
  businessId: {
    when: propEq('isSelfEmployed', true),
    validate: [isRequired(), hasOnlyDigits(), hasLength(8)],
  },
  birthDate: {
    validate: [isRequired(), hasAgeInInterval(18, 110)],
  },
  'birthNumber.number': {
    when: propEq('foreigner', false),
    validate: [
      isRequired(),
      hasOnlyDigits(),
      hasLengthMax(6),
      isBirthNumberValid(extractBirthNumber),
    ],
  },
  'birthNumber.suffix': {
    when: propEq('foreigner', false),
    validate: [isRequired(), hasOnlyDigits(), hasLengthMax(4)],
  },
  nationality: {
    validate: [isRequired()],
  },
};

export default curryN(2, (deps, values) =>
  runValidations(validations, deps, values)
);
